import { Icon, IconProps } from "@chakra-ui/react"

export default function ShareIcon(props: IconProps) {
  return (
    <Icon
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.10993 11.6502L11.6899 8.06015M5.39993 4.32015L13.8899 1.49015C17.6999 0.220154 19.7699 2.30015 18.5099 6.11015L15.6799 14.6002C13.7799 20.3102 10.6599 20.3102 8.75993 14.6002L7.91993 12.0802L5.39993 11.2402C-0.310068 9.34015 -0.310068 6.23015 5.39993 4.32015Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
