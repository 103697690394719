import { Box, BoxProps, Button, Flex, Image, Text } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import storiesIllustration from "../../assets/images/character-actions/community-action.png"

export function CommunityActionBlock({
  disableClick = false,
  ...props
}: {
  disableClick?: boolean
} & BoxProps) {
  const navigate = useNavigate()
  return (
    <Flex
      bg="secondary.500"
      borderRadius="20px"
      minH="168px"
      mt="0.5rem"
      p="1rem"
      position="relative"
      {...props}
    >
      <Box placeSelf="center" w="60%" textAlign="left">
        <Text variant="solid" size="md" fontWeight="bold">
          Discover new Mini creatures
        </Text>
        <Text variant="solid" size="xs">
          Check our community page
        </Text>
        <Button
          onClick={disableClick ? undefined : () => navigate("/community")}
          mt="1em"
          variant="white"
          size="sm"
        >
          Go to the MiniVerse
        </Button>
      </Box>
      <Image
        position="absolute"
        src={storiesIllustration}
        h={["160px", "180px"]}
        top="-10px"
        right="-10px"
      />
    </Flex>
  )
}
