import UserStoryPackDao from "../daos/UserStoryPackDao"
import { UserStoryPack } from "../data/models/UserStoryPack"

export default class UserStoryPackService {
  static async list(userId: string): Promise<UserStoryPack[]> {
    return await UserStoryPackDao.getAll(userId)
  }

  static async hasStoryPack(
    userId: string,
    storyPackId: string
  ): Promise<boolean> {
    return await UserStoryPackDao.getAll(userId).then((userStoryPacks) => {
      return userStoryPacks.some(
        (userStoryPack) => userStoryPack.storyPackId === storyPackId
      )
    })
  }

  static async get(
    userId: string,
    userStoryPackId: string
  ): Promise<UserStoryPack> {
    return await UserStoryPackDao.get(userId, userStoryPackId)
  }
}
