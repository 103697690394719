import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text
} from "@chakra-ui/react"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useSegment } from "../../../context/SegmentContext"
import { useUserContext } from "../../../context/userContext"
import { useQuery } from "../../../utilities/hooks"
import PasswordInput from "../../components/Input/PasswordInput"
import AuthContainer from "../../components/Wrapper/AuthContainer"

export default function Login() {
  const [email, setEmail] = useState("")
  const [pass, setPass] = useState("")
  const { track } = useSegment()
  const query = useQuery()

  const { signInUser, forgotPassword, authError, setAuthError, isLoadingUser } =
    useUserContext()

  const emailUpdateHandler: React.ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setEmail(target.value)
  }
  const passUpdateHandler: React.ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setPass(target.value)
  }
  const forgotPasswordHandler = () => {
    if (email) {
      forgotPassword(email).then(() => {
        window.alert("We sent you an email to reset your password")
      })
    } else {
      setAuthError("Please enter a valid email")
    }
  }
  const onSubmit: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault()
    if (email && pass) {
      signInUser(email, pass).then((success) => {
        if (success) {
          const redirect = query.get("redirectTo")
          if (redirect) {
            navigate(redirect)
          } else {
            navigate("/app")
          }
          track("Login", { email })
        }
      })
    } else {
      setAuthError("Invalid email or password")
    }
  }

  const navigate = useNavigate()

  return (
    <AuthContainer>
      <FormControl display={"flex"} flexDirection={"column"} p="1em">
        <Box>
          <FormLabel width="100%">
            <Text fontSize="12px" color="secondary.500" fontWeight="bold">
              Enter your email
            </Text>
            <Input
              textAlign="center"
              border="solid 1px #dbdbdb"
              borderRadius={100}
              id="email"
              type="text"
              placeholder="Email"
              onChange={emailUpdateHandler}
              _placeholder={{
                color: "#666",
              }}
            />
          </FormLabel>
          <FormLabel width="100%">
            <Text fontSize="12px" color="secondary.500" fontWeight="bold">
              Enter your password
            </Text>
            <PasswordInput
              textAlign="center"
              border="solid 1px #dbdbdb"
              borderRadius={100}
              placeholder="Password"
              onChange={passUpdateHandler}
              _placeholder={{
                color: "#666",
              }}
            />
          </FormLabel>
          <Button
            float="right"
            fontWeight="regular"
            size="xs"
            padding="0"
            onClick={forgotPasswordHandler}
            mb="1rem"
            variant="link"
            color="secondary.500"
            textDecor="underline"
          >
            Forgot password?
          </Button>
        </Box>

        {authError && (
          <Text
            size="xs"
            variant="error"
            textAlign="center"
            fontWeight="bold"
            mb="1rem"
          >
            {authError}
          </Text>
        )}
        <Button
          type="submit"
          onClick={onSubmit}
          isLoading={isLoadingUser}
          loadingText="Logging you in..."
          variant="fullSecondary"
        >
          Login
        </Button>

        <Button
          variant="link"
          color="secondary.500"
          onClick={() => navigate(`/sign-up${window.location.search}`)}
          mt="1rem"
          textDecor="underline"
        >
          First time here? Create account
        </Button>
      </FormControl>
    </AuthContainer>
  )
}
