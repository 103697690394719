import { onAuthStateChanged } from "firebase/auth"
import React, { createContext, useContext, useEffect, useState } from "react"
import { auth } from "../data/apis/firebase/Firebase"
import Creation from "../data/models/Creation"
import UserData from "../data/models/UserData"
import CreationService from "../service/CreationService"
import CreditService from "../service/CreditService"
import UserService from "../service/UserService"
import { Pronouns } from "../utilities/dateUtils"
import { useSegment } from "./SegmentContext"

export interface UserContextType {
  userLoaded: boolean
  user: UserData | null
  isLoadingUser: boolean
  isLoading: boolean
  userCreations: Creation[]
  authError: string
  setAuthError: (str: string) => any
  openBuyCredits: boolean
  signInUser: (email: string, password: string) => Promise<boolean>
  registerUser: (email: string, password: string) => Promise<boolean>
  setUserInfo: (childName: string, pronouns: Pronouns) => Promise<void>
  logoutUser: () => Promise<void>
  forgotPassword: (email: string) => Promise<void>
  fetchCreations: () => Promise<void>
  fetchUserCredits: () => Promise<void>
  setCredits: (credits: number) => void
  setOnboardingDone: () => Promise<void>
  setGiftOpen: (creation: Creation) => Promise<void>
  setSelectedImage: (creation: Creation, selectedImage: string) => Promise<void>
  setOpenBuyCredits: (openBuyCredits: boolean) => void
  updatePronouns: (pronouns: Pronouns) => Promise<void>
  addFreeCredit: () => Promise<void>
  dismissDailyReward: () => void
  showSSOLogin: boolean
  setShowSSOLogin: (show: boolean) => void
}

export const UserContext = createContext<UserContextType | null>({
  userLoaded: false,
  user: null,
  userCreations: [],
  isLoadingUser: false,
  isLoading: false,
  authError: "",
  setAuthError: () => {},
  openBuyCredits: false,
  signInUser: async () => false,
  registerUser: async () => false,
  logoutUser: async () => {},
  forgotPassword: async () => {},
  fetchCreations: async () => {},
  fetchUserCredits: async () => {},
  setOnboardingDone: async () => {},
  setOpenBuyCredits: () => {},
  setSelectedImage: async () => {},
  setGiftOpen: async () => {},
  updatePronouns: async () => {},
  addFreeCredit: async () => {},
  setCredits: () => {},
  dismissDailyReward: () => {},
  setUserInfo: async () => {},
  showSSOLogin: true,
  setShowSSOLogin() {},
})

export const useUserContext = () => {
  return useContext(UserContext) as UserContextType
}

async function fetchCreations(
  user: UserData,
  setLoading: (loading: boolean) => any,
  setCreations: (creations: Creation[]) => any
) {
  setLoading(true)
  await UserService.getCreations(user)
    .then((c) => {
      setCreations(
        c.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
      )
    })
    .finally(() => setLoading(false))
}

export const UserContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [creations, setCreations] = useState([] as Creation[])
  const [user, setUser] = useState(null as UserData | null)
  const [openBuyCredits, setOpenBuyCredits] = useState(false)
  const [isLoadingUser, setIsLoadingUser] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [authError, setAuthError] = useState("")
  const [userLoaded, setUserLoaded] = useState(false)
  const [showSSO, setShowSSO] = useState(true)
  const { track } = useSegment()

  useEffect(() => {
    let unsubCreations = () => {}
    const unsubscribe = onAuthStateChanged(auth, async (res) => {
      if (res) {
        setIsLoadingUser(true)
        UserService.getUser(res)
          .then(async (u) => {
            setUser(u)
            unsubCreations = UserService.subscribeCreations(u, (c) => setCreations(c.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())))
            await fetchCreations(u, setIsLoading, setCreations)
          })
          .finally(() => {
            setIsLoadingUser(false)
            setUserLoaded(true)
          })
      } else {
        UserService.resetDismissDailyReward()
        setUser(null)
        setIsLoadingUser(false)
        setUserLoaded(true)
        setCreations([])
        unsubCreations()
      }
      setAuthError("")
    })
    return () => {
      unsubCreations()
      unsubscribe()
    }
  }, [])

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     for (const creation of creations) {
  //       let now = new Date().getTime()
  //       if (
  //         (creation.generativeAiResults?.length ?? 0) >= 4 ||
  //         creation.slackNotification ||
  //         now - creation.createdAt.getTime() < 300_000 // 5 minutes
  //       )
  //         continue
  //       CreationService.sendSlackNotification(creation)
  //     }
  //   }, 30_000) // 30 seconds
  //   return () => clearInterval(interval)
  // }, [creations])

  // Translate errors to nice messages
  useEffect(() => {
    if (authError) {
      switch (authError) {
        case "auth/invalid-email":
          setAuthError("Invalid email")
          break
        case "auth/wrong-password":
          setAuthError("Wrong password")
          break
        case "auth/user-not-found":
          setAuthError("User not found")
          break
        case "auth/too-many-requests":
          setAuthError("Too many requests, try again later")
          break
        case "auth/weak-password":
          setAuthError("Password is too weak")
          break
        case "auth/email-already-in-use":
          setAuthError("Email already in use")
          break
      }
    }
  }, [authError])

  return (
    <UserContext.Provider
      value={{
        userLoaded: userLoaded,
        user,
        userCreations: creations,
        isLoadingUser,
        isLoading,
        authError,
        setAuthError,
        openBuyCredits,
        setOpenBuyCredits,

        fetchCreations: () =>
          user
            ? fetchCreations(user, setIsLoading, setCreations)
            : new Promise(() => {}),
        signInUser: async (email: string, password: string) => {
          setIsLoadingUser(true)
          return await UserService.authenticateUser(email, password)
            .then(async (u) => {
              setUser(u)
              await fetchCreations(u, setIsLoading, setCreations)
              return true
            })
            .catch((err) => {
              setAuthError(err.code)
              return false
            })
            .finally(() => {
              setIsLoadingUser(false)
            })
        },
        registerUser: async (email: string, password: string) => {
          setIsLoadingUser(true)
          return await UserService.registerUser(email, password)
            .then((res) => {
              setUser(res)
              return true
            })
            .catch((err) => {
              setAuthError(err.code)
              return false
            })
            .finally(() => {
              setIsLoadingUser(false)
            })
        },
        logoutUser: async () => {
          setIsLoading(true)
          await UserService.logout().finally(() => {
            setUser(null)
            setIsLoading(false)
            setCreations([])
          })
        },
        forgotPassword: async (email: string) =>
          await UserService.resetPassword(email),
        fetchUserCredits: async () => {
          // NOTE: disabled because credits are removed
          // if (user === null) return
          // setIsLoading(true)
          // await UserService.updateCredits(user)
          //   .then((newUser) => setUser(newUser))
          //   .finally(() => setIsLoading(false))
        },
        setOnboardingDone: async () => {
          if (user === null) return
          setIsLoading(true)
          track("Onboarding Done")
          await UserService.setOnboardingDone(user.id)
            .then(() => setUser({ ...user, onboarded: true }))
            .finally(() => setIsLoading(false))
        },
        setGiftOpen: async (creation: Creation) => {
          setIsLoading(true)
          await CreationService.setGiftOpen(creation).finally(() =>
            setIsLoading(false)
          )
        },
        setSelectedImage: async (creation: Creation, selectedImage: string) => {
          setIsLoading(true)
          await CreationService.setSelectedImage(
            creation,
            selectedImage
          ).finally(() => setIsLoading(false))
        },
        updatePronouns: async (pronouns: Pronouns) => {
          if (!user) return
          setIsLoading(true)
          await UserService.updatePronouns(user.id, pronouns)
            .then(() => setUser({ ...user, pronouns: pronouns }))
            .finally(() => setIsLoading(false))
        },
        addFreeCredit: async () => {
          if (!user) return
          setIsLoading(true)
          await CreditService.collectDailyFreeCredit(user)
            .then(async () => {
              const newUser = await UserService.getUser(user.fbUser)
              newUser.claimedDailyReward = true
              setUser(newUser)
            })
            .finally(() => setIsLoading(false))
        },
        setCredits(credits) {
          if (!user) return
          setUser({ ...user, credits: user.credits - credits })
        },
        dismissDailyReward() {
          if (!user) return
          UserService.dismissDailyReward()
          setUser({ ...user, claimedDailyReward: true })
        },
        async setUserInfo(childName, pronouns) {
          if (!user) return
          setIsLoadingUser(true)
          await UserService.setUserInfo(user.id, childName, pronouns).finally(
            () => setIsLoadingUser(false)
          )
          setUser({ ...user, childName, pronouns })
        },
        showSSOLogin: showSSO,
        setShowSSOLogin: setShowSSO,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
