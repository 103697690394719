import { Table, TableContainer, Tbody, Td, Text, Tr } from "@chakra-ui/react"
import { useState } from "react"
import { useParams } from "react-router-dom"
import img1 from "../../../../assets/images/character-actions/shop/shirt/shirt-1.png"
import img10 from "../../../../assets/images/character-actions/shop/shirt/shirt-10.png"
import img11 from "../../../../assets/images/character-actions/shop/shirt/shirt-11.png"
import img12 from "../../../../assets/images/character-actions/shop/shirt/shirt-12.png"
import img2 from "../../../../assets/images/character-actions/shop/shirt/shirt-2.png"
import img3 from "../../../../assets/images/character-actions/shop/shirt/shirt-3.png"
import img4 from "../../../../assets/images/character-actions/shop/shirt/shirt-4.png"
import img5 from "../../../../assets/images/character-actions/shop/shirt/shirt-5.png"
import img6 from "../../../../assets/images/character-actions/shop/shirt/shirt-6.png"
import img7 from "../../../../assets/images/character-actions/shop/shirt/shirt-7.png"
import img8 from "../../../../assets/images/character-actions/shop/shirt/shirt-8.png"
import img9 from "../../../../assets/images/character-actions/shop/shirt/shirt-9.png"
import PrintShopCharacterManager from "./PrintShopCharacterManager"
import { StoreSetting } from "./StorePageTemplate"

enum ShirtSize {
  "XS" = "XS",
  "S" = "S",
  "M" = "M",
  "L" = "L",
  "XL" = "XL",
}

const SHIRT_VARIANTS: {
  [key in ShirtSize]: string
} = {
  XS: "44603106099521",
  S: "44603106132289",
  M: "44603106165057",
  L: "44603106197825",
  XL: "44603106230593",
}

const DEFAULT_SHIRT_SETTINGS: StoreSetting = {
  title: "Size",
  values: [ShirtSize.XS, ShirtSize.S, ShirtSize.M, ShirtSize.L, ShirtSize.XL],
  value: ShirtSize.M,
}

export default function CreationPrintTshirt() {
  const { creationId } = useParams()
  const [setting, setSetting] = useState(DEFAULT_SHIRT_SETTINGS)

  return (
    <PrintShopCharacterManager
      variant={SHIRT_VARIANTS[setting.value as ShirtSize]}
      creationId={creationId}
      price={"$24.99"}
      productImgs={[
        img1,
        img2,
        img3,
        img4,
        img5,
        img6,
        img7,
        img8,
        img9,
        img10,
        img11,
        img12,
      ]}
      title="T-SHIRT"
      description={`- Perfect for eco conscious customers
- 100% organic ring-spun combed cotton
- Regular fit`}
      product={`The magic lives inside all of us! Wear the Mini Studio vessel with pride as it is the source of our magic. 

Kids will love wearing our new soft crewneck t-shirts. They’re made of 100% certified organic ring-spun cotton and will make your artwork pop.`}
      settings={[setting]}
      onSettingsChange={(_title, value) => {
        setSetting({
          ...setting,
          value,
        })
      }}
    >
      <Text fontSize="16px" color="#999">
        Size guide
      </Text>
      <TableContainer my="1rem">
        <Table variant="simple" size="sm">
          <Tbody>
            <Tr>
              <Td />
              <Td>
                <b>XS</b>
              </Td>
              <Td>
                <b>S</b>
              </Td>
              <Td>
                <b>M</b>
              </Td>
              <Td>
                <b>L</b>
              </Td>
              <Td>
                <b>XL</b>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <b>A) Length (cm)</b>
              </Td>
              <Td>42</Td>
              <Td>46</Td>
              <Td>51.50</Td>
              <Td>57.50</Td>
              <Td>62.50</Td>
            </Tr>
            <Tr>
              <Td>
                <b>B) Half Chest (cm)</b>
              </Td>
              <Td>33</Td>
              <Td>35</Td>
              <Td>37</Td>
              <Td>41</Td>
              <Td>44</Td>
            </Tr>
            <Tr>
              <Td>
                <b>C) Sleeve Length (cm)</b>
              </Td>
              <Td>105-115</Td>
              <Td>115-130</Td>
              <Td>130-145</Td>
              <Td>145-155</Td>
              <Td>155-165</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <Text fontSize="16px" color="#999">
        Care instructions
      </Text>
      <TableContainer my="1rem">
        <Table size="sm">
          <Tbody>
            <Tr>
              <Td>Wash</Td>
              <Td>Machine, warm, inside out, similar colors</Td>
            </Tr>
            <Tr>
              <Td>Tumble Dry</Td>
              <Td>Low</Td>
            </Tr>
            <Tr>
              <Td>Bleach</Td>
              <Td>Only non-chlorine</Td>
            </Tr>
            <Tr>
              <Td>Dry Clean</Td>
              <Td>Do not dry clean</Td>
            </Tr>
            <Tr>
              <Td>Iron</Td>
              <Td>Do not iron</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </PrintShopCharacterManager>
  )
}
