import { Flex, HStack, Image } from "@chakra-ui/react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import Creation from "../../../data/models/Creation"
import PendingCreationCard from "../../components/Creations/PendingCreationCard"
import ResponsiveContainer from "../../components/Wrapper/ResponsiveContainer"
import ZoomedImage from "../../components/ZoomedImage"

export default function SketchsPresentation({
  creations,
}: {
  creations: Creation[]
}) {
  const [zoomedImageUrl, setZoomedImageUrl] = useState<string>()
  const navigate = useNavigate()

  if (!creations) {
    return null
  }

  return (
    <ResponsiveContainer maxW="800px" p="0!important">
      <ZoomedImage
        isOpen={!!zoomedImageUrl}
        onClose={() => setZoomedImageUrl(undefined)}
        src={zoomedImageUrl || ""}
      />
      <Flex
        justifyContent={"center"}
        justifyItems={"center"}
        mt={["2rem", "2.5rem", "5rem", "4rem"]} // I did my best
        borderRadius="8px"
        overflowX={"auto"}
        gap="1em"
      >
        <HStack
          minWidth={"250px"}
          minH={"420px"}
          position={"relative"}
          pb="0.5rem"
        >
          {creations.map((creation, i) => {
            if (creation.selectedImage) {
              return (
                <Image
                  key={creation.id}
                  src={creation.selectedImage || ""}
                  w={"250px"}
                  h={"420px"}
                  objectFit="cover"
                  borderRadius="8px"
                  cursor="pointer"
                  onClick={() => {
                    if (
                      creation.selectedImage != null ||
                      creation.originalImagePath != null
                    )
                      setZoomedImageUrl(creation.selectedImage!)
                  }}
                />
              )
            }
            return (
              <PendingCreationCard
                ready={creation.generativeAiResults?.length === 4}
                key={creation.id}
                creation={creation}
                onClick={() => {
                  navigate(`/creations/${creation.id}`)
                }}
                w={"250px"}
                h={"420px"}
              />
            )
          })}
        </HStack>
      </Flex>
    </ResponsiveContainer>
  )
}
