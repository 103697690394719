import { Icon, IconProps } from "@chakra-ui/react"

export default function TwitterIcon(props: IconProps) {
  return (
    <Icon
      width="25"
      height="20"
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.0942 2.55917C23.2117 2.95026 22.2635 3.2145 21.2668 3.33394C22.2952 2.71856 23.0647 1.75001 23.4315 0.609025C22.4653 1.18296 21.4077 1.58695 20.3049 1.80342C19.5633 1.0116 18.581 0.486759 17.5106 0.310398C16.4401 0.134037 15.3414 0.316019 14.385 0.828089C13.4286 1.34016 12.6679 2.15367 12.2212 3.14232C11.7745 4.13096 11.6667 5.23943 11.9145 6.29563C9.95667 6.19732 8.04135 5.68844 6.29287 4.802C4.5444 3.91556 3.00185 2.67138 1.76534 1.1502C1.34254 1.87953 1.09944 2.72512 1.09944 3.62567C1.09896 4.43638 1.29861 5.23467 1.68065 5.94971C2.0627 6.66475 2.61533 7.27444 3.28952 7.72468C2.50764 7.6998 1.74301 7.48853 1.05927 7.10845V7.17187C1.05919 8.30892 1.4525 9.41097 2.17247 10.291C2.89244 11.1711 3.89472 11.775 5.00924 12.0002C4.28392 12.1965 3.52347 12.2254 2.78533 12.0848C3.09978 13.0631 3.71231 13.9187 4.53715 14.5316C5.362 15.1446 6.35787 15.4842 7.38535 15.5031C5.64114 16.8723 3.48705 17.615 1.26961 17.6118C0.876813 17.6119 0.484348 17.5889 0.0942383 17.5431C2.34507 18.9903 4.9652 19.7583 7.64114 19.7553C16.6995 19.7553 21.6515 12.2528 21.6515 5.74599C21.6515 5.5346 21.6463 5.32108 21.6367 5.10969C22.6 4.4131 23.4314 3.55052 24.0921 2.56234L24.0942 2.55917Z"
        fill="currentColor"
      />
    </Icon>
  )
}
