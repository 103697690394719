import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useSegment } from "../../context/SegmentContext"
import { useUserContext } from "../../context/userContext"
import UserData from "../../data/models/UserData"
import LoadingScreen from "./LoadingScreen"

const ProtectedRoute = (props: {
  children: JSX.Element
  redirectionManager: (user: UserData | null) => string | null
}) => {
  const { user, userLoaded } = useUserContext()
  const navigate = useNavigate()
  const { page } = useSegment()
  const location = useLocation()

  useEffect(() => {
    page(location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    if (userLoaded) {
      const redirectTo = props.redirectionManager(user)
      if (redirectTo !== null) {
        navigate(`${redirectTo}${window.location.search}`, { replace: true })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoaded, location])

  if (!userLoaded) return <LoadingScreen />

  return props.children
}

export default ProtectedRoute
