import { Icon, IconProps } from "@chakra-ui/react"

export function CloudsTopCropped({ ...props }: IconProps) {
  return <CloudsTop viewBox="250 40 250 25" {...props} />
}

export default function CloudsTop({ ...props }: IconProps) {
  return (
    <Icon width="100%" h="0%" viewBox="150 40 500 50" fill="none" color="white" {...props}>
      <g clipPath="url(#clip0_3874_3114)">
        <circle
          cx="131.334"
          cy="-69.1484"
          r="88.6416"
          transform="rotate(173.954 131.334 -69.1484)"
          fill="currentColor"
        />
        <circle
          cx="402.179"
          cy="-16.5442"
          r="56.0215"
          transform="rotate(173.954 402.179 -16.5442)"
          fill="currentColor"
        />
        <circle
          cx="270.448"
          cy="-14.5525"
          r="75.8772"
          transform="rotate(173.954 270.448 -14.5525)"
          fill="currentColor"
        />
        <circle
          cx="141.609"
          cy="34.5887"
          r="52.4758"
          transform="rotate(173.954 141.609 34.5887)"
          fill="currentColor"
        />
        <circle
          cx="208.894"
          cy="25.8933"
          r="39.0023"
          transform="rotate(173.954 208.894 25.8933)"
          fill="currentColor"
        />
        <circle
          cx="90.1697"
          cy="-13.445"
          r="75.8772"
          transform="rotate(173.954 90.1697 -13.445)"
          fill="currentColor"
        />
        <circle
          cx="610.633"
          cy="-14.3783"
          r="85.805"
          transform="rotate(173.954 610.633 -14.3783)"
          fill="currentColor"
        />
        <circle
          cx="691.438"
          cy="-72.8542"
          r="75.8772"
          transform="rotate(173.954 691.438 -72.8542)"
          fill="currentColor"
        />
        <circle
          cx="474.542"
          cy="5.79382"
          r="52.4758"
          transform="rotate(173.954 474.542 5.79382)"
          fill="currentColor"
        />
        <circle
          cx="534.668"
          cy="-2.33241"
          r="55.1638"
          transform="rotate(173.954 534.668 -2.33241)"
          fill="currentColor"
        />
        <circle
          cx="672.059"
          cy="-20.1718"
          r="51.0575"
          transform="rotate(173.954 672.059 -20.1718)"
          fill="currentColor"
        />
        <circle
          cx="339.127"
          cy="0.83116"
          r="51.0575"
          transform="rotate(173.954 339.127 0.83116)"
          fill="currentColor"
        />
        <circle
          cx="381.626"
          cy="4.62559"
          r="42.3964"
          transform="rotate(173.954 381.626 4.62559)"
          fill="currentColor"
        />
        <circle
          cx="417.073"
          cy="27.0731"
          r="26.4357"
          transform="rotate(173.954 417.073 27.0731)"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3874_3114">
          <rect width="782" height="88" fill="currentColor" />
        </clipPath>
      </defs>
    </Icon>
  )
}
