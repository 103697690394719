import { extendTheme } from "@chakra-ui/react"
import { Button } from "./Button"
import { Text } from "./Text"
import { Heading } from "./Heading"
import { FormLabel } from "./FormLabel"
import { Input } from "./Input"

const customTheme = extendTheme({
  fonts: {
    body: "GT Walsheim Pro, sans-serif",
  },
  components: {
    Button,
    Text,
    Heading,
    FormLabel,
    Input,
  },
  colors: {
    primary: {
      50: "#fff5f8",
      100: "#ffebf1",
      200: "#ffccda",
      300: "#f697a6",
      400: "#f06d83",
      500: "#FF4264",
      600: "#f80c4b",
      700: "#e60044",
      800: "#d9003c",
      900: "#ca002f",
    },
    secondary: {
      50: "#eee9fd",
      100: "#d3c9fa",
      200: "#b4a6f9",
      300: "#9380f7",
      400: "#7763f5",
      500: "#5648f2",
      600: "#4843eb",
      700: "#2f3be2",
      800: "#0035db",
      900: "#0029d2",
    },
    wander: "#FAFFF2",
    unlimitedImagination: "#AED3F3",
    grey: "#ccc",
    sparklyMagic: "#f53",
    dreamsAreReal: "#F8D7CE",
    neutral: "#dbdbdb",
  },
})

export default customTheme
