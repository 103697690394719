import { Box, Button, ButtonProps } from "@chakra-ui/react"

export default function Button3d({
  children,
  background = "primary.500",
  bg3dColor = "#AD1730",
  m,
  mx,
  my,
  mt,
  mb,
  ml,
  mr,
  margin,
  marginX,
  marginY,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  ...props
}: { bg3dColor?: string } & ButtonProps) {
  return (
    <Box
      position="relative"
      m={m}
      mx={mx}
      my={my}
      mt={mt}
      mb={mb}
      ml={ml}
      mr={mr}
      margin={margin}
      marginX={marginX}
      marginY={marginY}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      w={props.w}
      width={props.width}
      h={props.h ?? "fit-content"}
      height={props.height}
      maxW={props.maxW}
      maxH={props.maxH}
      minW={props.minW}
      minH={props.minH}
      maxWidth={props.maxWidth}
      maxHeight={props.maxHeight}
      minWidth={props.minWidth}
      minHeight={props.minHeight}
      opacity={props.disabled ? 0.5 : 1}
      transition="200ms"
    >
      <Box
        w="100%"
        h="100%"
        borderRadius="full"
        position="absolute"
        left="0"
        top="5px"
        bg={bg3dColor}
      />
      <Button
        _hover={{
          transform: "translateY(2px)",
        }}
        _active={{
          transform: "translateY(5px)",
        }}
        transition="200ms"
        bg={background}
        variant="unstyled"
        padding={{ xs: 2, sm: 4, md: 6, lg: 8 }[props.size?.toString() ?? "md"]}
        size={props.size}
        color="white"
        display="flex"
        w="100%"
        _disabled={{}}
        {...props}
      >
        {children}
      </Button>
    </Box>
  )
}
