import { Box, Button, Flex, Image, Text } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

import { useUserContext } from "../../../context/userContext"
import phonyDino from "../../assets/images/createPage/PhonyDino.png"
import phonyDinoDrawing from "../../assets/images/createPage/PhonyDinoDrawing.png"

export default function UploadActionBlock() {
  const { userCreations } = useUserContext()
  const navigate = useNavigate()
  return (
    <Flex
      background="primary.500"
      borderRadius="20px"
      padding="1em"
      mt="0.5em"
      minH="168px"
    >
      <Box width="60%" placeSelf="center" textAlign="left">
        <Text variant="solid" size="md" fontWeight="bold">
          Turn your drawings into magic
        </Text>
        <Text variant="solid" size="xs">
          Create awesome characters
        </Text>
        <Button
          onClick={() => navigate("/upload")}
          mt="1em"
          variant="white"
          size="sm"
        >
          Upload drawing
        </Button>
      </Box>
      <Flex
        width="40%"
        placeContent="flex-end"
        placeItems="end"
        position="relative"
      >
        <Image
          src={phonyDinoDrawing}
          borderRadius={5}
          objectFit={"cover"}
          width="80px"
          height="80px"
          position={"absolute"}
          transform={"rotate(18deg)"}
          right="0px"
          top="-20px"
        />
        <Image
          src={phonyDino}
          borderRadius={5}
          objectFit={"cover"}
          width="80px"
          height="80px"
          position={"absolute"}
          right="50px"
          top="40px"
          transform={"rotate(13deg)"}
        />
        <Text variant="solid" size="xs" zIndex={1} mt="auto">
          {"My characters "}
          <b>{userCreations.length}</b>
        </Text>
      </Flex>
    </Flex>
  )
}
