import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react"
import { Player } from "@lottiefiles/react-lottie-player"
import { useNavigate, useParams } from "react-router-dom"
import { useUserContext } from "../../../../context/userContext"
import { useQuery } from "../../../../utilities/hooks"
import BackButton from "../../../components/Buttons/BackButton"
import Background from "../../../components/Wrapper/Background"
import ResponsiveContainer from "../../../components/Wrapper/ResponsiveContainer"

export default function WebtoonFail() {
  const { user } = useUserContext()
  const navigate = useNavigate()
  const { storyId } = useParams()
  const query = useQuery()
  const creationId = query.get("creationId")

  let redirectTo =
    "/settings/payment?redirectTo=" +
    encodeURIComponent(`/create-webtoon/${storyId}`)
  if (creationId) redirectTo += encodeURIComponent(`?creationId=${creationId}`)

  return (
    <Background overflowY="auto">
      <ResponsiveContainer
        pb={["2em", "2em", "2em"]}
        display="flex"
        flexDir="column"
      >
        <Flex>
          <BackButton
            color="black"
            onClick={() => navigate("/stories")}
            mr="auto"
          />
          {/* <CreditsCount availableCredits={user?.credits ?? 0} /> */}
        </Flex>
        <Flex
          flexDir="column"
          alignItems="center"
          flexGrow={1}
          textAlign="center"
        >
          <Box w="400px" maxW="100%">
            <Player
              src={
                "https://lottie.host/71073cbb-992f-47ad-9fe0-07e51705e9a5/EgkMHWZGaa.json"
              }
              keepLastFrame
              autoplay
            />
          </Box>
          <Heading color="orange" mb="1rem" size="sm" maxW="340px">
            You are {15 - (user?.credits ?? 0)} star(s) away from being able to
            create a story
          </Heading>
          <Text size="sm" textAlign="center" mb="1rem">
            Creating a story requires 15 stars, but you only have{" "}
            {user?.credits ?? 0} star(s).
            <br />
            Do you want to buy more stars ?
          </Text>
          <Flex gap="1rem" m="auto" flexDir="column">
            <Button
              variant="fullSecondary"
              onClick={() => navigate(redirectTo)}
            >
              Purchase stars
            </Button>
            <Button
              variant="border"
              color="secondary.500"
              borderColor="secondary.500"
              onClick={() => navigate(-1)}
            >
              Not now
            </Button>
          </Flex>
        </Flex>
      </ResponsiveContainer>
    </Background>
  )
}
