import { CloseIcon } from "@chakra-ui/icons"
import { Box, Flex, Heading } from "@chakra-ui/react"
import { useEffect, useMemo, useState } from "react"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { useSegment } from "../../../../context/SegmentContext"
import { useUserContext } from "../../../../context/userContext"
import Story from "../../../../data/models/Story"
import { Webtoon } from "../../../../data/models/Webtoon"
import StoryService from "../../../../service/StoryService"
import WebtoonService from "../../../../service/WebtoonService"
import BackButton from "../../../components/Buttons/BackButton"
import { STORY_PACK_ID } from "../../../components/Creations/GlobalSettings"
import Background from "../../../components/Wrapper/Background"
import ResponsiveContainer from "../../../components/Wrapper/ResponsiveContainer"
import WebtoonCoverPage from "./WebtoonCoverPage"
import "./WebtoonPage.css"
import UserService from "../../../../service/UserService"

export default function WebtoonPage() {
  const { webtoonId, userStoryPackId } = useParams()
  const [webtoon, setWebtoon] = useState(null as Webtoon | null)
  const [story, setStory] = useState(null as Story | null)
  const { user, userCreations } = useUserContext()
  const { track } = useSegment()
  const navigate = useNavigate()
  const creation = useMemo(() => {
    return userCreations.find((c) => c.id === webtoon?.creationId)
  }, [userCreations, webtoon])

  useEffect(() => {
    if (userStoryPackId && webtoonId && user) {
      WebtoonService.get(user.id, webtoonId, userStoryPackId)
        .then((webtoon) => {
          if (webtoon) {
            setWebtoon(webtoon)
            StoryService.getStory(STORY_PACK_ID, webtoon.storyId).then((s) =>
              setStory(s)
            )
          } else navigate("/app", { replace: true })
        })
        .catch(() => navigate("/app", { replace: true }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webtoonId, user])

  if (!webtoonId || !userStoryPackId)
    return <Navigate replace to="/app" />
  if (!webtoon || !story) return <Background />

  return (
    <ResponsiveContainer maxW="500px" overflow="hidden">
      <WebtoonCoverPage
        story={story}
        creation={creation}
        onContinue={() => {
          navigate(
            `/creations/webtoon/${userStoryPackId}/${webtoonId}/${
              UserService.getStoryPosition(story.id) || 0
            }`
          )
          if (UserService.getStoryPosition(story.id) === 0) {
            track("Start the adventure", { story: story.title })
          } else {
            track("Continue the adventure", { story: story.title })
          }
        }}
      />

      <Box
        position="fixed"
        w="500px"
        maxW="100%"
        left="50%"
        top="0"
        transform={"translateX(-50%)"}
        zIndex={100}
      >
        <BackButton
          w="30px"
          h="30px"
          icon={<CloseIcon h="50%" w="50%" m="auto" />}
          buttonBg
          color="white"
          position="absolute"
          top="1rem"
          left="1rem"
        />
      </Box>
      <Flex py="2rem">
        <Heading mx="auto">{story?.title}</Heading>
      </Flex>
    </ResponsiveContainer>
  )
}
