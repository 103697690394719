import { Box, BoxProps, Button, Flex, Image, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import Creation from "../../../data/models/Creation"
import {
  drawCanvasBackground,
  drawCanvasCircleImage,
  drawCanvasImage,
} from "../../../utilities/canvasUtils"
import { getAgeString } from "../../../utilities/dateUtils"
import { createCanvas } from "canvas"
import arrow from "../../assets/images/Arrow.png"
import logo from "../../assets/images/brandLogo/logo-made-with.png"
import { isMobile } from "react-device-detect"
import ShareIcon from "../Icons/ShareIcon"
import { useSegment } from "../../../context/SegmentContext"

export default function CharacterNameTag({
  creation,
  showSelectedImage = false,
  onClick,
  showShareButton = false,
  ...props
}: {
  creation: Creation
  showSelectedImage?: boolean
  showShareButton?: boolean
} & BoxProps) {
  const [imageToShare, setImage] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { track } = useSegment()

  useEffect(() => {
    ;(async () => {
      const canvas = createCanvas(1080, 1080, "svg")
      drawCanvasBackground(canvas.getContext("2d") as any, "#F8D7CE")

      await drawCanvasImage(
        canvas.getContext("2d") as any,
        creation.selectedImage!,
        0,
        0,
        1080,
        1080
      )
      await drawCanvasImage(
        canvas.getContext("2d") as any,
        logo,
        700,
        960,
        300,
        72
      )

      await drawCanvasImage(
        canvas.getContext("2d") as any,
        arrow,
        320,
        700,
        280,
        300
      )
      if (creation.originalImagePath) {
        await drawCanvasCircleImage(
          canvas.getContext("2d") as any,
          creation.originalImagePath,
          180,
          890,
          165
        )
      }
      setImage(canvas.toDataURL())
    })()
  }, [creation])

  async function shareImage() {
    if (imageToShare) {
      track("Share", { creationId: creation.id })
      setIsLoading(true)
      if (imageToShare) {
        fetch(imageToShare).then((res) =>
          res.blob().then((blob) => {
            const filesArray = [
              new File([blob], creation.title + ".png", {
                type: "image/png",
                lastModified: new Date().getTime(),
              }),
            ]
            const shareData = {
              files: filesArray,
            }
            navigator
              .share(shareData as ShareData)
              .finally(() => setIsLoading(false))
          })
        )
      }
    }
  }

  return (
    <Box
      position="relative"
      mt="1em"
      p="1em"
      px="100px"
      bg="primary.100"
      border="solid 1px"
      borderColor="primary.500"
      borderRadius="20px"
      {...props}
    >
      {creation.originalImagePath && (
        <Image
          src={
            showSelectedImage
              ? creation.selectedImage
              : creation.originalImagePath
          }
          width="80px"
          height="80px"
          objectFit="cover"
          position="absolute"
          borderRadius="40px"
          left="1rem"
          top="-1rem"
        />
      )}
      <Text
        m="auto"
        textAlign="center"
        size="sm"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
      >
        {creation.title}
      </Text>
      <Text width="100%" textAlign="center" size="sm">
        Age: {getAgeString(creation.createdAt)}
      </Text>
      <Box
        position={"absolute"}
        w="100%"
        h="100%"
        top={0}
        left={0}
        onClick={onClick}
      />
      {showShareButton && isMobile && (
        <Flex>
          <Button
            onClick={shareImage}
            mt="1em"
            variant="white"
            size="sm"
            isLoading={isLoading}
            loadingText="Sharing..."
            mx="auto"
          >
            <ShareIcon mr="0.25rem" />
            Share
          </Button>
        </Flex>
      )}
    </Box>
  )
}
