import { Box, Button, HStack } from "@chakra-ui/react"
import { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useSegment } from "../../../../context/SegmentContext"
import { useUserContext } from "../../../../context/userContext"
import Creation from "../../../../data/models/Creation"
import Story from "../../../../data/models/Story"
import { Webtoon, WebtoonPanel } from "../../../../data/models/Webtoon"
import StoryService from "../../../../service/StoryService"
import UserService from "../../../../service/UserService"
import WebtoonService from "../../../../service/WebtoonService"
import {
  JOURNEY_SIZE,
  STORY_PACK_ID,
} from "../../../components/Creations/GlobalSettings"
import LoadingScreen from "../../../components/LoadingScreen"
import ResponsiveContainer from "../../../components/Wrapper/ResponsiveContainer"
import WebtoonEnd from "./WebtoonEnd"
import "./WebtoonPage.css"
import WebtoonPanelElement from "./WebtoonPanel"
import BackButton from "../../../components/Buttons/BackButton"
import { CloseIcon } from "@chakra-ui/icons"
import Background from "../../../components/Wrapper/Background"

function getCurrentJourneySize(panels: WebtoonPanel[], position: number) {
  if (position + JOURNEY_SIZE >= panels.length) {
    return panels.length - position
  }
  let size = JOURNEY_SIZE
  while (panels[position + size - 1].canBeLastPanel === false) {
    size++
  }
  return size
}

function getPreviousJourneySize(panels: WebtoonPanel[], position: number) {
  let size = 0
  let pos = 0

  do {
    pos += size
    size = getCurrentJourneySize(panels, pos)
  } while (pos + size < position)
  return size
}

export default function WebtoonPanelPage() {
  const [currentJourney, setCurrentJourney] = useState<WebtoonPanel[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const { webtoonId, userStoryPackId, panelPosition } = useParams()
  const [webtoon, setWebtoon] = useState(null as Webtoon | null)
  const [story, setStory] = useState(null as Story | null)

  const { user, userCreations } = useUserContext()
  const navigate = useNavigate()
  const { track } = useSegment()

  const creation = useMemo<Creation | undefined>(() => {
    return userCreations.find((c) => c.id === webtoon?.creationId)
  }, [userCreations, webtoon])

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 700)
    document.getElementById("background")?.scrollTo(0, 0)
  }, [panelPosition])

  useEffect(() => {
    if (userStoryPackId && webtoonId && user) {
      WebtoonService.get(user.id, webtoonId, userStoryPackId)
        .then((webtoon) => {
          if (webtoon) {
            setWebtoon(webtoon)
            StoryService.getStory(STORY_PACK_ID, webtoon.storyId).then(
              (story) => {
                setStory(story)
              }
            )
            updateJourney()
          } else navigate("/app", { replace: true })
        })
        .catch(() => navigate("/app", { replace: true }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webtoonId, user])

  useEffect(() => {
    if (webtoon && panelPosition) {
      updateJourney()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webtoon, panelPosition])

  function updateJourney(): void {
    if (webtoon && panelPosition) {
      setCurrentJourney(
        webtoon.panels.slice(
          parseInt(panelPosition) + 0,
          parseInt(panelPosition) +
            getCurrentJourneySize(webtoon.panels, parseInt(panelPosition))
        )
      )
    }
  }

  function nextJourney() {
    if (!webtoon) return
    setIsLoading(true)
    if (panelPosition) {
      const nextPosition =
        parseInt(panelPosition) +
        getCurrentJourneySize(webtoon.panels, parseInt(panelPosition))
      UserService.setStoryPosition(
        webtoon.storyId,
        nextPosition,
        nextPosition + getCurrentJourneySize(webtoon.panels, nextPosition) >=
          webtoon.panels.length
      )
      track("Next Panels", {
        webtoonId: webtoonId || "",
        webtoonTitle: story?.title || "",
        panelNumber: nextPosition,
      })
      navigate(
        `/creations/webtoon/${userStoryPackId}/${webtoonId}/${nextPosition}`
      )
    }
  }
  function previousJourney() {
    if (!webtoon) return
    setIsLoading(true)
    if (panelPosition) {
      const previousPosition =
        parseInt(panelPosition) -
        getPreviousJourneySize(webtoon.panels, parseInt(panelPosition))
      UserService.setStoryPosition(webtoon.storyId, previousPosition, false)
      track("Previous Panels", {
        webtoonId: webtoonId || "",
        webtoonTitle: story?.title || "",
        panelNumber: previousPosition,
      })
      navigate(
        `/creations/webtoon/${userStoryPackId}/${webtoonId}/${previousPosition}`
      )
    }
  }

  const showEnd = useMemo(() => {
    if (!webtoon || !panelPosition) return false
    return (
      getCurrentJourneySize(webtoon.panels, parseInt(panelPosition)) +
        parseInt(panelPosition) >=
      webtoon.panels.length
    )
  }, [webtoon, panelPosition])

  return (
    <Background overflow="auto">
      <ResponsiveContainer maxW="500px" overflow="hidden">
        <Box
          position="fixed"
          w="500px"
          maxW="100%"
          left="50%"
          top="0"
          transform={"translateX(-50%)"}
          zIndex={100}
        >
          <BackButton
            w="30px"
            h="30px"
            icon={<CloseIcon h="50%" w="50%" m="auto" />}
            buttonBg
            color="white"
            position="absolute"
            top="1rem"
            left="1rem"
            onClick={() => navigate("/app")}
          />
        </Box>
        {isLoading && <LoadingScreen />}
        {currentJourney.map((panel, i) => (
          <WebtoonPanelElement
            className="webtoon-panel"
            key={i}
            panel={panel}
          />
        ))}
        <HStack
          marginTop={"20px"}
          width={"100%"}
          justifyContent={"space-between"}
        >
          {panelPosition && parseInt(panelPosition) > 0 ? (
            <Button onClick={previousJourney}>Previous</Button>
          ) : (
            <Box />
          )}

          {!showEnd ? (
            <Button onClick={nextJourney}>Continue the journey</Button>
          ) : (
            <Box />
          )}
        </HStack>
        {showEnd && (
          <WebtoonEnd creation={creation} storyTitle={story?.title} />
        )}
      </ResponsiveContainer>
    </Background>
  )
}
