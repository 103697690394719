import { Box, Flex, FlexProps } from "@chakra-ui/react"
import { Player } from "@lottiefiles/react-lottie-player"
import FullBackButton from "../../../../components/Buttons/FullBackButton"
import Background from "../../../../components/Wrapper/Background"
import ResponsiveContainer from "../../../../components/Wrapper/ResponsiveContainer"

export default function RewardLayout(props: FlexProps) {
  return (
    <Background overflowY="auto">
      <ResponsiveContainer
        pb={["2em", "2em", "2em"]}
        display="flex"
        flexDir="column"
      >
        <Flex flexDir="column" alignItems="center" flexGrow={1}>
          <Box w="400px" maxW="100%">
            <Player
              src={
                "https://lottie.host/1ee52e01-48c7-441b-a678-cfac2bf2108d/9y7x0hlpGP.json"
              }
              keepLastFrame
              autoplay
            />
          </Box>
          <Flex flexDir="column" mb="auto" {...props} />
          <FullBackButton backTo={"/settings"} />
        </Flex>
      </ResponsiveContainer>
    </Background>
  )
}
