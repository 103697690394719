import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react"
import BackButton from "../../../../components/Buttons/BackButton"
import Carousel from "../../../../components/Sliders/Carousel"
import Navigation from "../../../../components/Wrapper/Navigation"
import { CloseIcon } from "@chakra-ui/icons"

export type StoreSetting = {
  title: string
  values: string[]
  value: string
}

export interface StorePageTemplateProps {
  title: string
  productImgs: string[]
  onAddToCart: () => void
  price: string
  product: string
  description: string
  settings?: StoreSetting[]
  onSettingsChange?: (settingTitle: string, settingValue: string) => void
  children?: React.ReactNode
}

export default function StorePageTemplate({
  title,
  price,
  productImgs,
  product,
  description,
  settings,
  onSettingsChange,
  children,
  onAddToCart,
}: StorePageTemplateProps) {
  return (
    <Navigation
      bg="white"
      backgroundColor="primary.50"
      width={["100%", "100%", "800px"]}
    >
      <Box mx="-1rem" mt="-1rem" position="relative">
        <Carousel
          images={productImgs}
          w="100%"
          h={["auto", "auto", "auto"]}
          maxH={["70vh", "70vh", "70vh"]}
          objectFit="contain"
        />
        <BackButton
          buttonBg
          color="white"
          position="absolute"
          top="1rem"
          left="1rem"
          icon={<CloseIcon h="50%" w="50%" m="auto" />}
        />
        <Box
          bg="white"
          position="absolute"
          w="100%"
          h="20px"
          bottom={0}
          left={0}
          borderRadius="16px 16px 0 0"
        />
      </Box>
      <Heading fontSize="17px" mb="1rem">
        {title}
      </Heading>
      <Flex placeItems="center" mb="1rem1">
        <Text fontWeight="light">{price}</Text>
        <Text fontWeight="light" ml="0.25rem" fontSize="16px">
          + Delivery fees
        </Text>
      </Flex>
      {settings?.map((setting) => (
        <Box mx="-1rem" mt="1rem" key={setting.title}>
          <Heading
            mx="1rem"
            as="h3"
            color="#999"
            fontWeight="normal"
            fontSize="16px"
          >
            {setting.title}
          </Heading>
          <Flex px="1rem" maxW="100%" overflow="auto" pb="0.5rem">
            {setting.values.map((value) => (
              <Button
                key={value}
                variant={
                  value === setting.value
                    ? "fullSecondaryDark"
                    : "outlineSecondaryDark"
                }
                border="solid 1px"
                size="sm"
                mx="0.25rem"
                my="0.5rem"
                onClick={() => onSettingsChange?.(setting.title, value)}
                minW="fit-content"
              >
                {value}
              </Button>
            ))}
          </Flex>
        </Box>
      ))}
      <Button variant="fullSecondary" w="100%" onClick={onAddToCart} mt="1rem">
        Add to cart
      </Button>
      <Box my="2rem" w="100%" h="1px" bg="#AFAFAF" mx="auto" />
      <Text fontSize="16px" color="#999">
        Product
      </Text>
      <Text fontSize="16px" my="1rem" whiteSpace="pre-wrap">
        {product}
      </Text>
      <Text fontSize="16px" color="#999">
        Description
      </Text>
      <Text fontSize="16px" my="1rem" whiteSpace="pre-wrap">
        {description}
      </Text>
      {children}
    </Navigation>
  )
}
