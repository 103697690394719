import { saveAs } from "file-saver"
import JSZip from "jszip"
import Creation from "../data/models/Creation"
import Resizer from "react-image-file-resizer"

export async function downloadCreationImages(creation: Creation) {
  let images = []

  if (creation.originalImagePath) {
    images.push({
      imageUrl: creation.originalImagePath,
      title: `Original ${creation.title}`,
    })
  
    images.push({
      imageUrl: creation.selectedImage ?? "",
      title: `Generated ${creation.title}`,
    })
  }

  downloadImages(
    images,
    `${creation.title}.zip`
  )
}

export async function downloadImages(
  images: { imageUrl: string; title: string }[],
  zipTitle: string = "images.zip"
): Promise<void> {
  const zip = new JSZip()
  for (const img of images) {
    let extension = ""
    const imgBlob = await fetch(img.imageUrl).then(async (res) => {
      const contentType = res.headers.get("content-type")
      if (contentType) {
        const parts = contentType.split("/")
        if (parts.length > 1) {
          extension = parts[parts.length - 1]
        }
      }

      return await res.blob()
    })
    zip.file(`${img.title}.${extension}`, imgBlob)
  }
  await zip.generateAsync({ type: "blob" }).then((content) => {
    saveAs(content, zipTitle)
  })
}

export async function resizeFile(file: Blob): Promise<null | string> {
  if (file instanceof Blob) {
    return await new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        512,
        512,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri as string)
        },
        "base64",
        512,
        512
      )
    })
  }
  return null
}

export function hexColorToRgbString(color: string) {
  const hex = color.replace("#", "")
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgb(${r}, ${g}, ${b})`
}