import axios from "axios"
import WebtoonDAO from "../daos/WebtoonDAO"
import { Webtoon } from "../data/models/Webtoon"
import { API_ENDPOINTS } from "../presentation/components/Creations/GlobalSettings"
import UserData from "../data/models/UserData"

export default class WebtoonService {

  static async get(
    userId: string,
    webtoonId: string,
    userStoryPackId: string
  ): Promise<Webtoon | undefined> {
    return await WebtoonDAO.getWebtoon(userId, webtoonId, userStoryPackId)
  }

  static async getAll(
    userId: string,
    userStoryPackId: string
  ): Promise<Webtoon[]> {
    return await WebtoonDAO.getAll(userId, userStoryPackId)
  }

  static async isStoryCreated(
    userId: string,
    userStoryPackId: string,
    storyId: string
  ): Promise<boolean> {
    const webtoons = await WebtoonDAO.getAll(userId, userStoryPackId)
    return webtoons.some((webtoon) => webtoon.storyId === storyId)
  }

  static async create(
    user: UserData,
    creationId: string,
    storyId: string,
    userStoryPackId: string,
  ): Promise<Webtoon> {
    try {
      const id = await axios
        .post(
          API_ENDPOINTS.WEBTOON_CREATE,
          {
            creationId,
            storyId,
            userStoryPackId,
          },
          {
            headers: {
              Authorization: `Bearer ${await user.fbUser.getIdToken(true)}`,
            },
          }
        )
        .then((res) => res.data.webtoonId)
      return await WebtoonDAO.getWebtoon(user.id, id, userStoryPackId)
    } catch (e) {
      throw new Error("Failed to create webtoon")
    }
  }

  static listenToUserWebtoons(
    userId: string,
    userStoryPackId: string,
    callback: (webtoons: Webtoon[]) => any
  ): () => void {
    return WebtoonDAO.listenToUserWebtoons(userId, userStoryPackId, callback)
  }
}
