import React, { createContext, useContext, useState } from "react"
import { LOCAL_STORAGE_CHAT_HISTORY } from "../presentation/components/Creations/GlobalSettings"

export interface ChatEntry {
  content: string
  role: "user" | "assistant"
  time?: number
  characterId?: string
}

export interface ContextProps {
  chat?: ChatEntry[]
  /** Returns true if session has been loaded */
  loadSession: (creationId: string) => boolean
  addChatEntry: (entry: ChatEntry, session: string) => void
}

const ChatContext = createContext<ContextProps>({
  loadSession: () => false,
  addChatEntry: () => {},
})

export function ChatProvider({ children }: { children: React.ReactNode }) {
  const [chat, setChat] = useState<ChatEntry[]>()

  return (
    <ChatContext.Provider
      value={{
        chat,
        loadSession(creationId) {
          try {
            const savedChat = JSON.parse(
              localStorage.getItem(
                `${LOCAL_STORAGE_CHAT_HISTORY}/${creationId}`
              ) ?? "[]"
            )
            if (savedChat && Array.isArray(savedChat)) {
              setChat(savedChat)
              return true
            }
          } catch {}
          return false
        },
        addChatEntry(entry, session) {
          chat?.push({...entry, time: Date.now()})
          setChat([...(chat ?? [])])
          localStorage.setItem(
            `${LOCAL_STORAGE_CHAT_HISTORY}/${session}`,
            JSON.stringify(chat)
          )
        },
      }}
    >
      {children}
    </ChatContext.Provider>
  )
}

export const useChat = (): ContextProps => {
  return useContext(ChatContext)
}
