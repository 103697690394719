import { Box, Text } from "@chakra-ui/react"
import Story from "../../../../data/models/Story"
import { Webtoon } from "../../../../data/models/Webtoon"
import StoryBookCard from "../../../components/Stories/StoryBookCard"

export default function WebtoonAvailableStories({
  stories,
  webtoons,
  onChoose,
  lightMode = false,
}: {
  stories: Story[]
  webtoons: Webtoon[]
  onChoose: (story: Story) => void
  lightMode?: boolean
}) {
  return (
    <>
      {stories
        .filter(
          (story) =>
            !webtoons.some((w) => w.storyId === story.id) && !story.comingSoon
        )
        .sort((a, b) => a.order - b.order)
        .map((story) => (
          <Box key={story.id} cursor="pointer" onClick={() => onChoose(story!)}>
            <StoryBookCard story={story} lightMode={lightMode} />
            <Text
              size="xs"
              fontWeight="light"
              color={lightMode ? "black" : "white"}
            >
              Create it now!
            </Text>
          </Box>
        ))}
    </>
  )
}
