import { CheckCircleIcon, ChevronRightIcon } from "@chakra-ui/icons"
import { Box, BoxProps, Button, Flex, Heading, Image, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useSegment } from "../../../context/SegmentContext"
import { useUserContext } from "../../../context/userContext"
import { useSketchQuests } from "../../../data/apis/SketchQuestUtils"
import star from "../../assets/images/star.png"
import { CloudsTopCropped } from "../../components/SectionSeparators/CloudsTop"

export interface ThemeCreation {
  creationTitle: string
  creationUrl: string
}

export default function WeeklyPreview(props: BoxProps) {
  const [completion, setCompletion] =
    useState<{ key: string; isCompleted: boolean }[]>()
  const { userCreations } = useUserContext()
  const navigate = useNavigate()
  const { track } = useSegment()

  const { sketchQuests } = useSketchQuests()

  function isCompleted(moduleKey: string) {
    return completion?.find((c) => c.key === moduleKey)?.isCompleted ?? false
  }

  useEffect(() => {
    const tempCompletion: { key: string; isCompleted: boolean }[] = []
    sketchQuests.forEach(async (skethQuest) => {
      const isCompleted =
        userCreations.find((uc) => uc.questId === skethQuest._key) !== undefined
      tempCompletion.push({ key: skethQuest._key, isCompleted })
      setCompletion(tempCompletion)
    })
  }, [userCreations, sketchQuests])

  if (!sketchQuests) return null

  const nbCompleted = completion?.filter((c) => c.isCompleted).length

  return (
    <Box w="100%" h="100%" {...props}>
      {sketchQuests.length > 0 && (
        <Flex mb="0.5rem" alignItems={"center"}>
          <Heading as="h2" fontSize="20px" color="black">
            Sketch Quest
          </Heading>
          <Image src={star} h="20px" my="auto" m="0.5rem" />
          <Text
            fontWeight="light"
            color="black"
            fontSize="16px"
            my="auto"
          >
            {nbCompleted} / {sketchQuests.length}
          </Text>
          <Button
            variant="link"
            color="black"
            textDecor="underline"
            ml="auto"
            fontWeight="light"
            onClick={() => navigate("/weeklyTheme")}
          >
            All Quests
          </Button>
        </Flex>
      )}
      <Box mx="-1rem">
        <Flex overflow="auto" px="1rem" gap="0.5rem" pb="1rem">
          {sketchQuests.map((sketchQuest, i) => (
            <Box
              key={sketchQuest._key}
              w="250px"
              minW="250px"
              minH="250px"
              h="250px"
              onClick={() => {
                navigate(`/weeklyTheme/${sketchQuest._key}`)
                track("Click on a squetch quest", {
                  title: sketchQuest.title || "",
                })
              }}
              cursor="pointer"
              position="relative"
            >
              <Image
                src={sketchQuest.creations[0].creationUrl}
                w={"100%"}
                h={"100%"}
                objectFit="cover"
                borderRadius="8px"
              />
              <Box
                position="absolute"
                top="0"
                height="40px"
                width="100%"
                bg="primary.500"
                borderRadius="8px 8px 0 0"
              />
              <CloudsTopCropped
                height="auto"
                position="absolute"
                top="39px"
                left="0"
                w="100%"
                color="primary.500"
              />
              <Text
                position="absolute"
                width="100%"
                textAlign="center"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="pre"
                top="12px"
                color="white"
                left="0"
                fontWeight="bold"
                px="0.5rem"
                fontSize="16px"
              >
                {sketchQuest.title}
              </Text>
              <Button
                position="absolute"
                bottom="1rem"
                left="50%"
                transform="translateX(-50%)"
                gap="0.25rem"
                variant={"colored"}
                bg={
                  isCompleted(sketchQuest._key)
                    ? "secondary.200"
                    : "primary.500"
                }
              >
                {isCompleted(sketchQuest._key) ? (
                  <>
                    <CheckCircleIcon
                      bg="#35D290"
                      borderRadius="full"
                      color="white"
                    />
                    Completed
                  </>
                ) : (
                  <>
                    <ChevronRightIcon />
                    Start quest
                  </>
                )}
              </Button>
            </Box>
          ))}
        </Flex>
      </Box>
    </Box>
  )
}
