import { useEffect, useState } from "react"
import { useSanity } from "../../context/SanityContext"
import SketchQuestModule from "../models/SketchQuest"
import { IS_PROD } from "../../presentation/components/Creations/GlobalSettings"

export function useSketchQuests() {
  const { sanityClient } = useSanity()
  const [sketchQuests, setSketchQuests] = useState<SketchQuestModule[]>([])
  const [state, setState] = useState<"init" | "loading" | "loaded">("init")

  useEffect(() => {
    const sanityQuery = '*[_type == "weeklyTheme"][0]{modules}'

    setState("loading")
    sanityClient
      .fetch<{ modules: SketchQuestModule[] }>(sanityQuery)
      .then((quests) => {
        setSketchQuests(
          quests.modules.filter((quest) => quest.isVisibleProd || !IS_PROD)
        )
      })
      .finally(() => setState("loaded"))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sanityClient])

  return {
    sketchQuests,
    sketchQuestState: state,
  }
}
