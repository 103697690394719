import Navigation from "../../../../components/Wrapper/Navigation"
import frame from "../../../../assets/images/character-actions/shop/frame/11x17-black-2.jpeg"
import totebag from "../../../../assets/images/character-actions/shop/bag/bag-3.jpeg"
import mug from "../../../../assets/images/character-actions/shop/mug/mug-1.jpeg"
import rug from "../../../../assets/images/character-actions/shop/rug/rug_horizontal.jpeg"
import tShirt from "../../../../assets/images/character-actions/shop/shirt/shirt-11.png"
import { Box, Flex, Heading, SimpleGrid, Text } from "@chakra-ui/react"
import BackButton from "../../../../components/Buttons/BackButton"
import { useNavigate, useParams } from "react-router-dom"

function PrintArtOption({
  title,
  image,
  link,
}: {
  title: string
  image: string
  link: string
}) {
  const navigate = useNavigate()
  return (
    <Box
      bgImage={image}
      bgSize="cover"
      bgPosition="center"
      width="100%"
      height="200px"
      borderRadius="12px"
      position="relative"
      onClick={() => navigate(link)}
      cursor="pointer"
    >
      <Box
        bg="linear-gradient(180deg, rgba(0, 0, 0, 0) 66.9%, rgba(0, 0, 0, 0.8) 109.03%, #000000 126.37%)"
        w="100%"
        h="100%"
        borderRadius="12px"
      />
      <Text
        color="white"
        position="absolute"
        bottom="1rem"
        left="1rem"
        fontWeight="bold"
      >
        {title}
      </Text>
    </Box>
  )
}

export default function CreationPrint() {
  const { creationId } = useParams()
  return (
    <Navigation
      bg="white"
      backgroundColor="primary.50"
      width={["100%", "100%", "800px"]}
    >
      <Flex placeItems="center" justifyContent="space-between">
        <BackButton />
        <Heading fontSize="28px">Print your art!</Heading>
        <Box w="50px" />
      </Flex>
      <Text my="1rem" fontWeight="bold">
        Bring your art home
      </Text>
      <SimpleGrid gap="1rem" flexDir="column" columns={[1, 1, 2]}>
        <PrintArtOption
          image={rug}
          link={`/creations/print/rug/${creationId ?? ""}`}
          title="Rug"
        />
        <PrintArtOption
          image={frame}
          link={`/creations/print/frame/${creationId ?? ""}`}
          title="Frame"
        />
        <PrintArtOption
          image={totebag}
          link={`/creations/print/totebag/${creationId ?? ""}`}
          title="Totebag"
        />
        <PrintArtOption
          image={mug}
          link={`/creations/print/mug/${creationId ?? ""}`}
          title="Mug"
        />
        <PrintArtOption
          image={tShirt}
          link={`/creations/print/t-shirt/${creationId ?? ""}`}
          title="T-shirt"
        />
      </SimpleGrid>
    </Navigation>
  )
}
