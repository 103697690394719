import { defineStyleConfig } from "@chakra-ui/react"

export const Input = defineStyleConfig({
  baseStyle: {
    fontSize: "1rem",
  },
  sizes: {
    md: {
      fontSize: "1.4rem",
      px: 6,
      py: 2,
    },
  },
  variants: {
    textInput: {
      color: "black",
      border: "1px solid black",
    },
  },
  defaultProps: {
    size: "md",
    variant: "textInput",
  },
})
