import {
  Box,
  BoxProps,
  Button,
  Flex,
  Grid,
  Image,
  Text,
} from "@chakra-ui/react"
import { Player } from "@lottiefiles/react-lottie-player"
import { useEffect, useRef, useState } from "react"
import { ChatEntry } from "../../../context/ChatContext"
import { useSegment } from "../../../context/SegmentContext"
import { useTextToSpeech } from "../../../context/TextToSpeechContext"
import { useUserContext } from "../../../context/userContext"
import camcam from "../../assets/images/camcam.png"
import SpeechToTextButton from "../../components/Buttons/SpeechToTextButton"
import CloseIcon from "../../components/Icons/CloseIcon"
import HintIcon from "../../components/Icons/HintIcon"
import SendIcon from "../../components/Icons/SendIcon"
import SpeakerIcon from "../../components/Icons/SpeakerIcon"
import ChatIdeas from "./ChatIdeas"
import "./ChatInput.css"
import CreationService from "../../../service/CreationService"
import { Pronouns } from "../../../utilities/dateUtils"

export function ChatInput({
  text,
  setText,
  onSubmitText,
  inputRef,
  isLoading,
  bg = "rgba(255, 255, 255, 0.9)",
  backdropFilter = "blur(2px)",
  borderRadius = 8,
  isOpenSuggestions,
  setOpenSuggestions,
  placeholder = "Imagine or ask anything...",
  isDisabled = false,
  ...props
}: {
  text: string
  setText: (text: string) => void
  onSubmitText: (text: string) => void
  inputRef: React.RefObject<HTMLTextAreaElement>
  isLoading?: boolean
  isOpenSuggestions: boolean
  setOpenSuggestions: (open: boolean) => void
  borderRadius?: number
  isDisabled?: boolean
} & BoxProps) {
  const [interimResult, setInterimResult] = useState("")
  const [isListening, setIsListening] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(54)

  const sendText = () => {
    setOpenSuggestions(false)
    onSubmitText(text + interimResult)
    setText("")
    setInterimResult("")
  }

  useEffect(() => {
    const h = ref.current?.getBoundingClientRect().height
    // inputRef.current?.setAttribute("style", `height: ${(h ?? 54) + 2}px`)
    setHeight((h ?? 54) + 2)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text])

  return (
    <Flex w="100%" {...props}>
      <Box w="100%" position="relative" zIndex={1}>
        <Box
          overflow="hidden"
          transition={"border-radius 300ms ease-out"}
          position="absolute"
          bottom="0"
          left="0"
          w="100%"
          pointerEvents={isOpenSuggestions ? "auto" : "none"}
        >
          <ChatIdeas
            isOpen={isOpenSuggestions}
            borderRadius={`${borderRadius}px ${borderRadius}px 0 0`}
            onChatSuggestion={(sugg) => {
              setText(
                sugg.possibleValues[
                  Math.floor(Math.random() * sugg.possibleValues.length)
                ]
              )
              inputRef.current?.focus()
            }}
            bg={bg}
            backdropFilter={backdropFilter}
            pt="0.5rem"
            pb={`${height + 28}px`}
            header={
              <Flex alignItems="center" justifyContent="space-between" w="100%">
                <HintIcon
                  color="#6E63F4"
                  width="40px"
                  height="40px"
                  padding="4px"
                  border="solid 1px rgba(0,0,0,0.1)"
                  borderRadius="full"
                />
                <Text fontWeight="bold">Ideas</Text>
                <Button
                  w="fit-content"
                  h="fit-content"
                  p="0"
                  variant="unstyled"
                  color="rgba(0,0,0,0.4)"
                >
                  <CloseIcon
                    width="24px"
                    height="24px"
                    onClick={() => setOpenSuggestions(false)}
                  />
                </Button>
              </Flex>
            }
          />
        </Box>
        <Grid
          gridTemplateColumns="1fr auto"
          borderRadius={`${borderRadius}px ${borderRadius}px 0 0`}
          p="0.5rem 0.5rem 1rem 0.5rem"
        >
          <Box position="relative" w="100%" height={`${height}px`}>
            <textarea
              className="chat-input"
              placeholder={isListening ? "Listening..." : placeholder}
              value={text + interimResult}
              onChange={(e) => {
                setInterimResult("")
                setText(e.target.value)
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !isLoading && e.shiftKey === false) {
                  sendText()
                  e.preventDefault()
                }
              }}
              ref={inputRef}
              style={{
                height: `${height}px`,
                cursor: isDisabled ? "not-allowed" : "auto",
                fontWeight: "bold",
                opacity: isDisabled ? 0.5 : 1,
              }}
              disabled={isDisabled}
            />
            <SpeechToTextButton
              position="absolute"
              right="8px"
              top="50%"
              transform={`translateY(-50%)`}
              bg="transparent"
              variant="unstyled"
              color="primary.500"
              onInterimResult={(interim) =>
                interim.length ? setInterimResult(` ${interim}`) : {}
              }
              onResult={(res) => {
                setText(`${text} ${res}`)
                setInterimResult("")
              }}
              onStartListening={() => setIsListening(true)}
              onStopListening={() => setIsListening(false)}
              isDisabled={isDisabled}
            />
            <Box
              opacity="0"
              position="absolute"
              pointerEvents="none"
              top="0"
              left="0"
              w="100%"
              height="fit-content"
              p="12px 40px 12px 20px"
              ref={ref}
              whiteSpace="pre-wrap"
              border="solid 2px transparent"
            >
              {"." + text + interimResult + "."}
            </Box>
          </Box>
          <Button
            p="0"
            w="54px"
            h="54px"
            border="solid 2px transparent"
            onClick={sendText}
            isLoading={isLoading}
            display={(text + interimResult).length > 0 ? "block" : "none"}
            ml="0.5rem"
          >
            <SendIcon />
          </Button>
        </Grid>
      </Box>
    </Flex>
  )
}

function CamcamBubble({
  content,
  showPlay,
  picture,
  pronouns,
}: {
  content: string
  showPlay: boolean
  picture?: string
  pronouns: Pronouns
}) {
  const { play, stop, loading, playing } = useTextToSpeech()
  const { track } = useSegment()

  return (
    <Flex flexDir="column" gap="8px" position="relative">
      {content.split("\n\n").map((text, i, array) => (
        <Box key={i} position="relative" w="fit-content">
          <Text
            bg={"#EBECF3"}
            color={"#101010"}
            padding={
              showPlay && i === array.length - 1
                ? "0.5rem 2.5rem 0.5rem 1rem"
                : "0.5rem 1rem"
            }
            fontSize="18px"
            borderRadius={i > 0 ? "12px" : "0 12px 12px 12px"}
            ml={"40px"}
            w="fit-content"
            whiteSpace="pre-wrap"
            maxWidth="100%"
            fontWeight="500"
          >
            {text}
          </Text>
          {showPlay && i === array.length - 1 && (
            <Button
              position="absolute"
              variant="white"
              bottom="10px"
              right="10px"
              w="24px"
              h="24px"
              p="0"
              minW="0"
              isLoading={loading[content]}
              onClick={() => {
                if (loading[content]) return
                if (playing[content]) {
                  track("Stop text to speech")
                  stop(content)
                } else {
                  track("Play text to speech")
                  play(content, pronouns)
                }
              }}
            >
              {playing[content] ? (
                <Player
                  src="/lottie/voice.json"
                  autoplay
                  loop
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                />
              ) : (
                <SpeakerIcon />
              )}
            </Button>
          )}
        </Box>
      ))}
      <Image
        src={picture ?? camcam}
        w="56px"
        h="auto"
        position="absolute"
        left="-8px"
        top="-16px"
        filter="drop-shadow(0px 3.16129px 3.16129px rgba(0, 0, 0, 0.25))"
      />
    </Flex>
  )
}

function UserBubble({ content }: { content: string }) {
  return (
    <Flex position="relative" flexDir="column">
      {content.split("\n\n").map((text, i) => (
        <Text
          key={i}
          bg={"secondary.500"}
          color={"#ECECF1"}
          padding="0.5rem 1rem"
          fontSize="18px"
          borderRadius={i > 0 ? "12px" : "12px 0 12px 12px"}
          ml={"auto"}
          w="fit-content"
          whiteSpace="pre-wrap"
          maxWidth="100%"
          fontWeight="500"
        >
          {text}
        </Text>
      ))}
    </Flex>
  )
}

export function ChatBubbles({
  chat,
  showPlay = true,
}: {
  chat: ChatEntry[]
  showPlay?: boolean
}) {
  const { userCreations } = useUserContext()

  return (
    <Flex gap="12px" flexDir="column">
      {chat?.map((entry, i) => {
        if (entry.role === "user")
          return <UserBubble key={i} content={entry.content} />
        const creation = userCreations.find((c) => c.id === entry.characterId)
        return (
          <CamcamBubble
            key={i}
            content={entry.content}
            showPlay={showPlay}
            picture={CreationService.getCroppedPicture(creation)}
            pronouns={creation?.pronouns ?? "they/them"}
          />
        )
      })}
    </Flex>
  )
}

export function LoadingChatAssistant({ creationId }: { creationId?: string }) {
  const [text, setText] = useState(".")

  useEffect(() => {
    const interval = setInterval(() => {
      setText((t) => {
        if (t.length > 2) return "."
        return t + "."
      })
    }, 500)
    return () => clearInterval(interval)
  }, [])

  return (
    <Box mt="8px">
      <ChatBubbles
        chat={[{ content: text, role: "assistant", characterId: creationId }]}
        showPlay={false}
      />
    </Box>
  )
}
