import { Navigate, useParams } from "react-router-dom"
import { useUserContext } from "../../../../context/userContext"
import ChooseCreationPage from "./ChooseCreationPage"
import CreationActions from "./CreationActions"
import { useState } from "react"

export default function CreationPage() {
  const { creationId } = useParams()
  const { userCreations, setGiftOpen, user, setSelectedImage } =
    useUserContext()
  const creation = userCreations.find((creation) => creation.id === creationId)
  const [isFirstTime] = useState(
    creation?.selectedImage === undefined ||
      creation?.selectedImage.length === 0
  )

  if (creation === undefined) {
    return <Navigate to="/app" replace />
  }

  if (
    creation.selectedImage === undefined ||
    creation.selectedImage.length === 0
  ) {
    return (
      <ChooseCreationPage
        creation={creation}
        setGiftOpen={setGiftOpen}
        setSelectedImage={setSelectedImage}
        user={user ?? undefined}
      />
    )
  }
  if (creation.questId != null) {
    return <Navigate to={`/weeklyTheme/${creation.questId}`} replace />
  }
  return <CreationActions showCongrats={isFirstTime} creation={creation} />
}
