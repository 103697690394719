import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Spinner,
  Text,
} from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useUserContext } from "../../../../context/userContext"
import Story from "../../../../data/models/Story"
import { UserStoryPack } from "../../../../data/models/UserStoryPack"
import { Webtoon } from "../../../../data/models/Webtoon"
import StoryService from "../../../../service/StoryService"
import UserStoryPackService from "../../../../service/UserStoryPackService"
import WebtoonService from "../../../../service/WebtoonService"
import { ComicBookActionBlock } from "../../../components/ActionBlocks/ComicBookActionBlock"
import WebtoonAvailableStories from "./WebtoonAvailableStories"
import WebtoonGeneratedElement from "./WebtoonGeneratedElement"
import WebtoonGeneratingElement from "./WebtoonGeneratingElement"
import WebtoonUnavailableStories from "./WebtoonUnavailableStories"
import WebtoonCreatePopup from "./WebtoonCreatePopup"
import UserService from "../../../../service/UserService"
import { JOURNEY_SIZE } from "../../../components/Creations/GlobalSettings"
import star from "../../../assets/images/star.png"
import { useNavigate } from "react-router-dom"

/**
 * Webtoon list. If onChoose is provided, it will be called when a story is chosen.
 * Otherwise, a popup will be shown to create the webtoon.
 */
export default function WebtoonList({
  hideTitle = false,
  hideGenerated = false,
  onChooseUngenerated,
  onChooseWebtoon,
  lightMode = false,
}: {
  hideTitle?: boolean
  hideGenerated?: boolean
  onChooseUngenerated?: (story: Story) => void
  onChooseWebtoon?: (webtoon: Webtoon) => void
  lightMode?: boolean
}) {
  const { user } = useUserContext()
  const [webtoons, setWebtoons] = useState(null as null | Webtoon[])
  const [userStoryPacks, setUserStoryPacks] = useState<UserStoryPack[]>()
  const [loading, setLoading] = useState(true)
  const [stories, setStories] = useState([] as Story[])
  const [storyToCreate, setStoryToCreate] = useState<Story>()
  const navigate = useNavigate()

  useEffect(() => {
    if (!user) return
    StoryService.getStories()
      .then(async (stories) => {
        setStories(stories)
        await UserStoryPackService.list(user.id).then(async (pack) => {
          setUserStoryPacks(pack)
          WebtoonService.listenToUserWebtoons(user.id, pack[0]?.id, (wts) =>
            setWebtoons(
              wts.sort(
                (a, b) =>
                  stories.find((s) => s.id === a.storyId)!.order -
                  stories.find((s) => s.id === b.storyId)!.order
              )
            )
          )
        })
      })
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  if (loading) {
    return (
      <Box py="1rem" textAlign="center">
        <Heading
          size="sm"
          as="h2"
          color={lightMode ? "black" : "white"}
          textAlign="left"
        >
          Mini Tales
        </Heading>
        <Spinner mx="auto" w="220px" h="220px" color="primary.500" />
      </Box>
    )
  }

  const redirToWebtoon = (webtoon: Webtoon) => {
    if (
      UserService.getStoryPosition(webtoon.storyId) + JOURNEY_SIZE >=
      webtoon.panels.length
    )
      UserService.setStoryPosition(webtoon.storyId, 0, false)
    navigate(`/creations/webtoon/${webtoon.userStoryPackId}/${webtoon.id}`)
  }

  return (
    <Box py="1rem" textAlign="center">
      {!hideTitle && (
        <Flex mb="0.5rem" alignItems={"center"}>
          <Heading
            as="h2"
            fontSize="20px"
            color={lightMode ? "black" : "white"}
          >
            Mini Tales
          </Heading>
          {userStoryPacks && (
            <>
              <Image src={star} h="20px" my="auto" m="0.5rem" />
              <Text
                fontWeight="light"
                color={lightMode ? "black" : "white"}
                fontSize="16px"
                my="auto"
              >
                {webtoons?.length} / {stories.length}
              </Text>
            </>
          )}
          <Button
            variant="link"
            color={lightMode ? "black" : "white"}
            textDecor="underline"
            ml="auto"
            fontWeight="light"
            onClick={() => navigate("/stories")}
          >
            All Stories
          </Button>
        </Flex>
      )}
      {userStoryPacks?.length === 0 && (
        <Box maxW="500px" w="100%" mt="1rem">
          <ComicBookActionBlock />
        </Box>
      )}
      <Box mx="-1rem">
        <Flex
          pt="1rem"
          maxW="100%"
          px="1em"
          gap="1rem"
          overflowX="auto"
          pb="1rem"
        >
          {webtoons
            ?.filter((webtoon) => webtoon.status !== "generated")
            .map((webtoon) => (
              <WebtoonGeneratingElement key={webtoon.id} webtoon={webtoon} />
            ))}
          {hideGenerated !== true &&
            webtoons
              ?.filter((webtoon) => webtoon.status === "generated")
              .map((webtoon) => (
                <WebtoonGeneratedElement
                  lightMode={lightMode}
                  key={webtoon.id}
                  webtoon={webtoon}
                  stories={stories}
                  onChoose={() => redirToWebtoon(webtoon)}
                />
              ))}
          {(userStoryPacks?.length ?? 0) > 0 && (
            <>
              <WebtoonAvailableStories
                lightMode={lightMode}
                stories={stories}
                webtoons={webtoons ?? []}
                onChoose={onChooseUngenerated || setStoryToCreate}
              />
              <WebtoonUnavailableStories
                lightMode={lightMode}
                stories={stories}
                webtoons={webtoons ?? []}
              />
            </>
          )}
        </Flex>
      </Box>
      {!onChooseUngenerated && (
        <WebtoonCreatePopup
          storyToBuy={storyToCreate}
          userStoryPack={userStoryPacks![0]}
          onClose={() => setStoryToCreate(undefined)}
          storyCount={stories.length}
        />
      )}
    </Box>
  )
}
