import { CloseIcon } from "@chakra-ui/icons"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  Image,
} from "@chakra-ui/react"
import { useRef } from "react"
import camcamFun from "../../assets/images/camcam-fun.png"

export default function LearnMoreModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) {
  const ref = useRef(null)
  return (
    <AlertDialog
      leastDestructiveRef={ref}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <AlertDialogOverlay>
        <AlertDialogContent bg="primary.500" color="white">
          <AlertDialogBody whiteSpace="pre-wrap" pt="1rem">
            {`Large Language Models (LLMs) that are used to power AI chat-bots such as ours use data from the open-internet to retrieve answers to questions.

We follow strict content moderation guidelines as well as engineer prompts that restrict unsafe behavior. We explicitly disallow hate speech, violence, sexual language, and any other type of speech that would be considered unsafe for children.

However, please keep in mind that this is nascent technology and still has its limits. Please be sure to have an adult present when conversing with our AI, Camcam.`}
            <Image src={camcamFun} w="350px" mt="1rem" mx="auto" />
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClose} mx="auto">
              <CloseIcon mr="0.5rem" /> Close
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
