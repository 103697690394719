// This is just a simple service worker setup.
// Your actual service worker script would go here.

// eslint-disable-next-line no-restricted-globals
self.addEventListener("install", (event) => {
  console.log("Service Worker installing.")
})
// eslint-disable-next-line no-restricted-globals
self.addEventListener("activate", (event) => {
  console.log("Service Worker activating.")
})

export function register() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/sw.js")
      .then((registration) => {
        console.log("SW registered: ", registration)
      })
      .catch((registrationError) => {
        console.log("SW registration failed: ", registrationError)
      })
  }
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister()
      })
      .catch((error) => {
        console.error(error.message)
      })
  }
}
