import { useParams } from "react-router-dom"
import img1 from "../../../../assets/images/character-actions/shop/bag/bag-1.jpeg"
import img2 from "../../../../assets/images/character-actions/shop/bag/bag-2.jpeg"
import img3 from "../../../../assets/images/character-actions/shop/bag/bag-3.jpeg"
import PrintShopCharacterManager from "./PrintShopCharacterManager"

const TOTEBAG_VARIANT = "44603022901569"

export default function CreationPrintTotebag() {
  const { creationId } = useParams()

  return (
    <PrintShopCharacterManager
      variant={TOTEBAG_VARIANT}
      creationId={creationId}
      price={"$19.99"}
      productImgs={[img1, img2, img3]}
      title="BAG"
      description={`Reinforced stitching on handles- Large printable area for front & back- Capacity 10 litres
100% cotton- 3 - 5 oz/yard², 100 - 170 g/m²
This product is made on demand. No minimums.`}
      product="Carry your art with you wherever you go with our magical tote bags. 
      Print your artwork onto a high-quality bag that are both stylish and durable, 
      perfect for carrying books, art supplies, or everyday essentials.
      Let your imagination run wild and create a tote bag that's as special and unique as you are!"
    />
  )
}
