import { ChevronLeftIcon } from "@chakra-ui/icons"
import { Box, Button, Text } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { CharacterSpeechBalloon } from "../../../components/CharacterSpeechBalloon"
import CreateActionBlocks from "../../Create/CreateActionBlocks"
import { useSegment } from "../../../../context/SegmentContext"
import Creation from "../../../../data/models/Creation"
import { useEffect } from "react"

export default function WebtoonEnd({
  creation,
  storyTitle,
}: {
  creation?: Creation
  storyTitle?: string
}) {
  const navigate = useNavigate()
  const hours = new Date().getHours()
  const { track } = useSegment()
  // const hours = 20
  useEffect(() => {
    track("Finish Webtoon", {
      currentTime: new Date().getTime.toString(),
      storyTitle: storyTitle || "",
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box pt="160px">
      {hours < 20 ? (
        <>
          <Text color="primary.500" textAlign="center" size="sm">
            What do you want to do now?
          </Text>
          <CreateActionBlocks alwaysColumn showCreation={creation} />
        </>
      ) : (
        <CharacterSpeechBalloon
          character="camcam"
          text="It's past 8pm, time to go to bed! Have a good night!"
        />
      )}
      <Button
        variant="ghost"
        w="100%"
        mt="2rem"
        color="primary.500"
        onClick={() => {
          navigate("/app")
          track("Finish Webtoon")
        }}
      >
        <ChevronLeftIcon fontSize="2em" /> Go back
      </Button>
    </Box>
  )
}
