import { Icon, IconProps } from "@chakra-ui/react"

export default function HeartIcon(props: IconProps) {
  return (
    <Icon
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.6822 17.2194C10.3803 17.326 9.8829 17.326 9.58092 17.2194C7.00526 16.3401 1.25 12.672 1.25 6.45492C1.25 3.71051 3.46151 1.49011 6.18816 1.49011C7.80461 1.49011 9.23454 2.27169 10.1316 3.47959C10.5879 2.86309 11.1823 2.36204 11.867 2.01656C12.5518 1.67108 13.308 1.49079 14.075 1.49011C16.8016 1.49011 19.0132 3.71051 19.0132 6.45492C19.0132 12.672 13.2579 16.3401 10.6822 17.2194Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
