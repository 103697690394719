import { useNavigate } from "react-router-dom"
import Creation from "../../../../data/models/Creation"
import UserData from "../../../../data/models/UserData"
import bg from "../../../assets/images/backgrounds/upload-background.png"
import BackButton from "../../../components/Buttons/BackButton"
import PendingCreationCard from "../../../components/Creations/PendingCreationCard"
import WaitingStoriesPopup from "../../../components/Popup/WaitingStoriesPopup"
import Background from "../../../components/Wrapper/Background"
import BackgroundImageBlurred from "../../../components/Wrapper/BackgroundImageBlurred"
import ResponsiveContainer from "../../../components/Wrapper/ResponsiveContainer"
import { useEffect, useState } from "react"

export default function OpenGift({
  onOpen,
  user,
  creation,
}: {
  onOpen: () => any
  user?: UserData
  creation: Creation
}) {
  const [openWaiting, setOpenWaiting] = useState(false)
  const navigate = useNavigate()
  const ready = (creation.generativeAiResults?.length ?? 0) > 0

  useEffect(() => {
    const creationReady = (creation.generativeAiResults?.length ?? 0) > 0
    setTimeout(() => {
      setOpenWaiting(!creationReady)
    }, 700)
  }, [creation])

  return (
    <Background display="flex">
      <WaitingStoriesPopup isOpen={openWaiting} onClose={() => {}} creationId={creation.id} />
      <BackgroundImageBlurred
        blur={false}
        backgroundImage={bg}
        backgroundColor="transparent"
        bgPos="50% 50%"
      />
      <ResponsiveContainer
        display="flex"
        flexDir="column"
        // height of Waiting Stories Popup
        transition="200ms"
        pb={!openWaiting ? undefined : "320px!important"}
      >
        <BackButton color="white" buttonBg onClick={() => navigate("/app")} />
        <PendingCreationCard
          onClick={ready ? onOpen : undefined}
          creation={creation}
          ready={ready}
          m="auto"
        />
      </ResponsiveContainer>
    </Background>
  )
}
