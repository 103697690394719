import { Box, Container, Flex, Text } from "@chakra-ui/react"
import { useRef, useState } from "react"
import "react-html5-camera-photo/build/css/index.css"
import { useNavigate } from "react-router-dom"
import { resizeFile } from "../../../../utilities/imageUtils"
import Button3d from "../../../components/Buttons/Button3d"
import FullscreenCamera from "../../../components/FullscreenCamera"
import CameraIcon from "../../../components/Icons/CameraIcon"
import UploadIcon from "../../../components/Icons/UploadIcon"

export default function UploadDrawingForm({
  userCredits,
  setImage,
  showCameraOnInit,
  onGotoDescription,
}: {
  userCredits: number
  setImage: (image: string) => any
  showCameraOnInit?: boolean
  onGotoDescription: () => any
}) {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [cameraOpen, setCameraOpen] = useState(showCameraOnInit ?? false)
  const navigate = useNavigate()

  const handleImgUploaded: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const file = event.target.files?.item(0)
    if (file) {
      if (file.type.substring(0, 5) === "image") {
        resizeFile(file).then((img) => {
          if (img) setImage(img)
        })
      }
    }
  }

  const handleClick = () => {
    if (userCredits) {
      fileInputRef.current?.click()
    } else {
      navigate(
        `/settings/payment?redirectTo=${encodeURIComponent(
          window.location.pathname
        )}`
      )
    }
  }

  return (
    <Flex w="100%" my="auto" zIndex="1" textAlign="center">
      <Container p="0">
        <Box
          display={userCredits === 0 ? "none" : "block"}
          position="relative"
          height="300px"
          border="solid 3px"
          borderColor="secondary.500"
          mb="1rem"
          borderRadius="25px"
          cursor="pointer"
          bg="#eee9fda0"
        >
          <Text
            fontSize="128px"
            color="secondary.500"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          >
            +
          </Text>
          <input
            type="file"
            onDragEnter={(e) => {
              e.currentTarget.parentElement?.setAttribute(
                "style",
                // "background: var(--chakra-colors-secondary-50)"
                "background: white"
              )
            }}
            onDragLeave={(e) => {
              e.currentTarget.parentElement?.setAttribute("style", "")
            }}
            style={{
              opacity: 0,
              width: "100%",
              height: "100%",
              cursor: "pointer",
            }}
            accept="image/jpeg, image/png, image/jpg"
            onChange={handleImgUploaded}
            ref={fileInputRef}
          />
        </Box>
        <Button3d
          bg="secondary.500"
          bg3dColor="#2418B1"
          onClick={handleClick}
          w="100%"
          maxW="400px"
          m="auto"
        >
          <UploadIcon mr="0.25rem" />
          {userCredits ? "Upload Drawing" : "Buy stars"}
        </Button3d>
        {userCredits > 0 && (
          <Button3d
            bg="secondary.50"
            bg3dColor="secondary.300"
            color="secondary.500"
            mt="1rem"
            mx="auto"
            onClick={() => setCameraOpen(true)}
            w="100%"
            maxW="400px"
          >
            <CameraIcon mr="0.25em" />
            Take a photo
          </Button3d>
        )}
        <Flex
          gap="0.5rem"
          alignItems="center"
          my="1rem"
          color="secondary.50"
          fontWeight="bold"
        >
          <Box w="100%" h="3px" bg="secondary.50" borderRadius="2px" />
          or
          <Box w="100%" h="3px" bg="secondary.50" borderRadius="2px" />
        </Flex>
        <Button3d
          bg="secondary.500"
          bg3dColor="#2418B1"
          onClick={onGotoDescription}
          m="auto"
          w="fit-content"
        >
          Describe it
        </Button3d>
        {/* <Text variant="black" className="creditsMsg" marginTop="2vh">
          You have{" "}
          <b>
            {userCredits} {userCredits <= 1 ? "star" : "stars"}
          </b>
        </Text> */}
        {/* <Text variant="black" className="creditsMsg">
          1 star = 1 drawing
        </Text> */}
        <FullscreenCamera
          open={cameraOpen}
          onTakeImage={setImage}
          setOpen={setCameraOpen}
        />
      </Container>
    </Flex>
  )
}
