import { CloseIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, Heading, Text, useToast } from "@chakra-ui/react"
import { useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useSegment } from "../../../context/SegmentContext"
import SketchQuestModule from "../../../data/models/SketchQuest"
import { resizeFile } from "../../../utilities/imageUtils"
import { sleep } from "../../../utilities/sleep"
import BackButton from "../../components/Buttons/BackButton"
import { IS_PROD } from "../../components/Creations/GlobalSettings"
import FullscreenCamera from "../../components/FullscreenCamera"
import CameraFullIcon from "../../components/Icons/CameraFullIcon"
import DrawingAddIcon from "../../components/Icons/DrawingAddIcon"
import TextIcon from "../../components/Icons/TextIcon"
import CloudsBottom from "../../components/SectionSeparators/CloudsBottom"
import CardCarousel from "../../components/Sliders/CardCarousel"
import ResponsiveContainer from "../../components/Wrapper/ResponsiveContainer"
import ImageDescription from "../Upload/Forms/CreationDescription"
import { UploadLoading } from "../Upload/Upload"

export default function WeeklyThemePresentation({
  setDrawingUrl,
  onUpload,
  module,
  hasSquetchCreations = false,
}: {
  setDrawingUrl: (url: string) => void
  onUpload: (description: string | undefined) => Promise<void>
  module: SketchQuestModule
  hasSquetchCreations?: boolean
}) {
  const { track } = useSegment()
  const [cameraOpen, setCameraOpen] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [describe, setDescribe] = useState(false)
  const [description, setDescription] = useState("")
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const navigate = useNavigate()

  function handleUpload() {
    setLoading(true)
    let desc = description.trim()
    if (desc.length < 3) {
      sleep(1000).then(() => {
        toast({
          title: "Description too short",
          description: "Please describe your drawing in a few words",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        })
        setLoading(false)
      })
    } else {
      onUpload(desc).finally(() => {
        track("Sketch Quest Upload", {
          questId: module._key,
          questTitle: module.title,
          questType: module._type,
        })
        setLoading(false)
      })
    }
  }

  const handleImgUploaded: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const file = event.target.files?.item(0)
    if (file) {
      if (file.type.substring(0, 5) === "image") {
        resizeFile(file).then((img) => {
          if (img) setDrawingUrl(img)
        })
      }
    }
  }

  return (
    <ResponsiveContainer
      maxW="800px"
      flexDir="column"
      position="relative"
      display="flex"
      pt="0!important"
    >
      <Flex
        mx="-1rem"
        flexDir="column"
        position="relative"
        mt="100px" // Header - wave size
      >
        {hasSquetchCreations && (
          <CloudsBottom
            w="100%"
            h="auto"
            pos="absolute"
            top="0"
            transform={"rotate(180deg)"}
            zIndex={1}
          />
        )}

        <CardCarousel
          onSwipe={() => {
            track("Swipe to next creation of Sketch Quest", {
              title: module.title,
            })
          }}
          images={module.creations.map((creation) => ({
            imageSrc: creation.creationUrl,
            cardContent: (
              <Box
                w="100%"
                position="absolute"
                bottom="0"
                textAlign="center"
                background="linear-gradient(0deg, rgba(0,0,0,0.67) 70%, rgba(0,0,0,0) 100%)"
                p="1rem"
                py={["40px", "80px"]}
                borderRadius="0 0 16px 16px"
              >
                <Heading fontSize={["20px", "24px"]} color="white">
                  {creation.creationTitle}
                </Heading>
              </Box>
            ),
          }))}
          borderRadius="16px"
        />
      </Flex>
      <Flex
        w="100%"
        justifyContent="center"
        maxW="500px"
        mx="auto"
        mt="3rem"
        mb="2rem"
      >
        {describe && (
          <BackButton
            icon={<CloseIcon width="24px" height="24px" m="auto" />}
            color="red"
            onClick={() => setDescribe(false)}
          />
        )}
        {module.sketchChallenge && (
          <Text
            my="auto"
            size={["md"]}
            textAlign="center"
            fontWeight="bold"
            color="white"
            textShadow="0 0 4px rgba(0,0,0,0.5)"
          >
            {module.sketchChallenge}
          </Text>
        )}
        {describe && <Box w="50px" />}
      </Flex>
      {!describe ? (
        <Flex
          gap="1rem"
          alignSelf="center"
          flexDirection={!IS_PROD ? "column" : "row"}
        >
          <Flex gap="1rem">
            <Button
              height="96px"
              w="96px"
              borderRadius="12px"
              onClick={() => setCameraOpen(true)}
            >
              <Flex flexDir="column" gap="0.25rem">
                <CameraFullIcon />
                Snap
              </Flex>
            </Button>
            <Button
              height="96px"
              w="96px"
              borderRadius="12px"
              variant="fullSecondary"
              onClick={() => fileInputRef.current?.click()}
            >
              <Flex flexDir="column" gap="0.25rem">
                <DrawingAddIcon />
                Upload
              </Flex>
            </Button>
          </Flex>
          <Flex gap="1rem">
            <Button
              height="96px"
              w="96px"
              borderRadius="12px"
              variant="colored"
              background="#34A96A"
              onClick={() => navigate(`/draw/` + module._key)}
            >
              <Flex flexDir="column" gap="0.25rem">
                <DrawingAddIcon />
                Draw
              </Flex>
            </Button>

            <Button
              height="96px"
              w="96px"
              borderRadius="12px"
              variant="yellow"
              onClick={() => setDescribe(true)}
            >
              <Flex flexDir="column" gap="0.25rem" h="100%">
                <TextIcon maxW="100%" maxH="80%" m="auto" />
                Write
              </Flex>
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Flex
          alignSelf="center"
          gap="1rem"
          flexDir="column"
          w="100%"
          maxW="500px"
        >
          {loading && <UploadLoading overlay />}
          <ImageDescription
            description={description}
            setDescription={setDescription}
          />
          <Button
            onClick={handleUpload}
            isLoading={loading}
            loadingText="Making magic..."
          >
            Complete the quest
          </Button>
        </Flex>
      )}
      <FullscreenCamera
        open={cameraOpen}
        onTakeImage={setDrawingUrl}
        setOpen={setCameraOpen}
      />
      <input
        type="file"
        style={{
          opacity: "none",
          position: "fixed",
          top: "-1px",
          left: "-1px",
          width: "1px",
          height: "1px",
        }}
        accept="image/jpeg, image/png, image/jpg"
        onChange={handleImgUploaded}
        ref={fileInputRef}
      />
    </ResponsiveContainer>
  )
}
