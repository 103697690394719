import { Box, Button, Flex, Image, Text, useDisclosure } from "@chakra-ui/react"
import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ChatEntry, ChatProvider, useChat } from "../../../context/ChatContext"
import { useSegment } from "../../../context/SegmentContext"
import { useUserContext } from "../../../context/userContext"
import ChatService from "../../../service/ChatService"

import { useTextToSpeech } from "../../../context/TextToSpeechContext"
import Creation from "../../../data/models/Creation"
import { useQuery, useScroll } from "../../../utilities/hooks"
import { sleep } from "../../../utilities/sleep"
import camcamLogin from "../../assets/images/camcam-login.png"
import ActionButton from "../../components/Buttons/ActionButton"
import GoogleSSOButton from "../../components/Buttons/GoogleSSOButton"
import {
  // LOCAL_STORAGE_CHAT_BOT_BACKGROUND,
  LOCAL_STORAGE_CHAT_BOT_MUTED,
  LOCAL_STORAGE_CHAT_TEXT,
} from "../../components/Creations/GlobalSettings"
import MinistudioLogo from "../../components/ImagePresets/MinistudioLogo"
import CloudsBottom from "../../components/SectionSeparators/CloudsBottom"
import Background from "../../components/Wrapper/Background"
import BackgroundImageBlurred from "../../components/Wrapper/BackgroundImageBlurred"
import ResponsiveContainer from "../../components/Wrapper/ResponsiveContainer"
import { ChatBubbles, ChatInput, LoadingChatAssistant } from "./ChatBoxUtils"
import ChatPageHeader from "./ChatPageHeader"
import LearnMoreModal from "./LearnMoreModal"

export const CAMCAM_CREATION_ID = "camcam"

const getGreetMessage = (creation: Creation | undefined): ChatEntry => {
  const name = creation?.title ?? "CamCam"
  return {
    content: `Hi! I’m ${name}, your Mini! Let’s get creative together or explore anything you’re curious about. What would you like to do?`,
    role: "assistant",
    characterId: creation?.id,
  }
}

function checkUsedFreeChats(chat: ChatEntry[]) {
  return (
    chat.filter((entry) => entry.role === "user").length >= 3 &&
    chat.filter((entry) => entry.role === "assistant").length >= 3
  )
}

function LoginOverlay() {
  const navigate = useNavigate()
  const { setShowSSOLogin } = useUserContext()
  const redirectToLogin = () => {
    const url = encodeURIComponent(window.location.pathname)
    setShowSSOLogin(false)
    navigate(`/sign-up?redirectTo=${url}`)
  }

  return (
    <Flex
      w="100%"
      position="fixed"
      top="0"
      left="0"
      h="100%"
      bg="rgba(0,0,0,0.5)"
      zIndex={2}
      flexDir="column"
    >
      <CloudsBottom width="100%" height="auto" color="primary.500" mt="auto" />
      <Flex flexDir="column" w="100%" bg="primary.500" p="1rem">
        <MinistudioLogo variant="white" width="150px" height="auto" mx="auto" />
        <Text color="white" textAlign="center" mt="0.5rem">
          Foster kids’ imagination <br />
          and curiosity
        </Text>
        <Image src={camcamLogin} w="300px" mx="auto" my="1rem" />
        <GoogleSSOButton maxW="400px" m="auto" w="100%" />
        <Button
          variant="link"
          color="white"
          textDecor="underline"
          w="100%"
          maxW="400px"
          mx="auto"
          mt="2rem"
          mb="3rem"
          onClick={redirectToLogin}
        >
          Or use email instead
        </Button>
      </Flex>
    </Flex>
  )
}

function ChatContent() {
  const query = useQuery()
  const { creationId } = useParams()
  const [text, setText] = useState<string>(() => {
    const t =
      localStorage
        .getItem(`${LOCAL_STORAGE_CHAT_TEXT}/${creationId}`)
        ?.trim() ?? ""
    const question = query.get("text")?.trim()
    const send = query.get("send") === "true"
    if (!t && question) return question
    if (send && question) return question
    return t
  })
  const navigate = useNavigate()
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const scrollableRef = useRef<HTMLDivElement>(null)
  const { chat, addChatEntry, loadSession } = useChat()
  const [loading, setLoading] = useState(false)
  const { user, userCreations } = useUserContext()
  const [showLogin, setShowLogin] = useState(false)
  const { track } = useSegment()
  const learnMoreModal = useDisclosure()
  const { playAfterLoad, stop, playing, play } = useTextToSpeech()
  const [muted, setMuted] = useState(
    localStorage.getItem(LOCAL_STORAGE_CHAT_BOT_MUTED) === "true" || false
  )
  const { scrollDirection, scroll } = useScroll(scrollableRef)
  const [isOpenSuggestions, setOpenSuggestions] = useState(true)
  const [action, setAction] = useState<"send" | "play">()

  const creation = userCreations.find((c) => c.id === creationId)

  useEffect(() => {
    if (!creationId) return navigate("/chat", { replace: true })
    if (creationId !== CAMCAM_CREATION_ID && creation === undefined) {
      return navigate("/chat", { replace: true })
    }
    loadSession(creationId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creationId])

  useEffect(() => {
    inputRef.current?.focus()
  }, [inputRef])

  useEffect(() => {
    localStorage.setItem(
      `${LOCAL_STORAGE_CHAT_TEXT}/${creationId}`,
      text.trim()
    )
  }, [text, creationId])

  useEffect(() => {
    if (query.get("send") !== "true") {
      setAction("play")
    } else {
      setAction("send")
    }
  }, [query])

  useEffect(() => {
    if (action === "send" && text.trim().length > 0) {
      if (!muted) {
        playAfterLoad(() => sendChat(text), creation?.pronouns ?? "they/them")
      } else {
        sendChat(text)
      }
      setAction(undefined)
      setOpenSuggestions(false)
      window.history.replaceState("", "", `/chat/${creationId}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action])

  useEffect(() => {
    if (action === "play" && !muted) {
      // play(
      // (
      // chat?.((ce) => ce.role === "assistant") ??
      // getGreetMessage(creation)
      // ).content
      // )
      let content = getGreetMessage(creation).content
      if (chat) {
        // we don't care about i === 0, it can only be a user message
        for (let i = chat.length - 1; i > 0; i++) {
          if (chat[i].role === "assistant") {
            content = chat[i].content
            break
          }
        }
      }
      play(content, creation?.pronouns ?? "they/them")
      setAction(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, chat])

  useEffect(() => {
    if (!user && checkUsedFreeChats(chat ?? [])) {
      setShowLogin(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat])

  useEffect(() => {
    if (user && showLogin) {
      if (!user.childName) {
        return navigate(
          `/sign-up-info?redirectTo=${encodeURIComponent(
            window.location.pathname
          )}`
        )
      }
      setShowLogin(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, showLogin])

  // useEffect(() => {
  //   const t = query.get("text")
  //   if (chat?.length === 0 && t && !background) {
  //     const bg =
  //       actions.find((a) => a.possibleValues.includes(t))?.imageUrl ||
  //       questions.find((q) => q.possibleValues.includes(t))?.imageUrl
  //     if (bg) setAndSaveBackground(bg)
  //   }
  // }, [chat, query, background, actions, questions])

  const scrollToBottom = () => {
    sleep(100).then(() => {
      scrollableRef.current?.scrollTo({
        top: scrollableRef.current.scrollHeight,
        behavior: "smooth",
      })
    })
  }

  useEffect(() => {
    scrollToBottom()
  }, [])

  const sendChat = async (text: string): Promise<string> => {
    const t = text.trim()
    if (t.length === 0) return ""
    scrollToBottom()
    setLoading(true)
    setText("")
    addChatEntry({ content: t, role: "user" }, creationId!)
    track("Chat with CamCam", { text: t })
    return await ChatService.sendChat(
      chat ?? [],
      user,
      creationId !== CAMCAM_CREATION_ID ? creationId : undefined
    )
      .then((res) => {
        addChatEntry({ ...res, characterId: creationId }, creationId!)
        scrollToBottom()
        return res.content
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const shownChat = [getGreetMessage(creation), ...(chat ?? [])]

  return (
    <Background
      display="flex"
      flexDir="column"
      maxHeight={"100vh; height: calc(var(--vh, 1vh) * 100)"}
      overflowY="hidden"
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
    >
      <BackgroundImageBlurred backgroundColor="transparent" />
      <Box overflow="auto" ref={scrollableRef} h="100%" w="100%">
        {showLogin && <LoginOverlay />}
        <LearnMoreModal {...learnMoreModal} />
        <ChatPageHeader
          muted={muted}
          onToggleMute={() => {
            setMuted(!muted)
            localStorage.setItem(
              LOCAL_STORAGE_CHAT_BOT_MUTED,
              (!muted).toString()
            )
            track(!muted ? "Mute text to speech" : "Unmute text to speech")
            if (!muted) {
              for (const playingText in playing) {
                stop(playingText)
              }
            }
          }}
          onCloseButton={() => {
            for (const playingText in playing) {
              stop(playingText)
            }
            navigate(-1)
          }}
          onOpenLearnMoreModal={learnMoreModal.onOpen}
          showCloseButton={!!user}
          show={scrollDirection === "up" || scroll <= 0}
        />
        <ResponsiveContainer
          minH="0"
          maxW="800px"
          my="0"
          position="relative"
          flexGrow="1"
          p="0!important"
          h="100%"
        >
          <Flex
            pt={["80px", "80px", "80px", "88px", "96px"]}
            pb={["120px", "120px", "140px", "160px", "180px"]}
            position="relative"
            display="flex"
            flexDir="column"
            w="100%"
            maxW="100%"
            px="1rem"
            maxH="100%"
            h="100%"
          >
            <ChatBubbles chat={shownChat} />
            {loading && <LoadingChatAssistant creationId={creationId} />}
            <Box pb={isOpenSuggestions ? "320px" : "144px"}></Box>
          </Flex>
          <Box
            position="fixed"
            bottom="0rem"
            w="100%"
            left="50%"
            maxW="800px"
            transform="translateX(-50%)"
          >
            <Flex w="100%" justifyContent="space-between" px="2rem">
              <ActionButton
                buttonType="hint"
                onClick={() => setOpenSuggestions(true)}
              />
            </Flex>
            <ChatInput
              isOpenSuggestions={isOpenSuggestions}
              setOpenSuggestions={setOpenSuggestions}
              onSubmitText={
                muted
                  ? sendChat
                  : (t) =>
                      playAfterLoad(
                        () => sendChat(t),
                        creation?.pronouns ?? "they/them"
                      )
              }
              text={text}
              setText={setText}
              inputRef={inputRef}
              isLoading={loading}
            />
          </Box>
        </ResponsiveContainer>
      </Box>
    </Background>
  )
}

export default function ChatPage() {
  return (
    <ChatProvider>
      <ChatContent />
    </ChatProvider>
  )
}
