import { Heading } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useUserContext } from "../../../context/userContext"
import Creation from "../../../data/models/Creation"
import CreationService from "../../../service/CreationService"
import Navigation from "../../components/Wrapper/Navigation"
import CommunityMasonry from "./CommunityMasonry"

export default function Community() {
  const { user } = useUserContext()
  const [creations, setCreations] = useState([] as Creation[])

  useEffect(() => {
    if (user)
      CreationService.getOtherCreations(user, 100).then((creations) =>
        setCreations(creations)
      )
  }, [user])

  return (
    <Navigation includeHeader blurredBackground backgroundColor="#ffffff07">
      <Heading size="md" color="black" py="1rem" mb="1rem">
        Get inspired! What kids are making
      </Heading>
      <CommunityMasonry creations={creations} />
    </Navigation>
  )
}
