import { Icon } from "@chakra-ui/react"

const CameraIcon: typeof Icon = (props) => (
  <Icon fill="none" viewBox="0 0 46 38" {...props}>
    <path
      d="M44.0832 32.4167C44.0832 33.4333 43.6793 34.4084 42.9604 35.1272C42.2415 35.8461 41.2665 36.25 40.2498 36.25H5.74984C4.73317 36.25 3.75815 35.8461 3.03926 35.1272C2.32037 34.4084 1.9165 33.4333 1.9165 32.4167V11.3333C1.9165 10.3167 2.32037 9.34165 3.03926 8.62276C3.75815 7.90387 4.73317 7.5 5.74984 7.5H13.4165L17.2498 1.75H28.7498L32.5832 7.5H40.2498C41.2665 7.5 42.2415 7.90387 42.9604 8.62276C43.6793 9.34165 44.0832 10.3167 44.0832 11.3333V32.4167Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.0002 28.5833C27.2343 28.5833 30.6668 25.1509 30.6668 20.9167C30.6668 16.6825 27.2343 13.25 23.0002 13.25C18.766 13.25 15.3335 16.6825 15.3335 20.9167C15.3335 25.1509 18.766 28.5833 23.0002 28.5833Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)

export default CameraIcon
