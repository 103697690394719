import {
  CollectionReference,
  DocumentReference,
  DocumentSnapshot,
  collection,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore"
import {
  UserStoryPack,
  UserStoryPackFirebase,
} from "../data/models/UserStoryPack"
import { db } from "../data/apis/firebase/Firebase"

export default class UserStoryPackDao {
  private static docToUserStoryPack(
    doc: DocumentSnapshot<UserStoryPackFirebase>
  ): UserStoryPack {
    const data = doc.data()
    if (!data) throw new Error("User story pack not found")
    return {
      id: doc.id,
      storyPackId: data.storyPackId ?? "",
      createdOn: data.createdOn ?? "",
      maxOrderCreation: data.maxOrderCreation ?? 0,
    }
  }

  static async getAll(userId: string): Promise<UserStoryPack[]> {
    return await getDocs<UserStoryPackFirebase>(
      collection(
        db,
        `users/${userId}/user_story_packs`
      ) as CollectionReference<UserStoryPackFirebase>
    ).then((docs) => docs.docs.map((doc) => this.docToUserStoryPack(doc)))
  }

  static async get(
    userId: string,
    userStoryPackId: string
  ): Promise<UserStoryPack> {
    return await getDoc<UserStoryPackFirebase>(
      doc(
        db,
        `users/${userId}/user_story_packs`,
        userStoryPackId
      ) as DocumentReference<UserStoryPackFirebase>
    ).then((doc) => {
      if (!doc.exists()) throw new Error("User story pack not found")
      return this.docToUserStoryPack(doc)
    })
  }
}
