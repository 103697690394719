import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { useUserContext } from "../../../../context/userContext"
import Story from "../../../../data/models/Story"
import { UserStoryPack } from "../../../../data/models/UserStoryPack"
import StoryService from "../../../../service/StoryService"
import UserStoryPackService from "../../../../service/UserStoryPackService"
import WebtoonService from "../../../../service/WebtoonService"
import Button3d from "../../../components/Buttons/Button3d"
import LoadingScreen from "../../../components/LoadingScreen"
import PrimarySectionSeparator from "../../../components/SectionSeparators/PrimarySectionStart"
import SecondarySectionSeparator from "../../../components/SectionSeparators/SecondarySectionStart"
import Navigation from "../../../components/Wrapper/Navigation"
import ResponsiveContainer from "../../../components/Wrapper/ResponsiveContainer"
import WebtoonCreatePopup from "../../Creations/WebtoonList/WebtoonCreatePopup"

export default function StoriesBuySuccess() {
  const [open, setOpen] = useState(false)
  const { storyPackId } = useParams()
  const { user, fetchUserCredits } = useUserContext()
  const [userStoryPack, setUserStoryPack] = useState(
    undefined as UserStoryPack | undefined
  )
  const [stories, setStories] = useState(undefined as Story[] | undefined)
  const [alreadyCreated, setAlreadyCreated] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (!storyPackId) return navigate("/", { replace: true })
    if (!user) return
    UserStoryPackService.list(user.id)
      .then((userStoryPacks) => {
        const pack = userStoryPacks.find(
          (pack) => pack.storyPackId === storyPackId
        )
        if (!pack) return navigate("/", { replace: true })
        setUserStoryPack(pack)
      })
      .catch(() => {
        navigate("/", { replace: true })
      })
    StoryService.getStoriesFromPack(storyPackId)
      .then((s) => setStories(s.sort((a, b) => a.order - b.order)))
      .catch(() => {
        navigate("/", { replace: true })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyPackId, user])

  useEffect(() => {
    if (!user || !stories || stories.length === 0 || !userStoryPack) return
    WebtoonService.isStoryCreated(
      user.id,
      userStoryPack.id,
      stories[0].id
    ).then((value) => setAlreadyCreated(value))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stories, userStoryPack])

  useEffect(() => {
    fetchUserCredits()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!userStoryPack || !stories) return <LoadingScreen />
  if (alreadyCreated) return <Navigate to="/app" replace />

  return (
    <Navigation
      w={["100%", "100%", "100%", "100%", "100%"]}
      p="0 !important"
      display="flex"
      flexDir="column"
    >
      <Box position="relative">
        <Image
          pos="absolute"
          w="100%"
          src={stories[0].cover}
          h="100%"
          objectFit="cover"
          objectPosition="center"
          zIndex={-1}
          filter="brightness(0.8)"
        />
        <ResponsiveContainer textAlign="center" pb="8rem !important" >
          <Heading color="white" fontSize="58px" mt="100px">
            Yay!
          </Heading>
          <Heading color="white" size="sm" fontWeight="normal">
            You just unlocked the Mini Studio
          </Heading>
          <Heading color="white" size="md">
            Story Ritual
          </Heading>
        </ResponsiveContainer>
      </Box>
      <Flex flexDir="column">
        <Flex
          flexDir="column"
          h="100%"
          flexGrow={1}
          position="relative"
          color="red"
        >
          {/* base image is of size 390x24px */}
          {/* 6.2vw because 24/390 ~= 6.2%, otherwise it doesn't work on safari */}
          <PrimarySectionSeparator
            mb="-6.2vw"
            transform={"translateY(-100%)"}
          />
          <Flex bg="primary.400" flex={1}>
            <ResponsiveContainer
              flexDir="column"
              px="1rem"
              display="flex"
              m="0 auto"
              textAlign="center"
            >
              <Text fontSize="14px" color="white" fontWeight="bold" mt="1rem">
                Dive into our
              </Text>
              <Text fontSize="30px" color="white" fontWeight="bold" mb="1rem">
                7 universes
              </Text>
              <Text fontSize="14px" color="white" fontWeight="bold">
                and discover
              </Text>
              <Text fontSize="30px" color="white" fontWeight="bold">
                new worlds and stories
              </Text>
            </ResponsiveContainer>
          </Flex>
        </Flex>
      </Flex>
      <Flex flexGrow={1} flexDir="column" bg="primary.400">
        <Flex flexDir="column" h="100%" flexGrow={1}>
          <SecondarySectionSeparator mb="-1px" />
          <Flex bg="secondary.500" flex={1} pb="75px">
            <ResponsiveContainer
              flexDir="column"
              px="1rem"
              display="flex"
              m="0 auto"
              textAlign="center"
            >
              <Text color="white" fontWeight="bold" mt="1rem">
                Personalize your first <br />
                story right away!
              </Text>
              <Button3d
                bg="white"
                bg3dColor="secondary.300"
                color="secondary.500"
                variant="white"
                mx="auto"
                w="100%"
                maxW="500px"
                mt="1rem"
                onClick={() => setOpen(true)}
              >
                Start now
              </Button3d>
              <Button
                variant="ghostWhite"
                mx="auto"
                w="100%"
                maxW="500px"
                onClick={() => navigate("/app")}
                mt="1rem"
              >
                Do it later
              </Button>
            </ResponsiveContainer>
          </Flex>
        </Flex>
      </Flex>
      <WebtoonCreatePopup
        open={open}
        storyToBuy={stories[0]}
        userStoryPack={userStoryPack}
        onClose={() => setOpen(false)}
        storyCount={stories.length}
      />
    </Navigation>
  )
}
