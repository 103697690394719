import { Box, Button, Grid, Heading, Text, useToast } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { useUserContext } from "../../../context/userContext"
import BackIcon from "../../components/Icons/BackIcon"
// import CreditCardIcon from "../../components/Icons/CreditCardIcon"
import LogoutIcon from "../../components/Icons/LogoutIcon"
import ProfileIcon from "../../components/Icons/ProfileIcon"
import Navigation from "../../components/Wrapper/Navigation"
import HeadsetIcon from "../../components/Icons/HeadsetIcon"
import BackButton from "../../components/Buttons/BackButton"

function SettingMenu({
  url,
  children,
}: {
  children: React.ReactNode
  url?: string
}) {
  const navigate = useNavigate()

  return (
    <Box borderBottom="1px solid rgba(208, 205, 215, 0.3)">
      <Button
        onClick={url ? () => navigate(url) : undefined}
        variant="ghostBlack"
        px="0"
        display="flex"
        w="100%"
      >
        {children}
      </Button>
    </Box>
  )
}

export default function Settings() {
  const { logoutUser } = useUserContext()
  const toast = useToast()
  const navigate = useNavigate()

  const logout = () => {
    logoutUser().then(() => {
      toast({
        title: "Logged out successfully",
        description: "See you soon!",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      })
      navigate("/")
    })
  }

  return (
    <Navigation includeHeader showBackButton backgroundColor="transparent">
      <Grid gridTemplateColumns="30px 1fr 30px">
        <BackButton m="auto" />
        <Heading size="md" color="black" textAlign="center" py="1rem">
          Settings
        </Heading>
      </Grid>
      <Box
        padding="1rem"
        bg="#ffffff7f"
        borderRadius="24px"
        backdropFilter="blur(4px)"
      >
        <SettingMenu url="/settings/profile">
          <Text size="sm" color="#252525">
            <ProfileIcon mr="0.25rem" /> Account
          </Text>
          <BackIcon color="black" ml="auto" transform="rotate(180deg)" />
        </SettingMenu>
        {/* <SettingMenu url="/settings/payment">
        <Text size="sm" color="#252525">
        <CreditCardIcon mr="0.25rem" /> Buy stars
        </Text>
        <BackIcon color="#C8C4CC" ml="auto" transform="rotate(180deg)" />
      </SettingMenu> */}
        <SettingMenu url="/settings/contact">
          <Text size="sm" color="#252525">
            <HeadsetIcon mr="0.25rem" /> Contact
          </Text>
          <BackIcon color="black" ml="auto" transform="rotate(180deg)" />
        </SettingMenu>
        <Button
          h="fit-content"
          variant="noBckBlue"
          onClick={logout}
          margin="auto"
          color="red"
          px="0"
          fontWeight="normal"
          pb="1rem"
        >
          <LogoutIcon mr="0.5rem" />
          Logout
        </Button>
      </Box>
    </Navigation>
  )
}
