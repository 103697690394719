import { Icon, IconProps } from "@chakra-ui/react"

export default function InstagramIcon(props: IconProps) {
  return (
    <Icon
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.45414 0H17.5341C21.3741 0 24.4941 3.12 24.4941 6.96V17.04C24.4941 18.8859 23.7609 20.6562 22.4556 21.9615C21.1504 23.2667 19.38 24 17.5341 24H7.45414C3.61414 24 0.494141 20.88 0.494141 17.04V6.96C0.494141 5.11409 1.22742 3.34379 2.53268 2.03854C3.83793 0.733284 5.60823 0 7.45414 0ZM7.21414 2.4C6.06841 2.4 4.9696 2.85514 4.15944 3.6653C3.34928 4.47546 2.89414 5.57427 2.89414 6.72V17.28C2.89414 19.668 4.82614 21.6 7.21414 21.6H17.7741C18.9199 21.6 20.0187 21.1449 20.8288 20.3347C21.639 19.5245 22.0941 18.4257 22.0941 17.28V6.72C22.0941 4.332 20.1621 2.4 17.7741 2.4H7.21414ZM18.7941 4.2C19.192 4.2 19.5735 4.35804 19.8548 4.63934C20.1361 4.92064 20.2941 5.30218 20.2941 5.7C20.2941 6.09783 20.1361 6.47936 19.8548 6.76066C19.5735 7.04197 19.192 7.2 18.7941 7.2C18.3963 7.2 18.0148 7.04197 17.7335 6.76066C17.4522 6.47936 17.2941 6.09783 17.2941 5.7C17.2941 5.30218 17.4522 4.92064 17.7335 4.63934C18.0148 4.35804 18.3963 4.2 18.7941 4.2ZM12.4941 6C14.0854 6 15.6116 6.63214 16.7368 7.75736C17.862 8.88258 18.4941 10.4087 18.4941 12C18.4941 13.5913 17.862 15.1174 16.7368 16.2426C15.6116 17.3679 14.0854 18 12.4941 18C10.9028 18 9.37672 17.3679 8.2515 16.2426C7.12628 15.1174 6.49414 13.5913 6.49414 12C6.49414 10.4087 7.12628 8.88258 8.2515 7.75736C9.37672 6.63214 10.9028 6 12.4941 6ZM12.4941 8.4C11.5394 8.4 10.6237 8.77928 9.94856 9.45442C9.27343 10.1295 8.89414 11.0452 8.89414 12C8.89414 12.9548 9.27343 13.8705 9.94856 14.5456C10.6237 15.2207 11.5394 15.6 12.4941 15.6C13.4489 15.6 14.3646 15.2207 15.0397 14.5456C15.7149 13.8705 16.0941 12.9548 16.0941 12C16.0941 11.0452 15.7149 10.1295 15.0397 9.45442C14.3646 8.77928 13.4489 8.4 12.4941 8.4Z"
        fill="currentColor"
      />
    </Icon>
  )
}
