import { Box, Image } from "@chakra-ui/react"
import backgroundImg from "../../assets/images/homepage/sky/sky.png"
import spaceship from "../../assets/images/homepage/sky/spaceship.png"
import ResponsiveContainer from "../../components/Wrapper/ResponsiveContainer"
import Button3d from "../../components/Buttons/Button3d"
import ArrowRightIcon from "../../components/Icons/ArrowRightIcon"

export default function SpaceshipBlock({ onClick }: { onClick: () => void }) {
  return (
    <Box position="relative" h="600px" minH="fit-content" w="100%" mt="-1px">
      <Image
        src={backgroundImg}
        position="absolute"
        top="0"
        left="0"
        h="100%"
        w="100%"
        objectFit="cover"
        zIndex={-1}
      />
      <Box
        position="absolute"
        top="0%"
        left="0"
        w="100%"
        height="10%"
        bg="linear-gradient(0, rgba(0, 0, 0, 0.00) 0.08%, #399EC3 90%)"
        zIndex={-1}
      />
      <ResponsiveContainer
        maxW={"500px"}
        display="flex"
        flexDirection="column"
        position="relative"
      >
        <Image src={spaceship} />
        <Button3d
          size="lg"
          m="0 auto auto auto"
          bg3dColor="#201789"
          color="white"
          background="secondary.500"
          onClick={onClick}
        >
          <ArrowRightIcon height="50px" width="100px" />
        </Button3d>
      </ResponsiveContainer>
      <Box
        zIndex={-1}
        position="absolute"
        bottom="0"
        left="0"
        w="100%"
        height="10%"
        bg="linear-gradient(180deg, rgba(181, 226, 255, 0.00) 0.52%, #AAE4FF 80.15%)"
      />
    </Box>
  )
}
