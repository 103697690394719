import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons"
import { Box, Input, InputProps } from "@chakra-ui/react"
import { useState } from "react"

export default function PasswordInput(props: InputProps) {
  const [show, setShow] = useState(false)

  return (
    <Box position="relative">
      <Input
        border="solid 1px #dbdbdb"
        type={show ? "text" : "password"}
        placeholder="Password"
        borderRadius="full"
        _placeholder={{
          color: "#666",
        }}
        {...props}
      />
      {show ? (
        <ViewOffIcon
          position="absolute"
          top="50%"
          right="1rem"
          transform="translateY(-50%)"
          onClick={() => setShow(!show)}
          cursor="pointer"
          color="neutral"
        />
      ) : (
        <ViewIcon
          color="#aaa"
          position="absolute"
          top="50%"
          right="1rem"
          transform="translateY(-50%)"
          onClick={() => setShow(!show)}
          cursor="pointer"
        />
      )}
    </Box>
  )
}
