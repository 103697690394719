import StoryDAO from "../daos/StoryDAO"
import Story from "../data/models/Story"

export default class StoryService {
  static async getStories(): Promise<Story[]> {
    const stories = await StoryDAO.getStories()
    return stories.sort((a, b) => {
      if (a.comingSoon && !b.comingSoon) return 1
      else if (!a.comingSoon && b.comingSoon) return -1
      else return a.title.localeCompare(b.title)
    })
  }
  static async getStoriesFromPack(storyPackId: string): Promise<Story[]> {
    const stories = await StoryDAO.getStoriesFromPack(storyPackId)
    return stories.sort((a, b) => {
      if (a.comingSoon && !b.comingSoon) return 1
      else if (!a.comingSoon && b.comingSoon) return -1
      else return a.title.localeCompare(b.title)
    })
  }

  static async getStoryOld(storyId: string): Promise<Story | null> {
    return (await StoryDAO.getStoryOld(storyId)) ?? null
  }

  static async getStory(storyPackId: string, storyId: string): Promise<Story | null> {
    return (await StoryDAO.getStory(storyPackId, storyId)) ?? null
  }
}