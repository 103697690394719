import {
  CollectionReference,
  DocumentReference,
  DocumentSnapshot,
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore"
import { db } from "../data/apis/firebase/Firebase"
import Story, { StoryFirebase } from "../data/models/Story"

function storyFirebaseToStory(
  storyFirebase: DocumentSnapshot<StoryFirebase>
): Story | undefined {
  const data = storyFirebase.data()
  if (!data) return undefined
  return {
    boards: data.boards,
    cover: data.cover,
    coverVideo: data.coverVideo,
    description: data.description,
    order: data.order,
    title: data.title,
    hide: data.hide,
    storyPackId: data.storyPackId,
    comingSoon: data.comingSoon,
    id: storyFirebase.id,
  }
}

export default class StoryDAO {
  static async getStories(): Promise<Story[]> {
    return await getDocs<StoryFirebase>(
      collectionGroup(db, "stories") as CollectionReference<StoryFirebase>
    ).then((querySnapshot) =>
      querySnapshot.docs
        .filter((doc) => doc.data().order !== undefined)
        .map((doc) => storyFirebaseToStory(doc)!)
    )
  }

  static async getStoriesFromPack(storyPackId: string): Promise<Story[]> {
    return await getDocs<StoryFirebase>(
      collection(
        db,
        `story_packs/${storyPackId}/stories`
      ) as CollectionReference<StoryFirebase>
    ).then((querySnapshot) =>
      querySnapshot.docs
        .filter((doc) => doc.data().order !== undefined)
        .map((doc) => storyFirebaseToStory(doc)!)
    )
  }

  static async getStoryOld(storyId: string): Promise<Story | undefined> {
    return await getDoc<StoryFirebase>(
      doc(db, `stories`, storyId) as DocumentReference<StoryFirebase>
    ).then(storyFirebaseToStory)
  }

  static async getStory(storyPackId: string, storyId: string): Promise<Story | undefined> {
    return await getDoc<StoryFirebase>(
      doc(db, `story_packs/${storyPackId}/stories`, storyId) as DocumentReference<StoryFirebase>
    ).then(storyFirebaseToStory)
  }
}
