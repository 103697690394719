import { Box } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { Navigate, useParams } from "react-router-dom"
import { useUserContext } from "../../../../context/userContext"
import Creation from "../../../../data/models/Creation"
import CreationService from "../../../../service/CreationService"
import CharacterView from "../../../components/Creations/CharacterView"
import LoadingScreen from "../../../components/LoadingScreen"
import Navigation from "../../../components/Wrapper/Navigation"
import CommunityMasonry from "../CommunityMasonry"

export default function CommunityZoom() {
  const { user } = useUserContext()
  const { creationId } = useParams()
  const [creations, setCreations] = useState([] as Creation[])
  const [loading, setLoading] = useState(true)
  const creation = creations.find((creation) => creation.id === creationId)

  useEffect(() => {
    if (user && creations.length === 0)
      CreationService.getOtherCreations(user, 100).then((creations) => {
        setCreations(creations)
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    document.getElementById("background")?.scrollTo(0, 0)
  }, [creationId])

  if (loading) {
    return <LoadingScreen />
  }

  if (creation === undefined) return <Navigate to="/community" replace />

  return (
    <Navigation>
      <CharacterView borderRadius="12px" creation={creation} />
      <Box pt="1em">
        <CommunityMasonry
          creations={creations.filter((c) => c.id !== creationId)}
        />
      </Box>
    </Navigation>
  )
}
