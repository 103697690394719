import { useEffect, useState } from "react"
import { useSanity } from "../../../context/SanityContext"

export type ChatSuggestion = {
  imageUrl: string
  title: string
  possibleValues: string[]
}

export default function useChatSuggestions() {
  const { sanityClient } = useSanity()
  const [actions, setActions] = useState<ChatSuggestion[]>([])
  const [questions, setQuestions] = useState<ChatSuggestion[]>([])

  useEffect(() => {
    sanityClient
      .fetch<{ actions: ChatSuggestion[]; questions: ChatSuggestion[] }[]>(
        '*[_type == "chatSuggestions"]'
      )
      .then((result) => {
        if (result.length === 0) return
        setActions(result[0].actions)
        setQuestions(result[0].questions)
      })
  }, [sanityClient])
  return { actions, questions }
}
