import { useState } from "react"
import { useParams } from "react-router-dom"
import frame11_1b from "../../../../assets/images/character-actions/shop/frame/11x17-black-1.jpeg"
import frame11_2b from "../../../../assets/images/character-actions/shop/frame/11x17-black-2.jpeg"
import frame11_3b from "../../../../assets/images/character-actions/shop/frame/11x17-black-3.jpeg"
import frame11_1dwd from "../../../../assets/images/character-actions/shop/frame/11x17-dark-wood-1.jpeg"
import frame11_2dwd from "../../../../assets/images/character-actions/shop/frame/11x17-dark-wood-2.jpeg"
import frame11_3dwd from "../../../../assets/images/character-actions/shop/frame/11x17-dark-wood-3.jpeg"
import frame11_1w from "../../../../assets/images/character-actions/shop/frame/11x17-white-1.jpeg"
import frame11_2w from "../../../../assets/images/character-actions/shop/frame/11x17-white-2.jpeg"
import frame11_3w from "../../../../assets/images/character-actions/shop/frame/11x17-white-3.jpeg"
import frame11_1wd from "../../../../assets/images/character-actions/shop/frame/11x17-wood-1.jpeg"
import frame11_2wd from "../../../../assets/images/character-actions/shop/frame/11x17-wood-2.jpeg"
import frame11_3wd from "../../../../assets/images/character-actions/shop/frame/11x17-wood-3.jpeg"
import frame5_1b from "../../../../assets/images/character-actions/shop/frame/5x7-black-1.jpeg"
import frame5_2b from "../../../../assets/images/character-actions/shop/frame/5x7-black-2.jpeg"
import frame5_3b from "../../../../assets/images/character-actions/shop/frame/5x7-black-3.jpeg"
import frame5_1dwd from "../../../../assets/images/character-actions/shop/frame/5x7-dark-wood-1.jpeg"
import frame5_2dwd from "../../../../assets/images/character-actions/shop/frame/5x7-dark-wood-2.jpeg"
import frame5_3dwd from "../../../../assets/images/character-actions/shop/frame/5x7-dark-wood-3.jpeg"
import frame5_1w from "../../../../assets/images/character-actions/shop/frame/5x7-white-1.jpeg"
import frame5_2w from "../../../../assets/images/character-actions/shop/frame/5x7-white-2.jpeg"
import frame5_3w from "../../../../assets/images/character-actions/shop/frame/5x7-white-3.jpeg"
import frame5_1wd from "../../../../assets/images/character-actions/shop/frame/5x7-wood-1.jpeg"
import frame5_2wd from "../../../../assets/images/character-actions/shop/frame/5x7-wood-2.jpeg"
import frame5_3wd from "../../../../assets/images/character-actions/shop/frame/5x7-wood-3.jpeg"
import frame6_1b from "../../../../assets/images/character-actions/shop/frame/6x8-black-1.jpeg"
import frame6_2b from "../../../../assets/images/character-actions/shop/frame/6x8-black-2.jpeg"
import frame6_3b from "../../../../assets/images/character-actions/shop/frame/6x8-black-3.jpeg"
import frame6_1dwd from "../../../../assets/images/character-actions/shop/frame/6x8-dark-wood-1.jpeg"
import frame6_2dwd from "../../../../assets/images/character-actions/shop/frame/6x8-dark-wood-2.jpeg"
import frame6_3dwd from "../../../../assets/images/character-actions/shop/frame/6x8-dark-wood-3.jpeg"
import frame6_1w from "../../../../assets/images/character-actions/shop/frame/6x8-white-1.jpeg"
import frame6_2w from "../../../../assets/images/character-actions/shop/frame/6x8-white-2.jpeg"
import frame6_3w from "../../../../assets/images/character-actions/shop/frame/6x8-white-3.jpeg"
import frame6_1wd from "../../../../assets/images/character-actions/shop/frame/6x8-wood-1.jpeg"
import frame6_2wd from "../../../../assets/images/character-actions/shop/frame/6x8-wood-2.jpeg"
import frame6_3wd from "../../../../assets/images/character-actions/shop/frame/6x8-wood-3.jpeg"
import frame8_1b from "../../../../assets/images/character-actions/shop/frame/8x12-black-1.jpeg"
import frame8_2b from "../../../../assets/images/character-actions/shop/frame/8x12-black-2.jpeg"
import frame8_3b from "../../../../assets/images/character-actions/shop/frame/8x12-black-3.jpeg"
import frame8_1dwd from "../../../../assets/images/character-actions/shop/frame/8x12-dark-wood-1.jpeg"
import frame8_2dwd from "../../../../assets/images/character-actions/shop/frame/8x12-dark-wood-2.jpeg"
import frame8_3dwd from "../../../../assets/images/character-actions/shop/frame/8x12-dark-wood-3.jpeg"
import frame8_1w from "../../../../assets/images/character-actions/shop/frame/8x12-white-1.jpeg"
import frame8_2w from "../../../../assets/images/character-actions/shop/frame/8x12-white-2.jpeg"
import frame8_3w from "../../../../assets/images/character-actions/shop/frame/8x12-white-3.jpeg"
import frame8_1wd from "../../../../assets/images/character-actions/shop/frame/8x12-wood-1.jpeg"
import frame8_2wd from "../../../../assets/images/character-actions/shop/frame/8x12-wood-2.jpeg"
import frame8_3wd from "../../../../assets/images/character-actions/shop/frame/8x12-wood-3.jpeg"
import PrintShopCharacterManager from "./PrintShopCharacterManager"
import { StoreSetting } from "./StorePageTemplate"

enum PrintSize {
  "13x18 cm / 5x7″" = "13x18 cm / 5x7″",
  "15x20 cm / 6x8″" = "15x20 cm / 6x8″",
  "A4 21x29.7 cm / 8x12″" = "A4 21x29.7 cm / 8x12″",
  "28x43 cm / XL (11x17″)" = "28x43 cm / XL (11x17″)",
}

enum PrintFrame {
  "Black" = "Black",
  "Dark wood" = "Dark wood",
  "Wood" = "Wood",
  "White" = "White",
}

const DEFAULT_PRINT_SETTINGS: StoreSetting[] = [
  {
    title: "Size",
    values: [
      PrintSize["13x18 cm / 5x7″"],
      PrintSize["15x20 cm / 6x8″"],
      PrintSize["A4 21x29.7 cm / 8x12″"],
      PrintSize["28x43 cm / XL (11x17″)"],
    ],
    value: PrintSize["13x18 cm / 5x7″"],
  },
  {
    title: "Frame",
    values: [
      PrintFrame.Black,
      PrintFrame["Dark wood"],
      PrintFrame.Wood,
      PrintFrame.White,
    ],
    value: PrintFrame.Black,
  },
]

const PRINT_SIZE_VARIANTS: {
  [key in PrintSize]: {
    [key in PrintFrame]: {
      price: string
      variant: string
      img: string[]
    }
  }
} = {
  "13x18 cm / 5x7″": {
    Black: {
      price: "$35.16",
      variant: "44944559178049",
      img: [frame5_1b, frame5_2b, frame5_3b],
    },
    "Dark wood": {
      price: "$36.78",
      variant: "44944559210817",
      img: [frame5_1dwd, frame5_2dwd, frame5_3dwd],
    },
    Wood: {
      price: "$36.78",
      variant: "44944559243585",
      img: [frame5_1wd, frame5_2wd, frame5_3wd],
    },
    White: {
      price: "$35.16",
      variant: "44944559276353",
      img: [frame5_1w, frame5_2w, frame5_3w],
    },
  },
  "15x20 cm / 6x8″": {
    Black: {
      price: "$35.40",
      variant: "44944559309121",
      img: [frame6_1b, frame6_2b, frame6_3b],
    },
    "Dark wood": {
      price: "$37.06",
      variant: "44944559341889",
      img: [frame6_1dwd, frame6_2dwd, frame6_3dwd],
    },
    Wood: {
      price: "$37.06",
      variant: "44944559374657",
      img: [frame6_1wd, frame6_2wd, frame6_3wd],
    },
    White: {
      price: "$35.40",
      variant: "44944559407425",
      img: [frame6_1w, frame6_2w, frame6_3w],
    },
  },
  "A4 21x29.7 cm / 8x12″": {
    Black: {
      price: "$40.98",
      variant: "44944559440193",
      img: [frame8_1b, frame8_2b, frame8_3b],
    },
    "Dark wood": {
      price: "$43.18",
      variant: "44944559472961",
      img: [frame8_1dwd, frame8_2dwd, frame8_3dwd],
    },
    Wood: {
      price: "$43.18",
      variant: "44944559505729",
      img: [frame8_1wd, frame8_2wd, frame8_3wd],
    },
    White: {
      price: "$40.98",
      variant: "44944559636801",
      img: [frame8_1w, frame8_2w, frame8_3w],
    },
  },
  "28x43 cm / XL (11x17″)": {
    Black: {
      price: "$53.00",
      variant: "44944559571265",
      img: [frame11_1b, frame11_2b, frame11_3b],
    },
    "Dark wood": {
      price: "$55.82",
      variant: "44944559604033",
      img: [frame11_1dwd, frame11_2dwd, frame11_3dwd],
    },
    Wood: {
      price: "$55.82",
      variant: "44944559636801",
      img: [frame11_1wd, frame11_2wd, frame11_3wd],
    },
    White: {
      price: "$53.00",
      variant: "44944559669569",
      img: [frame11_1w, frame11_2w, frame11_3w],
    },
  },
}

export default function CreationPrintFrame() {
  const [settings, setSettings] = useState(DEFAULT_PRINT_SETTINGS)
  const { creationId } = useParams()

  const size = settings[0].value as PrintSize
  const frameType = settings[1].value as PrintFrame
  const price = PRINT_SIZE_VARIANTS[size][frameType].price
  const img = PRINT_SIZE_VARIANTS[size][frameType].img
  const variant = PRINT_SIZE_VARIANTS[size][frameType].variant

  return (
    <PrintShopCharacterManager
      variant={variant}
      creationId={creationId}
      price={price}
      productImgs={img}
      title="READY-TO-HANG FRAMED POSTER"
      description={`Museum-quality paper.

200 gsm / 80 lb matte (uncoated) paper

Thickness: 20-25 mm /0.79"–0.98" and for the USA market thickness is 1.9 cm/0.75"

Shatterproof, transparent plexiglass.

Includes a hanging kit.

For indoor use.

Ready-to-hang, poster is placed within the frame. The end recipient can hang it directly on the wall.`}
      product="Transform your artwork into a magical framed poster that will brighten up any room.
      Our expert printers use high-quality materials to ensure your print is vibrant and long-lasting.
      Hang your artwork proudly and show off your creative talent!"
      settings={settings}
      onSettingsChange={(title, value) => {
        const newSettings = [...settings]
        const setting = newSettings.find((setting) => setting.title === title)
        if (setting) {
          setting.value = value
          setSettings(newSettings)
        }
      }}
    />
  )
}
