import { useEffect, useMemo, useState } from "react"

import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Image,
  Spinner,
  Text,
} from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import CreationDAO from "../../../daos/CreationDAO"
import UserDAO from "../../../daos/UserDAO"
import Creation from "../../../data/models/Creation"
import { UserFirebaseWithId } from "../../../data/models/UserData"
import CreationService from "../../../service/CreationService"
import { useQuery } from "../../../utilities/hooks"

function CreationImages({ creation }: { creation: Creation }) {
  return (
    <>
      <Box>
        <Image
          src={creation.originalImagePath ?? creation.selectedImage}
          minW="160px"
          w="160px"
          h="160px"
          objectFit="cover"
        />
      </Box>
      {creation.selectedImage ? (
        <Box>
          <Image
            src={creation.selectedImage}
            minW="160px"
            w="160px"
            h="160px"
            objectFit="cover"
          />
        </Box>
      ) : creation.generativeAiResults?.length === 4 ? (
        <Flex flexWrap="wrap" w="160px">
          {creation.generativeAiResults.map((r) => (
            <Image key={r} src={r} w="80px" minW="80px" h="80px" />
          ))}
        </Flex>
      ) : (
        <Box w="160px" textAlign="center" my="auto" fontWeight="bold">
          Generating...
        </Box>
      )}
    </>
  )
}

function CreationShare({ creation }: { creation: Creation }) {
  let agreed = "No answer"
  switch (creation.shareAgreement) {
    case true:
      agreed = "Yes"
      break
    case false:
      agreed = "No"
      break
  }
  const [loading, setLoading] = useState(false)
  const onShare = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    CreationService.setShareable(creation, e.currentTarget.checked).finally(
      () => setLoading(false)
    )
  }

  return (
    <Box w="100px" position="relative" p="0.5rem">
      {loading && (
        <Flex
          position="absolute"
          w="100%"
          h="100%"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          bg="rgba(0,0,0,0.5)"
          borderRadius="8px"
          zIndex={1}
        >
          <Spinner m="auto" w="50px" h="50px" color="white" />
        </Flex>
      )}

      <Text>Share:</Text>
      <Flex gap="1rem">
        <Text size="sm">
          Can share? <br />
          <b>{agreed}</b>
        </Text>
      </Flex>
      {creation.shareAgreement && (
        <Flex gap="1rem">
          <Checkbox isChecked={creation.shareable} onChange={onShare}>
            <Text size="sm">Share</Text>
          </Checkbox>
        </Flex>
      )}
    </Box>
  )
}

function CreationUserInfo({
  creation,
  users,
  getUserInfo,
}: {
  creation: Creation
  users: UserFirebaseWithId[]
  getUserInfo: (uid: string) => Promise<UserFirebaseWithId | undefined>
}) {
  const [userData, setUserData] = useState<UserFirebaseWithId>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!creation.userId) return
    const user = users.find((u) => u.id === creation.userId)
    if (user) {
      setUserData(user)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])

  if (!userData) {
    return (
      <Flex w="200px">
        <Button
          m="auto"
          isLoading={loading}
          onClick={() => {
            if (!creation.userId) return
            setLoading(true)
            getUserInfo(creation.userId).finally(() => setLoading(false))
          }}
        >
          Get User Data
        </Button>
      </Flex>
    )
  }
  return (
    <Flex w="200px" flexDir="column">
      <Text
        size="xs"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
      >
        {userData.emailAddress}
      </Text>
      <Text
        size="sm"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
      >
        Child: {userData.childName}
      </Text>
      <Text
        size="sm"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
      >
        Username: {userData.childName}
      </Text>
      <Text
        size="sm"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
      >
        Pronouns: {userData.pronouns ?? "they/them"}
      </Text>
    </Flex>
  )
}

function CreationInfo({
  creation,
  getUserInfo,
  users,
}: {
  creation: Creation
  users: UserFirebaseWithId[]

  getUserInfo: (uid: string) => Promise<UserFirebaseWithId | undefined>
}) {
  return (
    <Flex
      w="100%"
      minW="fit-content"
      border="solid 1px black"
      borderRadius="16px"
      h="200px"
      p="1rem"
      gap="1rem"
    >
      <Flex flexDir="column" maxH="100%">
        <Text
          w="200px"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          title={creation.title}
        >
          {creation.title}
        </Text>
        <Text
          w="200px"
          whiteSpace="normal"
          overflow="hidden"
          textOverflow="ellipsis"
          size="sm"
          flex="1"
          title={creation.prompt ?? ""}
        >
          {creation.prompt}
        </Text>
        <Text fontSize="sm" fontWeight="bold">
          Created at: {creation.createdAt.toLocaleTimeString()}
        </Text>
      </Flex>
      <CreationImages creation={creation} />
      <CreationShare creation={creation} />
      <CreationUserInfo
        creation={creation}
        getUserInfo={getUserInfo}
        users={users}
      />
    </Flex>
  )
}

function PageNav({ page }: { page: number }) {
  const navigate = useNavigate()
  return (
    <Flex m="auto" py="1rem">
      <Button
        onClick={() =>
          navigate(`${window.location.pathname}?page=${Math.max(page - 1, 0)}`)
        }
      >
        Previous
      </Button>
      <Text my="auto" mx="1rem">
        {page}
      </Text>
      <Button
        onClick={() => navigate(`${window.location.pathname}?page=${page + 1}`)}
      >
        Next
      </Button>
    </Flex>
  )
}

function CreationsTable() {
  const [creations, setCreations] = useState<Creation[]>([])
  const [users, setUsers] = useState<UserFirebaseWithId[]>([])
  const query = useQuery()
  const page = useMemo(() => {
    try {
      return parseInt(query.get("page") ?? "0")
    } catch {
      return 0
    }
  }, [query])
  const [shownCreations, setShownCreations] = useState<Creation[]>([])
  const [onlyShareable, setOnlyShareable] = useState(false)
  const [hideShared, setHideShared] = useState(false)

  useEffect(() => {
    let filtered = creations
    if (onlyShareable) {
      filtered = filtered.filter((c) => c.shareAgreement)
    }
    if (hideShared) {
      filtered = filtered.filter((c) => !c.shareable)
    }
    setShownCreations(filtered.slice(page * 50, (page + 1) * 50))
  }, [page, creations, onlyShareable, hideShared])

  useEffect(() => {
    CreationDAO.listenToCreations((c) => {
      setCreations(
        c.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
      )
    })
  }, [])

  return (
    <Flex flexDir="column">
      <Box>
        <Heading size="sm">Filters:</Heading>
        <Flex gap="0.5rem">
          <Checkbox
            isChecked={onlyShareable}
            onChange={(e) => setOnlyShareable(e.currentTarget.checked)}
          >
            Only shareable
          </Checkbox>
          <Checkbox
            isChecked={hideShared}
            onChange={(e) => setHideShared(e.currentTarget.checked)}
          >
            Hide shared
          </Checkbox>
        </Flex>
      </Box>
      <PageNav page={page} />
      <Flex flexDir="column" gap="1rem" overflowX="auto">
        {shownCreations.map((creation, i) => (
          <CreationInfo
            key={creation.id}
            creation={creation}
            users={users}
            getUserInfo={async (id) => {
              const user = users.find((u) => u.id === id)
              if (user) return user
              return await UserDAO.getUser(id).then((user) => {
                if (!user.exists()) return undefined
                const userWithId: UserFirebaseWithId = {
                  ...user.data(),
                  id: id,
                }
                setUsers(users.concat([userWithId]))
                return userWithId
              })
            }}
          />
        ))}
      </Flex>
      <PageNav page={page} />
    </Flex>
  )
}

export default CreationsTable
