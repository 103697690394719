import { Icon, IconProps } from "@chakra-ui/react"

export function RedCloudsBottomCropped(props: IconProps) {
  return <RedCloudsBottom viewBox="0 0 500 85" width="500px" height="137px" {...props} />
}

export function RedCloudsBottom(props: IconProps) {
  return (
    <Icon
      width="1440px"
      height="137px"
      viewBox="0 0 1440 137"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color="primary.500"
      {...props}
    >
      <g clipPath="url(#clip0_4264_3477)">
        <path
          d="M144.144 93.6806C139.746 52.1592 169.84 14.9342 211.362 10.5361C252.883 6.13801 290.108 36.2324 294.506 77.7537C298.904 119.275 268.81 156.5 227.288 160.898C185.767 165.296 148.542 135.202 144.144 93.6806Z"
          fill="currentColor"
        />
        <path
          d="M144.144 93.6806C139.746 52.1592 169.84 14.9342 211.362 10.5361C252.883 6.13801 290.108 36.2324 294.506 77.7537C298.904 119.275 268.81 156.5 227.288 160.898C185.767 165.296 148.542 135.202 144.144 93.6806Z"
          fill="currentColor"
        />
        <path
          d="M246.665 109.214C242.267 67.6927 272.361 30.4677 313.882 26.0696C355.404 21.6715 392.629 51.7659 397.027 93.2872C401.425 134.809 371.33 172.034 329.809 176.432C288.288 180.83 251.063 150.735 246.665 109.214Z"
          fill="currentColor"
        />
        <path
          d="M-240.436 207.017C-249.698 119.577 -186.322 41.1846 -98.8822 31.9227C-11.4424 22.6607 66.9498 86.0364 76.2117 173.476C85.4736 260.916 22.0979 339.308 -65.3418 348.57C-152.782 357.832 -231.174 294.456 -240.436 207.017Z"
          fill="currentColor"
        />
        <path
          d="M587.897 353.807C580.112 280.311 633.382 214.42 706.878 206.635C780.374 198.85 846.265 252.12 854.05 325.615C861.835 399.111 808.565 465.003 735.069 472.788C661.573 480.573 595.682 427.303 587.897 353.807Z"
          fill="currentColor"
        />
        <path
          d="M527.145 138.934C521.894 89.3604 477.45 53.4298 427.876 58.6808C378.303 63.9318 342.372 108.376 347.623 157.95C352.874 207.523 397.318 243.454 446.892 238.203C496.466 232.952 532.396 188.508 527.145 138.934Z"
          fill="currentColor"
        />
        <path
          d="M627.156 160.739C623.356 124.86 591.189 98.8543 555.309 102.655C519.429 106.455 493.424 138.623 497.224 174.502C501.025 210.382 533.192 236.388 569.072 232.587C604.952 228.787 630.957 196.619 627.156 160.739Z"
          fill="currentColor"
        />
        <path
          d="M627.156 160.739C623.356 124.86 591.189 98.8543 555.309 102.655C519.429 106.455 493.424 138.623 497.224 174.502C501.025 210.382 533.192 236.388 569.072 232.587C604.952 228.787 630.957 196.619 627.156 160.739Z"
          fill="currentColor"
        />
        <path
          d="M223.843 358.301C213.299 258.756 285.449 169.51 384.994 158.966C484.539 148.422 573.784 220.572 584.328 320.117C594.873 419.662 522.723 508.907 423.178 519.451C323.633 529.995 234.388 457.846 223.843 358.301Z"
          fill="currentColor"
        />
        <path
          d="M-26.1143 475.058C-33.4066 406.214 16.4913 344.493 85.3356 337.201C154.18 329.908 215.901 379.806 223.193 448.651C230.485 517.495 180.587 579.216 111.743 586.508C42.8987 593.8 -18.8221 543.902 -26.1143 475.058Z"
          fill="currentColor"
        />
        <path
          d="M-12.1636 141.207C-19.4558 72.3628 30.442 10.6419 99.2863 3.3497C168.131 -3.94253 229.851 45.9553 237.144 114.8C244.436 183.644 194.538 245.365 125.694 252.657C56.8495 259.949 -4.87137 210.051 -12.1636 141.207Z"
          fill="currentColor"
        />
        <path
          d="M128.126 419.542C122.706 368.374 159.793 322.5 210.961 317.08C262.129 311.66 308.002 348.747 313.422 399.915C318.842 451.083 281.756 496.956 230.588 502.376C179.42 507.796 133.546 470.71 128.126 419.542Z"
          fill="currentColor"
        />
        <path
          d="M-204.578 366.206C-215.122 266.661 -142.973 177.416 -43.4275 166.871C56.1177 156.327 145.363 228.477 155.907 328.022C166.451 427.567 94.3015 516.812 -5.24368 527.356C-104.789 537.901 -194.034 465.751 -204.578 366.206Z"
          fill="currentColor"
        />
        <path
          d="M1015.09 366.473C1003.17 253.904 1084.76 152.982 1197.33 141.058C1309.9 129.134 1410.82 210.724 1422.74 323.294C1434.67 435.863 1353.08 536.785 1240.51 548.709C1127.94 560.633 1027.01 479.043 1015.09 366.473Z"
          fill="currentColor"
        />
        <path
          d="M964.661 136.724C961.785 109.566 981.469 85.2181 1008.63 82.3414C1035.78 79.4648 1060.13 99.1486 1063.01 126.306C1065.89 153.464 1046.2 177.812 1019.04 180.689C991.886 183.565 967.538 163.882 964.661 136.724Z"
          fill="currentColor"
        />
        <path
          d="M1024.28 131.528C1021.08 101.264 1043.01 74.1314 1073.28 70.9258C1103.54 67.7201 1130.67 89.6552 1133.88 119.919C1137.08 150.183 1115.15 177.315 1084.89 180.521C1054.62 183.727 1027.49 161.792 1024.28 131.528Z"
          fill="currentColor"
        />
        <path
          d="M1092.48 106.468C1090.02 83.1882 1106.89 62.3169 1130.17 59.851C1153.45 57.3851 1174.32 74.2583 1176.79 97.5385C1179.25 120.819 1162.38 141.69 1139.1 144.156C1115.82 146.622 1094.95 129.748 1092.48 106.468Z"
          fill="currentColor"
        />
        <path
          d="M888.695 427.557C878.151 328.011 950.3 238.766 1049.85 228.222C1149.39 217.678 1238.64 289.828 1249.18 389.373C1259.72 488.918 1187.57 578.163 1088.03 588.707C988.484 599.251 899.239 527.102 888.695 427.557Z"
          fill="currentColor"
        />
        <path
          d="M1231.69 224.291C1221.15 124.746 1293.3 35.501 1392.84 24.9568C1492.39 14.4127 1581.63 86.5622 1592.18 186.107C1602.72 285.653 1530.57 374.898 1431.03 385.442C1331.48 395.986 1242.24 323.836 1231.69 224.291Z"
          fill="currentColor"
        />
        <path
          d="M1119.93 166.709C1113.66 107.48 1156.59 54.3794 1215.82 48.1056C1275.05 41.8319 1328.15 84.7608 1334.42 143.99C1340.7 203.219 1297.77 256.32 1238.54 262.594C1179.31 268.867 1126.21 225.938 1119.93 166.709Z"
          fill="currentColor"
        />
        <path
          d="M769.174 424.886C761.882 356.042 811.78 294.321 880.624 287.029C949.468 279.737 1011.19 329.635 1018.48 398.479C1025.77 467.323 975.876 529.044 907.032 536.336C838.187 543.628 776.466 493.731 769.174 424.886Z"
          fill="currentColor"
        />
        <path
          d="M748.65 298.666C738.106 199.121 810.255 109.876 909.8 99.3318C1009.35 88.7876 1098.59 160.937 1109.13 260.482C1119.68 360.028 1047.53 449.273 947.984 459.817C848.439 470.361 759.194 398.211 748.65 298.666Z"
          fill="currentColor"
        />
        <path
          d="M572.383 223.094C566.536 167.901 606.54 118.418 661.734 112.572C716.927 106.726 766.41 146.73 772.256 201.923C778.102 257.117 738.098 306.599 682.905 312.445C627.711 318.292 578.229 278.288 572.383 223.094Z"
          fill="currentColor"
        />
        <path
          d="M684.223 201.348C678.377 146.154 718.381 96.6716 773.574 90.8254C828.768 84.9791 878.25 124.983 884.097 180.176C889.943 235.37 849.939 284.852 794.746 290.699C739.552 296.545 690.07 256.541 684.223 201.348Z"
          fill="currentColor"
        />
        <path
          d="M1244.36 343.891C1237.27 276.908 1285.82 216.855 1352.8 209.76C1419.78 202.665 1479.84 251.214 1486.93 318.198C1494.03 385.181 1445.48 445.234 1378.49 452.329C1311.51 459.424 1251.46 410.875 1244.36 343.891Z"
          fill="currentColor"
        />
        <path
          d="M449.075 394.063C441.98 327.079 490.529 267.026 557.512 259.931C624.496 252.836 684.549 301.385 691.644 368.369C698.739 435.353 650.19 495.405 583.206 502.5C516.223 509.596 456.17 461.046 449.075 394.063Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_4264_3477">
          <rect width="1440" height="137" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  )
}
