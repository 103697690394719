import { Box, Button, Grid, Heading, Image, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useSegment } from "../../../context/SegmentContext"
import { useUserContext } from "../../../context/userContext"
import CreationDAO from "../../../daos/CreationDAO"
import { useSketchQuests } from "../../../data/apis/SketchQuestUtils"
import Creation from "../../../data/models/Creation"
import SketchQuestModule from "../../../data/models/SketchQuest"
import CreationService from "../../../service/CreationService"
import { sleep } from "../../../utilities/sleep"
import BackButton from "../../components/Buttons/BackButton"
import BrushIcon from "../../components/Icons/BrushIcon"
import LoadingScreen from "../../components/LoadingScreen"
import Background from "../../components/Wrapper/Background"
import BackgroundImageBlurred from "../../components/Wrapper/BackgroundImageBlurred"
import ResponsiveContainer from "../../components/Wrapper/ResponsiveContainer"
import ImageDescription from "../Upload/Forms/CreationDescription"
import { UploadLoading } from "../Upload/Upload"
import SketchsPresentation from "./SketchsPresentation"
import WeeklyThemePresentation from "./WeeklyThemePresentation"
import bg from "../../assets/images/backgrounds/upload-background.png"

export function WeeklyThemeUploadImage({
  drawingUrl,
  clearDrawing,
  onUpload,
}: {
  drawingUrl: string
  clearDrawing: () => void
  onUpload: (description: string | undefined) => Promise<void>
}) {
  const [transform, setTransform] = useState(false)
  const [description, setDescription] = useState("")
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const navigate = useNavigate()

  function handleUpload() {
    setLoading(true)
    let desc = description.trim()
    if (transform && desc.length < 3) {
      sleep(1000).then(() => {
        toast({
          title: "Description too short",
          description: "Please describe your drawing in a few words",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        })
        setLoading(false)
      })
    } else {
      onUpload(transform ? desc : undefined)
        .then(() => {
          navigate("/app")
        })
        .finally(() => setLoading(false))
    }
  }

  return (
    <ResponsiveContainer
      pt={["116px", "166px", "200px"]}
      display="flex"
      flexDir="column"
      maxW="500px"
      gap="1rem"
    >
      <BackgroundImageBlurred
        blur={false}
        backgroundImage={bg}
        bgPos="50% 50%"
        backgroundColor="transparent"
      />
      {loading && <UploadLoading overlay />}
      <Image
        src={drawingUrl}
        mx="auto"
        w="80%"
        maxH="40vh"
        objectFit="cover"
        borderRadius="20px"
        filter="drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.25));"
      />
      {transform && (
        <Box w="80%" mx="auto" mt="1rem">
          <ImageDescription
            description={description}
            setDescription={setDescription}
            imageUrl={drawingUrl}
          />
        </Box>
      )}
      <Button
        w="80%"
        mx="auto"
        onClick={handleUpload}
        isLoading={loading}
        loadingText="Uploading..."
      >
        Complete the quest
      </Button>
      {!transform && (
        <Button
          isDisabled={loading}
          w="80%"
          mx="auto"
          variant="fullSecondary"
          onClick={() => setTransform(true)}
        >
          Transform it with AI
        </Button>
      )}
      <Button
        onClick={clearDrawing}
        variant="ghostPrimary"
        textAlign="right"
        w="80%"
        mx="auto"
        color="white"
        filter="drop-shadow(0 0 4px rgba(0,0,0,0.5))"
      >
        <BrushIcon width="1.5em" height="1.5em" />
        Change the drawing
      </Button>
    </ResponsiveContainer>
  )
}

export default function WeeklyTheme() {
  const { themeId } = useParams()
  const { track } = useSegment()
  const { sketchQuests, sketchQuestState } = useSketchQuests()
  const [drawingUrl, setDrawingUrl] = useState<string>()
  const navigate = useNavigate()
  const { user } = useUserContext()
  const [userSketchs, setUserSketchs] = useState<Creation[]>([])
  const [sketchQuest, setSketchQuest] = useState<SketchQuestModule>()

  useEffect(() => {
    if (sketchQuestState !== "loaded") return
    const module = sketchQuests.find(
      (module: SketchQuestModule) => module._key === themeId
    )
    if (!module) return navigate("/app", { replace: true })
    setSketchQuest(module)
  }, [sketchQuestState, sketchQuests, navigate, themeId])

  useEffect(() => {
    if (user && user.id && themeId) {
      CreationDAO.getCreationsByQuestId(user.id, themeId).then(
        (userSketchs) => {
          setUserSketchs(
            userSketchs
            // .filter(
            //   (creation) =>
            //     creation.selectedImage ||
            //     creation.originalImagePath ||
            //     creation.generativeAiResults?.length === 4
            // )
          )
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, themeId])

  const handleUpload = async (description: string | undefined) => {
    if (!user || !sketchQuest) return
    return CreationService.uploadQuestCreation(
      user!,
      drawingUrl,
      sketchQuest.title ?? "",
      description,
      sketchQuest._key
    ).then(() => {
      track("Sketch Quest Upload", {
        questId: sketchQuest._key,
        questTitle: sketchQuest.title ?? "",
        questType: sketchQuest._type,
      })
      if (description) {
        navigate("/app")
      } else {
        // TODO show uploaded quest creation
      }
    })
  }

  if (!sketchQuest) {
    return <LoadingScreen />
  }

  let content = null

  if (drawingUrl) {
    content = (
      <WeeklyThemeUploadImage
        onUpload={handleUpload}
        drawingUrl={drawingUrl}
        clearDrawing={() => setDrawingUrl(undefined)}
      />
    )
  } else {
    content = (
      <>
        {userSketchs.length > 0 && (
          <SketchsPresentation creations={userSketchs} />
        )}
        <WeeklyThemePresentation
          module={sketchQuest}
          setDrawingUrl={setDrawingUrl}
          onUpload={handleUpload}
        />
      </>
    )
  }

  return (
    <Background overflowY="auto">
      <BackgroundImageBlurred />
      <ResponsiveContainer
        maxW="800px"
        pt="2rem !important"
        display="flex"
        flexDir="column"
      >
        <Grid w="100%" gridTemplateColumns="50px 1fr 50px">
          <BackButton
            color="white"
            onClick={() => {
              if (drawingUrl) {
                setDrawingUrl(undefined)
              } else {
                navigate(-1)
              }
            }}
          />
          <Heading
            size={["sm", "md"]}
            color="white"
            m="auto"
            textAlign="center"
          >
            Sketch Quest
          </Heading>
        </Grid>
        {content}
      </ResponsiveContainer>
    </Background>
  )
}
