import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  User,
  UserCredential,
} from "firebase/auth"
import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  getDocs,
  limit,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore"
import { auth, db } from "../data/apis/firebase/Firebase"
import { UserFirebase, UserFirebaseWithId } from "../data/models/UserData"

function getDayWithFormat(): string {
  const date = new Date()
  const year = date.toLocaleDateString("en-CA", { year: "numeric" })
  const month = date.toLocaleDateString("en-CA", { month: "2-digit" })
  const day = date.toLocaleDateString("en-CA", { day: "2-digit" })
  return `${year}-${month}-${day}`
}

export default class UserDAO {
  static async signInUser(
    email: string,
    password: string
  ): Promise<UserCredential> {
    return signInWithEmailAndPassword(auth, email, password)
  }

  static async getAllUsers(): Promise<UserFirebaseWithId[]> {
    const coll = collection(
      db,
      "users"
    ) as CollectionReference<UserFirebaseWithId>
    return await getDocs<UserFirebaseWithId>(
      query<UserFirebaseWithId>(coll, limit(10000))
    ).then((q) => q.docs.map((d) => ({ ...d.data(), id: d.id })))
  }

  static async registerUser(
    email: string,
    password: string
  ): Promise<UserCredential> {
    const cred = await createUserWithEmailAndPassword(auth, email, password)

    const fbUser: UserFirebase = {
      onboardingDone: false,
      emailAddress: email,
      appLastOpenedAt: new Date().toISOString(),
      feedbackEmailSent: false,
      lastClaimReward: getDayWithFormat(),
    }

    await setDoc<UserFirebase>(
      doc(db, "users", cred.user.uid) as DocumentReference<UserFirebase>,
      fbUser
    )

    // if (auth.currentUser) {
    // updateProfile(auth.currentUser, {
    //   displayName: childName,
    // })
    // }

    const coll = collection(db, "invited_emails")
    const query_ = query(coll, where("email", "==", email))
    // const invitedEmailRef = ().docs[0].ref;

    await getDocs(query_)
      .then(async (q) => {
        const invitedEmailRef = q.docs[0].ref

        await setDoc(
          invitedEmailRef,
          {
            email: email,
            has_signed: true,
            user_id: cred.user.uid,
            updated_at: new Date().toISOString(),
          },
          { merge: true }
        )
      })
      .catch(() => {})

    return cred
  }

  static async registerWithSSO(user: User, onRegister?: (fbUser: UserFirebase) => void) {
    if ((await getDoc(doc(db, "users", user.uid))).exists()) return
    const fbUser: UserFirebase = {
      onboardingDone: false,
      emailAddress: user.email ?? "",
      appLastOpenedAt: new Date().toISOString(),
      feedbackEmailSent: false,
      lastClaimReward: getDayWithFormat(),
    }
    if (onRegister) onRegister(fbUser)

    await setDoc<UserFirebase>(
      doc(db, "users", user.uid) as DocumentReference<UserFirebase>,
      fbUser
    )
  }

  static async getUser(id: string): Promise<DocumentSnapshot<UserFirebase>> {
    const userDoc = await getDoc<UserFirebase>(
      doc(db, "users", id) as DocumentReference<UserFirebase>
    )
    updateDoc(doc(db, "users", id), {
      appLastOpenedAt: new Date().toISOString(),
    })
    return userDoc
  }

  static async setOnboardingDone(uid: string): Promise<void> {
    updateDoc(doc(db, "users", uid), {
      appLastOpenedAt: new Date().toISOString(),
      onboardingDone: true,
    })
  }

  static async updateField(
    uid: string,
    field: string,
    value: any
  ): Promise<void> {
    updateDoc(doc(db, "users", uid), {
      appLastOpenedAt: new Date().toISOString(),
      [field]: value,
    })
  }

  static async logout(): Promise<void> {
    await signOut(auth)
  }

  static async resetPassword(email: string): Promise<void> {
    await sendPasswordResetEmail(auth, email)
  }
}
