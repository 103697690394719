import { Box, Flex } from "@chakra-ui/react"
import Creation from "../../../data/models/Creation"
import PrintBookActionBlock from "../../components/ActionBlocks/PrintBookActionBlock"
import UploadActionBlock from "../../components/ActionBlocks/UploadActionBlock"
import { ComicBookActionBlock } from "../../components/ActionBlocks/ComicBookActionBlock"

export default function CreateActionBlocks({
  alwaysColumn = false,
  showCreation,
}: {
  alwaysColumn?: boolean
  showCreation?: Creation
}) {
  return (
    <Flex
      pt="2em"
      flexDir={["column", "column", "column", alwaysColumn ? "column" : "row"]}
      gap="1rem"
    >
      {showCreation && (
        <Box flex="1">
          <PrintBookActionBlock creation={showCreation} />
        </Box>
      )}
      <Box flex="1">
        <UploadActionBlock />
      </Box>
      {!showCreation && (
        <Box flex="1">
          <ComicBookActionBlock />
        </Box>
      )}
    </Flex>
  )
}
