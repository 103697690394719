import { Box, BoxProps, Button, Flex, Image, Text } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import storiesIllustration from "../../assets/images/createPage/story-book-illustration.png"

export function ComicBookActionBlock({
  disableClick = false,
  onCreateStories,
  ...props
}: {
  disableClick?: boolean
  onCreateStories?: () => void
} & BoxProps) {
  const navigate = useNavigate()
  const clickFct = onCreateStories ?? (() => navigate("/stories"))
  return (
    <Flex
      bg="#5F6FFF"
      borderRadius="20px"
      minH="168px"
      mt="0.5rem"
      p="1rem"
      position="relative"
      {...props}
    >
      <Box placeSelf="center" w="60%" textAlign="left">
        <Text variant="solid" size="md" fontWeight="bold">
          Make your own MiniTales
        </Text>
        <Text variant="solid" size="xs">
          Your characters, your stories
        </Text>
        <Button
          onClick={disableClick ? undefined : clickFct}
          mt="1em"
          variant="white"
          size="sm"
        >
          Create stories
        </Button>
      </Box>
      <Image
        position="absolute"
        src={storiesIllustration}
        h={["160px", "180px"]}
        top="-10px"
        right="-10px"
      />
      <Text
        position="absolute"
        size="sm"
        color="white"
        fontWeight="bold"
        px="1rem"
        bg="secondary.500"
        top="0"
        right="50px"
        transform="translateY(-50%)"
      >
        NEW
      </Text>
    </Flex>
  )
}
