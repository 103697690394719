import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Link,
  Text,
} from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useLoading } from "../../../context/LoadingContext"
import { useSegment } from "../../../context/SegmentContext"
import { useUserContext } from "../../../context/userContext"
import giftBck from "../../assets/images/backgrounds/giftBck.jpg"
import AuthContainer from "../../components/Wrapper/AuthContainer"
import PasswordInput from "../../components/Input/PasswordInput"

export default function SignUp() {
  const [email, setEmail] = useState("")
  const [pass, setPass] = useState("")
  const { setLoading } = useLoading()
  const { track } = useSegment()

  const { registerUser, authError, setAuthError, isLoadingUser } =
    useUserContext()

  const emailUpdateHandler: React.ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setEmail(target.value)
  }
  const passUpdateHandler: React.ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setPass(target.value)
  }
  const onSubmit: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    setLoading(true)
    e.preventDefault()
    if (email && pass) {
      const search = window.location.search
      registerUser(email, pass).then((success) => {
        if (success) {
          track("Sign Up Part 1", { email, type: "email" })
          navigate(`/signup-info${search}`)
        }
      })
    } else {
      setAuthError("Invalid email, password, name, or pronouns")
    }
    setLoading(false)
  }
  const navigate = useNavigate()

  useEffect(() => {
    const allImages = [giftBck]
    const cacheAssets = async (imagesArray: any[], callback: () => any) => {
      setLoading(true)
      const promises = imagesArray.map((src) => {
        return new Promise((resolve, reject) => {
          const img = new Image()
          img.src = src
          img.onload = () => resolve(true)
          img.onerror = () => reject(true)
        })
      })
      await Promise.all(promises)
      callback()
    }
    cacheAssets(allImages, () => {
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AuthContainer>
      <FormControl
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        p={"1em"}
      >
        <Box width={"full"}>
          <FormLabel>
            <Text fontSize="12px" color="secondary.500" fontWeight="bold">
              Choose an email for your account
            </Text>
            <Input
              name="email"
              id="email"
              border="solid 1px #dbdbdb"
              type="text"
              placeholder="Email"
              onChange={emailUpdateHandler}
              borderRadius="100px"
              _placeholder={{
                color: "#666",
              }}
              textAlign="center"
            />
          </FormLabel>
          <FormLabel>
            <Text fontSize="12px" color="secondary.500" fontWeight="bold">
              Create a password for your account
            </Text>
            <PasswordInput
              textAlign="center"
              border="solid 1px #dbdbdb"
              borderRadius={100}
              placeholder="Password"
              onChange={passUpdateHandler}
              _placeholder={{
                color: "#666",
              }}
            />
          </FormLabel>
        </Box>
        {authError && (
          <Text size="xs" variant="error" textAlign="center" fontWeight="bold">
            {authError}
          </Text>
        )}
        <Text align="center" fontSize="12px" variant="raleway" color="black">
          {"By signing up you agree to our "}
          <Link
            href="https://www.termsfeed.com/live/2bfb1178-f967-4952-bc0a-d859bed1a4a7"
            target={"_blank"}
            fontWeight="bold"
            color="secondary.500"
            textDecor="underline"
          >
            Terms & Conditions and Privacy Policy
          </Link>
        </Text>
        <Button
        variant="fullSecondary"
          mt="1rem"
          type="submit"
          onClick={onSubmit}
          width="full"
          isLoading={isLoadingUser}
          loadingText="Creating your account..."
        >
          Sign up
        </Button>
        <Button
          mt="1rem"
          variant="link"
          color="secondary.500"
          onClick={() => navigate(`/login${window.location.search}`)}
          width="full"
          textDecor="underline"
        >
          Already have an account? Login
        </Button>
      </FormControl>
    </AuthContainer>
  )
}
