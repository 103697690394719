import { Box, useToken } from "@chakra-ui/react"
import { WebtoonPanelTransition } from "../../../../data/models/Webtoon"

function TransitionLine({
  height,
  direction,
  position,
  absolute,
}: {
  height: number
  direction: "up" | "down"
  position: "top" | "bottom"
  absolute: boolean
}) {
  if (!absolute) return null
  const borderSettings = "min(calc(500px), 100vw) solid transparent"
  let dir = direction
  if (position === "bottom") {
    if (dir === "up") dir = "down"
    else dir = "up"
  }
  return (
    <>
      <Box
        w="100%"
        position="absolute"
        top={position === "top" ? 0 : undefined}
        bottom={position === "bottom" ? 0 : undefined}
        left="0"
        height="7px"
        bg="black"
        zIndex={1}
      />
      <Box
        display="inline-block"
        borderTop={position === "top" ? `${height}px solid black` : undefined}
        borderBottom={
          position === "bottom" ? `${height}px solid black` : undefined
        }
        borderRight={dir === "up" ? borderSettings : undefined}
        borderLeft={dir === "down" ? borderSettings : undefined}
        height="0"
        width="0"
        top={position === "top" ? "7px" : undefined}
        bottom={position === "bottom" ? "7px" : undefined}
        left="0"
        position="absolute"
        zIndex={1}
      />
      <Box
        display="inline-block"
        borderTop={position === "top" ? `${height}px solid white` : undefined}
        borderBottom={
          position === "bottom" ? `${height}px solid white` : undefined
        }
        borderRight={dir === "up" ? borderSettings : undefined}
        borderLeft={dir === "down" ? borderSettings : undefined}
        height="0"
        width="0"
        position="absolute"
        top={position === "top" ? "-0.5px" : undefined}
        bottom={position === "bottom" ? "-0.5px" : undefined}
        left="0"
        zIndex={1}
      />
    </>
  )
}

function TransitionFade({
  position,
  color,
  absolute,
}: {
  position: "top" | "bottom"
  color: string
  absolute: boolean
}) {
  const c = useToken("hex", [color])

  if (!absolute) return null

  return (
    <Box
      position="absolute"
      top={position === "top" ? "-1px" : undefined}
      bottom={position === "bottom" ? "-1px" : undefined}
      width="100%"
      h="120px"
      background={
        position === "top"
          ? `linear-gradient(180deg, ${c} 0%, rgba(0, 0, 0, 0.465398) 16.87%, rgba(0, 0, 0, 0) 100%)`
          : `linear-gradient(0deg, ${c} 0%, rgba(0, 0, 0, 0.465398) 16.87%, rgba(0, 0, 0, 0) 100%)`
      }
      zIndex={1}
    />
  )
}

function TransitionPadding({
  height,
  color = "transparent",
  absolute,
}: {
  height: number
  color?: string
  absolute: boolean
}) {
  if (absolute) return null
  return <Box width="100%" h={`${height}px`} bgColor={color} />
}

export default function WebtoonTransition({
  transition,
  absolute,
}: {
  transition: WebtoonPanelTransition
  absolute: boolean
}) {
  switch (transition.type) {
    case "LINE":
      return (
        <TransitionLine
          direction={transition.direction}
          height={transition.height ?? 0}
          position={transition.position}
          absolute={absolute}
        />
      )
    case "FADE":
      return (
        <TransitionFade
          color={transition.color}
          position={transition.position}
          absolute={absolute}
        />
      )
    case "PADDING":
      return (
        <TransitionPadding
          height={transition.height ?? 0}
          color={transition.color}
          absolute={absolute}
        />
      )
    case "NULL":
      return null
    default:
      return null
  }
}
