import { Box, Flex, Image, Text } from "@chakra-ui/react"
import DOMPurify from "dompurify"
import { useEffect, useRef } from "react"
import { WebtoonPanel } from "../../../../data/models/Webtoon"
import SpeechBalloon from "../../../components/SpeechBalloon"
import NarratoryBlock from "./NarratoryBlock"
import WebtoonAnimatedAssetElement from "./WebtoonAnimatedAsset"
import WebtoonAssetElement from "./WebtoonAssetElement"
import WebtoonMaskElem from "./WebtoonMask"
import WebtoonTransition from "./WebtoonTransition"
import WebtoonFixedAssetElement from "./WebtoonFixedAsset"

export default function WebtoonPanelElement({
  panel,
  className,
}: {
  panel: WebtoonPanel
  className?: string
}) {
  const ref = useRef<HTMLVideoElement>(null)
  const rootRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!ref || !ref.current || ref.current.src.length) return
    fetch(panel.video!).then((res) => {
      res.blob().then((blob) => {
        ref.current!.src = URL.createObjectURL(blob)
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])

  return (
    <Box
      mx="-1em"
      bg={panel.backgroundColor}
      ref={rootRef}
      className={className}
    >
      <Box position="relative">
        {panel.transitions?.map(
          (t) =>
            t.position === "top" && (
              <WebtoonTransition
                key={`${t.position}-${t.type}`}
                transition={t}
                absolute={false}
              />
            )
        )}
        <Box position="relative">
          <Box position="relative" display="flex" flexDir="column">
            {panel.speechBalloons?.map(
              (balloon, i) =>
                balloon.position === "top" && (
                  <Flex
                    key={balloon.text}
                    position="absolute"
                    bottom={`calc(60px + ${
                      (1 - (balloon.offsetY ?? 0)) * 100
                    }%)`}
                    px="0.5rem"
                    w="100%"
                  >
                    <SpeechBalloon key={i} {...balloon} />
                  </Flex>
                )
            )}
            <Box
              position="relative"
              className={panel.effect === "SHAKING" ? "panel-shake" : undefined}
              // minH={isLoading ? "500px" : undefined}
            >
              {panel.transitions?.map(
                (t) =>
                  t.position === "top" && (
                    <WebtoonTransition
                      key={`${t.position}-${t.type}`}
                      transition={t}
                      absolute
                    />
                  )
              )}
              {panel.video ? (
                <video
                  src={panel.video}
                  playsInline
                  style={{
                    width: "100%",
                  }}
                  autoPlay
                  muted
                  loop
                  disablePictureInPicture
                ></video>
              ) : (
                panel.image && <Image src={panel.image} w="100%" />
              )}
              {panel.masks?.map((mask, i) => (
                <WebtoonMaskElem mask={mask} key={i} />
              ))}
              {panel.animatedAssets?.map((asset, i) => (
                <WebtoonAnimatedAssetElement
                  key={i}
                  asset={asset}
                  startAnimation={true}
                />
              ))}
              {panel.fixedAssets?.map((asset, i) => (
                <WebtoonFixedAssetElement key={i} asset={asset} />
              ))}
              {panel.transitions?.map(
                (t) =>
                  t.position === "bottom" && (
                    <WebtoonTransition
                      key={`${t.position}-${t.type}`}
                      transition={t}
                      absolute
                    />
                  )
              )}
            </Box>
            {panel.speechBalloons?.map(
              (balloon, i) =>
                balloon.position === "bottom" && (
                  <Flex
                    key={balloon.text}
                    position="absolute"
                    top={`calc(60px + ${(1 + (balloon.offsetY ?? 0)) * 100}%)`}
                    px="0.5rem"
                    w="100%"
                  >
                    <SpeechBalloon key={i} {...balloon} />
                  </Flex>
                )
            )}
            {panel.narratoryBlock && <NarratoryBlock panel={panel} />}
          </Box>
        </Box>
        {panel.movableAsset && (
          <WebtoonAssetElement asset={panel.movableAsset} />
        )}
      </Box>
      {panel.text && (
        <Text
          maxW={`${(panel.maxWidthPercentage || 1) * 100}%`}
          textAlign={panel.textAlign as any}
          mx="auto"
          variant="cartoon"
          p="1rem"
          fontSize={`${panel.size || 24}px`}
          color={panel.color}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(panel.text, {
              FORBID_TAGS: ["script"],
            }),
          }}
        />
      )}
      {panel.transitions?.map(
        (t) =>
          t.position === "bottom" && (
            <WebtoonTransition
              key={`${t.position}-${t.type}`}
              transition={t}
              absolute={false}
            />
          )
      )}
    </Box>
  )
}
