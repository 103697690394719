import { initializeApp } from "firebase/app"
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth"
import {
  collection,
  getCountFromServer,
  getFirestore,
  query,
  where,
} from "firebase/firestore"
import { getStorage } from "firebase/storage"
import UserService from "../../../service/UserService"
import { UserFirebase } from "../../models/UserData"

const firebaseConfig = {
  apiKey: "AIzaSyDO2cL9IaAqGhCua98MKCggg5Wuyn80BjI",
  authDomain: "mini-studio-ai-for-kids.firebaseapp.com",
  projectId: "mini-studio-ai-for-kids",
  storageBucket: "mini-studio-ai-for-kids.appspot.com",
  messagingSenderId: "1049080514088",
  appId: "1:1049080514088:web:5b634ce69085301eb4d71d",
  measurementId: "G-B7MNZYR3FC",
}

export const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage()

export const getAvailableCreditsCount = async () => {
  const user = auth.currentUser
  if (!user) return 0

  const coll = collection(db, "users", user.uid, "credits")
  const query_ = query(coll, where("used", "==", false))
  const snapshot = await getCountFromServer(query_)
  return snapshot.data().count
}

export const googleAuthProvider = new GoogleAuthProvider()


export async function signInWithGoogle(onRegister?: (fbUser: UserFirebase) => void) {
  try {
    const result = await signInWithPopup(auth, googleAuthProvider);
    const credential = GoogleAuthProvider.credentialFromResult(result);
    if (credential && result.user) {
      const { user } = result;
      UserService.registerUserWithSSO(user, onRegister);
    }
    return result;
  } catch (error) {
    console.error("Google SSO Sign-in Error:", error);
    throw error;
  }
}