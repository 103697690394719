import { HamburgerIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Image,
  Text,
} from "@chakra-ui/react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import ball from "../../assets/images/mission-page/ball.png"
import backgroundImg from "../../assets/images/mission-page/intro-mission.png"
import section1 from "../../assets/images/mission-page/section-1.png"
import section2 from "../../assets/images/mission-page/section-2.png"
import section3 from "../../assets/images/mission-page/section-3.png"
import section4 from "../../assets/images/mission-page/section-4.png"
import section5 from "../../assets/images/mission-page/section-5.png"
import section6 from "../../assets/images/mission-page/section-6.png"
import section7 from "../../assets/images/mission-page/section-7.png"
import section8 from "../../assets/images/mission-page/section-8.png"
import stars from "../../assets/images/mission-page/stars.png"
import MinistudioLogo from "../../components/ImagePresets/MinistudioLogo"
import BurgerMenu from "../../components/Popup/BurgerMenu"
import ResponsiveContainer from "../../components/Wrapper/ResponsiveContainer"
import Footer from "../Home/Footer"

export default function Mission() {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)

  return (
    <Box overflow="auto">
      <IconButton
        aria-label="Open"
        my="auto"
        ml="0.5rem"
        onClick={() => setOpen(true)}
        variant="ghostWhite"
        fontSize="40px"
        position="fixed"
        top="2rem"
        right="1rem"
        bg="#0000007f"
        borderRadius="12px"
        height="fit-content"
        padding="8px 8px"
        zIndex={10}
      >
        <HamburgerIcon />
      </IconButton>
      <BurgerMenu onClose={() => setOpen(false)} open={open} />

      <Box
        position="relative"
        h="600px"
        minH="fit-content"
        w="100%"
        bg="#0000004f"
      >
        <Image
          src={backgroundImg}
          h="100%"
          w="100%"
          objectFit="cover"
          position="absolute"
          objectPosition="50% 30%"
          top="0"
          left="0"
          zIndex={-1}
        />
        <Box
          position="absolute"
          bottom="0"
          left="0"
          w="100%"
          height="20%"
          bg="linear-gradient(180deg, #27091C00 2.08%, #1B1335 67%)"
        />
        <ResponsiveContainer
          p="0!important"
          position="relative"
          width={["100%", "100%", "100%", "1000px", "1200px", "1400px"]}
          display="flex"
          flexDir="column"
        >
          <MinistudioLogo
            mt={["2rem", "2rem", "4rem"]}
            variant="white"
            height={["60px", "60px", "100px"]}
            width=""
            ml="1rem"
            mr="auto"
          />
          <Heading m="auto" textAlign="center" color="white">
            Mission
          </Heading>
        </ResponsiveContainer>
      </Box>
      <Box
        background="linear-gradient(180deg, #1c1336 0%, #211c59 100%)"
        mt="-1px"
      >
        <ResponsiveContainer>
          <Heading
            fontWeight="normal"
            fontSize={["30px", "32px", "38px", "50px", "60px"]}
            color="white"
            textAlign="center"
          >
            Foster children’s innate creative and curious spirit to incite a
            culture of possibility.
          </Heading>
        </ResponsiveContainer>
      </Box>

      <Box
        position="relative"
        h={["800px", "800px", "800px", "1000px"]}
        minH="fit-content"
        w="100%"
        mt="-1px"
      >
        <Image
          src={section1}
          h="100%"
          w="100%"
          objectFit="cover"
          position="absolute"
          objectPosition="50% 30%"
          top="0"
          left="0"
          zIndex={-1}
        />
        <Box
          position="absolute"
          top="0"
          left="0"
          w="100%"
          height="20%"
          bg="linear-gradient(0deg, #211c5900 2.08%, #211c59 67%)"
          zIndex={-1}
        />{" "}
        <Box
          position="absolute"
          bottom="0"
          left="0"
          w="100%"
          height="20%"
          bg="linear-gradient(180deg, #5141c600 2.08%, #5141c6 67%)"
          zIndex={-1}
        />
        <ResponsiveContainer display="flex">
          <Heading m="auto" textAlign="center" color="white">
            Unleashing Imagination: Empowering Dreams.
          </Heading>
        </ResponsiveContainer>
      </Box>
      <Box background="linear-gradient(180deg, #5141c6 0%, #376ddc 100%)">
        <ResponsiveContainer>
          <Text color="white" fontSize={["17px", "17px", "17px", "30px"]}>
            We are empowering kids to believe in their own unlimited potential.
            By crafting technology purposefully, safely, and ethically our brand
            stokes imagination and invites meaningful connection through art and
            learning. We believe that kids who feel creatively empowered grow up
            to be more resilient, optimistic, and successful.
          </Text>
        </ResponsiveContainer>
      </Box>

      <Box
        position="relative"
        h={["800px", "1000px", "1200px", "2000px"]}
        minH="fit-content"
        w="100%"
        mt="-1px"
      >
        <Image
          src={section2}
          h="100%"
          w="100%"
          objectFit="cover"
          position="absolute"
          objectPosition="50% 80%"
          top="0"
          left="0"
          zIndex={-1}
        />
        <Box
          position="absolute"
          top="0"
          left="0"
          w="100%"
          height="50%"
          bg="linear-gradient(0deg, #376ddc00 2.08%, #376ddc 67%)"
          zIndex={-1}
        />
        <Box
          position="absolute"
          bottom="0"
          left="0"
          w="100%"
          height="20%"
          bg="linear-gradient(180deg, #021d4700 2.08%, #021d47 67%)"
          zIndex={-1}
        />
        <ResponsiveContainer display="flex" flexDir="column" gap="2rem">
          <Image src={stars} w="120px" mx="auto" mt="2rem" />
          <Heading
            fontWeight="normal"
            fontSize={["30px", "32px", "38px", "50px", "60px"]}
            color="white"
            textAlign="center"
          >
            Inspire children and caregivers to imagine and create a better world
            together.
          </Heading>
          <Flex
            gap="1rem"
            flexDir={["column-reverse", "column-reverse", "row"]}
            mt="auto"
          >
            <Heading
              fontSize={["40px", "40px", "40px", "50px", "60px"]}
              color="white"
              textAlign="center"
              placeSelf="center"
            >
              Tech for Good
            </Heading>
            <Image src={ball} w="60px" h="60px" mx={["auto", "auto", 0]} />
          </Flex>
        </ResponsiveContainer>
      </Box>

      <Box background="#021d47" mt="-1px">
        <ResponsiveContainer display="flex" flexDir="column">
          <Heading
            fontSize={["30px", "30px", "30px", "40px", "50px"]}
            color="white"
            fontWeight="normal"
            mb="1rem"
          >
            Privacy, safety and quality by design.{" "}
          </Heading>

          <Text color="white" fontSize={["17px", "17px", "17px", "30px"]}>
            We’re here to shape the future of kid-friendly and value-first AI
            technologies. While we’re leveraging the most innovative AI and NLP
            interfaces, children’s wellbeing and safety come first. We’ve
            detailed all our security measures and protocols for you below.{" "}
          </Text>
          <Button
            variant="link"
            color="white"
            textDecor="underline"
            fontWeight="normal"
            fontSize={["17px", "17px", "17px", "30px"]}
            mx="auto"
            mt="1rem"
            onClick={() => navigate("/privacy")}
          >
            Learn More
          </Button>
        </ResponsiveContainer>
      </Box>

      <Box position="relative" h="800px" minH="fit-content" w="100%" mt="-1px">
        <Image
          src={section3}
          h="100%"
          w="100%"
          objectFit="cover"
          position="absolute"
          objectPosition="50% 20%"
          top="0"
          left="0"
          zIndex={-1}
        />
        <Box
          position="absolute"
          top="0"
          left="0"
          w="100%"
          height="30%"
          bg="linear-gradient(0deg, #021d4700 2.08%, #021d47 67%)"
          zIndex={-1}
        />
        <Box
          position="absolute"
          bottom="0"
          left="0"
          w="100%"
          height="20%"
          bg="linear-gradient(180deg, #0c192e00 2.08%, #0c192e 67%)"
          zIndex={-1}
        />
        <ResponsiveContainer display="flex" flexDir="column">
          <Heading
            fontSize={["40px", "40px", "40px", "50px", "60px"]}
            color="white"
            textAlign="center"
            placeSelf="center"
            m="auto"
          >
            Our values
          </Heading>
        </ResponsiveContainer>
      </Box>

      <Box background="#0c192e" mt="-1px">
        <ResponsiveContainer display="flex" flexDir="column">
          <Heading
            fontSize={["40px", "40px", "40px", "50px", "60px"]}
            color="white"
            mb="1rem"
          >
            We Spread Love
          </Heading>

          <Text color="white" fontSize={["17px", "17px", "17px", "30px"]}>
            Our love is pure, powerful and transformative. It is not shallow
            affection or social media “likes.” It is light that conquers
            darkness.
          </Text>
        </ResponsiveContainer>
      </Box>

      <Box
        position="relative"
        h={["800px", "1000px", "1200px", "1400px"]}
        minH="fit-content"
        w="100%"
        mt="-1px"
      >
        <Image
          src={section4}
          h="100%"
          w="100%"
          objectFit="cover"
          position="absolute"
          objectPosition="50% 50%"
          top="0"
          left="0"
          zIndex={-1}
        />
        <Box
          position="absolute"
          top="0"
          left="0"
          w="100%"
          height="30%"
          bg="linear-gradient(0deg, #0c192e00 2.08%, #0c192e 67%)"
          zIndex={-1}
        />
        <Box
          position="absolute"
          bottom="0"
          left="0"
          w="100%"
          height="20%"
          bg="linear-gradient(180deg, #0c192e00 2.08%, #0c192e 67%)"
          zIndex={-1}
        />
      </Box>

      <Box background="#0c192e" mt="-1px">
        <ResponsiveContainer display="flex" flexDir="column">
          <Heading
            fontSize={["40px", "40px", "40px", "50px", "60px"]}
            color="white"
            mb="1rem"
          >
            We Nurture Confidence through Creativity
          </Heading>

          <Text color="white" fontSize={["17px", "17px", "17px", "30px"]}>
            Creative confidence is the courage to express yourself. It is not
            derived from a place of arrogance. It comes from knowing your voice
            is valuable and your potential is limitless.
          </Text>
        </ResponsiveContainer>
      </Box>

      <Box
        position="relative"
        h={["800px", "800px", "1000px", "1000px"]}
        minH="fit-content"
        w="100%"
        mt="-1px"
      >
        <Image
          src={section5}
          h="100%"
          w="100%"
          objectFit="cover"
          position="absolute"
          objectPosition="50% 30%"
          top="0"
          left="0"
          zIndex={-1}
        />
        <Box
          position="absolute"
          top="0"
          left="0"
          w="100%"
          height="30%"
          bg="linear-gradient(0deg, #0c192e00 2.08%, #0c192e 67%)"
          zIndex={-1}
        />
        <Box
          position="absolute"
          bottom="0"
          left="0"
          w="100%"
          height="20%"
          bg="linear-gradient(180deg, #0c192e00 2.08%, #0c192e 67%)"
          zIndex={-1}
        />
      </Box>

      <Box background="#0c192e" mt="-1px">
        <ResponsiveContainer display="flex" flexDir="column">
          <Heading
            fontSize={["40px", "40px", "40px", "50px", "60px"]}
            color="white"
            mb="1rem"
          >
            We believe <Box display={["block", "none"]} /> in magic
          </Heading>

          <Text color="white" fontSize={["17px", "17px", "17px", "30px"]}>
            Our love is pure, powerful and transformative. It is not shallow
            affection or social media “likes.” It is light that conquers
            darkness.
          </Text>
        </ResponsiveContainer>
      </Box>

      <Box
        position="relative"
        h={["800px", "800px", "1000px", "1000px"]}
        minH="fit-content"
        w="100%"
        mt="-1px"
      >
        <Image
          src={section6}
          h="100%"
          w="100%"
          objectFit="cover"
          position="absolute"
          objectPosition="50% 30%"
          top="0"
          left="0"
          zIndex={-1}
        />
        <Box
          position="absolute"
          top="0"
          left="0"
          w="100%"
          height="30%"
          bg="linear-gradient(0deg, #0c192e00 2.08%, #0c192e 67%)"
          zIndex={-1}
        />
        <Box
          position="absolute"
          bottom="0"
          left="0"
          w="100%"
          height="20%"
          bg="linear-gradient(180deg, #0c192e00 2.08%, #0c192e 67%)"
          zIndex={-1}
        />
      </Box>

      <Box background="#0c192e" mt="-1px">
        <ResponsiveContainer display="flex" flexDir="column">
          <Heading
            fontSize={["40px", "40px", "40px", "50px", "60px"]}
            color="white"
            mb="1rem"
          >
            Creativity is a Superpower
          </Heading>

          <Text color="white" fontSize={["17px", "17px", "17px", "30px"]}>
            Creativity is a superpower that unleashes innovation, sparks
            imagination, and generates groundbreaking solutions to shape a
            brighter future.
          </Text>
        </ResponsiveContainer>
      </Box>

      <Box
        position="relative"
        h={["800px", "800px", "1000px", "1000px"]}
        minH="fit-content"
        w="100%"
        mt="-1px"
      >
        <Image
          src={section7}
          h="100%"
          w="100%"
          objectFit="cover"
          position="absolute"
          objectPosition="50% 30%"
          top="0"
          left="0"
          zIndex={-1}
        />
        <Box
          position="absolute"
          top="0"
          left="0"
          w="100%"
          height="30%"
          bg="linear-gradient(0deg, #0c192e00 2.08%, #0c192e 67%)"
          zIndex={-1}
        />
        <Box
          position="absolute"
          bottom="0"
          left="0"
          w="100%"
          height="20%"
          bg="linear-gradient(180deg, #0f1d3300 2.08%, #0f1d33 67%)"
          zIndex={-1}
        />
      </Box>

      <Box
        background="linear-gradient(180deg, #0f1d33 0%, #23486c 100%)"
        mt="-1px"
      >
        <ResponsiveContainer display="flex" flexDir="column">
          <Heading
            fontSize={["40px", "40px", "40px", "50px", "60px"]}
            color="white"
            mb="1rem"
          >
            We build real connections
          </Heading>

          <Text color="white" fontSize={["17px", "17px", "17px", "30px"]}>
            Meaningful connections built trust and resilience that comes from
            being seen and understood.
          </Text>
        </ResponsiveContainer>
      </Box>

      <Image
        mt="-1px"
        src={section8}
        w="100%"
        h={["400px", "600px", "800px", "auto"]}
        objectFit="cover"
        objectPosition="50% 0%"
        top="0"
        left="0"
        zIndex={-1}
      />
      <Box bg="secondary.500">
        <Box
          w="100%"
          transform={[
            "translateY(-20vw)",
            "translateY(-20vw)",
            "translateY(-10vw)",
          ]}
        >
          <Footer
            onSignup={() => navigate("/sign-up")}
            onLogin={() => navigate("/login")}
          />
        </Box>
      </Box>
    </Box>
  )
}
