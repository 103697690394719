import { HamburgerIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, IconButton } from "@chakra-ui/react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { IS_PROD } from "../../components/Creations/GlobalSettings"
import MinistudioLogo from "../../components/ImagePresets/MinistudioLogo"
import BurgerMenu from "../../components/Popup/BurgerMenu"
import HeaderWave from "../../components/Header/HeaderWave"

export default function Header({ onClick }: { onClick: () => void }) {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)

  return (
    <Box bg="secondary.500" position="relative" w="100%" zIndex={1}>
      <Flex
        px={["2rem", "2rem", "100px"]}
        py="1rem"
        alignItems={["center", "center", "left"]}
      >
        <Flex
          onClick={() => navigate("/")}
          cursor="pointer"
          flexDir={["column", "column", "column", "row"]}
          mr="auto"
          ml={IS_PROD ? ["0", "0", "0"] : undefined}
        >
          <MinistudioLogo variant="white" height={["45px", "45px", "60px"]} />
        </Flex>
        {!IS_PROD && (
          <Flex ml="auto" my="auto">
            <Button
              display={["none", "none", "flex"]}
              onClick={() => navigate("/changelog")}
              variant="ghostWhite"
            >
              Changelog
            </Button>
          </Flex>
        )}
        <Button my="auto" display={["flex"]} onClick={onClick} variant="white">
          Sign up
        </Button>
        {!IS_PROD && (
          <IconButton
            aria-label="Open"
            my="auto"
            ml="0.5rem"
            display={["flex", "flex", "none"]}
            onClick={() => setOpen(true)}
            variant="ghostWhite"
            fontSize="40px"
          >
            <HamburgerIcon />
          </IconButton>
        )}
      </Flex>
      <BurgerMenu onClose={() => setOpen(false)} open={open} />
      <HeaderWave
        color="secondary.500"
        position="absolute"
        bottom="1px"
        transform="translateY(100%)"
        left="0"
        width="100%"
        height="auto"
        zIndex={-1}
      />
    </Box>
  )
}
