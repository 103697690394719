import { Icon, IconProps } from "@chakra-ui/react"

export default function SecondarySectionSeparator(props: IconProps) {
  return (
    <Icon
      width="100%"
      height="fit-content"
      viewBox="0 0 390 24"
      fill="none"
      color="secondary.500"
      {...props}
    >
      <g clipPath="url(#clip0_3777_3103)">
        <path
          d="M-29.2466 33.514L-57.8262 261.768L-61.0803 287.757L392.346 351.991C421.85 220.258 472.409 -38.0681 438.611 -17.5099C396.363 8.18791 280.368 31.5379 160.567 11.9468C64.7253 -3.72602 -5.90943 19.7946 -29.2466 33.514Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3777_3103">
          <rect
            width="518"
            height="24"
            fill="white"
            transform="translate(-62)"
          />
        </clipPath>
      </defs>
    </Icon>
  )
}
