import React, { RefObject, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

export function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export function useScroll(ref: RefObject<HTMLDivElement>) {
  const [scroll, setScroll] = useState(0)
  const [scrollDirection, setScrollDirection] = useState<"up" | "down">("up")

  const listener = (e: Event) => {
    const element = e.target as HTMLDivElement
    setScroll(element.scrollTop)
    if (Math.abs(element.scrollTop - scroll) < 10) return
    if (element.scrollTop > scroll) {
      setScrollDirection("down")
    } else {
      setScrollDirection("up")
    }
  }

  useEffect(() => {
    const current = ref.current
    current?.addEventListener("scroll", listener)
    return () => {
      current?.removeEventListener("scroll", listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scroll, ref])

  return {
    scroll,
    scrollDirection,
  }
}
