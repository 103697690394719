import { Icon } from "@chakra-ui/react"

const CommunityIcon: typeof Icon = (props) => (
  <Icon
    viewBox="5 0 41 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M24.9105 13.9386C26.7405 13.9386 28.4955 13.2118 29.7893 11.9177C31.0831 10.6236 31.8099 8.86881 31.8099 7.03889C31.8096 5.20898 31.0828 3.45391 29.7887 2.16011C28.4946 0.866309 26.7395 0.139818 24.9097 0.140137C23.0798 0.140137 21.325 0.86729 20.0312 2.16171C18.7377 3.45583 18.0109 5.2109 18.0112 7.04073C18.0137 8.86968 18.7412 10.6232 20.0346 11.9163C21.3281 13.2095 23.0817 13.9366 24.9105 13.9385V13.9386ZM24.9105 2.93392C25.9998 2.93392 27.0443 3.36653 27.8145 4.13668C28.5847 4.90684 29.0173 5.95127 29.0173 7.04065C29.0173 8.12963 28.5847 9.17408 27.8145 9.9443C27.0444 10.7145 25.9999 11.1471 24.9105 11.1471C23.8216 11.1471 22.7768 10.7144 22.0069 9.9443C21.2367 9.17414 20.8038 8.12971 20.8038 7.04065C20.8054 5.95191 21.2386 4.90809 22.0085 4.13827C22.7783 3.36842 23.8219 2.93551 24.9105 2.93392Z"
        fill="currentColor"
      />
      <path
        d="M11.8764 17.9947C13.2836 17.9947 14.6332 17.4355 15.6284 16.4406C16.6233 15.4454 17.1824 14.0958 17.1824 12.6886C17.1824 11.2814 16.6233 9.93179 15.6284 8.93665C14.6331 7.94142 13.2836 7.38257 11.8764 7.38257C10.4692 7.38257 9.11954 7.94141 8.12439 8.93665C7.12916 9.93187 6.57031 11.2814 6.57031 12.6886C6.57031 14.0958 7.12916 15.4455 8.12439 16.4406C9.11962 17.4355 10.4692 17.9947 11.8764 17.9947ZM11.8764 10.1752C12.5431 10.1752 13.1823 10.4398 13.6535 10.9114C14.1248 11.3827 14.3897 12.0219 14.3897 12.6886C14.3897 13.3552 14.1248 13.9945 13.6535 14.4658C13.1823 14.937 12.543 15.202 11.8764 15.202C11.2097 15.202 10.5704 14.937 10.0992 14.4658C9.62763 13.9945 9.36301 13.3552 9.36301 12.6886C9.36301 12.0219 9.62763 11.3827 10.0992 10.9114C10.5705 10.4398 11.2097 10.1752 11.8764 10.1752Z"
        fill="currentColor"
      />
      <path
        d="M37.9423 17.9947C39.3496 17.9947 40.6991 17.4355 41.6943 16.4406C42.6892 15.4454 43.2484 14.0958 43.2484 12.6886C43.2484 11.2814 42.6892 9.93179 41.6943 8.93665C40.6991 7.94142 39.3495 7.38257 37.9423 7.38257C36.5351 7.38257 35.1855 7.94141 34.1903 8.93665C33.1954 9.93187 32.6362 11.2814 32.6362 12.6886C32.6362 14.0958 33.1954 15.4455 34.1903 16.4406C35.1855 17.4355 36.5351 17.9947 37.9423 17.9947ZM37.9423 10.1752C38.609 10.1752 39.2482 10.4398 39.7195 10.9114C40.1907 11.3827 40.4557 12.0219 40.4557 12.6886C40.4557 13.3552 40.1907 13.9945 39.7195 14.4658C39.2482 14.937 38.6089 15.202 37.9423 15.202C37.2756 15.202 36.6364 14.937 36.1651 14.4658C35.6939 13.9945 35.4289 13.3552 35.4289 12.6886C35.4289 12.0219 35.6939 11.3827 36.1651 10.9114C36.6364 10.4398 37.2756 10.1752 37.9423 10.1752Z"
        fill="currentColor"
      />
      <path
        d="M38.1266 20.7937C36.9023 20.8018 35.6998 21.1151 34.6274 21.7054C32.5562 18.4265 28.9722 16.4121 25.0948 16.3477C21.2172 16.4122 17.6331 18.4266 15.5622 21.7054C14.4898 21.1151 13.2873 20.8019 12.063 20.7937C9.86665 20.901 7.80144 21.8727 6.31909 23.497C4.83642 25.1214 4.05687 27.2661 4.15007 29.4634C4.15007 29.9624 4.41624 30.4234 4.84823 30.6727C5.28023 30.9221 5.81258 30.9221 6.24457 30.6727C6.67655 30.4234 6.94273 29.9624 6.94273 29.4634C6.8511 28.0073 7.33732 26.5735 8.29606 25.4733C9.2545 24.3731 10.6081 23.6952 12.063 23.5864C12.8348 23.5923 13.5921 23.7937 14.2651 24.1714C13.6071 25.8299 13.2702 27.5983 13.2724 29.3824C13.2724 29.8815 13.5386 30.3424 13.9706 30.5918C14.4026 30.8411 14.9349 30.8411 15.3669 30.5918C15.7989 30.3424 16.0651 29.8814 16.0651 29.3824C16.0651 23.7342 20.1144 19.1406 25.095 19.1406C30.0755 19.1406 34.1248 23.7344 34.1248 29.3824C34.1248 29.8815 34.391 30.3424 34.823 30.5918C35.255 30.8411 35.7874 30.8411 36.2193 30.5918C36.6513 30.3424 36.9175 29.8814 36.9175 29.3824C36.9197 27.5984 36.5828 25.8299 35.9248 24.1714C36.5978 23.7937 37.3552 23.5923 38.1269 23.5864C39.5818 23.6952 40.9354 24.3731 41.8939 25.4733C42.8526 26.5735 43.3388 28.0073 43.2472 29.4634C43.2472 29.9624 43.5134 30.4234 43.9454 30.6727C44.3774 30.9221 44.9097 30.9221 45.3417 30.6727C45.7737 30.4234 46.0399 29.9624 46.0399 29.4634C46.1331 27.2661 45.3535 25.1214 43.8708 23.497C42.3885 21.8728 40.3233 20.901 38.1269 20.7937H38.1266Z"
        fill="currentColor"
      />
    </g>
  </Icon>
)

export default CommunityIcon
