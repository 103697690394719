import { Box, Flex, Image, Text } from "@chakra-ui/react"
import { StoryBoard } from "../../../../data/models/Story"
import Background from "../../../components/Wrapper/Background"
import BackButton from "../../../components/Buttons/BackButton"
import { useEffect, useState } from "react"
import "./StoryBoardPage.scss"
import { CloseIcon } from "@chakra-ui/icons"

export default function StoryBoardPage({
  board,
  onPrevious,
  onNext,
}: {
  board: StoryBoard
  onPrevious?: () => any
  onNext?: () => any
}) {
  const [videoHeight, setVideoHeight] = useState(-1)
  let height: number | undefined = undefined
  if (videoHeight > -1 && videoHeight < window.innerHeight - 260) {
    height = window.innerHeight - videoHeight - 60
  }

  useEffect(() => {
    setVideoHeight(-1)
  }, [board])

  return (
    <Background position="relative" bg="black">
      {board.isVideo ? (
        <video
          playsInline
          src={window.innerWidth < 769 && board.backgroundMobile.length > 0 ? board.backgroundMobile : board.background}
          className="storyVideo"
          autoPlay
          muted
          loop
          disablePictureInPicture
          onCanPlay={(e) => {
            setVideoHeight(
              (e.currentTarget.videoHeight * window.innerWidth) /
                e.currentTarget.videoWidth
            )
            e.currentTarget.play()
          }}
        />
      ) : (
        <Image
          src={window.innerWidth < 769 && board.backgroundMobile.length > 0 ? board.backgroundMobile : board.background}
          position="absolute"
          top="0"
          left="0"
          h="100%"
          w="100%"
          objectFit={["cover", "cover", "contain"]}
        />
      )}
      <Flex
        flexDir="column"
        position="absolute"
        bottom="0px"
        w="100%"
        background="linear-gradient(180deg, #00000000 0%, #000000cf 100px)"
        pt="100px"
      >
        <Box w="100%" h={`${height}px`} maxW="600px" mx="auto">
          <Text
            p="1rem"
            overflowY="auto"
            maxH={"200px"}
            fontSize="16px"
            variant="solid"
            fontWeight="bold"
          >
            {board.text}
          </Text>
        </Box>
        <Flex
          h="80px"
          w="full"
          maxW="600px"
          mx="auto"
          justifyContent={"space-evenly"}
        >
          {onPrevious ? (
            <Box>
              <BackButton color="white" onClick={onPrevious} />
              <Text
                size="xs"
                variant="solid"
                textAlign="center"
                userSelect="none"
              >
                Previous
              </Text>
            </Box>
          ) : (
            <Box w="50px" />
          )}
          <Box>
            <BackButton
              icon={<CloseIcon color="white" h="50%" w="50%" m="auto" />}
            />
            <Text
              size="xs"
              variant="solid"
              textAlign="center"
              userSelect="none"
            >
              Exit
            </Text>
          </Box>

          {onNext ? (
            <Box>
              <BackButton
                color="white"
                transform="rotate(180deg)"
                onClick={onNext}
              />
              <Text
                size="xs"
                variant="solid"
                textAlign="center"
                userSelect="none"
              >
                Next
              </Text>
            </Box>
          ) : (
            <Box w="50px" />
          )}
        </Flex>
      </Flex>
    </Background>
  )
}
