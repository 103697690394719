import { Icon, IconProps } from "@chakra-ui/react"

export default function CameraFullIcon(props: IconProps) {
  return (
    <Icon
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.375 14.3332C5.375 15.3186 6.18125 16.1248 7.16667 16.1248C8.15208 16.1248 8.95833 15.3186 8.95833 14.3332V10.7498H12.5417C13.5271 10.7498 14.3333 9.94359 14.3333 8.95817C14.3333 7.97275 13.5271 7.1665 12.5417 7.1665H8.95833V3.58317C8.95833 2.59775 8.15208 1.7915 7.16667 1.7915C6.18125 1.7915 5.375 2.59775 5.375 3.58317V7.1665H1.79167C0.80625 7.1665 0 7.97275 0 8.95817C0 9.94359 0.80625 10.7498 1.79167 10.7498H5.375V14.3332Z"
        fill="currentColor"
      />
      <path
        d="M23.291 30.4585C26.2595 30.4585 28.666 28.052 28.666 25.0835C28.666 22.115 26.2595 19.7085 23.291 19.7085C20.3225 19.7085 17.916 22.115 17.916 25.0835C17.916 28.052 20.3225 30.4585 23.291 30.4585Z"
        fill="currentColor"
      />
      <path
        d="M37.625 10.7498H31.9454L29.7237 8.33109C29.3899 7.9645 28.9832 7.67159 28.5297 7.47106C28.0762 7.27054 27.5859 7.16681 27.09 7.1665H15.6233C15.9279 7.704 16.125 8.29525 16.125 8.95817C16.125 10.929 14.5125 12.5415 12.5417 12.5415H10.75V14.3332C10.75 16.304 9.1375 17.9165 7.16667 17.9165C6.50375 17.9165 5.9125 17.7194 5.375 17.4148V35.8332C5.375 37.804 6.9875 39.4165 8.95833 39.4165H37.625C39.5958 39.4165 41.2083 37.804 41.2083 35.8332V14.3332C41.2083 12.3623 39.5958 10.7498 37.625 10.7498ZM23.2917 34.0415C18.3467 34.0415 14.3333 30.0282 14.3333 25.0832C14.3333 20.1382 18.3467 16.1248 23.2917 16.1248C28.2367 16.1248 32.25 20.1382 32.25 25.0832C32.25 30.0282 28.2367 34.0415 23.2917 34.0415Z"
        fill="currentColor"
      />
    </Icon>
  )
}
