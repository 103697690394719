import { useMemo } from "react"
import Story from "../../../../data/models/Story"
import { Webtoon } from "../../../../data/models/Webtoon"
import StoryBookCard from "../../../components/Stories/StoryBookCard"

export default function WebtoonGeneratedElement({
  webtoon,
  stories,
  onChoose,
  lightMode = false,
}: {
  webtoon: Webtoon
  stories: Story[]
  onChoose: () => void
  lightMode?: boolean
}) {
  const story = useMemo(() => {
    return stories.find((s) => s.id === webtoon.storyId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webtoon])
  if (!story) return <></>
  return (
    <StoryBookCard
      lightMode={lightMode}
      displayState
      story={story}
      onClick={onChoose}
      cursor="pointer"
    />
  )
}
