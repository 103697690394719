import { Box, Image } from "@chakra-ui/react"
import { WebtoonFixedAsset } from "../../../../data/models/Webtoon"

export default function WebtoonFixedAssetElement({
  asset,
}: {
  asset: WebtoonFixedAsset
}) {
  return (
    <Box
      maxH={`${asset.maxHeightPercent * 100}%`}
      maxW={`${asset.maxWidthPercent * 100}%`}
      h={`${asset.maxHeightPercent * 100}%`}
      w={`${asset.maxWidthPercent * 100}%`}
      top={`${asset.y * 100}%`}
      left={`${asset.x * 100}%`}
      transition={"all 4s linear"}
      transform={`translate(-50%, -50%)`}
      position="absolute"
      zIndex={1}
    >
      <Image
        src={asset.image}
        w="100%"
        h="100%"
        objectFit="contain"
        objectPosition="center"
        transition={"all 4s linear !important"}
        transform={`rotate(${asset.angle}deg)`}
      />
    </Box>
  )
}
