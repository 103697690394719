import { useState } from "react"
import { useParams } from "react-router-dom"
import img1 from "../../../../assets/images/character-actions/shop/rug/rug.jpeg"
import PrintShopCharacterManager from "./PrintShopCharacterManager"

const variants = [
  {
    size: `3'11" x 5'3"`,
    price: "$100.00",
    variant: "45077973074241",
  },
  {
    size: `5'3" x 7'3"`,
    price: "$149.00",
    variant: "45077973107009",
  },
  {
    size: `7'10" x 9'10"`,
    price: "$239.00",
    variant: "45077973139777",
  },
  {
    size: `9'3" x 12'6"`,
    price: "$299.00",
    variant: "45077973172545",
  },
]

export default function CreationPrintRug() {
  const { creationId } = useParams()
  const [currentSize, setCurrentSize] = useState(0)

  return (
    <PrintShopCharacterManager
      creationId={creationId}
      variant={variants[currentSize].variant}
      price={variants[currentSize].price}
      productImgs={[img1]}
      title="RUG"
      onSettingsChange={(_, settingValue) => {
        setCurrentSize(
          variants.findIndex((variant) => variant.size === settingValue)
        )
      }}
      settings={[
        {
          title: "Size",
          values: variants.map((variant) => variant.size),
          value: variants[currentSize].size,
        },
      ]}
      description={`Soft and durable, perfect for embarking on adventures and playtime. Decorate your floor or hang it up on your wall!`}
      product={`Introducing the beautiful Magic Carpet, a beautifully crafted rug for kids to enjoy life size characters and landscapes they create. This exquisite piece is designed to captivate and inspire, bringing a touch of magic to any room it graces.`}
    />
  )
}
