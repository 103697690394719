import axios from "axios"
import { User } from "firebase/auth"
import { DocumentSnapshot } from "firebase/firestore"
import CreationDAO from "../daos/CreationDAO"
import CreditDAO from "../daos/CreditDAO"
import UserDAO from "../daos/UserDAO"
import UserStoryPackDao from "../daos/UserStoryPackDao"
import Creation from "../data/models/Creation"
import UserData, { UserFirebase } from "../data/models/UserData"
import {
  API_ENDPOINTS,
  STORY_PACK_ID,
} from "../presentation/components/Creations/GlobalSettings"
import { Pronouns } from "../utilities/dateUtils"
import StoryService from "./StoryService"
import WebtoonService from "./WebtoonService"

type WebtoonPageInfo = {
  page: number
  finished: boolean
}

export const DISMISS_REWARD_LOCAL_STORAGE_KEY = "dismissRewardFor"
export const STORY_POSITION_KEY = "storyPosition"

function getDateString(date: Date = new Date()): string {
  return `${date.getFullYear()}-${date.toLocaleDateString("en-US", {
    month: "2-digit",
  })}-${date.toLocaleDateString("en-US", { day: "2-digit" })}`
}

async function fbUserToUserData(
  fbUser: User,
  userdoc: DocumentSnapshot<UserFirebase>
): Promise<UserData> {
  const dateString = getDateString()
  let claimedReward = false

  const data = userdoc.data()

  if (
    (typeof data?.lastClaimReward === "string" &&
      data?.lastClaimReward >= dateString) ||
    localStorage.getItem(DISMISS_REWARD_LOCAL_STORAGE_KEY) === dateString
  ) {
    claimedReward = true
  }

  return {
    id: fbUser.uid,
    childName: userdoc.data()?.childName ?? "",
    email: fbUser.email ?? "",
    onboarded: userdoc.data()?.onboardingDone ?? false,
    inWhitelist: true,
    credits: await CreditDAO.getAvailableCredits(fbUser.uid),
    fbUser: fbUser,
    admin: userdoc.data()?.admin ?? false,
    pronouns: userdoc.data()?.pronouns ?? "they/them",
    claimedDailyReward: claimedReward,
  }
}

export default class UserService {
  static dismissDailyReward(): void {
    const dateString = getDateString()
    localStorage.setItem(DISMISS_REWARD_LOCAL_STORAGE_KEY, dateString)
  }

  static setStoryPosition(
    storyId: string,
    position: number,
    finished: boolean
  ): void {
    let storyPos: { [storyId: string]: WebtoonPageInfo }
    try {
      storyPos = JSON.parse(localStorage.getItem(STORY_POSITION_KEY) ?? "{}")
      storyPos[storyId] = { page: position, finished: finished }
    } catch {
      storyPos = { [storyId]: { page: position, finished: finished } }
    }
    localStorage.setItem(STORY_POSITION_KEY, JSON.stringify(storyPos))
  }

  static getStoryPosition(storyId: string): number {
    try {
      const currentPos: { [key: string]: WebtoonPageInfo } = JSON.parse(
        localStorage.getItem(STORY_POSITION_KEY) ?? "{}"
      )
      if (typeof currentPos[storyId].page !== "number") return 0
      return currentPos[storyId].page
    } catch {
      return 0
    }
  }

  static isStoryFinished(storyId: string): boolean {
    try {
      const currentPos: { [key: string]: WebtoonPageInfo } = JSON.parse(
        localStorage.getItem(STORY_POSITION_KEY) ?? "{}"
      )
      if (typeof currentPos[storyId].finished !== "boolean") return false
      return currentPos[storyId].finished
    } catch {
      return false
    }
  }

  static resetDismissDailyReward(): void {
    localStorage.removeItem(DISMISS_REWARD_LOCAL_STORAGE_KEY)
  }

  static subscribeCreations(
    user: UserData,
    onUpdate: (creations: Creation[]) => any
  ): () => void {
    return CreationDAO.listenToUserCreations(user.id, onUpdate)
  }

  static async getCreations(user: UserData): Promise<Creation[]> {
    return await CreationDAO.getCreations(user.id)
  }

  static async getCreation(
    user: UserData,
    creationId: string
  ): Promise<Creation | null> {
    return await CreationDAO.getCreation(user.id, creationId)
  }

  static async getUser(fbUser: User): Promise<UserData> {
    const userdoc = await UserDAO.getUser(fbUser.uid)
    return await fbUserToUserData(fbUser, userdoc)
  }

  static async getUserEmail(userId: string): Promise<string | null> {
    const userdoc = await UserDAO.getUser(userId)
    return userdoc.data()?.emailAddress ?? null
  }

  static async authenticateUser(
    email: string,
    password: string
  ): Promise<UserData> {
    const cred = await UserDAO.signInUser(email, password)
    const userdoc = await UserDAO.getUser(cred.user.uid)
    return await fbUserToUserData(cred.user, userdoc)
  }

  static async registerUser(
    email: string,
    password: string
  ): Promise<UserData> {
    const cred = await UserDAO.registerUser(email, password)
    const userdoc = await UserDAO.getUser(cred.user.uid)
    return { ...(await fbUserToUserData(cred.user, userdoc)), credits: 5 }
  }

  static async registerUserWithSSO(
    user: User,
    onRegister?: (fbUser: UserFirebase) => void
  ) {
    UserDAO.registerWithSSO(user, onRegister)
  }

  static async setUserInfo(
    userId: string,
    childName: string,
    pronouns: Pronouns
  ): Promise<void> {
    await UserDAO.updateField(userId, "childName", childName)
    await UserDAO.updateField(userId, "pronouns", pronouns)
  }

  static async updateCredits(user: UserData): Promise<UserData> {
    const newUser = { ...user }
    newUser.credits = await CreditDAO.getAvailableCredits(user.id)
    return newUser
  }

  static async setOnboardingDone(userId: string): Promise<void> {
    await UserDAO.setOnboardingDone(userId)
  }

  static async logout(): Promise<void> {
    await UserDAO.logout()
  }

  static async resetPassword(email: string): Promise<void> {
    await UserDAO.resetPassword(email)
  }

  static async updatePronouns(
    userId: string,
    pronouns: Pronouns
  ): Promise<void> {
    await UserDAO.updateField(userId, "pronouns", pronouns)
  }

  static async canCreateStories(userId: string): Promise<boolean> {
    const packs = await UserStoryPackDao.getAll(userId)
    if (!packs.length) return true
    const pack = packs[0]
    const webtoons = await WebtoonService.getAll(userId, pack.id)
    if (!webtoons.length) return true
    const stories = await StoryService.getStoriesFromPack(STORY_PACK_ID)
    return stories.filter((s) => !s.comingSoon).length > webtoons.length
  }

  static async setChatCharacter(user: UserData, creationId: string) {
    await axios.put(
      API_ENDPOINTS.CHANGE_CHARACTER,
      {
        creationId,
      },
      {
        headers: {
          Authorization: `Bearer ${await user.fbUser.getIdToken(true)}`,
        },
      }
    )
  }
}
