import { defineStyleConfig } from '@chakra-ui/react'

export const FormLabel = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    fontSize: '2rem',
    width: "100%",
  },
  // Two sizes: sm and md
  sizes: {
    md: {
      fontSize: '1rem',
    },
  },
  // Two variants: outline and solid
  // The default size and variant values
  defaultProps: {
    size: 'md',
  },
})
