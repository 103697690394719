import { Icon } from "@chakra-ui/react"

const LogoutIcon: typeof Icon = (props) => (
  <Icon viewBox="2 2 20 20" {...props} fill="none">
    <path
      d="M6 2H15C15.5304 2 16.0391 2.21071 16.4142 2.58579C16.7893 2.96086 17 3.46957 17 4V5C17 5.26522 16.8946 5.51957 16.7071 5.70711C16.5196 5.89464 16.2652 6 16 6C15.7348 6 15.4804 5.89464 15.2929 5.70711C15.1054 5.51957 15 5.26522 15 5V4H6V20H15V19C15 18.7348 15.1054 18.4804 15.2929 18.2929C15.4804 18.1054 15.7348 18 16 18C16.2652 18 16.5196 18.1054 16.7071 18.2929C16.8946 18.4804 17 18.7348 17 19V20C17 20.5304 16.7893 21.0391 16.4142 21.4142C16.0391 21.7893 15.5304 22 15 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V4C4 3.46957 4.21071 2.96086 4.58579 2.58579C4.96086 2.21071 5.46957 2 6 2Z"
      fill="currentColor"
    />
    <path
      d="M16.795 16.295C17.185 16.685 17.815 16.685 18.205 16.295L21.793 12.707C21.9805 12.5195 22.0858 12.2652 22.0858 12C22.0858 11.7348 21.9805 11.4805 21.793 11.293L18.205 7.705C18.0149 7.5332 17.7661 7.441 17.5099 7.44746C17.2538 7.45393 17.0099 7.55857 16.8287 7.73974C16.6476 7.92092 16.5429 8.16479 16.5365 8.42093C16.53 8.67708 16.6222 8.92591 16.794 9.116L18.67 11H10C9.73478 11 9.48043 11.1054 9.29289 11.2929C9.10536 11.4804 9 11.7348 9 12C9 12.2652 9.10536 12.5196 9.29289 12.7071C9.48043 12.8946 9.73478 13 10 13H18.67L16.794 14.884C16.6073 15.0714 16.5026 15.3252 16.5028 15.5897C16.503 15.8542 16.6081 16.1079 16.795 16.295Z"
      fill="currentColor"
    />
  </Icon>
)

export default LogoutIcon
