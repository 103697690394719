import axios from "axios"
import CreationDAO from "../daos/CreationDAO"
import Creation from "../data/models/Creation"
import UserData from "../data/models/UserData"
import { Pronouns } from "../utilities/dateUtils"
import { API_ENDPOINTS } from "../presentation/components/Creations/GlobalSettings"
import bannedWords from "./bannedWords.json"

export default class CreationService {
  static getCroppedPicture(character?: Creation) {
    // if (!characterId) return undefined
    // const character = userCreations.find((c) => c.id === characterId)
    if (!character) return undefined
    const index =
      character.generativeAiResults?.findIndex(
        (gr) => gr === character.selectedImage
      ) ?? -1
    if (index >= 0) return character.generativeAiResultsCropped?.[index]
  }

  /** Return creation ID */
  static async uploadCreation(
    user: UserData,
    picture: string | undefined,
    title: string,
    prompt: string,
    pronouns: Pronouns,
    isDrawing: boolean = false,
    retries: number = 3
  ): Promise<string> {
    for (const bannedWord of bannedWords) {
      const regexp = new RegExp(`\\b${bannedWord}\\b`, "gi")
      const found = prompt.match(regexp)
      if (found) {
        window.alert(
          `Banned word detected in description: ${found[0]}.\nPlease remove it and try again.`
        )
        throw new Error("Banned word detected")
      }
    }

    return await axios
      .post<{ creationId: string }>(
        API_ENDPOINTS.ADD_CREATION,
        {
          title,
          prompt,
          picture,
          pronouns,
          isDrawing,
        },
        {
          headers: {
            Authorization: `Bearer ${await user.fbUser.getIdToken()}`,
          },
        }
      )
      .then((res) => {
        return res.data.creationId
      })
      .catch(async (error) => {
        if (retries > 0) {
          await new Promise((resolve, reject) =>
            setTimeout(async () => {
              await CreationService.uploadCreation(
                user,
                picture,
                title,
                prompt,
                pronouns,
                isDrawing,
                retries - 1
              ).catch((err) => reject(err))
              resolve(null)
            }, 500)
          ).catch((err) => {
            throw err
          })
        } else {
          throw error
        }
        return ""
      })
  }

  static async uploadQuestCreation(
    user: UserData,
    picture: string | undefined,
    title: string,
    prompt: string | undefined,
    questId: string,
    isDrawing: boolean = false,
    retries: number = 3
  ): Promise<void> {
    await axios
      .post(
        API_ENDPOINTS.ADD_CREATION_QUEST,
        {
          title,
          prompt,
          picture,
          questId,
          isDrawing,
        },
        {
          headers: {
            Authorization: `Bearer ${await user.fbUser.getIdToken()}`,
          },
        }
      )
      .catch(async (error) => {
        if (retries > 0) {
          await new Promise((resolve, reject) =>
            setTimeout(async () => {
              await CreationService.uploadQuestCreation(
                user,
                picture,
                title,
                prompt,
                questId,
                isDrawing,
                retries - 1
              ).catch((err) => reject(err))
              resolve(null)
            }, 500)
          ).catch((err) => {
            throw err
          })
        } else {
          throw error
        }
      })
  }

  static async getAllCreations(): Promise<Creation[]> {
    return await CreationDAO.getAllCreations()
  }

  static async getOtherCreations(
    user: UserData,
    quantity: number = 15
  ): Promise<Creation[]> {
    return await CreationDAO.getOtherCreations(user.id, quantity)
  }

  static async updatepromptForAI(
    creation: Creation,
    promptForAI: string
  ): Promise<void> {
    await CreationDAO.updateField(
      creation.userId ?? "",
      creation.id,
      "promptForAI",
      promptForAI
    )
  }

  static async updatePronouns(
    creation: Creation,
    pronouns: Pronouns
  ): Promise<void> {
    await CreationDAO.updateField(
      creation.userId ?? "",
      creation.id,
      "pronouns",
      pronouns
    )
  }

  static subscribeToAllCreations(
    onUpdate: (creations: Creation[]) => any
  ): () => void {
    return CreationDAO.listenToCreations(onUpdate)
  }

  static setGiftOpen(creation: Creation): Promise<void> {
    return CreationDAO.updateField(
      creation.userId ?? "",
      creation.id,
      "isOpened",
      true
    )
  }

  static setSelectedImage(
    creation: Creation,
    selectedImage: string
  ): Promise<void> {
    return CreationDAO.updateField(
      creation.userId ?? "",
      creation.id,
      "selectedImage",
      selectedImage
    )
  }

  static setShareAgreement(
    creation: Creation,
    shareAgreement: boolean
  ): Promise<void> {
    return CreationDAO.updateField(
      creation.userId ?? "",
      creation.id,
      "shareAgreement",
      shareAgreement
    )
  }

  static setShareable(creation: Creation, shareable: boolean): Promise<void> {
    return CreationDAO.updateField(
      creation.userId ?? "",
      creation.id,
      "shareable",
      shareable
    )
  }

  static setRating(creation: Creation, rating: number): Promise<void> {
    return CreationDAO.updateField(
      creation.userId ?? "",
      creation.id,
      "rating",
      rating
    )
  }

  static async sendSlackNotification(creation: Creation): Promise<void> {
    await axios.post(API_ENDPOINTS.SEND_SLACK_NOTIFICATION, {
      creationId: creation.id,
    })
    await CreationDAO.updateField(
      creation.userId ?? "",
      creation.id,
      "slackNotification",
      true
    )
  }
}
