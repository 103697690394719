import { Box, BoxProps, Flex, Image, Text } from "@chakra-ui/react"
import Creation from "../../../data/models/Creation"
import whiteMonster from "../../assets/images/white-monster.png"
import ClickIcon from "../Icons/ClickIcon"
import CreationService from "../../../service/CreationService"
import PendingCreationAnimation from "./PendingCreationAnimation"

export default function PendingCreationCard({
  creation,
  ready,
  onClick,
  ...props
}: {
  creation: Creation
  ready: boolean
  onClick?: () => any
} & BoxProps) {
  // const [color, setColor] = useState("secondary.400")
  // const [loading, setLoading] = useState(true)

  // useEffect(() => {
  //   if (creation.originalImagePath)
  //     try {
  //       extractColors(creation.originalImagePath, {
  //         crossOrigin: "anonymous",
  //         splitPower: 2,
  //       })
  //         .then((colors) => {
  //           let color = colors
  //             .filter(
  //               (color) =>
  //                 color.lightness < 0.5 &&
  //                 color.lightness > 0.2 &&
  //                 color.saturation > 0.2
  //             )
  //             .sort((a, b) => b.saturation - a.saturation)[0]
  //           if (color) setColor(color.hex)
  //         })
  //         .finally(() => setLoading(false))
  //     } catch (e) {
  //       setLoading(false)
  //     }
  //   else {
  //     setColor("secondary.400")
  //     setLoading(false)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // if (loading)
  //   return (
  //     <Flex
  //       minW="180px"
  //       minH="220px"
  //       borderRadius="20px"
  //       bg="#fee"
  //       pos="relative"
  //       {...props}
  //     >
  //       <Spinner m="auto" w="50px" h="50px" color="primary.500" />
  //     </Flex>
  //   )

  let picture = ""
  if (creation?.questId) {
    picture = whiteMonster
  } else if (creation.selectedImage) {
    picture = CreationService.getCroppedPicture(creation) ?? whiteMonster
  } else {
    picture = creation?.generativeAiResultsCropped?.[0] ?? whiteMonster
  }
  return (
    <Flex
      minW={props.w ?? "180px"}
      w="180px"
      h="220px"
      bg={
        ready
          ? "linear-gradient(180deg, #4DF3B7 0%, #46C232 100%)"
          : "linear-gradient(180deg, #594DF3 0%, #9087F5 100%)"
      }
      p="1em"
      borderRadius="10px"
      onClick={onClick}
      cursor="pointer"
      flexDir="column"
      position="relative"
      {...props}
    >
      {/* {creation.questId == null && creation.originalImagePath && (creation.generativeAiResults?.length ?? 0) === 0 && (
        <Image
          bg="white"
          src={creation.originalImagePath}
          w="45px"
          h="45px"
          borderRadius="50px"
          ml="auto"
          objectFit="cover"
          position="absolute"
          top="1rem"
          right="1rem"
        />
      )} */}

      <Box my="auto" textAlign="center">
        {ready ? (
          <>
            {/* <Image h="140px" w="100%" objectFit="contain" src={whiteMonster} /> */}
            <Box
              style={{
                WebkitMaskBoxImage: `url(${picture})`,
                WebkitMaskBoxImageRepeat: "no-repeat",
              }}
              bg="white"
              position="relative"
              w="fit-content"
              m="auto"
            >
              {/* Image added here for adjusting parent's width */}
              <Image
                src={picture}
                minW="fit-content"
                minH="140px"
                maxH="140px"
                m="0"
                opacity={0}
              />
            </Box>

            <ClickIcon
              height="50px"
              width="50px"
              color="white"
              position="absolute"
              right="5px"
              bottom="10px"
            />
          </>
        ) : (
          <PendingCreationAnimation w="100%" h="140px" />
         // <Image h="140px" w="100%" objectFit="contain" src={whiteMonster} />
        )}

        <Text fontWeight="light" variant="solid">
          {ready ? "Ready" : "Generating"}
        </Text>
      </Box>
    </Flex>
  )
}
