import { Icon, IconProps } from "@chakra-ui/react"

export default function ChatIcon(props: IconProps) {
  return (
    <Icon
      viewBox="0 0 40 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.00086 19.4596L2.97022 23.6165C2.83094 24.2084 3.06785 24.8142 3.56902 25.1381C3.80593 25.2932 4.05652 25.3636 4.3211 25.3636C4.62766 25.3636 4.9339 25.2649 5.18483 25.0535L11.7729 19.7273H14.0711C14.0711 22.8415 16.5642 25.3637 19.6425 25.3637H27.5122L34.1002 30.6899C34.3508 30.9012 34.6574 31 34.964 31C35.2285 31 35.4931 30.9295 35.716 30.7745C36.2175 30.4505 36.4541 29.8302 36.3148 29.2528L35.2842 25.096C37.5128 24.3631 39.1426 22.2357 39.1426 19.7273V12.6818C39.1426 9.56765 36.6494 7.04546 33.5711 7.04546H27.9997V5.63637C27.9997 2.52219 25.5066 0 22.4283 0H5.71401C2.63571 0 0.142578 2.52219 0.142578 5.63637V14.0909C0.142578 16.5989 1.75837 18.7267 4.00095 19.4596H4.00086ZM33.5708 9.86332C35.103 9.86332 36.3565 11.1315 36.3565 12.6815V19.727C36.3565 21.277 35.103 22.5451 33.5708 22.5451C33.139 22.5451 32.7351 22.7424 32.4705 23.0805C32.206 23.4186 32.1083 23.8696 32.2199 24.2924L32.6098 25.8845L28.8631 22.8549C28.6125 22.6577 28.306 22.5448 27.9994 22.5448H19.6422C18.1101 22.5448 16.8565 21.2766 16.8565 19.7266H22.428C25.5063 19.7266 27.9994 17.2044 27.9994 14.0903V9.86298H33.5708V9.86332ZM2.92796 5.63604C2.92796 4.08604 4.18153 2.81786 5.71367 2.81786H22.428C23.9601 2.81786 25.2137 4.08604 25.2137 5.63604V14.0906C25.2137 15.6406 23.9601 16.9088 22.428 16.9088H11.2851C10.9649 16.9088 10.6583 17.0214 10.4214 17.2189L6.67466 20.2485L7.06454 18.6563C7.17584 18.2336 7.06454 17.7825 6.81395 17.4444C6.56336 17.1063 6.14551 16.9091 5.71367 16.9091C4.18153 16.9091 2.92796 15.6409 2.92796 14.0909V5.63604Z"
        fill="currentColor"
      />
    </Icon>
  )
}
