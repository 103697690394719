import { useNavigate } from "react-router-dom"
import Header from "../Home/Header"
import ResponsiveContainer from "../../components/Wrapper/ResponsiveContainer"
import { Box, BoxProps, Flex, Heading, Text } from "@chakra-ui/react"
import { ComicBookActionBlock } from "../../components/ActionBlocks/ComicBookActionBlock"
import Footer from "../Home/Footer"

function ChangelogBlock({
  latest = false,
  date,
  children,
  ...props
}: BoxProps & {
  date: string
  latest?: boolean
}) {
  return (
    <Box
      w="100%"
      bg="primary.50"
      borderRadius="20px"
      p={["1.5rem", "1.5rem", "3rem"]}
      {...props}
    >
      {latest && (
        <Text fontSize="30px" color="primary.500">
          Latest
        </Text>
      )}
      <Text fontSize="30px">{date}</Text>
      {children}
    </Box>
  )
}

export default function Changelog() {
  const navigate = useNavigate()
  return (
    <Box>
      <Header onClick={() => navigate("/login")} />
      <ResponsiveContainer overflowY="auto" pb={["80px", "80px", "80px"]}>
        <Heading size="sm" textAlign="center" mt="4.5rem">
          Changelog
        </Heading>
        <Text
          fontWeight="light"
          textAlign="center"
          fontSize="md"
          mt="0.5rem"
          mb="1.5rem"
        >
          We update our product so kids experience <br />
          more magic everyday
        </Text>
        <ChangelogBlock date="06.04.2023" latest>
          <Flex
            rowGap="2rem"
            columnGap="1rem"
            flexDir={["column", "column", "column", "row"]}
          >
            <Box w="100%">
              <Text fontSize="24px" fontWeight="bold" mt="0.5rem">
                Launched the personalized story rituals <br />
                Create a story with your character
              </Text>
              <Text fontSize="24px" fontWeight="bold" mt="0.5rem">
                Features
              </Text>
              <Text variant="raleway">
                • A pack of 7 stories happening in magical universes <br />
                • Each story is focused on 1 theme for social emotional learning
                <br /> • You get 1 story delivered to you weekly
              </Text>
              <Text fontSize="24px" fontWeight="bold" mt="0.5rem">
                Fixes
              </Text>
              <Text variant="raleway">
                • A pack of 7 stories happening in magical universes <br />
                • Each story is focused on 1 theme for social emotional learning
                <br /> • You get 1 story delivered to you weekly
              </Text>
              <Text
                fontSize="18px"
                variant="raleway"
                fontWeight="bold"
                mt="1rem"
                bg="#FDE3B2"
                w="fit-content"
                px="1rem"
              >
                COMING SOON? Get physical copies of your art books!
              </Text>
            </Box>
            <ComicBookActionBlock
              h="fit-content"
              disableClick
              w="100%"
              maxW="450px"
              m="auto"
            />
          </Flex>
        </ChangelogBlock>
      </ResponsiveContainer>
      <Footer
        onSignup={() => navigate("/sign-up")}
        onLogin={() => navigate("/login")}
      />
    </Box>
  )
}
