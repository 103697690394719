import { Box, Flex, Grid, Heading, Text, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useUserContext } from "../../../context/userContext"
import Story from "../../../data/models/Story"
import { UserStoryPack } from "../../../data/models/UserStoryPack"
import StoryPackService from "../../../service/StoryPackService"
import StoryService from "../../../service/StoryService"
import UserStoryPackService from "../../../service/UserStoryPackService"
import BackButton from "../../components/Buttons/BackButton"
import Button3d from "../../components/Buttons/Button3d"
import { STORY_PACK_ID } from "../../components/Creations/GlobalSettings"
import StoryBookCard from "../../components/Stories/StoryBookCard"
import Navigation from "../../components/Wrapper/Navigation"
import StoriesBought from "./StoriesBought"
import "./Story.css"

export default function Stories() {
  const [stories, setStories] = useState([] as Story[])
  const [userStoryPack, setUSP] = useState<UserStoryPack>()
  const [loading, setLoading] = useState(false)
  const { user } = useUserContext()
  const navigate = useNavigate()
  const toast = useToast()

  useEffect(() => {
    StoryService.getStoriesFromPack(STORY_PACK_ID).then((s) =>
      setStories(s.sort((a, b) => a.order - b.order))
    )
  }, [])

  useEffect(() => {
    if (!user) return
    UserStoryPackService.list(user.id)
      .then((usp) => {
        setUSP(usp.find((u) => u.storyPackId === STORY_PACK_ID))
      })
      .catch(() => {})
  }, [user])

  const buyStoryPack = () => {
    if (!user) return
    setLoading(true)
    StoryPackService.buyStoryPack(user, STORY_PACK_ID)
      .then(async () => {
        navigate(`/stories/buy/${STORY_PACK_ID}/success`)
      })
      .catch(() =>
        toast({
          title: "Error",
          description: "Something went wrong, please try again later",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        })
      )
      .finally(() => {
        setLoading(false)
      })
  }

  if (userStoryPack) {
    return <StoriesBought />
  }

  return (
    <Navigation px="1rem !important">
      <Grid mb="1rem" gridTemplateColumns="50px 1fr 50px">
        <BackButton color="white" buttonBg />
        <Heading
          fontSize="24px"
          my="auto"
          textAlign="center"
          color="white"
          textShadow="0 0 4px black"
        >
          Create stories
        </Heading>
      </Grid>
      <Box
        bg="#ffffffb0"
        borderRadius="32px"
        p={["1.5rem"]}
        mx="-1rem"
        backdropFilter="blur(4px)"
      >
        <Heading size="sm" color="black">
          Embark on journeys to fantastical realms with our magical comic book
          collection
        </Heading>
        <Text fontWeight="light" color="black">
          Explore universes and themes
        </Text>
        <Box mx="-1rem" mt="1rem">
          <Flex gap="1rem" overflowX="auto" pb="0.5rem" px="1rem">
            {stories.map((story) => (
              <Box key={story.id}>
                <StoryBookCard height="400px" width="300px" story={story} opacity={story.comingSoon ? 0.6 : 1} />
                {story.comingSoon && (
                  <Text
                    size="xs"
                    fontWeight="light"
                    color="black"
                    textAlign="center"
                  >
                    {"Coming soon!"}
                  </Text>
                )}
              </Box>
            ))}
          </Flex>
        </Box>
        <Flex w="100%" mt="1rem">
          <Button3d
            w={["100%", "100%", "auto"]}
            m="auto"
            loadingText="Preparing your stories"
            isLoading={loading}
            onClick={buyStoryPack}
          >
            Get the collection
          </Button3d>
        </Flex>
      </Box>
    </Navigation>
  )
}
