export function getAgeString(date: Date) {
  let timeSinceCreation = new Date().getTime() - date.getTime()
  let minutesSinceCreation = Math.floor(timeSinceCreation / 60000)
  let hoursSinceCreation = Math.floor(minutesSinceCreation / 60)
  let daysSinceCreation = Math.floor(hoursSinceCreation / 24)
  let monthsSinceCreation = Math.floor(daysSinceCreation / 30)
  let yearsSinceCreation = Math.floor(daysSinceCreation / 365)
  let ageString = ""

  if (yearsSinceCreation > 0) {
    ageString = `${yearsSinceCreation} year${yearsSinceCreation > 0 ? "s" : ""}`
  } else if (monthsSinceCreation > 0) {
    ageString = `${monthsSinceCreation} month${
      monthsSinceCreation > 0 ? "s" : ""
    }`
  } else if (daysSinceCreation > 0) {
    ageString = `${daysSinceCreation} day${daysSinceCreation !== 1 ? "s" : ""}`
  } else if (hoursSinceCreation > 0) {
    ageString = `${hoursSinceCreation} hour${
      hoursSinceCreation !== 1 ? "s" : ""
    }`
  } else if (minutesSinceCreation > 0) {
    ageString = `${minutesSinceCreation} minute${
      minutesSinceCreation !== 1 ? "s" : ""
    }`
  } else {
    ageString = "A few seconds"
  }

  return ageString
}

export type Pronouns = "he/him" | "she/her" | "they/them"

export const ORDER_NAMES: {
  [key: number]: string
} = {
  0: "first",
  1: "second",
  2: "third",
  3: "fourth",
  4: "fifth",
  5: "sixth",
  6: "seventh",
  7: "eighth",
  8: "ninth",
  9: "tenth",
  10: "eleventh",
  11: "twelfth",
  12: "thirteenth",
  13: "fourteenth",
  14: "fifteenth",
}

export function getWeeksSinceDate(date: Date): number {
  const d = new Date(date)
  d.setHours(0, 0, 0, 0)
  const diff = new Date().getTime() - d.getTime()
  const days = diff / (1000 * 60 * 60 * 24)
  return Math.max(0, Math.floor(days / 7))
}
