import { useEffect, useState } from "react"
import { Navigate, useParams } from "react-router-dom"
import Story, { StoryBoard } from "../../../../data/models/Story"
import StoryService from "../../../../service/StoryService"
import LoadingScreen from "../../../components/LoadingScreen"
import StoryBoardPage from "./StoryBoardPage"

async function cacheAssets(boards: StoryBoard[]): Promise<any> {
  const promises = boards.map((board) => {
    return new Promise((resolve, reject) => {
      if (board.isVideo) {
        const video = document.createElement("video")
        video.style.width = "1"
        video.style.height = "1px"
        video.style.opacity = "0"
        video.style.position = "fixed"
        video.style.top = "0"
        video.style.left = "0"
        video.src = window.innerWidth < 769 && board.backgroundMobile.length > 0 ? board.backgroundMobile : board.background
        video.onloadedmetadata = () => resolve(true)
        video.onerror = () => resolve(true)
      } else {
        const img = new Image()
        img.src = board.background
        img.onload = () => resolve(true)
        img.onerror = () => reject(true)
      }
    })
  })
  return Promise.all(promises)
}

export default function StoryPage() {
  const { storyId } = useParams()
  const [loading, setLoading] = useState(true)
  const [story, setStory] = useState(null as Story | null)
  const [page, setPage] = useState(0)
  const [loadedPage, setLoadedPage] = useState(0)

  useEffect(() => {
    if (storyId === undefined) {
      setLoading(false)
      return
    }
    setLoading(true)
    StoryService.getStoryOld(storyId).then((story) => {
      setStory(story)
      if (story) {
        cacheAssets(story.boards.slice(0, 2)).finally(() => setLoading(false))
        setLoadedPage(1)
      } else setLoading(false)
    })
  }, [storyId])

  useEffect(() => {
    if (story && page >= loadedPage && story.boards.length > page + 1) {
      setLoadedPage(page)
      cacheAssets(story.boards.slice(page + 1, page + 2)).finally(() =>
        setLoading(false)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  if (loading) return <LoadingScreen />
  if (story === null || story.boards.length === 0)
    return <Navigate to={`/stories`} replace />

  return (
    <StoryBoardPage
      board={story.boards[page]}
      onNext={
        page + 1 < story.boards.length ? () => setPage(page + 1) : undefined
      }
      onPrevious={page > 0 ? () => setPage(page - 1) : undefined}
    />
  )
}
