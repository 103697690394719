import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react"
import { Player } from "@lottiefiles/react-lottie-player"
import { useNavigate } from "react-router-dom"
import BackButton from "../../../components/Buttons/BackButton"
import Background from "../../../components/Wrapper/Background"
import ResponsiveContainer from "../../../components/Wrapper/ResponsiveContainer"

export default function WebtoonSuccess() {
  const navigate = useNavigate()

  return (
    <Background overflowY="auto">
      <ResponsiveContainer
        pb={["2em", "2em", "2em"]}
        display="flex"
        flexDir="column"
      >
        <BackButton color="black" onClick={() => navigate("/stories")} />
        <Flex
          flexDir="column"
          alignItems="center"
          flexGrow={1}
          textAlign="center"
        >
          <Box w="400px" maxW="100%">
            <Player
              src={
                "https://lottie.host/1ee52e01-48c7-441b-a678-cfac2bf2108d/9y7x0hlpGP.json"
              }
              keepLastFrame
              autoplay
            />
          </Box>
          <Heading color="#3BC8A0" mb="1rem" size="sm">
            You successfuly created a new story
          </Heading>
          <Text size="sm" textAlign="center" mb="1rem">
            Wait a little bit until your magic book is created. We will notify
            you once it’s ready!
          </Text>
          <Flex gap="1rem" m="auto" flexDir="column">
            <Button
              variant="fullSecondary"
              onClick={() => navigate("/stories")}
            >
              Create another story
            </Button>
            <Button
              variant="border"
              color="secondary.500"
              borderColor="secondary.500"
              onClick={() => navigate("/app")}
            >
              Back to homepage
            </Button>
          </Flex>
        </Flex>
      </ResponsiveContainer>
    </Background>
  )
}
