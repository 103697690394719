import {
  CollectionReference,
  DocumentReference,
  DocumentSnapshot,
  collection,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore"
import { db } from "../data/apis/firebase/Firebase"
import { StoryPack, StoryPackFirebase } from "../data/models/StoryPack"

export default class StoryPackDAO {
  private static storyFirebaseToStoryPack = (
    storyPackFirebase: DocumentSnapshot<StoryPackFirebase>
  ): StoryPack => {
    const data = storyPackFirebase.data()
    if (!data) throw new Error("No data found for story pack")
    return {
      id: storyPackFirebase.id,
      price: data.price || 0,
      title: data.title || "",
      description: data.description || "",
      cover: data.cover || "",
    }
  }

  static async getStoryPack(id: string): Promise<StoryPack> {
    return await getDoc<StoryPackFirebase>(
      doc(db, "story_packs", id) as DocumentReference<StoryPackFirebase>
    ).then((d) => {
      if (!d.exists()) throw new Error("No story pack found")
      return StoryPackDAO.storyFirebaseToStoryPack(d)
    })
  }

  static async getStoryPacks(): Promise<StoryPack[]> {
    return await getDocs<StoryPackFirebase>(
      collection(db, "story_packs") as CollectionReference<StoryPackFirebase>
    ).then((d) => d.docs.map(StoryPackDAO.storyFirebaseToStoryPack))
  }
}
