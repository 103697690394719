import { Box, Button, Flex, Heading, Text, useToast } from "@chakra-ui/react"
import { Player } from "@lottiefiles/react-lottie-player"
import { ChangeEvent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useUserContext } from "../../../../context/userContext"
import Creation from "../../../../data/models/Creation"
import Story from "../../../../data/models/Story"
import { UserStoryPack } from "../../../../data/models/UserStoryPack"
import CreationService from "../../../../service/CreationService"
import UserService from "../../../../service/UserService"
import UserStoryPackService from "../../../../service/UserStoryPackService"
import WebtoonService from "../../../../service/WebtoonService"
import { Pronouns } from "../../../../utilities/dateUtils"
import { ComicBookActionBlock } from "../../../components/ActionBlocks/ComicBookActionBlock"
import PrintActionBlock from "../../../components/ActionBlocks/PrintActionBlock"
import UploadActionBlock from "../../../components/ActionBlocks/UploadActionBlock"
import CharacterView from "../../../components/Creations/CharacterView"
import MagicWandIcon from "../../../components/Icons/MagicWandIcon"
import SlidingPopup from "../../../components/Popup/SlidingPopup"
import PronounSelect from "../../../components/Select/PronounsSelect"
import StoryBookCard from "../../../components/Stories/StoryBookCard"
import Navigation from "../../../components/Wrapper/Navigation"
import WebtoonList from "../WebtoonList/WebtoonList"

function CreateStoryPopup({
  show,
  onClose,
  creation,
  pack,
}: {
  show: boolean
  onClose: () => any
  creation: Creation
  pack?: UserStoryPack
}) {
  const [story, setStory] = useState<Story>()
  const [shownStory, setShownStory] = useState<Story>()
  const { user } = useUserContext()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const toast = useToast()

  useEffect(() => {
    if (story) {
      setShownStory(story)
    }
  }, [story])

  const createStory = async () => {
    if (!user || !story || !pack) return
    setLoading(true)
    WebtoonService.create(user, creation.id, story.id, pack.id)
      .then((wt) => {
        navigate(`/creations/webtoon/${wt.userStoryPackId}/${wt.id}/success`)
      })
      .catch(() => {
        toast({
          title: "Something went wrong...",
          description: "Please try again later",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <>
      <SlidingPopup
        id="story-list"
        show={show && story === undefined}
        onClose={onClose}
      >
        <WebtoonList hideGenerated hideTitle onChooseUngenerated={setStory} />
      </SlidingPopup>
      <SlidingPopup
        id="create-story"
        show={show && story !== undefined}
        onClose={() => {
          setStory(undefined)
          onClose()
        }}
      >
        <Heading size="md" mb="1rem" textAlign="center">
          Create story with {creation.title}
        </Heading>
        {shownStory && (
          <StoryBookCard m="auto" h="350px" w="250px" story={shownStory} />
        )}
        <Button
          w="100%"
          mt="1rem"
          onClick={createStory}
          isLoading={loading}
          loadingText="Building your world..."
        >
          Yes, I choose this story
        </Button>
      </SlidingPopup>
    </>
  )
}

function ActionButtons({ creation }: { creation: Creation }) {
  const [showPopup, setShowPopup] = useState(false)
  const [pack, setPack] = useState<UserStoryPack>()
  const { user } = useUserContext()
  const navigate = useNavigate()
  const [canCreateStories, setCanCreateStories] = useState(false)

  useEffect(() => {
    if (!user) return
    UserStoryPackService.list(user.id)
      .then((p) => {
        if (p.length > 0) setPack(p[0])
      })
      .catch()
    UserService.canCreateStories(user.id).then((can) =>
      setCanCreateStories(can)
    )
  }, [user])

  return (
    <Box mt="2rem" gap="1rem" display="flex" flexDir="column">
      <Box w="500px" maxW="100%" m="auto">
        <PrintActionBlock creation={creation} />
      </Box>
      {canCreateStories && (
        <Box w="500px" maxW="100%" m="auto">
          <ComicBookActionBlock
            onCreateStories={() => {
              if (!pack) return navigate("/stories")
              setShowPopup(true)
            }}
          />
        </Box>
      )}
      <Box w="500px" maxW="100%" m="auto">
        <UploadActionBlock />
      </Box>
      <CreateStoryPopup
        onClose={() => {
          setShowPopup(false)
        }}
        show={showPopup}
        creation={creation}
        pack={pack}
      />
    </Box>
  )
}

function ShareArtPrompt(props: { onAccept: () => any; onDecline: () => any }) {
  return (
    <Box pb="1em">
      <Text size="sm" pb="0.5em">
        This art is yours! Do you allow us to share it on our community page and
        social medial? 🥰
      </Text>
      <Flex gap="1em" justifyContent="center">
        <Button variant="white" onClick={props.onDecline}>
          No
        </Button>
        <Button onClick={props.onAccept}>Yes</Button>
      </Flex>
    </Box>
  )
}

function Congrats() {
  return (
    <Flex flexDir="column">
      <Box w="150px" h="150px" mx="auto">
        <Player
          src={
            "https://lottie.host/1ee52e01-48c7-441b-a678-cfac2bf2108d/9y7x0hlpGP.json"
          }
          keepLastFrame
          autoplay
        />
      </Box>
      <Text textAlign="center" color="black">
        You have created your own <br />
        magical character!
      </Text>
    </Flex>
  )
}

function CreationActionContent({
  onAccept,
  onDecline,
  creation,
}: {
  onAccept: () => any
  onDecline: () => any
  creation: Creation
}) {
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const updatePronouns = async (e: ChangeEvent<HTMLSelectElement>) => {
    setLoading(true)
    await CreationService.updatePronouns(
      creation,
      e.currentTarget.value as Pronouns
    ).finally(() => setLoading(false))
    toast({
      title: "Pronouns updated",
      status: "success",
      duration: 2000,
      isClosable: true,
      position: "top-right",
      description: "Your character's pronouns have been updated",
    })
  }

  return (
    <Box textAlign="center" pt="1em">
      {creation.shareAgreement === undefined && (
        <ShareArtPrompt onAccept={onAccept} onDecline={onDecline} />
      )}
      <Flex placeContent="center">
        <MagicWandIcon width="2em" height="2em" />
        <Heading size="sm" alignSelf="center">
          Keep Creating!
        </Heading>
      </Flex>
      <ActionButtons creation={creation} />
      <Flex py="1rem" gap="1rem" mt="2rem">
        <Text size="sm" placeSelf="center">
          Pronouns:
        </Text>
        <PronounSelect
          bg="white"
          disabled={loading}
          value={creation.pronouns}
          w="150px"
          onChange={updatePronouns}
        />
      </Flex>
    </Box>
  )
}

export default function CreationActions({
  creation,
  showCongrats,
}: {
  creation: Creation
  showCongrats: boolean
}) {
  const toast = useToast()
  const [congratsTimedOut, setCongratsTimedOut] = useState(!showCongrats)
  const navigate = useNavigate()

  useEffect(() => {
    if (showCongrats) {
      setTimeout(() => {
        setCongratsTimedOut(true)
        navigate("/app")
      }, 5000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCongrats])

  const onAccept = () => {
    toast({
      title: "Thank you!",
      description: "Other users might now see your art on our community page",
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "top-right",
    })
    CreationService.setShareAgreement(creation, true)
  }

  const onDecline = () => {
    toast({
      title: "Okay!",
      description: "Your art will not be shared on our community page",
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "top-right",
    })
    CreationService.setShareAgreement(creation, false)
  }

  return (
    <Navigation
      width={["100%", "100%", "700px", "800px", "800px"]}
      px={"0!important"}
    >
      <Box
        bg="#ffffffb0"
        backdropFilter="blur(4px)"
        borderRadius="32px"
        p="1.5rem"
        pt={[0, 0, "1.5rem"]}
      >
        <CharacterView
          onClickBackButton={() => navigate("/app")}
          borderRadius={["32px 32px 0 0", "32px 32px 0 0", "16px"]}
          creation={creation}
          mx={["-1.5rem", "-1.5rem", "0", "0"]}
          showShareButton
        />
        {!congratsTimedOut ? (
          <Congrats />
        ) : (
          <CreationActionContent
            creation={creation}
            onAccept={onAccept}
            onDecline={onDecline}
          />
        )}
      </Box>
    </Navigation>
  )
}
