import { ChevronLeftIcon } from "@chakra-ui/icons"
import { Button, ButtonProps } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

export default function FullBackButton({
  backTo,
  backDelta = -1,
  ...props
}: {
  backTo?: string
  backDelta?: number
} & ButtonProps) {
  const navigate = useNavigate()

  return (
    <Button
      variant="ghost"
      w="100%"
      mt="2rem"
      color="primary.500"
      onClick={() => (backTo ? navigate(backTo) : navigate(backDelta))}
      {...props}
    >
      <ChevronLeftIcon fontSize="2em" /> Go back
    </Button>
  )
}
