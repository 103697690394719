import { Icon, IconProps } from "@chakra-ui/react"

export default function CardBottomClouds({ ...props }: IconProps) {
  return (
    <Icon
      width="130"
      height="55"
      viewBox="0 0 130 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMin slice"
      color="primary.500"
      {...props}
    >
      <path
        d="M-1.59379 58.3129V16.1476C-1.59379 7.12905 -1.59473 2.23315 -1.59473 2.23315C12.2966 -4.18759 22.982 6.0218 22.982 14.2206C32.214 4.973 42.0016 13.1496 42.0016 18.7164C44.7797 11.6511 52.4727 14.8621 52.4727 18.7164C58.4564 -1.6188 85.383 5.2313 87.3066 18.7164C92.8629 10.3667 104.617 13.5777 106.326 18.7164C110.6 4.80103 122.995 4.30265 131.329 5.44433V16.1476V58.3129H-1.59379Z"
        fill="currentColor"
      />
    </Icon>
  )
}
