import { Image, Text, TextProps } from "@chakra-ui/react"
import { WebtoonPanel } from "../../../../data/models/Webtoon"
import epicNarratoryBlock from "../../../assets/images/epic-narratory-block.png"

export default function NarratoryBlock({ panel }: { panel: WebtoonPanel }) {
  let props: TextProps = {}
  if (panel.narratoryBlock?.maxWidthPercent) {
    props.maxW = `${panel.narratoryBlock.maxWidthPercent * 100}%`
  }

  props.alignSelf = "center"
  switch (panel.narratoryBlock?.align) {
    case "left":
      props.alignSelf = "flex-start"
      break
    case "right":
      props.alignSelf = "flex-end"
      break
  }

  let float = panel.video != null || panel.image != null

  // compatibility with old data
  let offsetY: string
  let offsetYnb = panel.narratoryBlock?.offsetY ?? 0
  if (Math.abs(offsetYnb) <= 1) {
    offsetYnb = offsetYnb * 100
    offsetY = `${
      panel.narratoryBlock?.position === "top" ? offsetYnb : -offsetYnb
    }%`
  } else {
    offsetY = `${
      panel.narratoryBlock?.position === "top" ? offsetYnb : -offsetYnb
    }px`
  }

  if (float) {
    props.position = "absolute"
    let position =
      panel.narratoryBlock?.position ??
      (panel.narratoryBlock?.overlapPanels ? "bottom" : "center")
    switch (position) {
      case "top":
        props.top = offsetY
        if (panel.narratoryBlock?.overlapPanels === "FULL") {
          props.transform = "translateY(-50%)"
        } else if (panel.narratoryBlock?.overlapPanels === "HALF") {
          props.transform = "translateY(-25%)"
        }
        break
      case "bottom":
        props.bottom = offsetY
        if (panel.narratoryBlock?.overlapPanels === "FULL") {
          props.transform = "translateY(50%)"
        } else if (panel.narratoryBlock?.overlapPanels === "HALF") {
          props.transform = "translateY(25%)"
        }
        break
      default:
        props.top = "50%"
        props.transform = "translateY(-50%)"
        break
    }
  } else {
    props.position = "relative"
    props.my = "120px"
  }

  switch (panel.narratoryBlock?.style) {
    case "EPIC":
      props.padding = "4rem 25%"
      props.textAlign = "center"
      break
    case "BUBBLE":
      props.bg = "white"
      props.border = "solid 1px black"
      props.borderRadius = "100%"
      props.padding = "32px 44px"
      props.textAlign = "center"
      break
    default:
      props.boxShadow = "0px 4px 4px rgba(0, 0, 0, 0.25)"
      props.border = "solid 1px black"
      props.bg = "white"
      props.padding = "20px"
      break
  }

  const fontSize = panel.narratoryBlock?.size ?? 20

  return (
    <>
      <Text
        w="fit-content"
        fontSize={[`${fontSize*0.8}px`, `${fontSize}px`]}
        variant="cartoon"
        zIndex={2}
        mx="0.5rem"
        whiteSpace="pre-wrap"
        {...props}
      >
        {panel.narratoryBlock?.style === "EPIC" && (
          <Image
            src={epicNarratoryBlock}
            pos="absolute"
            top="0"
            left="0"
            w="100%"
            h="100%"
            zIndex={-1}
          />
        )}
        {panel.narratoryBlock!.text}
      </Text>
      {panel.narratoryBlock?.overlapPanels === "HALF" && (
        <Text
          w="fit-content"
          maxW={panel.narratoryBlock.maxWidthPercent! * 50 + "%"}
          fontSize={[`${fontSize*0.4}px`, `${fontSize / 2}px`]}
          variant="cartoon"
          zIndex={-1}
          padding="10px"
          userSelect="none"
          whiteSpace="pre-wrap"
          opacity={0}
        >
          {panel.narratoryBlock!.text}
        </Text>
      )}
    </>
  )
}
