import { Container, Flex, Grid, Image, Input } from "@chakra-ui/react"
import { useState } from "react"
import { Pronouns } from "../../../../utilities/dateUtils"
import Button3d from "../../../components/Buttons/Button3d"
import ArrowRightIcon from "../../../components/Icons/ArrowRightIcon"
import PronounSelect from "../../../components/Select/PronounsSelect"
import { UploadLoading } from "../Upload"
import ImageDescription from "./CreationDescription"
import BackButton from "../../../components/Buttons/BackButton"
import ResponsiveContainer from "../../../components/Wrapper/ResponsiveContainer"
import Background from "../../../components/Wrapper/Background"
import BackgroundImageBlurred from "../../../components/Wrapper/BackgroundImageBlurred"
import bg from "../../../assets/images/backgrounds/upload-background.png"

export function CharacterDescriptionForm({
  handleNext,
  description,
  setDescription,
  imageUrl,
}: {
  handleNext: () => void
  description: string
  setDescription: (description: string) => void
  imageUrl: string | undefined
}) {
  return (
    <Container p="0" pt="1rem" flexGrow="1">
      <Flex
        height="100%"
        flex={1}
        justifyItems="center"
        alignItems={"center"}
        flexDirection={"column"}
        placeItems="center"
        placeContent="end"
      >
        {imageUrl && (
          <Image
            src={imageUrl}
            h={["200px", "300px", "400px"]}
            objectFit="contain"
            border="solid 2px #494949"
            borderRadius="12px"
            mb="1rem"
          />
        )}
        <ImageDescription
          description={description}
          setDescription={setDescription}
          imageUrl={imageUrl}
        />
        <Button3d
          onClick={() => handleNext()}
          disabled={description.length < 3}
          loadingText="Uploading..."
          marginBottom="1em"
          size="lg"
          mt="1rem"
        >
          <ArrowRightIcon h="50px" w="100px" />
        </Button3d>
      </Flex>
    </Container>
  )
}

export function CharacterTitleForm({
  handleNext,
  title,
  setTitle,
  pronouns,
  setPronouns,
  imageUrl,
}: {
  handleNext: () => void
  title: string
  setTitle: (title: string) => void
  pronouns: Pronouns | undefined
  setPronouns: (pronouns: Pronouns) => void
  imageUrl?: string
}) {
  return (
    <Container p="0" pt="1rem" flexGrow="1">
      <Flex
        height="100%"
        flex={1}
        justifyItems="center"
        alignItems={"center"}
        flexDirection={"column"}
        placeItems="center"
        placeContent="end"
      >
        {imageUrl && (
          <Image
            src={imageUrl}
            h={["200px", "300px", "400px"]}
            objectFit="contain"
            border="solid 2px #494949"
            borderRadius="12px"
            mb="1rem"
          />
        )}
        <Grid gridTemplateColumns="1fr 120px" gap="0.5rem" w="100%">
          <Input
            w="100%"
            border="solid 2px #494949"
            type="text"
            placeholder="What's their name?"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            marginBottom="1em"
            borderRadius="12px"
            padding="0.5rem 1.5rem"
            fontWeight="500"
            height="fit-content"
          />
          <PronounSelect
            bg="white"
            placeholder="Pronoun"
            value={pronouns}
            onChange={(e) => setPronouns(e.currentTarget.value as Pronouns)}
            marginBottom="1em"
          />
        </Grid>
        <Button3d
          onClick={() => handleNext()}
          disabled={pronouns === undefined || title.trim().length < 2}
          loadingText="Uploading..."
          marginBottom="1em"
          size="lg"
          mt="1rem"
        >
          <ArrowRightIcon h="50px" w="100px" />
        </Button3d>
      </Flex>
    </Container>
  )
}

export default function DrawingDetailsForm({
  handleUpload,
  resetImage,
  imageUrl,
}: {
  handleUpload: (
    title: string,
    description: string,
    pronouns: Pronouns | undefined
  ) => Promise<void>
  resetImage: () => any
  imageUrl: string
}) {
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [pronouns, setPronouns] = useState<Pronouns>()
  const [loading, setLoading] = useState(false)

  return (
    <Background overflow="auto">
      <BackgroundImageBlurred
        blur={false}
        backgroundImage={bg}
        bgPos="50% 50%"
        backgroundColor="transparent"
      />
      <ResponsiveContainer maxW="600px" display="flex" flexDir="column">
        <BackButton onClick={resetImage} color="white" buttonBg />
        {loading && <UploadLoading overlay />}
        <Flex
          mt="auto"
          justifyItems="center"
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Image
            src={imageUrl}
            h={["200px", "300px", "400px"]}
            objectFit="cover"
            border="solid 2px #494949"
            borderRadius="12px"
            mb="1rem"
          />
          <Input
            border="solid 2px #494949"
            type="text"
            placeholder="What's their name?"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            marginBottom="1rem"
            borderRadius="12px"
            padding="0.5rem 1.5rem"
            height="fit-content"
            fontWeight="500"
          />
          <ImageDescription
            description={description}
            setDescription={setDescription}
            imageUrl={imageUrl}
          />
          <PronounSelect
            bg="white"
            placeholder="Pronoun"
            value={pronouns}
            onChange={(e) => setPronouns(e.currentTarget.value as Pronouns)}
            my="1rem"
          />
          <Button3d
            onClick={() => {
              setLoading(true)
              handleUpload(title, description, pronouns).finally(() =>
                setLoading(false)
              )
            }}
            isLoading={loading}
            loadingText="Uploading..."
            marginBottom="1rem"
            size="lg"
          >
            <ArrowRightIcon h="50px" w="100px" />
          </Button3d>
        </Flex>
      </ResponsiveContainer>
    </Background>
  )
}
