import { Flex, Progress, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useQuery } from "../../../utilities/hooks"
import Background from "../../components/Wrapper/Background"
import "./UploadAnimations.css"
import { UploadScreen } from "./UploadScreen"

const LOADING_TEXTS = [
  // "Waking up Camcam...", // RIP Camcam
  "Reloading the art machine...",
  "Looking for a new canvas...",
  "Getting the brushes ready...",
  "Loading the pixels...",
  "Naming colors...",
  "Finding inspiration...",
]

export function UploadLoading({ overlay = false }: { overlay?: boolean }) {
  const [loadingText, setLoadingText] = useState(
    LOADING_TEXTS[Math.floor(Math.random() * LOADING_TEXTS.length)]
  )

  useEffect(() => {
    const interval = setInterval(() => {
      let text = LOADING_TEXTS[Math.floor(Math.random() * LOADING_TEXTS.length)]
      while (text === loadingText) {
        text = LOADING_TEXTS[Math.floor(Math.random() * LOADING_TEXTS.length)]
      }
      setLoadingText(text)
    }, 2000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {overlay && (
        <Flex
          position="fixed"
          top="0"
          left="0"
          w="100%"
          h="100%"
          bg="rgba(0,0,0,0.4)"
          zIndex="2"
        />
      )}
      <Flex
        position="fixed"
        transform="translate(-50%, -50%)"
        top="50%"
        left="50%"
        flexDirection="column"
        gap="0.5rem"
        background={!overlay ? "transparent" : "#eee9fdd0"}
        backdropFilter="blur(4px)"
        width="400px"
        zIndex="2"
        py="2rem"
        borderRadius="20px"
      >
        <Text
          color="secondary.500"
          fontWeight="bold"
          w="100%"
          px="1rem"
          textAlign="center"
          className="three-dots-loading"
        >
          Generating your Mini
        </Text>
        <Progress
          isIndeterminate
          size="xs"
          colorScheme="secondary"
          bg="secondary.50"
          borderRadius="full"
          width="300px"
          mx="auto"
        />
        <Text color="secondary.500" size="14px" textAlign="center">
          {loadingText}
        </Text>
      </Flex>
    </>
  )
}

const Upload = () => {
  const [loading, setLoading] = useState(false)
  // const [availableCredits, setAvailableCredits] = useState<number | null>(null)
  const query = useQuery()
  const camera = query.get("camera") === "true"
  const describe = query.get("describe") === "true"

  // const getAvailableCredits = async () => {
  //   const creditsCount = await getAvailableCreditsCount()
  //   setAvailableCredits(creditsCount)
  // }
  // useEffect(() => {
  //   getAvailableCredits()
  //   const allImages = [papersBck, leftTriangle1, commandCenter]
  //   const cacheAssets = async (imagesArray: any[], callback: () => any) => {
  //     setLoadingImages(true)
  //     const promises = await imagesArray.map((src) => {
  //       return new Promise((resolve, reject) => {
  //         const img = new Image()
  //         img.src = src
  //         img.onload = () => resolve(true)
  //         img.onerror = () => reject(true)
  //       })
  //     })
  //     await Promise.all(promises)
  //     callback()
  //   }
  //   cacheAssets(allImages, () => {
  //     setLoadingImages(false)
  //   })
  // }, [])

  return (
    <Background overflowY="auto">
      {loading && <UploadLoading overlay />}
      <UploadScreen
        describeOnInit={describe}
        setUploadLoading={setLoading}
        userCredits={1000}
        showCameraOnInit={camera}
      />
    </Background>
  )
}

export default Upload
