import { Box, Heading, Image } from "@chakra-ui/react"
import backgroundImg from "../../assets/images/homepage/ask-them-anything/art-studio.png"
import deskLeft from "../../assets/images/homepage/ask-them-anything/ask-desk-left.png"
import deskRight from "../../assets/images/homepage/ask-them-anything/ask-desk-right.png"
import mobile from "../../assets/images/homepage/ask-them-anything/ask-mobile.png"
import Button3d from "../../components/Buttons/Button3d"
import ArrowRightIcon from "../../components/Icons/ArrowRightIcon"
import ResponsiveContainer from "../../components/Wrapper/ResponsiveContainer"

export default function AskThemAnything({ onClick }: { onClick: () => void }) {
  return (
    <Box position="relative" h="800px" minH="fit-content" w="100%" mt="-1px">
      <Image
        src={backgroundImg}
        position="absolute"
        top="0"
        left="0"
        h="100%"
        w="100%"
        objectFit="cover"
        zIndex={-1}
      />
      <Box
        position="absolute"
        top="0%"
        left="0"
        w="100%"
        height="20%"
        bg="linear-gradient(0, rgba(35, 27, 42, 0.00) 2.08%, #191321 100%)"
        zIndex={-1}
      />
      <ResponsiveContainer
        width={["100%", "100%", "100%", "1000px", "1200px", "1400px"]}
        display="flex"
        flexDirection="column"
        position="relative"
      >
        <Heading
          color="white"
          textAlign="center"
          mt="2rem"
          fontSize={["30px", "32px", "38px", "50px", "60px"]}
        >
          Ask them Anything!
        </Heading>
        <Image
          display={["none", "none", "none", "block"]}
          src={deskLeft}
          h="600px"
          objectFit="contain"
          position="absolute"
          left="0"
          top="50px"
        />
        <Image
          display={["none", "none", "none", "block"]}
          src={deskRight}
          h="600px"
          objectFit="contain"
          position="absolute"
          right="0"
          top="50px"
        />
        <Image
          display={["block", "block", "block", "none"]}
          src={mobile}
          h="600px"
          objectFit="cover"
          position="absolute"
          left="50%"
          top="100px"
          transform="translateX(-50%)"
        />
        <Button3d
          size="lg"
          m="auto auto 2rem auto"
          bg3dColor="#201789"
          color="white"
          background="secondary.500"
          onClick={onClick}
        >
          <ArrowRightIcon height="50px" width="100px" />
        </Button3d>
      </ResponsiveContainer>
      <Box
        zIndex={-1}
        position="absolute"
        bottom="0"
        left="0"
        w="100%"
        height="20%"
        bg="linear-gradient(180deg, rgba(0, 0, 0, 0.00) 2.08%, #000000 67%)"
      />
    </Box>
  )
}
