import { Box, Flex, Text } from "@chakra-ui/react"
import { WebtoonSpeechBalloon } from "../../data/models/Webtoon"

const ARROW_CONFIG: {
  [key in WebtoonSpeechBalloon["position"]]: {
    [key in WebtoonSpeechBalloon["direction"]]: {
      black: React.ReactNode
      white: React.ReactNode
    }
  }
} = {
  top: {
    left: {
      black: (
        <Box
          transform="translateY(80%) rotate(10deg)"
          display="inline-block"
          height="0"
          width="0"
          borderTop="80px solid black"
          borderLeft="10px solid transparent"
          borderRight="10px solid transparent"
          position="absolute"
          bottom="0"
          left="30%"
          zIndex={1}
        />
      ),
      white: (
        <Box
          transform="translateY(80%) rotate(10deg)"
          display="inline-block"
          height="0"
          width="0"
          borderTop="80px solid white"
          borderLeft="10px solid transparent"
          borderRight="10px solid transparent"
          position="absolute"
          bottom="6px"
          left="calc(30% + 1px)"
          zIndex={1}
        />
      ),
    },
    center: {
      black: (
        <Box
          transform="translate(50%, 80%) rotate(15deg)"
          display="inline-block"
          height="0"
          width="0"
          borderTop="80px solid black"
          borderLeft="10px solid transparent"
          borderRight="10px solid transparent"
          position="absolute"
          bottom="0"
          right="50%"
          zIndex={1}
        />
      ),
      white: (
        <Box
          transform="translate(50%, 80%) rotate(15deg)"
          display="inline-block"
          height="0"
          width="0"
          borderTop="80px solid white"
          borderLeft="10px solid transparent"
          borderRight="10px solid transparent"
          position="absolute"
          bottom="8px"
          right="calc(50% - 2px)"
          zIndex={1}
        />
      ),
    },
    right: {
      black: (
        <Box
          transform="translateY(80%) rotate(-10deg)"
          display="inline-block"
          height="0"
          width="0"
          borderTop="80px solid black"
          borderLeft="10px solid transparent"
          borderRight="10px solid transparent"
          position="absolute"
          bottom="0"
          right="30%"
          zIndex={1}
        />
      ),
      white: (
        <Box
          transform="translateY(80%) rotate(-10deg)"
          display="inline-block"
          height="0"
          width="0"
          borderTop="80px solid white"
          borderLeft="10px solid transparent"
          borderRight="10px solid transparent"
          position="absolute"
          bottom="6px"
          right="calc(30% + 1px)"
          zIndex={1}
        />
      ),
    },
  },
  bottom: {
    left: {
      black: (
        <Box
          transform="translateY(-80%) rotate(-10deg)"
          display="inline-block"
          height="0"
          width="0"
          borderBottom="80px solid black"
          borderLeft="10px solid transparent"
          borderRight="10px solid transparent"
          position="absolute"
          top="0"
          left="30%"
          zIndex={1}
        />
      ),
      white: (
        <Box
          transform="translateY(-80%) rotate(-10deg)"
          display="inline-block"
          height="0"
          width="0"
          borderBottom="80px solid white"
          borderLeft="10px solid transparent"
          borderRight="10px solid transparent"
          position="absolute"
          top="6px"
          left="calc(30% + 1px)"
          zIndex={1}
        />
      ),
    },
    center: {
      black: (
        <Box
          transform="translate(50%, -80%) rotate(15deg)"
          display="inline-block"
          height="0"
          width="0"
          borderBottom="80px solid black"
          borderLeft="10px solid transparent"
          borderRight="10px solid transparent"
          position="absolute"
          top="0"
          right="50%"
          zIndex={1}
        />
      ),
      white: (
        <Box
          transform="translate(50%, -80%) rotate(15deg)"
          display="inline-block"
          height="0"
          width="0"
          borderBottom="80px solid white"
          borderLeft="10px solid transparent"
          borderRight="10px solid transparent"
          position="absolute"
          top="8px"
          right="calc(50% + 2px)"
          zIndex={1}
        />
      ),
    },
    right: {
      black: (
        <Box
          transform="translateY(-80%) rotate(10deg)"
          display="inline-block"
          height="0"
          width="0"
          borderBottom="80px solid black"
          borderLeft="10px solid transparent"
          borderRight="10px solid transparent"
          position="absolute"
          top="0"
          right="30%"
          zIndex={1}
        />
      ),
      white: (
        <Box
          transform="translateY(-80%) rotate(10deg)"
          display="inline-block"
          height="0"
          width="0"
          borderBottom="80px solid white"
          borderLeft="10px solid transparent"
          borderRight="10px solid transparent"
          position="absolute"
          top="6px"
          right="calc(30% + 1px)"
          zIndex={1}
        />
      ),
    },
  },
}

function RecursiveSpeechBubble({
  texts,
  position,
  first = true,
  size,
}: {
  texts: string[]
  position: "top" | "bottom"
  first?: boolean
  size: number
}) {
  let content: React.ReactNode = null
  if (texts.length > 1) {
    if (position === "top") {
      content = (
        <RecursiveSpeechBubble
          texts={texts.slice(0, texts.length - 1)}
          position={position}
          first={false}
          size={size}
        />
      )
    } else {
      content = (
        <RecursiveSpeechBubble
          texts={texts.slice(1, texts.length)}
          position={position}
          first={false}
          size={size}
        />
      )
    }
  }
  const textContent = position === "bottom" ? texts[0] : texts[texts.length - 1]

  if (!first) {
    return (
      <>
        <Flex
          position="absolute"
          w="100%"
          h="fit-content"
          top={position === "top" ? "0" : undefined}
          bottom={position === "bottom" ? "0" : undefined}
          transform={
            position === "top" ? "translateY(-85%)" : "translateY(85%)"
          }
          zIndex={1}
        >
          <Text
            w="80%"
            ml={texts.length % 2 === 1 ? "auto" : "0"}
            transform={
              texts.length % 2 === 1 ? "translateX(10%)" : "translateX(-10%)"
            }
            variant="cartoon"
            fontSize={[`${size * 0.8}px`, `${size}px`]}
            borderRadius="50%"
            px="10%"
            py="max(20px, 10%)"
            textAlign="center"
            zIndex={2}
          >
            {textContent}
          </Text>
          {content}
        </Flex>
        <Flex
          position="absolute"
          w="100%"
          h="fit-content"
          top={position === "top" ? "0" : undefined}
          bottom={position === "bottom" ? "0" : undefined}
          transform={
            position === "top" ? "translateY(-85%)" : "translateY(85%)"
          }
        >
          <Box
            transform={
              texts.length % 2 === 1 ? "translateX(10%)" : "translateX(-10%)"
            }
            top="0"
            left={texts.length % 2 === 1 ? undefined : "0"}
            right={texts.length % 2 === 1 ? "0px" : undefined}
            bg="white"
            zIndex={0}
            w="calc(80%)"
            h="calc(100%)"
            position="absolute"
            borderRadius="50%"
          />
          <Text
            w="80%"
            ml={texts.length % 2 === 1 ? "auto" : "0"}
            transform={
              texts.length % 2 === 1 ? "translateX(10%)" : "translateX(-10%)"
            }
            variant="cartoon"
            fontSize={[`${size * 0.8}px`, `${size}px`]}
            borderRadius="50%"
            px="10%"
            py="max(20px, 10%)"
            textAlign="center"
            zIndex={2}
            opacity={0}
          >
            {textContent}
          </Text>
          <Box
            transform={
              texts.length % 2 === 1 ? "translateX(10%)" : "translateX(-10%)"
            }
            top="-1px"
            left={texts.length % 2 === 1 ? undefined : "-1px"}
            right="-1px"
            bg="black"
            zIndex={-1}
            w="calc(80% + 2px)"
            h="calc(100% + 2px)"
            position="absolute"
            borderRadius="50%"
          />
        </Flex>
      </>
    )
  }
  return (
    <Flex position="relative">
      <Box
        top="-1px"
        left="-1px"
        bg="black"
        w="calc(100% + 2px)"
        h="calc(100% + 2px)"
        position="absolute"
        borderRadius="50%"
        zIndex={-1}
      />
      <Box
        top="0"
        left="0"
        bg="white"
        w="calc(100%)"
        h="calc(100%)"
        position="absolute"
        borderRadius="50%"
        zIndex={1}
      />
      <Text
        variant="cartoon"
        fontSize={[`${size * 0.8}px`, `${size}px`]}
        borderRadius="50%"
        px="15%"
        py="max(20px, 10%)"
        textAlign="center"
        zIndex={1}
      >
        {textContent}
      </Text>
      {content}
    </Flex>
  )
}

export default function SpeechBalloon({
  text,
  bubbles = 1,
  position,
  direction,
  maxWidthPercent = 1,
  size = 18,
  align,
}: {
  text: string
  bubbles?: number
  position: "top" | "bottom"
  direction: "left" | "center" | "right"
  maxWidthPercent?: number
  size?: number
  align?: "left" | "center" | "right"
}) {
  let ml = ""
  let mr = ""
  switch (align || direction) {
    case "left":
      mr = "auto"
      break
    case "center":
      mr = "auto"
      ml = "auto"
      break
    case "right":
      ml = "auto"
      break
  }

  const texts = text.split("\n").reduce((acc, cur) => {
    if (bubbles <= acc.length) {
      const last = acc.pop()
      return [...acc, `${last}\n${cur}`]
    }
    return [...acc, cur]
  }, [] as string[])

  return (
    <Flex
      position="relative"
      ml={ml}
      mr={mr}
      maxW={`${maxWidthPercent * 100}%`}
      w="fit-content"
      zIndex={2}
    >
      {ARROW_CONFIG[position][direction]["black"]}
      <RecursiveSpeechBubble position={position} size={size} texts={texts} />
      {ARROW_CONFIG[position][direction]["white"]}
    </Flex>
  )
}
