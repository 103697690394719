import { Icon, IconProps } from "@chakra-ui/react"

export default function LinkedinIcon(props: IconProps) {
  return (
    <Icon
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.42728 2.66785C5.42692 3.37506 5.14565 4.05316 4.64533 4.55298C4.14501 5.05279 3.46663 5.33339 2.75942 5.33304C2.05222 5.33268 1.37412 5.05141 0.874301 4.55109C0.374482 4.05077 0.093885 3.37239 0.0942386 2.66519C0.0945922 1.95798 0.375867 1.27988 0.876187 0.780063C1.37651 0.280243 2.05489 -0.000353269 2.76209 3.33797e-07C3.4693 0.000353936 4.14739 0.281629 4.64721 0.781948C5.14703 1.28227 5.42763 1.96065 5.42728 2.66785ZM5.50727 7.30759H0.174234V24H5.50727V7.30759ZM13.9335 7.30759H8.6271V24H13.8801V15.2405C13.8801 10.3608 20.2398 9.90745 20.2398 15.2405V24H25.5062V13.4273C25.5062 5.20104 16.0934 5.50769 13.8801 9.54747L13.9335 7.30759Z"
        fill="currentColor"
      />
    </Icon>
  )
}
