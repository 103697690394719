import {
  CollectionReference,
  DocumentReference,
  DocumentSnapshot,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
} from "firebase/firestore"
import { db } from "../data/apis/firebase/Firebase"
import { Webtoon, WebtoonFirebase } from "../data/models/Webtoon"

export default class WebtoonDAO {
  private static docToWebtoon(
    doc: DocumentSnapshot<WebtoonFirebase>
  ): Webtoon | undefined {
    const data = doc.data()
    if (!data) return undefined
    return {
      id: doc.id,
      cover: data.cover ?? "",
      creationId: data.creationId ?? "",
      panels: data.panels ?? [],
      storyId: data.storyId ?? "",
      status: data.status ?? "",
      userStoryPackId: doc.ref.parent.parent?.id ?? "",
    }
  }

  static async getWebtoon(
    userId: string,
    webtoonId: string,
    userStoryPackId: string
  ): Promise<Webtoon> {
    return getDoc<WebtoonFirebase>(
      doc(
        db,
        `users/${userId}/user_story_packs/${userStoryPackId}/webtoons`,
        webtoonId
      ) as DocumentReference<WebtoonFirebase>
    ).then((doc) => {
      if (!doc.exists()) {
        throw new Error("Webtoon not found")
      }
      return this.docToWebtoon(doc)!
    })
  }

  static async getAll(
    userId: string,
    userStoryPackId: string
  ): Promise<Webtoon[]> {
    return getDocs<WebtoonFirebase>(
      collection(
        db,
        `users/${userId}/user_story_packs/${userStoryPackId}/webtoons`
      ) as CollectionReference<WebtoonFirebase>
    ).then((docs) =>
      docs.docs.map((doc) => {
        if (!doc.exists()) {
          throw new Error("Webtoon not found")
        }
        return this.docToWebtoon(doc)!
      })
    )
  }

  static listenToUserWebtoons(
    userId: string,
    userStoryPackId: string,
    callback: (webtoons: Webtoon[]) => any
  ): () => void {
    return onSnapshot<WebtoonFirebase>(
      collection(
        db,
        `users/${userId}/user_story_packs/${userStoryPackId}/webtoons`
      ) as CollectionReference<WebtoonFirebase>,
      (webtoonDocs) => {
        callback(webtoonDocs.docs.map((wt) => this.docToWebtoon(wt)!))
      }
    )
  }
}
