import { Icon, IconProps } from "@chakra-ui/react"

export default function CloseIcon(props: IconProps) {
  return (
    <Icon
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.9287 0.685904C4.13298 -0.210853 2.73249 -0.210853 1.93677 0.685903L1.17788 1.54114C0.505841 2.29851 0.505842 3.43864 1.17788 4.196L1.75289 4.84401L4.92928 8.48402C5.59086 9.24217 5.58617 10.3738 4.91832 11.1264L1.75289 14.6938L1.21883 15.2706C0.525331 16.0195 0.507135 17.1706 1.17661 17.9411L1.93881 18.8184C2.73063 19.7297 4.14324 19.7371 4.94452 18.8341L5.18562 18.5623L8.08367 15.2964C8.88381 14.3946 10.2939 14.4005 11.0866 15.3088L13.9257 18.5623L14.1141 18.7839C14.9017 19.7101 16.3267 19.7251 17.1337 18.8157L17.9081 17.9429C18.5909 17.1734 18.5786 16.0115 17.8796 15.2567L17.3584 14.6938L14.1507 11.1387C13.4682 10.3822 13.4634 9.23358 14.1397 8.47147L17.3584 4.84401L17.921 4.21006C18.5983 3.44672 18.5923 2.29583 17.907 1.53961L17.1353 0.688103C16.3246 -0.206583 14.9119 -0.184308 14.1297 0.735495L13.9257 0.97544L11.0757 4.1873C10.2844 5.07908 8.89353 5.08486 8.09483 4.19968L5.18562 0.97544L4.9287 0.685904Z"
        fill="currentColor"
      />
    </Icon>
  )
}
