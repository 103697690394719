import { Box, BoxProps, Flex, Image } from "@chakra-ui/react"
import { useState } from "react"
import Creation from "../../../data/models/Creation"
import BackButton from "../Buttons/BackButton"
import CharacterNameTag from "./CharacterNameTag"

export default function CharacterView({
  creation,
  showShareButton = false,
  borderRadius,
  onClickBackButton,
  ...props
}: {
  creation: Creation
  showShareButton?: boolean
  onClickBackButton?: () => any
} & BoxProps) {
  const [showDrawing, setShowDrawing] = useState(false)
  return (
    <>
      <Flex
        height={["400px", "500px", "600px"]}
        position="relative"
        mx={["-1em", "-1em", "0", "0"]}
        {...props}
      >
        <Image
          objectFit="cover"
          h="100%"
          w="100%"
          maxW={["500px", "500px", "600px"]}
          mx="auto"
          src={
            showDrawing
              ? creation.originalImagePath || undefined
              : creation.selectedImage
          }
          borderRadius={borderRadius}
          alt=""
        />
        <Flex
          position="absolute"
          top="0"
          left="0"
          padding="1em 2em"
          textAlign="right"
        >
          <Box ml="-1em" bg="#0000007f" borderRadius={50}>
            <BackButton color="white" onClick={onClickBackButton} />
          </Box>
        </Flex>
      </Flex>
      <CharacterNameTag
        creation={creation}
        mt="-2em"
        maxW={["360px", "460px", "560px"]}
        mx="auto"
        showSelectedImage={showDrawing}
        onClick={() => setShowDrawing(!showDrawing)}
        cursor="pointer"
        showShareButton={showShareButton}
      />
    </>
  )
}
