import { Icon, IconProps } from "@chakra-ui/react"

export default function MicIcon(props: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9996 14C11.1662 14 10.4579 13.7083 9.87457 13.125C9.29124 12.5417 8.99957 11.8333 8.99957 11V5C8.99957 4.16667 9.29124 3.45833 9.87457 2.875C10.4579 2.29167 11.1662 2 11.9996 2C12.8329 2 13.5412 2.29167 14.1246 2.875C14.7079 3.45833 14.9996 4.16667 14.9996 5V11C14.9996 11.8333 14.7079 12.5417 14.1246 13.125C13.5412 13.7083 12.8329 14 11.9996 14ZM11.9996 21C11.7162 21 11.4786 20.904 11.2866 20.712C11.0946 20.52 10.9989 20.2827 10.9996 20V17.925C9.44957 17.7083 8.1329 17.0583 7.04957 15.975C5.96624 14.8917 5.3079 13.5917 5.07457 12.075C5.02457 11.7917 5.09957 11.5417 5.29957 11.325C5.49957 11.1083 5.76624 11 6.09957 11C6.3329 11 6.54124 11.0877 6.72457 11.263C6.9079 11.4383 7.02457 11.6507 7.07457 11.9C7.29124 13.0667 7.8579 14.0417 8.77457 14.825C9.69124 15.6083 10.7662 16 11.9996 16C13.2329 16 14.3079 15.6083 15.2246 14.825C16.1412 14.0417 16.7079 13.0667 16.9246 11.9C16.9746 11.65 17.0952 11.4373 17.2866 11.262C17.4779 11.0867 17.6906 10.9993 17.9246 11C18.2412 11 18.4996 11.1083 18.6996 11.325C18.8996 11.5417 18.9746 11.7917 18.9246 12.075C18.6912 13.5917 18.0329 14.8917 16.9496 15.975C15.8662 17.0583 14.5496 17.7083 12.9996 17.925V20C12.9996 20.2833 12.9036 20.521 12.7116 20.713C12.5196 20.905 12.2822 21.0007 11.9996 21ZM11.9996 12C12.2829 12 12.5206 11.904 12.7126 11.712C12.9046 11.52 13.0002 11.2827 12.9996 11V5C12.9996 4.71667 12.9036 4.479 12.7116 4.287C12.5196 4.095 12.2822 3.99933 11.9996 4C11.7162 4 11.4786 4.096 11.2866 4.288C11.0946 4.48 10.9989 4.71733 10.9996 5V11C10.9996 11.2833 11.0956 11.521 11.2876 11.713C11.4796 11.905 11.7169 12.0007 11.9996 12Z"
        fill="currentColor"
      />
    </Icon>
  )
}
