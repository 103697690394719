import { Box, Flex, Image, Text } from "@chakra-ui/react"
import starIcon from "../assets/images/stars.png"

interface CreditsCountProps {
  credits: string
  starWithS?: boolean
  size?: "md" | "lg"
}

export default function CreditsDisplay({
  credits,
  starWithS = false,
  size = "md",
}: CreditsCountProps) {
  return (
    <Flex
      height={size === "md" ? "52px" : "80px"}
      borderRadius="50px"
      p="0 1rem"
      justifyContent="center"
    >
      <Image h="100%" src={starIcon} ml="-0.5rem" />
      <Box height="fit-content">
        <Text
          textAlign="center"
          fontSize={size === "md" ? "25px" : "40px"}
          margin="auto"
          color="primary.500"
        >
          {credits}
        </Text>
        <Text variant="red" fontSize={size === "md" ? "10px" : "16px"} textAlign="center" mt="-0.5em">
          STAR{starWithS ? "S" : ""}
        </Text>
      </Box>
    </Flex>
  )
}
