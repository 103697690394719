import { Flex, Image, Text } from "@chakra-ui/react"
import Background from "./Wrapper/Background"
import BackgroundImageBlurred from "./Wrapper/BackgroundImageBlurred"
import monster1 from "../assets/images/loading/loading-monster-1.png"
import monster2 from "../assets/images/loading/loading-monster-2.png"
import monster3 from "../assets/images/loading/loading-monster-3.png"
import "./LoadingScreen.css"

export default function LoadingScreen({ color = "primary.500" }) {
  return (
    <Background display="flex">
      <BackgroundImageBlurred backgroundColor={"rgba(255,255,255,0.35)"} />
      <Flex flexDir="column" placeItems="center" m="auto">
        <Flex placeContent="space-evenly" w="100%">
          <Image w="45px" h="45px" objectFit="contain" src={monster1} className="character1-loading" />
          <Image w="45px" h="45px" objectFit="contain" src={monster2} className="character2-loading" />
          <Image w="45px" h="45px" objectFit="contain" src={monster3} className="character3-loading" />
        </Flex>
        <Text color={color} fontWeight="bold" fontSize="22px">
          Making magic...
        </Text>
      </Flex>
    </Background>
  )
}
