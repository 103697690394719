import { Box, Button, Flex, Heading } from "@chakra-ui/react"
import WeeklyPreview from "../../pages/Create/WeeklyPreview"
import WebtoonList from "../../pages/Creations/WebtoonList/WebtoonList"
import PrintActionBlock from "../ActionBlocks/PrintActionBlock"
import CloseIcon from "../Icons/CloseIcon"
import StarIcon from "../Icons/StarIcon"
import SlidingPopup from "./SlidingPopup"

function MagicPopupHeader({ onClose }: { onClose: () => any }) {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <StarIcon
        color="#FF4264"
        width="40px"
        height="40px"
        padding="4px"
        border="solid 1px rgba(0,0,0,0.1)"
        borderRadius="full"
      />
      <Heading size="sm">Magic</Heading>
      <Button
        p="0"
        color="#777"
        variant="ghostBlack"
        onClick={onClose}
        fontSize="12px"
      >
        <CloseIcon width="24px" height="24px" />
      </Button>
    </Flex>
  )
}

export default function MagicPopup({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => any
}) {
  return (
    <SlidingPopup
      id="magic-popup"
      onClose={onClose}
      show={isOpen}
      header={<MagicPopupHeader onClose={onClose} />}
      mt="1rem"
      mb="-1rem"
      textAlign="center"
      overflowY={"auto"}
      mx="-1rem"
      px="1rem"
    >
      <WebtoonList lightMode />
      <WeeklyPreview />
      <Box mb="1rem" />
      <PrintActionBlock />
      <Box mb="2rem" />
    </SlidingPopup>
  )
}
