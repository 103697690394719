import { Flex, useToast } from "@chakra-ui/react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useUserContext } from "../../../context/userContext"
import CreationService from "../../../service/CreationService"
import { Pronouns } from "../../../utilities/dateUtils"
import { sleep } from "../../../utilities/sleep"
import bg from "../../assets/images/backgrounds/upload-background.png"
import BackButton from "../../components/Buttons/BackButton"
import BackgroundImageBlurred from "../../components/Wrapper/BackgroundImageBlurred"
import ResponsiveContainer from "../../components/Wrapper/ResponsiveContainer"
import {
  CharacterDescriptionForm,
  CharacterTitleForm,
} from "./Forms/DrawingDetailsForms"
import UploadDrawingForm from "./Forms/UploadDrawingForm"

export const UploadScreen = (props: {
  userCredits?: number
  setUploadLoading: (uploading: boolean) => void
  dontRedirect?: boolean
  showCameraOnInit?: boolean
  describeOnInit: boolean
}) => {
  const { user } = useUserContext()
  const [referenceImg, setReferenceImg] = useState(null as string | null)
  const [step, setStep] = useState(props.describeOnInit ? 1 : 0)
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [pronouns, setPronouns] = useState<Pronouns>()
  const navigate = useNavigate()
  const toast = useToast()

  const handleUploadThenContinue = async (
    title: string,
    description: string,
    pronouns: Pronouns | undefined
  ) => {
    props.setUploadLoading(true)
    if (title.trim() !== "" && description.trim() !== "" && pronouns) {
      if (!user) return
      if (user.credits < 1) {
        window.alert("You don't have credits available")
        props.setUploadLoading(false)
        navigate("/")
        return
      }

      try {
        await CreationService.uploadCreation(
          user,
          referenceImg ?? undefined,
          title,
          description,
          pronouns
        ).then(async (creationId) => {
          navigate(`/creations/${creationId}`)
        })
      } catch (e) {
        toast({
          title: "Error",
          description: "Something went wrong. Please try again later.",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        })
      } finally {
        props.setUploadLoading(false)
      }
    } else {
      await sleep(500)
      const fields = []
      if (!title) fields.push("Name")
      if (!description) fields.push("Description")
      if (!pronouns) fields.push("Pronouns")

      toast({
        title: "Fields missing",
        description: `Please fill in the following fields: ${fields.join(
          ", "
        )}`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      })
      props.setUploadLoading(false)
    }
  }

  let content: React.ReactNode = null

  switch (step) {
    case 0:
      content = (
        <UploadDrawingForm
          setImage={(img) => {
            setReferenceImg(img)
            setStep(1)
          }}
          userCredits={user?.credits ?? 0}
          showCameraOnInit={props.showCameraOnInit}
          onGotoDescription={() => setStep(1)}
        />
      )
      break
    case 1:
      content = (
        <CharacterDescriptionForm
          imageUrl={referenceImg ?? undefined}
          handleNext={() => {
            setStep(2)
          }}
          setDescription={setDescription}
          description={description}
        />
      )
      break
    case 2:
      content = (
        <CharacterTitleForm
          handleNext={() =>
            handleUploadThenContinue(title, description, pronouns)
          }
          pronouns={pronouns}
          setPronouns={setPronouns}
          setTitle={setTitle}
          title={title}
          imageUrl={referenceImg ?? undefined}
        />
      )
      break
  }

  return (
    <ResponsiveContainer display="flex" flexDir={"column"} height="100%">
      <BackgroundImageBlurred
        backgroundImage={bg}
        bgPos="50%"
        blur={step === 0}
        backgroundColor={step === 0 ? undefined : "transparent"}
      />
      <Flex py="1em">
        <BackButton
          buttonBg
          color="white"
          onClick={() => {
            if (step === 0 || (step === 1 && props.describeOnInit)) {
              navigate(-1)
            } else {
              if (step - 1 === 0) setReferenceImg(null)
              setStep(step - 1)
            }
          }}
        />
      </Flex>
      {content}
    </ResponsiveContainer>
  )
}
