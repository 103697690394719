import { Button, Heading, Text, Textarea, useToast } from "@chakra-ui/react"
import { useUserContext } from "../../../context/userContext"
import Background from "../../components/Wrapper/Background"
import ResponsiveContainer from "../../components/Wrapper/ResponsiveContainer"
import { useState } from "react"
import axios from "axios"
import { API_ENDPOINTS } from "../../components/Creations/GlobalSettings"

export default function InviteEmails() {
  const { user } = useUserContext()
  const [emailText, setEmailText] = useState("")
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const sendEmails = async () => {
    setLoading(true)
    const emails = emailText.split("\n").map((e) => e.trim())
    axios
      .post(
        API_ENDPOINTS.INVITE_EMAILS,
        { emails },
        {
          headers: {
            Authorization: `Bearer ${await user?.fbUser.getIdToken(true)}`,
          },
        }
      )
      .then(() => {
        toast({
          title: "Emails added to whitelist",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        })
      })
      .finally(() => {
        setLoading(false)
        setEmailText("")
      })
  }

  return (
    <Background>
      <ResponsiveContainer>
        <Heading>Invite emails</Heading>
        <Text>Add one email per line</Text>
        <Textarea
          value={emailText}
          onChange={(e) => setEmailText(e.target.value)}
        />
        <Button
          disabled={loading || emailText.trim().length === 90}
          onClick={sendEmails}
        >
          Add emails
        </Button>
      </ResponsiveContainer>
    </Background>
  )
}
