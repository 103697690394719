import { Box, Button, Heading, Image, Text } from "@chakra-ui/react"
import { useEffect, useMemo, useState } from "react"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { useUserContext } from "../../../../../context/userContext"
import Story from "../../../../../data/models/Story"
import { Webtoon } from "../../../../../data/models/Webtoon"
import StoryService from "../../../../../service/StoryService"
import WebtoonService from "../../../../../service/WebtoonService"
import webtoonSuccess from "../../../../assets/images/webtoon-success.png"
import Navigation from "../../../../components/Wrapper/Navigation"

export default function WebtoonSuccessPage() {
  const { webtoonId, userStoryPackId } = useParams()
  const [webtoon, setWebtoon] = useState(null as Webtoon | null)
  const [story, setStory] = useState(null as Story | null)
  const { user, userCreations } = useUserContext()
  const navigate = useNavigate()
  const creation = useMemo(() => {
    if (!webtoon) return null
    return userCreations.find((c) => c.id === webtoon.creationId)
  }, [userCreations, webtoon])

  useEffect(() => {
    if (userStoryPackId && webtoonId && user) {
      WebtoonService.get(user.id, webtoonId, userStoryPackId)
        .then((webtoon) => {
          if (webtoon) {
            setWebtoon(webtoon)
            StoryService.getStoryOld(webtoon.storyId).then((s) => setStory(s))
          } else navigate("/app", { replace: true })
        })
        .catch(() => navigate("/app", { replace: true }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webtoonId, user])

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate("/app")
    }, 20000) // 20 seconds
    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!webtoonId || !userStoryPackId)
    return <Navigate replace to="/app" />
  if (!webtoon) return <Navigation />

  return (
    <Navigation maxW="600px">
      <Box mx={["-1rem", "-1rem", "-1rem", 0]} mt="-1rem">
        <Image
          src={webtoonSuccess}
          mx="auto"
          maxH="600px"
          w="100%"
          objectFit="cover"
        />
      </Box>
      <Heading size="sm" textAlign="center" mt="1rem" color="white">
        Generating your story with {creation?.title}
      </Heading>
      <Text textAlign="center" mt="0.5rem" fontSize="18px" fontWeight="light" color="white">
        We need ~3 min to travel
        <br /> together to the {story?.title}
      </Text>
      <Button
        variant="white"
        borderColor="secondary.500"
        color="secondary.500"
        w="100%"
        mt="1rem"
        onClick={() => navigate("/app")}
      >
        Back to my studio
      </Button>
    </Navigation>
  )
}
