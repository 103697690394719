import { Heading } from "@chakra-ui/react"
import ResponsiveContainer from "../../components/Wrapper/ResponsiveContainer"
import CreationsTable from "./CreationsTable"
import "./Dashboard.css"

export default function Dashboard() {
  return (
    <ResponsiveContainer>
      <Heading mb="1rem">
        Dashboard
      </Heading>
      <CreationsTable />
    </ResponsiveContainer>
  )
}
