import { Box, BoxProps, Flex, Image, Text } from "@chakra-ui/react"
import { useSegment } from "../../../context/SegmentContext"
import Story from "../../../data/models/Story"
import UserService from "../../../service/UserService"

const SMALL_WORDS = ["the", "of", "a"]

function StoryBookTitleFormatted({
  title,
  description,
}: {
  title: string
  description: string
}) {
  const words = title.split(" ")
  return (
    <Flex flexDir="column" w="100%">
      {words.map((word, i) =>
        SMALL_WORDS.some((w) => word.toLowerCase() === w) ? (
          <Text
            key={`${word}-${i}`}
            color="white"
            fontWeight="bold"
            mx="auto"
            fontSize="12px"
            lineHeight={1}
            mb="0.25rem"
          >
            {word}
          </Text>
        ) : (
          <Text
            key={`${word}-${i}`}
            color="white"
            fontWeight="bold"
            mx="auto"
            fontSize="18px"
            lineHeight={1.2}
          >
            {word}
          </Text>
        )
      )}
      <Text mt="auto" color="white" fontWeight="bold" mx="auto" fontSize="12px">
        {description}
      </Text>
    </Flex>
  )
}

export default function StoryBookCard({
  story,
  w,
  h,
  width = "150px",
  height = "220px",
  dontPlayVideo = false,
  onClick,
  displayState = false,
  lightMode = false,
  ...props
}: {
  story: Story
  dontPlayVideo?: boolean
  displayState?: boolean
  lightMode?: boolean
} & BoxProps) {
  const { track } = useSegment()

  let storyState = "Ready"

  if (UserService.getStoryPosition(story.id) > 0) {
    if (UserService.isStoryFinished(story.id)) {
      storyState = "Finished"
    } else {
      storyState = "Continue"
    }
  }

  return (
    <Box
      w={w ?? width}
      minW={w ?? width}
      maxW={w ?? width}
      textAlign="center"
      h={h ?? height}
      borderRadius="10px"
      position="relative"
      {...props}
      onClick={(e) => {
        track("Click on a story", { story: story.title })
        if (onClick) onClick(e)
      }}
    >
      <Image
        src={story.cover}
        display={dontPlayVideo ? "block" : "none"}
        position="absolute"
        top="0"
        left="0"
        borderRadius="10px"
        w="100%"
        h="100%"
        objectFit="cover"
      />
      <video
        disablePictureInPicture
        muted
        autoPlay
        loop
        playsInline
        style={{
          display: dontPlayVideo ? "none" : "block",
          position: "absolute",
          top: 0,
          left: 0,
          borderRadius: "10px",
          height: "100%",
          width: "100%",
          objectFit: "cover",
        }}
        src={story.coverVideo}
        poster={story.cover}
      />

      <Flex
        background="rgba(0,0,0,0.2)"
        h="100%"
        w="100%"
        p="1rem"
        position="absolute"
        top="0"
        left="0"
        borderRadius="10px"
      >
        <StoryBookTitleFormatted
          title={story.title}
          description={story.description ?? ""}
        />
      </Flex>
      {!dontPlayVideo && displayState && (
        <Text
          position="absolute"
          size="sm"
          color="white"
          fontWeight="bold"
          px="1rem"
          bg="secondary.500"
          top="0"
          right="0px"
          transform="translateY(-50%) "
        >
          {storyState}
        </Text>
      )}
    </Box>
  )
}
