import { Icon } from "@chakra-ui/react";

const MagicWandIcon: typeof Icon = (props) => (
  <Icon viewBox="0 0 21 33" {...props}>
    <path d="M5.07831 19.6528L0 31.8228L2.82124 33L7.89955 20.8301L5.07831 19.6528Z" fill="currentColor" />
    <path d="M6.54568 0C7.22196 1.89855 8.36746 2.44492 10 1.74179C9.47262 2.41467 8.75911 2.67227 8.46052 3.46157C8.15418 4.27655 8.51869 5.07227 8.48534 6C7.74701 4.21788 6.66899 3.44599 5 4.25821C5.4948 3.57433 6.24089 3.3259 6.54103 2.52284C6.84737 1.7042 6.4751 0.912147 6.54568 0Z" fill="#FF5533" />
    <path d="M17.7232 3C17.9646 5.00753 19.1029 5.75319 21 5.72451C19.1193 6.0591 18.1268 7.01854 18.3441 9C17.8603 8.29345 17.8672 7.42613 17.2593 6.88297C16.6513 6.3398 15.8037 6.52926 15 6.19206C16.9083 6.00348 17.8043 4.99102 17.7232 3Z" fill="#FFC845" />
    <path d="M16 13.2161C14.7532 12.6253 13.7595 12.7494 12.9259 14C13.4376 12.6654 13.1555 11.651 12 10.8269C13.2722 11.4298 14.2902 11.3028 15.0958 10C14.5606 11.3439 14.8128 12.3826 16 13.2161Z" fill="#F71AFF" />
    <path d="M10 18.0208C8.44276 17.2826 7.2013 17.4409 6.15731 19C6.79553 17.3325 6.44393 16.0642 5 15.0325C6.5889 15.7863 7.86119 15.6279 8.86769 14C8.20114 15.6796 8.51608 16.9806 10 18.0208Z" fill="#84D432" />
    <path d="M17 1.39883C15.7956 1.60044 15.1059 2.02038 15.2065 3C14.8099 2.67176 14.8418 2.25255 14.4713 1.9869C14.0738 1.7016 13.504 1.81296 13 1.5786C14.2314 1.47016 14.7944 0.943232 14.838 0C15.0837 0.921398 15.727 1.3952 17 1.39883Z" fill="#00DFFF" />
    <path d="M10.2925 6C10.6005 5.37934 10.4642 4.86878 10 4.34087C10.6366 4.65234 11.2117 4.70287 11.7629 4C11.7506 4.48643 11.5096 4.71568 11.525 5.01508C11.5412 5.33937 11.8737 5.4819 12 5.93062C11.3533 5.55354 10.8168 5.59955 10.2925 6Z" fill="#E94147" />
    <path d="M8.29299 12C8.59656 11.3779 8.46429 10.8654 8 10.3412C8.63691 10.6533 9.21164 10.7039 9.76256 10C9.75066 10.4865 9.50992 10.7136 9.52513 11.0165C9.54101 11.3409 9.87368 11.483 10 11.9325C9.35516 11.5524 8.81812 11.5991 8.29299 12Z" fill="#F89FB3" />
  </Icon>
)

export default MagicWandIcon;
