import { Icon, IconProps } from "@chakra-ui/react"

export default function CloudsBottom({ ...props }: IconProps) {
  return (
    <Icon
      w="390"
      h="89"
      preserveAspectRatio="xMidYMin slice"
      viewBox="1 0 388 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color="white"
      {...props}
    >
      <g clipPath="url(#clip0_3723_3090)">
        <circle
          cx="138.692"
          cy="150.924"
          r="66.6792"
          transform="rotate(173.954 138.692 150.924)"
          fill="currentColor"
        />
        <circle
          cx="44.9757"
          cy="44.9757"
          r="44.9757"
          transform="matrix(0.994437 -0.105334 -0.105334 -0.994437 -42.6013 105.082)"
          fill="currentColor"
        />
        <circle
          cx="32.5519"
          cy="32.5519"
          r="32.5519"
          transform="matrix(0.994437 -0.105334 -0.105334 -0.994437 30.6323 100.976)"
          fill="currentColor"
        />
        <circle
          cx="32.5519"
          cy="32.5519"
          r="32.5519"
          transform="matrix(0.994437 -0.105334 -0.105334 -0.994437 30.6323 100.976)"
          fill="currentColor"
        />
        <circle
          cx="-19.2047"
          cy="150.674"
          r="90.3123"
          transform="rotate(173.954 -19.2047 150.674)"
          fill="currentColor"
        />
        <circle
          cx="386.802"
          cy="153.501"
          r="102.129"
          transform="rotate(173.954 386.802 153.501)"
          fill="currentColor"
        />
        <circle
          cx="284.617"
          cy="47.1853"
          r="24.6389"
          transform="rotate(173.954 284.617 47.1853)"
          fill="currentColor"
        />
        <circle
          cx="317.127"
          cy="44.2999"
          r="27.4569"
          transform="rotate(173.954 317.127 44.2999)"
          fill="currentColor"
        />
        <circle
          cx="344.808"
          cy="32.4811"
          r="21.1209"
          transform="rotate(173.954 344.808 32.4811)"
          fill="currentColor"
        />
        <circle
          cx="312.072"
          cy="185.182"
          r="90.3123"
          transform="rotate(173.954 312.072 185.182)"
          fill="currentColor"
        />
        <circle
          cx="482.979"
          cy="83.9004"
          r="90.3123"
          transform="rotate(173.954 482.979 83.9004)"
          fill="currentColor"
        />
        <circle
          cx="390.919"
          cy="59.0614"
          r="53.7357"
          transform="rotate(173.954 390.919 59.0614)"
          fill="currentColor"
        />
        <circle
          cx="242.292"
          cy="120.959"
          r="90.3123"
          transform="rotate(173.954 242.292 120.959)"
          fill="currentColor"
        />
        <circle
          cx="114.448"
          cy="87.5423"
          r="50.0742"
          transform="rotate(173.954 114.448 87.5423)"
          fill="currentColor"
        />
        <circle
          cx="170.176"
          cy="76.7064"
          r="50.0742"
          transform="rotate(173.954 170.176 76.7064)"
          fill="currentColor"
        />
        <rect x="272" y="56" width="92" height="28" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_3723_3090">
          <rect width="390" height="89" fill="currentColor" />
        </clipPath>
      </defs>
    </Icon>
  )
}
