import { Box, Button, Flex, Image, Text } from "@chakra-ui/react"
import { useUserContext } from "../../../../context/userContext"
import Creation from "../../../../data/models/Creation"
import Story from "../../../../data/models/Story"
import { capitalizeFirstLetter } from "../../../../utilities/stringUtils"
import UserService from "../../../../service/UserService"

export default function WebtoonCoverPage({
  story,
  creation,
  onContinue,
}: {
  story: Story
  creation?: Creation
  onContinue: () => void
}) {
  const { user } = useUserContext()

  return (
    <Box
      position="fixed"
      w="100%"
      h="100%"
      left="0"
      top="0"
      zIndex="100"
      bg="black"
    >
      {story.coverVideo ? (
        <video
          preload="none"
          src={story.coverVideo}
          poster={story.cover}
          autoPlay
          muted
          loop
          disablePictureInPicture
          playsInline
          style={{
            objectFit: "cover",
            height: "100%",
            width: "100%",
          }}
        />
      ) : (
        <Image
          src={story.cover}
          w="100%"
          h="100%"
          objectFit="cover"
          loading="lazy"
        />
      )}
      <Flex
        position="absolute"
        top="0"
        left="0"
        h="100%"
        w="100%"
        justifyContent="space-evenly"
        flexDir="column"
        textAlign="center"
      >
        <Box>
          <Text color="white" variant="coverTitle" fontSize="48px">
            {creation?.title && capitalizeFirstLetter(creation.title)}
          </Text>
          <Text color="white" variant="coverTitle">
            & {story.title}
          </Text>
          <Text color="white" variant="coverAuthor">
            By {user?.childName}
          </Text>
        </Box>
        <Box>
          <Flex justifyContent="center" gap="1rem">
            <Image
              src={creation?.originalImagePath || undefined}
              maxW="100px"
              maxH="100px"
              mt="auto"
              transform="rotate(-12deg)"
            />
            <Image
              src={creation?.selectedImageCroppedLeft}
              maxW="200px"
              maxH="200px"
            />
          </Flex>
          <Button
            borderRadius="15px"
            variant="white"
            mt="1rem"
            onClick={onContinue}
          >
            {UserService.getStoryPosition(story.id) > 0
              ? "Continue the adventure"
              : "Start the adventure"}
          </Button>
        </Box>
      </Flex>
    </Box>
  )
}
