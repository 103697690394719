import { Box, Button, ButtonProps } from "@chakra-ui/react"
import { useEffect } from "react"
import useSpeechToText, { ResultType } from "react-hook-speech-to-text"
import { useSegment } from "../../../context/SegmentContext"
import { GOOGLE_SPEECH_TO_TEXT_CLOUD_API_KEY } from "../Creations/GlobalSettings"
import MicIcon from "../Icons/MicIcon"
import "./SpeechToTextButton.css"

export default function SpeechToTextButton({
  onInterimResult,
  onResult,
  onStartListening,
  onStopListening,
  onSpeaking,
  onStopSpeaking,
  ...props
}: {
  onInterimResult?: (interimResult: string) => void
  onResult: (result: string) => void
  onStartListening?: () => void
  onStopListening?: () => void
  onSpeaking?: () => void
  onStopSpeaking?: () => void
} & ButtonProps) {
  const {
    interimResult,
    isRecording,
    startSpeechToText,
    stopSpeechToText,
    results,
    setResults,
  } = useSpeechToText({
    googleApiKey: GOOGLE_SPEECH_TO_TEXT_CLOUD_API_KEY,
    crossBrowser: true,
    onStartSpeaking() {
      if (onSpeaking) onSpeaking()
    },
    onStoppedSpeaking() {
      if (onStopSpeaking) onStopSpeaking()
    },
    googleCloudRecognitionConfig: {
      profanityFilter: true,
      enableAutomaticPunctuation: true,
    },
    useLegacyResults: false,
  })
  const { track } = useSegment()

  useEffect(() => {
    if (onInterimResult) onInterimResult(interimResult ?? "")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interimResult])

  useEffect(() => {
    if (results.length > 0) {
      const result = results[results.length - 1] as ResultType
      setResults([])
      onResult(result.transcript)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results])

  useEffect(() => {
    if (isRecording) {
      if (onStartListening) onStartListening()
    } else if (onStopListening) onStopListening()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecording])

  const startListening = () => {
    track("Speech to text")
    startSpeechToText()
  }
  const stopListening = () => {
    stopSpeechToText()
  }

  return (
    <Button
      onClick={isRecording ? stopListening : startListening}
      variant={"full"}
      p="0"
      zIndex={1}
      position="relative"
      {...props}
    >
      {isRecording && (
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          borderRadius="full"
          className={"stt-button-listening"}
          zIndex={-1}
        />
      )}

      <MicIcon width="36px" height="36px" />
    </Button>
  )
}
