import { Box, Button, Flex, Image } from "@chakra-ui/react"
import Creation from "../../../../data/models/Creation"
import BackButton from "../../../components/Buttons/BackButton"
import CharacterNameTag from "../../../components/Creations/CharacterNameTag"
import Background from "../../../components/Wrapper/Background"
import ResponsiveContainer from "../../../components/Wrapper/ResponsiveContainer"
import { useSegment } from "../../../../context/SegmentContext"
import BackgroundImageBlurred from "../../../components/Wrapper/BackgroundImageBlurred"

export default function ConfirmDrawing(props: {
  image: string
  creation: Creation
  onConfirm: () => any
  onCancel: () => any
}) {
  const { track } = useSegment()
  return (
    <Background overflowY="auto">
      <BackgroundImageBlurred />
      <ResponsiveContainer
        padding="1em"
        width={["100%", "100%", "700px", "700px", "700px"]}
      >
        <Flex
          w="fit-content"
          position="relative"
          mx={["-1em", "auto", "auto"]}
          mt="-1em"
          flexDir="column"
        >
          <Image
            height={["400px", "400px", "700px"]}
            objectFit={["cover", "contain"]}
            maxH="60vh"
            w={["100vw", ""]}
            src={props.image}
          />
          <Flex
            position="absolute"
            top="0"
            left="0"
            padding="1em 2em"
            textAlign="right"
          >
            <Box ml="-1em" bg="#0000007f" borderRadius={50}>
              <BackButton color="white" onClick={props.onCancel} />
            </Box>
          </Flex>
        </Flex>

        <CharacterNameTag creation={props.creation} />

        <Flex
          mt="2em"
          width="100%"
          justifyContent="space-around"
          gap="1em"
          flexWrap="wrap-reverse"
        >
          <Button
            variant="border"
            width="45%"
            minWidth="fit-content"
            flexGrow={1}
            onClick={props.onCancel}
          >
            Pick another one
          </Button>
          <Button
            width="45%"
            minWidth="fit-content"
            flexGrow={1}
            onClick={() => {
              props.onConfirm()
              track("I want this one", { selectedDrawingUrl: props.image })
            }}
          >
            I want this one
          </Button>
        </Flex>
      </ResponsiveContainer>
    </Background>
  )
}
