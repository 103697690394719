import { Box, Button, Flex, Image, Text } from "@chakra-ui/react"

import { useUserContext } from "../../../context/userContext"
import Creation from "../../../data/models/Creation"

export default function PrintBookActionBlock({
  creation,
}: {
  creation: Creation
}) {
  const { user } = useUserContext()
  function addToCart() {
    const creationId = creation?.id
    const selectedImageUrl = encodeURIComponent(creation?.selectedImage ?? "")
    const characterOriginalUrl = encodeURIComponent(creation?.originalImagePath ?? "")
    const urlToCart = `https://shop.ministudio.ai/cart/44135659012417:1?storefront=true&characterName=${creation?.title}&creationId=${creationId}&characterSelectedUrl=${selectedImageUrl}&userId=${user?.id}&characterOriginalUrl=${characterOriginalUrl}`
    window.open(urlToCart, "_blank")
  }

  return (
    <Flex
      background="#C36B97"
      borderRadius="20px"
      padding="1em"
      mt="0.5em"
      h="168px"
    >
      <Box width="60%" placeSelf="center" textAlign="left">
        <Text variant="solid" size="md" fontWeight="bold">
          Read your MiniTale offline
        </Text>
        <Text variant="solid" size="xs">
          in a personalized book
        </Text>
        <Button onClick={addToCart} mt="1em" variant="white" size="sm">
          Print Your Book
        </Button>
      </Box>
      <Flex
        width="40%"
        placeContent="flex-end"
        placeItems="end"
        position="relative"
      >
        <Image
          src={
            "https://res.cloudinary.com/ministudioai/image/upload/v1682698286/assets/firewithin-print-2.webp"
          }
          borderRadius="8px"
          objectFit={"cover"}
          height="100%"
          transform={"translateY(-20%)"}
          right="0px"
          top="-20px"
        />
      </Flex>
    </Flex>
  )
}
