import { Box, BoxProps } from "@chakra-ui/react"
import BackIcon from "../Icons/BackIcon"
import { useNavigate } from "react-router-dom"

export default function BackButton({
  onClick,
  color = "black",
  buttonBg = false,
  icon,
  ...props
}: {
  onClick?: () => void
  color?: "white" | "black" | "red"
  buttonBg?: boolean
  icon?: React.ReactNode
} & BoxProps) {
  const navigate = useNavigate()
  let c = "primary.500"

  if (color !== "red") {
    c = color
  }

  return (
    <Box
      onClick={onClick ? onClick : () => navigate(-1)}
      color={c}
      width="50px"
      height="50px"
      display="flex"
      cursor="pointer"
      bg={buttonBg ? "rgba(0,0,0,0.4)" : undefined}
      borderRadius={100}
      {...props}
    >
      {icon ? icon : <BackIcon h="50%" w="50%" m="auto" />}
    </Box>
  )
}
