import character1 from "../assets/images/camcam.png"
import character2 from "../assets/images/pixel.png"
import "../pages/Home/CharactersAndDialogues.css"

import { Flex, Image } from "@chakra-ui/react"
import SpeechBalloon from "./SpeechBalloon"

interface CharacterProps {
  character: "camcam" | "pixel"
  text: string
}

export const CharacterSpeechBalloon = ({
  character,
  text,
}: CharacterProps): JSX.Element => {
  return (
    <Flex flexDir="column">
      <SpeechBalloon
        direction="left"
        position="top"
        text={text}
        maxWidthPercent={1}
      />
      <Image
        src={character === "camcam" ? character1 : character2}
        height="250px"
        objectFit="contain"
        width="fit-content"
      />
    </Flex>
  )
}
