import { InfoOutlineIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  IconButton,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { ChatEntry } from "../../../context/ChatContext"
import { useUserContext } from "../../../context/userContext"
import Creation from "../../../data/models/Creation"
import CreationService from "../../../service/CreationService"
import characters from "../../assets/images/characters.png"
import whiteMonster from "../../assets/images/white-monster.png"
import { LOCAL_STORAGE_CHAT_HISTORY } from "../../components/Creations/GlobalSettings"
import HeaderWave from "../../components/Header/HeaderWave"
import SendIcon from "../../components/Icons/SendIcon"
import MinistudioLogo from "../../components/ImagePresets/MinistudioLogo"
import CreatePopup from "../../components/Popup/CreatePopup"
import Navigation, { Navbar } from "../../components/Wrapper/Navigation"
import ResponsiveContainer from "../../components/Wrapper/ResponsiveContainer"
import ChatIdeas from "./ChatIdeas"
import { CAMCAM_CREATION_ID } from "./ChatPage"
import LearnMoreModal from "./LearnMoreModal"
import Button3d from "../../components/Buttons/Button3d"

function ChatPresentationLayout({ children }: { children: React.ReactNode }) {
  const learnMoreModal = useDisclosure()
  return (
    <Box maxW="100%" w="100% !important" p="0 !important">
      <LearnMoreModal {...learnMoreModal} />
      <Box w="100%">
        <Flex bg="primary.500">
          <ResponsiveContainer
            maxW="800px"
            display="flex"
            flexDir="column"
            textAlign="center"
            position="relative"
          >
            <Box>
              <MinistudioLogo
                variant="white"
                mx="auto"
                width="100px"
                height="auto"
              />
              <Text fontSize="16px" color="white" mt="0.25rem">
                A safe Chat-GPT for kids
              </Text>
            </Box>
            <Button
              position="absolute"
              right="0"
              variant="ghostWhite"
              top="50%"
              transform="translateY(-50%)"
              onClick={learnMoreModal.onOpen}
            >
              <InfoOutlineIcon w="24px" h="auto" />
            </Button>
          </ResponsiveContainer>
        </Flex>
        <HeaderWave width="100%" height="auto" mt="-1px" />
      </Box>
      <ResponsiveContainer maxW="800px" display="flex" flexDir="column">
        {children}
      </ResponsiveContainer>
      <Navbar />
    </Box>
  )
}

function getDateString(date: Date) {
  const now = new Date()

  if (
    now.getFullYear() === date.getFullYear() &&
    date.getMonth() === now.getMonth()
  ) {
    if (date.getDate() === now.getDate()) return "Today"
    if (date.getDate() === now.getDate() - 1) return "Yesterday"
  }
  return date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  })
}

function ChatPresentationLoggedOut({
  onChat,
}: {
  onChat: (text?: string) => void
}) {
  return (
    <ChatPresentationLayout>
      <ChatIdeas
        isOpen
        onChatSuggestion={(sugg) => {
          onChat(
            sugg.possibleValues[
              Math.floor(Math.random() * sugg.possibleValues.length)
            ]
          )
        }}
        height="96px"
      />
      <Heading color="primary.500" fontSize="20px">
        Start chat here
      </Heading>
      <Flex mt={"0.5rem"} gap="0.5rem" maxW="100%" onClick={() => onChat()}>
        <Box
          w="100px"
          border="1px solid"
          borderColor="neutral"
          borderRadius="full"
          p="0.5rem 1rem"
          flexGrow="1"
          cursor="pointer"
        >
          <Text
            color="neutral"
            userSelect="none"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            fontSize="16px"
          >
            Type here and start chatting!
          </Text>
        </Box>
        <IconButton
          m="auto"
          aria-label="Send"
          icon={<SendIcon width="24px" height="auto" />}
        />
      </Flex>
      <Image src={characters} w="100%" />
    </ChatPresentationLayout>
  )
}

function ChatCharacterLine({
  creation,
  onClick,
}: {
  creation: Creation
  onClick: () => void
}) {
  const { lastMessage, lastMessageDate } = useMemo(() => {
    try {
      const savedChat: ChatEntry[] = JSON.parse(
        localStorage.getItem(`${LOCAL_STORAGE_CHAT_HISTORY}/${creation.id}`) ??
          "[]"
      )
      if (savedChat && Array.isArray(savedChat) && savedChat.length > 0) {
        const lastMessage = savedChat[savedChat.length - 1]
        return {
          lastMessage: lastMessage.content,
          lastMessageDate: lastMessage.time
            ? new Date(lastMessage.time)
            : undefined,
        }
      }
    } catch {}
    return { lastMessage: undefined, lastMessageDate: undefined }
  }, [creation])

  const dateString = lastMessageDate ? `${getDateString(lastMessageDate)}` : ""
  return (
    <Box cursor="pointer" onClick={onClick} pt="1rem">
      <Grid
        gridTemplateColumns="120px 1fr max-content"
        gridTemplateRows={"1.5rem 1fr"}
        height="120px"
      >
        <Image
          height="120px"
          width="120px"
          objectFit="contain"
          src={CreationService.getCroppedPicture(creation)}
          gridColumn="1"
          gridRow="1 / span 2"
        />
        <Text
          color="white"
          fontWeight="bold"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          w="100%"
        >
          {creation.title}
        </Text>
        <Text color="white" textAlign="right" mt="auto" fontSize="12px">
          {dateString}{" "}
          {lastMessageDate?.toLocaleTimeString("en-US", {
            minute: "2-digit",
            hour: "2-digit",
          })}
        </Text>
        {lastMessage ? (
          <Text
            fontWeight="light"
            bg="#FBF6F3cc"
            noOfLines={2}
            gridColumn="2 / span 3"
            borderRadius="12px 12px 12px 0px"
            overflow="hidden"
            p="12px"
            my="auto"
            mr="auto"
            height="79px"
          >
            {lastMessage}
          </Text>
        ) : (
          <Button gridColumn="2 / span 3" my="auto" variant="fullSecondary">
            Chat now!
          </Button>
        )}
      </Grid>

      <Box border="solid 1px white" filter="blur(1px)" mt="1rem" />
    </Box>
  )
}

function ChatPresentation({
  onChat,
  userCreations,
}: {
  onChat: (creationId: string, text?: string) => void
  userCreations: Creation[]
}) {
  const createPopup = useDisclosure()

  return (
    <Navigation maxW="700px" blurredBackground backgroundColor="rgba(0,0,0,0.5)">
      <CreatePopup isOpen={createPopup.isOpen} onClose={createPopup.onClose} />
      <Heading size="sm" color="white">
        Chats
      </Heading>
      <Box>
        {userCreations
          .filter((c) => c.selectedImage && !c.questId)
          .map((c) => (
            <ChatCharacterLine
              key={c.id}
              creation={c}
              onClick={() => onChat(c.id)}
            />
          ))}
        <Button3d
          bg="secondary.500"
          bg3dColor="#2418B1"
          gap="0.5rem"
          w="100%"
          mt="2rem"
          onClick={createPopup.onOpen}
        >
          <Image src={whiteMonster} h="40px" w="40px" objectFit="contain" />
          Create a new Mini
        </Button3d>
      </Box>
    </Navigation>
  )
}

export default function Chat() {
  const navigate = useNavigate()
  const { user, userCreations } = useUserContext()

  if (!user)
    return (
      <ChatPresentationLoggedOut
        onChat={(t) => {
          // let session = new Date().getTime()
          if (t) navigate(`/chat/${CAMCAM_CREATION_ID}?text=${t}`)
          else navigate(`/chat/${CAMCAM_CREATION_ID}`)
        }}
      />
    )
  return (
    <ChatPresentation
      userCreations={userCreations}
      onChat={(creationId, t) => {
        if (t) navigate(`/chat/${creationId}?text=${t}`)
        else navigate(`/chat/${creationId}`)
      }}
    />
  )
}
