import { Box, Button, Container, Flex, Image, Text } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { useUserContext } from "../../../context/userContext"
import { useQuery } from "../../../utilities/hooks"
import characters from "../../assets/images/characters.png"
import loginbgsquare from "../../assets/video/login-bg-square.mp4"
import loginbg from "../../assets/video/login-bg.mp4"
import BackButton from "../Buttons/BackButton"
import GoogleSSOButton from "../Buttons/GoogleSSOButton"
import { HeaderWaveNarrow } from "../Header/HeaderWave"
import MinistudioLogo from "../ImagePresets/MinistudioLogo"
import CloudsBottom from "../SectionSeparators/CloudsBottom"
import Background from "./Background"
import ResponsiveContainer from "./ResponsiveContainer"

function SSOLogin() {
  const { setShowSSOLogin } = useUserContext()
  const query = useQuery()
  const navigate = useNavigate()

  const screenWidth = window.innerWidth

  const mobileThresholdWidth = 768

  const isMobile = screenWidth < mobileThresholdWidth

  return (
    <Flex mb="auto" flexDir="column" position="relative" h="100%">
      <Flex
        display={["none", "none", "none", "flex"]}
        flexDir="column"
        mx="auto"
        gap="1rem"
        my="3rem"
      >
        <MinistudioLogo variant="white" width="200px" height="auto" mx="auto" />
        <Text color="white" textAlign="center" mx="auto" fontSize="26px">
          Foster kids’ imagination <br />
          and curiosity
        </Text>
      </Flex>
      <Box
        bg="secondary.500"
        w="100%"
        display={["flex", "flex", "flex", "none"]}
        position="fixed"
        top="0"
        left="0"
      >
        <Flex
          position="relative"
          flexDir="column"
          textAlign="center"
          gap="0.5rem"
          w="100%"
          p="1rem"
        >
          <MinistudioLogo
            variant="white"
            height="80px"
            width="auto"
            mx="auto"
          />
          <HeaderWaveNarrow
            color="secondary.500"
            width="calc(100%)"
            height="auto"
            position="absolute"
            bottom="1px"
            transform="translateY(100%)"
            left="0"
          />
        </Flex>
      </Box>
      <Box
        display={["flex", "flex", "flex", "none"]}
        height={"100%"}
        paddingBottom={["6rem", 0]}
      >
        <video
          src={isMobile ? loginbgsquare : loginbg}
          playsInline
          style={{
            objectFit: "contain",
          }}
          autoPlay
          muted
          loop
          disablePictureInPicture
        />
      </Box>

      <Flex
        flexDir="column"
        gap="2rem"
        position={["fixed", "fixed", "fixed", "initial"]}
        w="100%"
        left="0"
        bottom="0"
        pb={["80px", 0]}
        pt={["16px", "16px", "16px", 0]}
        bg={["secondary.500", "secondary.500", "secondary.500", "none"]}
      >
        <CloudsBottom
          position="absolute"
          color="secondary.500"
          top="1px"
          transform="translateY(-100%)"
          width="100%"
          height="auto"
          display={["block", "block", "block", "none"]}
        />
        <GoogleSSOButton
          mx="auto"
          w="90%"
          maxW="400px"
          onLogin={async () => {
            const redirectTo = query.get("redirectTo")
            if (redirectTo) {
              navigate(redirectTo)
            } else {
              navigate("/app")
            }
          }}
        />
        <Button
          variant="link"
          color="white"
          textDecor="underline"
          onClick={() => setShowSSOLogin(false)}
          mb={["0", "2rem", "2rem", "0"]}
          zIndex={1}
        >
          Or use email instead
        </Button>
        <Image
          position="absolute"
          mt="auto"
          src={characters}
          w="80%"
          height={["90px", "0", "0", "200px"]}
          objectFit="cover"
          objectPosition="50% 0%"
          bottom="0"
          left="50%"
          transform="translateX(-50%)"
        />
      </Flex>
    </Flex>
  )
}

export default function AuthContainer({
  children,
  hideSSO = false,
}: {
  children: React.ReactNode
  hideSSO?: boolean
}) {
  const { showSSOLogin, setShowSSOLogin } = useUserContext()

  const sso = !hideSSO && showSSOLogin

  return (
    <Background
      bg={[
        "white",
        "white",
        "white",
        "linear-gradient(158.96deg, #9A54F2 6.45%, #D049FF 86.11%)",
      ]}
      display="flex"
      flexDir="column"
      m="auto"
      overflowY="auto"
    >
      {!sso && (
        <Box display={["block", "block", "block", "none"]}>
          <Box w="100%" position="relative" mb="2rem">
            <Flex
              bg="secondary.500"
              display={["flex", "flex", "flex", "none"]}
              mr="auto"
              position="relative"
              width="100%"
              height="80px"
              zIndex={1}
              p="1rem"
            >
              <ResponsiveContainer display="flex">
                {!hideSSO && (
                  <BackButton
                    color="white"
                    onClick={() => {
                      setShowSSOLogin(true)
                    }}
                  />
                )}
                <MinistudioLogo height="45px" width="auto" variant="white" />
              </ResponsiveContainer>
            </Flex>
            <HeaderWaveNarrow
              color="secondary.500"
              width="100%"
              height="auto"
              display="block"
              mt="-1px"
            />
          </Box>
        </Box>
      )}

      <ResponsiveContainer
        display="flex"
        p={["0em", "1em", "1em", "1em"]}
        paddingBottom={["1em"]}
        filter={[
          "none",
          "none",
          "none",
          "drop-shadow(17px 27px 13px rgba(51, 0, 9, 0.01)) drop-shadow(10px 15px 11px rgba(51, 0, 9, 0.05)) drop-shadow(4px 7px 8px rgba(51, 0, 9, 0.09)) drop-shadow(1px 2px 4px rgba(51, 0, 9, 0.1)) drop-shadow(0px 0px 0px rgba(51, 0, 9, 0.1));",
        ]}
      >
        <Box
          height="700px"
          w="100%"
          my={"auto"}
          ml={"auto"}
          borderRadius="20px 0 0 20px"
          display={["none", "none", "none", "none", "block"]}
        >
          <video
            src={loginbg}
            playsInline
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              borderRadius: "20px 0 0 20px",
              background: "white",
            }}
            autoPlay
            muted
            loop
            disablePictureInPicture
          />
        </Box>

        <Container
          borderRadius={[0, 0, 0, "20px", "0 20px 20px 0"]}
          bg={[
            "white",
            "white",
            "white",
            sso ? "secondary.500" : "secondary.100",
          ]}
          p={[0, 0, 0, 0]}
          my={[0, 0, 0, "auto"]}
          mx={[0, "auto", "auto", "auto", 0]}
          mr={[0, "auto", "auto", "auto"]}
          h={["100%", "100%", "100%", 700]}
          display="flex"
          flexDir="column"
          justifyContent="center"
        >
          {!sso && (
            <Box
              mt={["-2rem", "-2rem", "-2rem", 0]}
              mb="auto"
              display={["none", "none", "none", "block"]}
            >
              <Flex width="100%" alignSelf="center" position="relative">
                {!hideSSO && (
                  <BackButton
                    position="absolute"
                    left="1rem"
                    top="2rem"
                    color="white"
                    zIndex={1}
                    onClick={() => setShowSSOLogin(true)}
                  />
                )}
                <MinistudioLogo
                  variant="white"
                  mx="auto"
                  mt="2rem"
                  width="250px"
                  height="auto"
                  zIndex={1}
                />
                <Box position="absolute" width="100%" left="0" top="0">
                  <Box
                    w="100%"
                    height="180px"
                    bg="secondary.500"
                    borderRadius={[
                      "20px 20px 0 0",
                      "20px 20px 0 0",
                      "20px 20px 0 0",
                      "20px 20px 0 0",
                      "0 20px 0 0",
                    ]}
                  />
                  <HeaderWaveNarrow
                    color="secondary.500"
                    width="100%"
                    height="auto"
                    mt="-1px"
                  />
                </Box>
              </Flex>
            </Box>
          )}
          {sso ? <SSOLogin /> : <Box mb="auto">{children}</Box>}
        </Container>
      </ResponsiveContainer>
    </Background>
  )
}
