import { Image, Text } from "@chakra-ui/react"
import { useCallback, useRef, useState } from "react"
import { WebtoonAsset } from "../../../../data/models/Webtoon"
import "./WebtoonAnimation.css"

function stopScrolling(e: TouchEvent) {
  e.preventDefault()
}

export default function WebtoonAssetElement({
  asset,
}: {
  asset: WebtoonAsset
}) {
  const [position, setPosition] = useState<{ top: number; left: number }>({
    top: -1,
    left: -1,
  })
  const ref = useRef<HTMLImageElement>(null)

  const placeItem = (
    windowLocation: { x: number; y: number },
    e: HTMLImageElement
  ) => {
    const parentPos = e.parentElement!.getBoundingClientRect()
    const currentPos = e.getBoundingClientRect()
    const pos = { top: 0, left: 0 }
    pos.top = windowLocation.y - parentPos.top - currentPos.height / 2
    pos.left = windowLocation.x - parentPos.left - currentPos.width / 2
    if (pos.top < 0) pos.top = 0
    if (pos.left < 0) pos.left = 0
    if (pos.top > parentPos.height - currentPos.height)
      pos.top = parentPos.height - currentPos.height
    if (pos.left > parentPos.width - currentPos.width)
      pos.left = parentPos.width - currentPos.width
    setPosition(pos)
  }

  const mousemove = useCallback((e: MouseEvent) => {
    placeItem({ x: e.pageX, y: e.pageY }, ref.current!)
  }, [])

  const realPos = position.top === -1 ? { top: "50%", left: "50%" } : position
  const className = position.top === -1 ? "webtoon-untouched-asset webtoon-floating-animation" : undefined

  return (
    <>
      <Image
        ref={ref}
        src={asset.image}
        zIndex={2}
        position="absolute"
        draggable
        onTouchStartCapture={() => {
          document
            .getElementById("background")
            ?.addEventListener("touchmove", stopScrolling)
        }}
        onTouchEnd={() => {
          document
            .getElementById("background")
            ?.removeEventListener("touchmove", stopScrolling)
        }}
        onTouchMove={(e) => {
          const touchLocation = e.targetTouches[0]
          placeItem(
            { x: touchLocation.pageX, y: touchLocation.pageY },
            e.currentTarget
          )
        }}
        w="100px"
        h="100px"
        borderRadius="75px"
        objectFit="contain"
        top={realPos.top}
        left={realPos.left}
        className={className}
        onDragStart={(e) => {
          e.preventDefault()
        }}
        onMouseDown={(e) => {
          e.currentTarget.parentElement!.addEventListener(
            "mousemove",
            mousemove
          )
        }}
        onMouseUpCapture={(e) => {
          e.currentTarget.parentElement!.removeEventListener(
            "mousemove",
            mousemove
          )
        }}
      />
      {asset.text && position.top === -1 && (
        <Text
          w="fit-content"
          position="absolute"
          top="50%"
          left="50%"
          bg="white"
          padding="4px 16px"
          borderRadius="20px"
          transform="translate(-50%, 150%)"
        >
          {asset.text}
        </Text>
      )}
    </>
  )
}
