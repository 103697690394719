import { Icon, IconProps } from "@chakra-ui/react"

export default function CanvasIcon(props: IconProps) {
  return (
    <Icon
      viewBox="-3 -4 28 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M21.7003 0.139893H1.44237C0.655595 0.139893 0 0.78657 0 1.56277V19.542C0 20.3182 0.655537 20.9649 1.44237 20.9649H6.16271L2.6225 28.8551C2.29461 29.5665 2.6225 30.4072 3.34365 30.7306C4.06481 31.0541 4.91702 30.7306 5.24488 30.0192L9.30963 20.9649H13.8333L17.898 30.0192C18.1276 30.5366 18.6519 30.8599 19.2091 30.8599C19.4058 30.8599 19.6024 30.8276 19.799 30.7306C20.5202 30.4072 20.8481 29.5665 20.5202 28.8551L16.9797 20.9649H21.7001C22.4869 20.9649 23.1424 20.3183 23.1424 19.5421V1.56281C23.1424 0.786669 22.5196 0.139933 21.7001 0.139933L21.7003 0.139893ZM20.2579 18.1191H2.88462V2.98553H20.2907V18.1191H20.2579Z"
          fill="currentColor"
        />
        <path
          d="M5.96637 15.8547H17.177C17.4393 15.8547 17.7016 15.6929 17.8326 15.4667C17.9636 15.2402 17.9309 14.9493 17.7999 14.723L14.9481 10.6162C14.6858 10.2282 13.9976 10.2282 13.7353 10.6162L12.5552 12.2653L10.3917 9.16085C10.1295 8.77288 9.44122 8.77288 9.17893 9.16085L5.3437 14.6906C5.17975 14.917 5.17975 15.208 5.311 15.4342C5.44202 15.693 5.70428 15.8547 5.9663 15.8547L5.96637 15.8547Z"
          fill="currentColor"
        />
        <path
          d="M15.7678 6.9305C15.7678 7.98411 14.9019 8.83832 13.8338 8.83832C12.7656 8.83832 11.8999 7.98411 11.8999 6.9305C11.8999 5.87667 12.7656 5.02246 13.8338 5.02246C14.9019 5.02246 15.7678 5.87667 15.7678 6.9305Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  )
}
