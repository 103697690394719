import { Text, useDisclosure } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useUserContext } from "../../../../../context/userContext"
import Creation from "../../../../../data/models/Creation"
import ChooseCharacterMenu from "../../../../components/Creations/ChooseCharacterMenu"
import LoadingScreen from "../../../../components/LoadingScreen"
import SlidingPopup from "../../../../components/Popup/SlidingPopup"
import StorePageTemplate, { StorePageTemplateProps } from "./StorePageTemplate"
import { getFrameShopLink, openNewWindow } from "./shopUtils"
import { useSegment } from "../../../../../context/SegmentContext"

export default function PrintShopCharacterManager({
  creationId,
  variant,
  ...props
}: {
  creationId: string | undefined
  variant: string
} & Omit<StorePageTemplateProps, "onAddToCart">) {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { user, userCreations } = useUserContext()
  const [creation, setCreation] = useState<Creation>()
  const [tempCreation, setTempCreation] = useState<Creation>()
  const navigate = useNavigate()
  const { track } = useSegment()

  useEffect(() => {
    if (!user || !creationId) return
    const creation = userCreations.find(
      (creation) => creation.id === creationId
    )
    if (!creation) return navigate("/app", { replace: true })
    setCreation(creation)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, creationId])

  if ((creationId && !creation) || !user) {
    return <LoadingScreen />
  }

  const openWindow = (c: Creation) => {
    track("Add to cart print shop", {
      item: props.title,
      variant: variant,
      creationId: c.id,
    })
    openNewWindow(getFrameShopLink(c, variant, user))
  }

  return (
    <>
      <StorePageTemplate
        onAddToCart={() => {
          if (creation) openWindow(creation)
          else onOpen()
        }}
        {...props}
      />
      <SlidingPopup id="print-shop-character" show={isOpen} onClose={onClose}>
        <ChooseCharacterMenu
          chosenCreation={tempCreation || null}
          creations={userCreations}
          loading={false}
          onChoose={(c) => setTempCreation(c)}
          onCreate={() => {
            onClose()
            openWindow(tempCreation!)
          }}
          chooseText="Print with this character"
          disclamer={
            <Text size="sm" textAlign="center" maxW="400px" mx="auto" mt="1rem">
              Choose a character to put on your printed product.
            </Text>
          }
          choosingForYour="print"
        />
      </SlidingPopup>
    </>
  )
}
