import { FC, useEffect, useRef, useState } from "react"

import { CopyIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Image,
  Spinner,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react"
import { CopyToClipboard } from "react-copy-to-clipboard"

import { arrayRemove, arrayUnion, doc, updateDoc } from "firebase/firestore"
import { getDownloadURL, ref, uploadString } from "firebase/storage"
import React from "react"
import Resizer from "react-image-file-resizer"
import { useUserContext } from "../../../context/userContext"
import CreationDAO from "../../../daos/CreationDAO"
import UserDAO from "../../../daos/UserDAO"
import { db, storage } from "../../../data/apis/firebase/Firebase"
import Creation from "../../../data/models/Creation"
import { UserFirebaseWithId } from "../../../data/models/UserData"
import CreationService from "../../../service/CreationService"
import { getAgeString } from "../../../utilities/dateUtils"
interface TableTrProps {
  creation: Creation
  onUpdate: () => Promise<void>
  emailAddress: string
  childName: string
}

function listsAreEqual(a: string[], b: string[]) {
  return a.length === b.length && a.every((v, i) => v === b[i])
}

const TableTr: FC<TableTrProps> = ({
  creation,
  onUpdate,
  emailAddress,
  childName,
}) => {
  const { user } = useUserContext()
  const [isLoading, setIsLoading] = useState(false)
  const [currentImg, setCurrentImg] = useState(null as File | null)
  const [referenceImg, setReferenceImg] = useState<string | null>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [promptForAI, setPromptForAI] = useState(creation.promptForAI || "")
  const [creationOld, setCreationOld] = useState(creation)
  const [disablePrompt, setDisablePrompt] = useState(false)
  const [highlight, setHighlight] = useState(false)
  const objRef = useRef<HTMLTableRowElement>(null)

  useEffect(() => {
    if (window.location.hash === "#" + creation.id) {
      setHighlight(true)
      objRef.current?.focus()
      objRef.current?.scrollIntoView()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (JSON.stringify(creation) === JSON.stringify(creationOld)) return
    if (
      disablePrompt &&
      !listsAreEqual(
        creation.generativeAiResults ?? [],
        creationOld.generativeAiResults ?? []
      )
    ) {
      setDisablePrompt(false)
    }
    setCreationOld(creation)
    setPromptForAI(creation.promptForAI ?? "")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creation])

  const download = (imageUrl: string) => {
    fetch(imageUrl, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", "image.png") // or any other extension
          document.body.appendChild(link)
          link.click()
        })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    if (currentImg) {
      resizeFile(currentImg)
    } else {
      setReferenceImg(null)
    }
  }, [currentImg])
  if (!creation) {
    return null
  }

  const handleImgUploaded: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const file = event.target.files?.item(0)
    if (file) {
      if (file?.type.substring(0, 5) === "image") {
        setCurrentImg(file)
      } else {
        setCurrentImg(null)
      }
    } else {
      setCurrentImg(null)
    }
  }
  const resizeFile = (file: File) => {
    if (file instanceof Blob) {
      new Promise(() => {
        Resizer.imageFileResizer(
          file,
          512,
          512,
          "JPEG",
          100,
          0,
          (uri) => {
            setReferenceImg(uri as string)
          },
          "base64",
          512,
          512
        )
      })
    } else {
      if (file) {
        console.error(file)
        // setImgPreview(file);
      }
    }
  }
  const handleUploadThenContinue = async () => {
    setIsLoading(true)
    if (!referenceImg || !user) {
      // no image selected
      return
    }

    // TODO: refactor logic in Firebase function
    const creationRef = doc(
      db,
      `users/${creation.userId}/creations/${creation.id}`
    )
    const storagePath = `users/${user.id}/creations/${
      creation.id
    }/generativeResult_${Date.now()}}`
    const storageRef = ref(storage, storagePath)
    await uploadString(storageRef, referenceImg, "data_url").then(
      (snapshot) => {
        getDownloadURL(snapshot.ref).then(async (url) => {
          await updateDoc(creationRef, {
            generativeAiResults: arrayUnion(url),
          }).then(() => {
            onUpdate().then(() => setIsLoading(false))
          })
        })
      }
    )
  }

  const handleDelete = async (generativeResult: string) => {
    setIsLoading(true)
    // TODO: refactor logic in Firebase function
    const creationRef = doc(
      db,
      `users/${creation.userId}/creations/${creation.id}`
    )
    await updateDoc(creationRef, {
      generativeAiResults: arrayRemove(generativeResult),
    })
    onUpdate().then(() => setIsLoading(false))
  }
  const handleValidation = async () => {
    setIsLoading(true)
    const creationRef = doc(
      db,
      `users/${creation.userId}/creations/${creation.id}`
    )
    await updateDoc(creationRef, {
      isValidated: !creation.isValidated,
    })
    onUpdate().then(() => setIsLoading(false))
  }

  const handleSetShareable = async (shareable: boolean) => {
    setIsLoading(true)
    CreationService.setShareable(creation, shareable).finally(() =>
      setIsLoading(false)
    )
  }

  const updatePromptForAI = () => {
    let newPrompt = promptForAI
    if (promptForAI === creation.promptForAI) {
      newPrompt += " "
    }
    setIsLoading(true)
    setDisablePrompt(true)
    CreationService.updatepromptForAI(creation, newPrompt).then(() =>
      onUpdate().then(() => setIsLoading(false))
    )
  }

  const { locale, timeZone } = Intl.DateTimeFormat().resolvedOptions()

  return (
    <Tr
      id={creation.id}
      ref={objRef}
      boxSizing="border-box"
      border={highlight ? "solid 4px #004AAD" : undefined}
    >
      <Td>
        {isLoading && (
          <Box
            w={"full"}
            h={"full"}
            justifyItems={"center"}
            alignItems={"center"}
            bgColor={"rgba(0,0,0,0.5)"}
            position={"fixed"}
            zIndex={1}
            top={0}
            left={0}
            transitionDelay={"0.5s"}
            overflowX={"hidden"}
          >
            <Spinner
              size={"lg"}
              position={"fixed"}
              left="50%"
              top="50%"
              transform={"translate(-50%, -50%)"}
              color="white"
            />
          </Box>
        )}
        <VStack justifyItems={"center"} alignItems={"center"}>
          <Text color={"black"} size="xs">
            {new Date(creation.createdAt).toLocaleString(locale, { timeZone })}
          </Text>
          {creation.userId && (
            <CopyToClipboard text={creation.userId}>
              <HStack>
                <Text color={"black"} size="xs">
                  {creation.userId}
                </Text>
                <CopyIcon />
              </HStack>
            </CopyToClipboard>
          )}
          {creation.title && (
            <CopyToClipboard text={creation.title}>
              <HStack>
                <Text color={"black"} size="xs">
                  {creation.title}
                </Text>
                <CopyIcon />
              </HStack>
            </CopyToClipboard>
          )}
          <HStack>
            <Text color={"black"} size="xs">
              Age: {getAgeString(creation.createdAt)}
            </Text>
          </HStack>
          {childName && (
            <CopyToClipboard text={childName}>
              <HStack>
                <Text color={"black"} size="xs">
                  by {childName}
                </Text>
                <CopyIcon />
              </HStack>
            </CopyToClipboard>
          )}

          {creation.prompt && (
            <CopyToClipboard text={creation.prompt}>
              <HStack>
                <Text color={"black"} size="xs">
                  {creation.prompt}
                </Text>
                <CopyIcon />
              </HStack>
            </CopyToClipboard>
          )}
          <CopyToClipboard text={emailAddress}>
            <HStack>
              <Text color={"black"} size="xs">
                {emailAddress}
              </Text>
              <CopyIcon />
            </HStack>
          </CopyToClipboard>
        </VStack>
      </Td>
      <Td>
        <Checkbox
          isChecked={creation.isValidated}
          onChange={handleValidation}
          colorScheme="blackAlpha"
        />
      </Td>
      <Td textAlign="center">
        <Text size="sm">
          Accepted Sharing?
          <br />
          <b>
            {creation.shareAgreement === undefined
              ? "no answer"
              : creation.shareAgreement
              ? "yes"
              : "no"}
          </b>
        </Text>
        {creation.shareAgreement === true && (
          <Box>
            <Text size="sm">Share with community?</Text>
            <Checkbox
              isChecked={creation.shareable ?? false}
              onChange={() => handleSetShareable(!creation.shareable)}
              colorScheme="blackAlpha"
            />
          </Box>
        )}
      </Td>
      <Td>
        <Text size="sm">
          {creation.rating === undefined
            ? "No rating"
            : `Rating: ${creation.rating}/5`}
        </Text>
      </Td>

      <Td>
        <CopyToClipboard text={creation.originalGeneratedPrompt ?? ""}>
          <HStack>
            <Text
              px="4"
              py="2"
              width="300px"
              h="200px"
              variant="black"
              size="sm"
              border="solid 1px lightgray"
              borderRadius={4}
              cursor="not-allowed"
              title={creation.originalGeneratedPrompt ?? ""}
              whiteSpace={"normal"}
              overflowY={"auto"}
            >
              {creation.originalGeneratedPrompt}
            </Text>
            <CopyIcon />
          </HStack>
        </CopyToClipboard>
      </Td>
      <Td minWidth="400px">
        <HStack>
          <Flex width="100%" flexDir={"column"}>
            <Textarea
              onChange={(e) => setPromptForAI(e.target.value)}
              value={promptForAI}
              height={150}
              border="solid 1px black"
              disabled={creation.promptForAI === undefined}
            />
            <Button
              onClick={updatePromptForAI}
              size="sm"
              variant="kingBlueBtn"
              disabled={creation.promptForAI === undefined || disablePrompt}
            >
              {promptForAI === creation.promptForAI
                ? "Regenerate"
                : "Generate with new prompt"}
            </Button>
          </Flex>
          <CopyToClipboard text={promptForAI}>
            <CopyIcon />
          </CopyToClipboard>
        </HStack>
      </Td>
      <Td>
        <Image
          src={creation.originalImagePath ?? ""}
          alt={creation.title}
          minWidth={200}
          height={200}
          objectFit={"cover"}
          onClick={(e) => download(e.currentTarget.src)}
        />
      </Td>
      <Td>
        <HStack flex={1}>
          {creation.generativeAiResults &&
            creation.generativeAiResults?.length > 0 &&
            creation.generativeAiResults?.map((result, index) => (
              <Box flex={1} flexDirection={"column"} key={index}>
                <Image
                  objectFit="cover"
                  src={result}
                  alt={result}
                  width={100}
                  height={100}
                />
                <Button
                  variant="kingBlueBtn"
                  onClick={() => handleDelete(result)}
                  size="sm"
                  px="10"
                >
                  Delete
                </Button>
              </Box>
            ))}
          {creation.generativeAiResults &&
            creation.generativeAiResults?.length < 4 && (
              <VStack
                flex={1}
                flexDirection={"column"}
                justifyItems={"center"}
                alignItems={"center"}
                bgColor={"gray.100"}
              >
                <input
                  type="file"
                  accept="image/jpeg, image/png, image/jpg"
                  onChange={handleImgUploaded}
                  ref={fileInputRef}
                />
                {referenceImg && (
                  <Button onClick={handleUploadThenContinue}>Valider</Button>
                )}
              </VStack>
            )}
        </HStack>
      </Td>
    </Tr>
  )
}

export default function CreationsTablePending() {
  const [creations, setCreations] = useState<Creation[]>([])
  const [allUsers, setUsers] = useState<UserFirebaseWithId[]>([])

  const updateCreationsList = () =>
    CreationDAO.getAllCreations().then((creations) => {
      setCreations(
        creations
          .filter((creation) => creation.originalImagePath?.includes("https"))
          .filter((c) => (c.generativeAiResults?.length ?? 0) !== 4)
          .filter(c => !c.selectedImage)
      )
    })

  useEffect(() => {
    updateCreationsList()
    UserDAO.getAllUsers().then((emails) =>
      setUsers(
        emails.filter(
          (value, index, self) =>
            index ===
            self.findIndex((t) => t.emailAddress === value.emailAddress)
        )
      )
    )
    return CreationService.subscribeToAllCreations(updateCreationsList)
  }, [])

  useEffect(() => {
    for (const creation of creations) {
      const email = allUsers.find((user) => user.id === creation.userId)
      if (!email) {
        UserDAO.getAllUsers().then((emails) => setUsers(emails))
        return
      }
    }
  }, [creations, allUsers])

  return (
    <TableContainer px={8} pt={8} pb={16} flex="1" w="100%" minW="100%">
      <Table colorScheme="black">
        <TableCaption>
          If this table is empty, then there are no ungenerated creations :D
        </TableCaption>
        <Thead>
          <Tr>
            <Th fontSize={"xs"}>Request time</Th>
            <Th>Validated</Th>
            <Th>Share</Th>
            <Th>Rating</Th>
            <Th>Original prompt</Th>
            <Th>Generated prompt</Th>
            <Th>Original drawing</Th>
            <Th>Creations results</Th>
          </Tr>
        </Thead>
        <Tbody>
          {creations
            .sort(
              (creation1, creation2) =>
                creation2.createdAt.getTime() - creation1.createdAt.getTime()
            )
            .map((creation) => {
              const user = allUsers.find((e) => e.id === creation.userId)
              return (
                creation.userId && (
                  <TableTr
                    key={creation.id}
                    creation={creation}
                    onUpdate={updateCreationsList}
                    emailAddress={user?.emailAddress ?? "Loading email..."}
                    childName={user?.childName ?? "..."}
                  />
                )
              )
            })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
