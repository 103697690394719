import { AddIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { useUserContext } from "../../../context/userContext"
import Story from "../../../data/models/Story"
import StoryService from "../../../service/StoryService"
import { useQuery } from "../../../utilities/hooks"
import webtoonDemo from "../../assets/images/webtoon-demo.png"
import BackButton from "../../components/Buttons/BackButton"
import CharacterCard from "../../components/Creations/CharacterCard"
import LoadingScreen from "../../components/LoadingScreen"
import SlidingPopup from "../../components/Popup/SlidingPopup"
import Navigation from "../../components/Wrapper/Navigation"
// import { IS_PROD } from "../../components/Creations/GlobalSettings"

export default function CreateWebtoonPage() {
  const { storyId } = useParams()
  const [loading, setLoading] = useState(true)
  const [story, setStory] = useState(null as Story | null)
  const { user, userCreations } = useUserContext()
  const query = useQuery()
  const creationId = query.get("creationId")
  const navigate = useNavigate()
  const [showPopup, setShowPopup] = useState(creationId !== null)
  const readyCreations = userCreations
    .filter((c) => (c.selectedImage?.length ?? 0) > 0)
    .sort((a, b) => {
      if (a.id === creationId) return -1
      else if (b.id === creationId) return 1
      return a.title?.localeCompare(b.title ?? "") ?? 0
    })
  const [chosenCreation, setChosenCreation] = useState(
    readyCreations.at(0) ?? null
  )
  const [creationLoading, setCreationLoading] = useState(false)
  const [error, setError] = useState("")

  useEffect(() => {
    if (typeof storyId === "string") {
      StoryService.getStoryOld(storyId)
        .then((story) => setStory(story))
        .finally(() => setLoading(false))
    } else setLoading(false)
  }, [storyId])

  useEffect(() => {
    setError("")
  }, [chosenCreation])

  if (loading) return <LoadingScreen />
  else if (story === null) return <Navigate to={`/stories`} replace />

  const createWebtoon = async () => {
    if (!user || !chosenCreation) return
    setError("")
    setCreationLoading(true)
    if (user.credits < 15) {
      setTimeout(
        () =>
          navigate(
            `/create-webtoon/${storyId}/failure?creationId=${chosenCreation.id}`
          ),
        1000
      )
      return
    }
    // WebtoonService.create(user, chosenCreation.id, story.id)
    //   .then(async () => {
    //     await fetchUserCredits()
    //     navigate(`/create-webtoon/${storyId}/success`)
    //   })
    //   .catch(() => setError("Something went wrong, please try again later..."))
    //   .finally(() => setCreationLoading(false))
    setCreationLoading(false)
  }
  return (
    <Navigation maxW="768px" backgroundColor={"#edf5fc"}>
      <Box
        height="500px"
        mt="-1rem"
        mx={["-1rem", "-1rem", 0]}
        position="relative"
      >
        <Image
          src={story.cover}
          height="100%"
          width="100%"
          objectFit="cover"
          borderRadius={["0 0 0 1000px", "0 0 0 1000px", "0 0 500px 500px"]}
        />
        <Flex
          h="100%"
          w="100%"
          flexDir="column"
          borderRadius={["0 0 0 1000px", "0 0 0 1000px", "0 0 500px 500px"]}
          bg="rgba(0,0,0,0.2)"
          position="absolute"
          top="0"
        >
          <Box my="auto">
            <Heading
              as="h1"
              color="white"
              fontSize="28px"
              fontWeight="normal"
              textAlign="center"
              my="1.25rem"
            >
              Create your story
            </Heading>
            <Heading as="h2" color="white" fontSize="20px" textAlign="center">
              {story.title}
            </Heading>
          </Box>
          <Image src={webtoonDemo} h="400px" objectFit="contain" />
        </Flex>
        <BackButton
          color="white"
          buttonBg
          position="absolute"
          top="1rem"
          left="1rem"
        />
      </Box>
      <Heading color="secondary.500" fontSize="20px" my="2rem">
        Take your original characters on adventures to epic universes!
      </Heading>
      <Flex>
        <Button mx="auto" mt="1rem" onClick={() => setShowPopup(true)}>
          Create a story now! (15 stars)
        </Button>
      </Flex>
      <SlidingPopup
        id="create-webtoon-choose-creation"
        show={showPopup}
        onClose={() => setShowPopup(false)}
        textAlign="center"
      >
        {chosenCreation && (
          <Flex gap="0.25rem" justifyContent="center" mb="1rem">
            <Heading fontSize="1rem" as="h3">
              You chose
            </Heading>
            <Heading fontSize="1rem" as="h3" color="primary.500">
              {chosenCreation?.title}
            </Heading>
            <Heading fontSize="1rem" as="h3">
              for your story
            </Heading>
          </Flex>
        )}
        {chosenCreation === null && (
          <Heading fontSize="1rem" as="h3" mb="1rem">
            You need to create a new character
          </Heading>
        )}
        <Flex mx="-1rem">
          <Flex
            maxW="100%"
            gap="1rem"
            px="1rem"
            overflowX="auto"
            pb="1rem"
            m="auto"
          >
            <Flex
              minW="150px"
              h="220px"
              minH="220px"
              border="solid 4px"
              borderColor="primary.500"
              borderRadius="10px"
              color="primary.500"
              fontWeight="bold"
              alignItems="center"
              justifyContent="center"
              fontSize="48px"
              onClick={() => navigate("/upload")}
              cursor="pointer"
            >
              <AddIcon />
            </Flex>
            {readyCreations.map((creation) => (
              <CharacterCard
                key={creation.id}
                creation={creation}
                onClick={() => setChosenCreation(creation)}
                imageHeight="220px"
                borderImage={creation.id === chosenCreation?.id}
              />
            ))}
          </Flex>
        </Flex>
        <Text variant="raleway" size="sm" my="1rem">
          Once you accept, we will bring this character to a new universe inside
          a MiniTale.
        </Text>
        <Text variant="error" size="sm" mb="0.5rem">
          {error}
        </Text>
        <Button
          disabled={chosenCreation === null || creationLoading}
          onClick={createWebtoon}
          isLoading={creationLoading}
          loadingText="Creating magic..."
        >
          Create my MiniTale now!
        </Button>
      </SlidingPopup>
    </Navigation>
  )
}
