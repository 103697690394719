import { Location, useLocation, useNavigate } from "react-router-dom"
import { useUserContext } from "../../../context/userContext"
import {
  OnboardingStep1,
  OnboardingStep2,
  OnboardingStep3,
  OnboardingStep4,
} from "./OnboardingSteps"
// import onboardingSteps from "./OnboardingSteps"

const ONBOARDING_STEPS: (({
  onNext,
}: {
  onNext: () => void
}) => JSX.Element)[] = [
  OnboardingStep1,
  OnboardingStep2,
  OnboardingStep3,
  OnboardingStep4,
]

function getStep(location: Location): number {
  const queryParams = new URLSearchParams(location.search)
  const stepQuery = queryParams.get("step")
  let step = 0
  if (stepQuery) {
    try {
      const stepNumber = parseInt(stepQuery)
      if (!Number.isNaN(stepNumber)) step = stepNumber
    } catch {}
  }
  return step
}

export default function Onboarding(): JSX.Element {
  const { setOnboardingDone } = useUserContext()
  const location = useLocation()
  const navigate = useNavigate()
  const step = getStep(location)

  const onClick = async () => {
    if (step + 1 >= ONBOARDING_STEPS.length) {
      // await fetchUserCredits()
      setOnboardingDone().then(() => navigate("/upload"))
    } else {
      navigate(`/onboarding?step=${step + 1}`)
    }
  }

  const Step = ONBOARDING_STEPS[step]

  return <Step onNext={onClick} />
}
