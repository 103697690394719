import { Button, ButtonProps, Flex } from "@chakra-ui/react"
import { Player } from "@lottiefiles/react-lottie-player"
import { useEffect, useRef, useState } from "react"

export default function MuteButton({
  muted,
  onToggle,
  ...props
}: {
  muted: boolean
  onToggle: () => void
} & ButtonProps) {
  const ref = useRef<Player>(null)
  const [tries, setTries] = useState(0)

  useEffect(() => {
    // This is a hack to fix the small size of the svg

    const svg = ref.current?.container?.getElementsByTagName("svg")[0]
    const target = muted ? 0 : 49
    const targetViewbox = "350 345 100 110"
    if (
      ref.current?.state.seeker === target &&
      svg?.getAttribute("viewBox") === targetViewbox
    )
      return
    svg?.setAttribute("viewBox", "350 345 100 110")
    ref.current?.setSeeker(muted ? 0 : 49, false)
    setTimeout(() => {
      setTries(tries + 1)
    }, 50)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tries])

  // manage initial state without animation
  useEffect(() => {
    ref.current?.setState({
      ...ref.current.state,
      animationData: {
        ...ref.current.state.animationData,
        fr: muted ? 0 : 60,
      },
    })
    ref.current?.setPlayerDirection(muted ? -1 : 1)
    ref.current?.play()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current])

  useEffect(() => {
    ref.current?.setPlayerDirection(muted ? -1 : 1)
    ref.current?.play()
  }, [muted])

  return (
    <Button p="8px" w="40px" h="40px" onClick={onToggle} {...props}>
      <Flex>
        <Player
          ref={ref}
          keepLastFrame
          speed={2}
          src={"/lottie/mute-speaker.json"}
          renderer="svg"
        />
      </Flex>
    </Button>
  )
}
