import { CheckCircleIcon } from "@chakra-ui/icons"
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useSegment } from "../../../context/SegmentContext"
import { useUserContext } from "../../../context/userContext"
import { useSketchQuests } from "../../../data/apis/SketchQuestUtils"
import star from "../../assets/images/star.png"
import Navigation from "../../components/Wrapper/Navigation"

export default function WeeklyThemeList() {
  const [completion, setCompletion] =
    useState<{ key: string; isCompleted: boolean }[]>()
  const { userCreations } = useUserContext()
  const navigate = useNavigate()
  const { track } = useSegment()
  const { sketchQuests } = useSketchQuests()

  function isCompleted(moduleKey: string) {
    return completion?.find((c) => c.key === moduleKey)?.isCompleted ?? false
  }

  useEffect(() => {
    const tempCompletion: { key: string; isCompleted: boolean }[] = []
    sketchQuests.forEach(async (module) => {
      const isCompleted =
        userCreations.find((uc) => uc.questId === module._key) !== undefined
      tempCompletion.push({ key: module._key, isCompleted })
      setCompletion(tempCompletion)
    })
  }, [userCreations, sketchQuests])

  if (!sketchQuests) return null

  const nbCompleted = completion?.filter((c) => c.isCompleted).length

  return (
    <Navigation showBackButton includeHeader maxW="600px">
      <Box>
        {sketchQuests.length > 0 && (
          <Flex mt="2rem" mb="0.5rem">
            <Heading as="h2" fontSize="20px" color="white" textShadow="0 0 4px black">
              Sketch Quests
            </Heading>
            <Image src={star} h="20px" my="auto" ml="0.5rem" />
            <Text fontWeight="light" color="white" textShadow="0 0 4px black" fontSize="16px" my="auto">
              {nbCompleted}/{sketchQuests.length}
            </Text>
          </Flex>
        )}
        <Box mx="-1rem">
          <Flex px="1rem" gap="0.5rem" pb="1rem" flexDir="column">
            {sketchQuests.map((module, i) => {
              return (
                <Box
                  key={i}
                  w="250px"
                  minW="100%"
                  minH={["250px", "350px", "500px"]}
                  h={["250px", "350px", "500px"]}
                  onClick={() => {
                    navigate(`/weeklyTheme/${module._key}`)
                    track("Click on a squetch quest", {
                      title: module.title || "",
                    })
                  }}
                  cursor="pointer"
                  position="relative"
                  bgImg={module.creations[0].creationUrl}
                  bgSize="cover"
                  bgPos="center"
                  borderRadius="20px"
                >
                  <Box
                    w={"100%"}
                    h={"100%"}
                    borderRadius="20px"
                    objectFit="cover"
                    bg="rgba(0,0,0,0.33)"
                  />
                  <Flex
                    position="absolute"
                    width="100%"
                    textAlign="center"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="pre"
                    left="50%"
                    bottom="2rem"
                    color="white"
                    fontWeight="bold"
                    px="0.5rem"
                    fontSize="16px"
                    transform="translateX(-50%)"
                    justifyContent="center"
                  >
                    <Text
                      textAlign="center"
                      whiteSpace="pre-wrap"
                      color="white"
                      fontWeight="bold"
                      px="0.5rem"
                      fontSize={["20px", "24px"]}
                    >
                      {module.title}
                    </Text>
                    {isCompleted(module._key) && (
                      <CheckCircleIcon
                        width="1.5rem"
                        height="1.5rem"
                        color="#35D290"
                        borderRadius="full"
                        bg="white"
                        mt="0.25rem"
                      />
                    )}
                  </Flex>
                  {/* {c.map((creation, i) => (
                    <Box
                      key={creation.id}
                      position="absolute"
                      top={`${16 + i * 16}px`}
                      right={`${50 + i * 16}px`}
                      display={["none", "block"]}
                    >
                      <CharacterCard
                        onClick={() => {}}
                        imageHeight="150px"
                        imageWidth="150px"
                        creation={creation}
                      />
                    </Box>
                  ))} */}
                </Box>
              )
            })}
          </Flex>
        </Box>
      </Box>
    </Navigation>
  )
}
