import Masonry from "react-masonry-css"
import Creation from "../../../data/models/Creation"
import CharacterCard from "../../components/Creations/CharacterCard"
import "./masonry.css"

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  768: 2,
}

export default function CommunityMasonry({
  creations,
}: {
  creations: Creation[]
}) {
  let creationItems = creations.map((creation) => (
    <CharacterCard
      imageHeight={`${Math.random() * 150 + 260}px`}
      imageWidth="100%"
      key={creation.id}
      creation={creation}
      url={`/community/${creation.id}`}
      showCreatedBy={false}
    />
  ))

  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
    >
      {creationItems}
    </Masonry>
  )
}
