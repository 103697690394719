import axios from "axios"
import StoryPackDAO from "../daos/StoryPackDAO"
import { StoryPack } from "../data/models/StoryPack"
import UserData from "../data/models/UserData"
import { API_ENDPOINTS } from "../presentation/components/Creations/GlobalSettings"

export default class StoryPackService {
  static async getStoryPacks(): Promise<StoryPack[]> {
    return await StoryPackDAO.getStoryPacks()
  }
  static async getStoryPack(id: string): Promise<StoryPack> {
    return await StoryPackDAO.getStoryPack(id)
  }

  static async buyStoryPack(
    user: UserData,
    storyPackId: string
  ): Promise<void> {
    await axios.post(
      API_ENDPOINTS.BUY_STORY_PACK,
      {
        storyPackId,
      },
      {
        headers: {
          Authorization: `Bearer ${await user.fbUser.getIdToken(true)}`,
        },
      }
    )
  }
}
