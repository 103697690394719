import { Icon, IconProps } from "@chakra-ui/react"

export function HeaderWaveWide({ ...props }: IconProps) {
  return (
    <Icon
      width="2000"
      height="70"
      viewBox="50 0 1900 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color="primary.500"
      preserveAspectRatio="xMidYMax slice"
      {...props}
    >
      <g clipPath="url(#clip1_6053_13203)">
        <path
          d="M849.369 39.2365C595.662 85.3059 179.066 10.5714 0 0L-65 -4L0 -143.888H1954.33C1990.97 -95.4638 2035.53 -14.6233 1954.33 23.3794C1865.02 65.1784 1508.66 95.045 1360.55 39.2365C1263.07 2.50357 1082.73 -3.1373 849.369 39.2365Z"
          fill="#D3EB40"
        />
      </g>
      <g clipPath="url(#clip0_6053_13203)">
        <path
          d="M848.869 34.1242C595.162 80.1936 194.066 8.07141 15 -2.5L-18 -8.5L-0.5 -149H1953.83C1990.47 -100.576 2035.03 -19.7356 1953.83 18.2671C1864.52 60.0661 1508.16 89.9326 1360.05 34.1242C1262.57 -2.60873 1082.23 -8.2496 848.869 34.1242Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_6053_13203">
          <rect width="2000" height="70" fill="white" />
        </clipPath>
        <clipPath id="clip1_6053_13203">
          <rect
            width="1999"
            transform="translate(0 1)"
            height="70"
            fill="white"
          />
        </clipPath>
      </defs>
    </Icon>
  )
}

export function HeaderWaveNarrow({ ...props }: IconProps) {
  return (
    <Icon
      width="390px"
      height="25px"
      viewBox="2 0 380 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color="primary.500"
      {...props}
    >
      <g clipPath="url(#clip2_6434_28061)">
        <g clipPath="url(#clip1_6434_28061)">
          <path
            d="M197.812 9C117.11 21.868 32.9688 13.9999 0 4.49993V-60H390V24.9999C358.594 8.66659 288.75 -5.49993 197.812 9Z"
            fill="#D3EB40"
            style={{clipPath: "inset(61px 0 0 0)"}}
          />
        </g>
      </g>
      <g clipPath="url(#clip0_6434_28061)">
        <g clipPath="url(#clip1_6434_28061)">
          <path
            d="M197.812 7C117.11 19.868 32.9688 11.9999 0 2.49993V-62H390V22.9999C358.594 6.66659 288.75 -7.49993 197.812 7Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_6434_28061">
          <rect width="390" height="25" fill="white" />
        </clipPath>
        <clipPath id="clip1_6434_28061">
          <rect
            width="390"
            height="87"
            fill="white"
            transform="translate(0 -62)"
          />
        </clipPath>
        <clipPath id="clip2_6434_28061">
          <rect
            width="390"
            height="24"
            transform="translate(0 1)"
            fill="white"
          />
        </clipPath>
      </defs>
    </Icon>
  )
}

export default function HeaderWave({ ...props }: IconProps) {
  return (
    <>
      <HeaderWaveWide {...props} display={["none", "none", "none", "block"]} />
      <HeaderWaveNarrow
        {...props}
        display={["block", "block", "block", "none"]}
      />
    </>
  )
}
