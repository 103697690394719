import { useParams } from "react-router-dom"
import img1 from "../../../../assets/images/character-actions/shop/mug/mug-1.jpeg"
import img2 from "../../../../assets/images/character-actions/shop/mug/mug-2.jpeg"
import img3 from "../../../../assets/images/character-actions/shop/mug/mug-3.jpeg"
import img4 from "../../../../assets/images/character-actions/shop/mug/mug-4.jpeg"
import PrintShopCharacterManager from "./PrintShopCharacterManager"

const MUG_VARIANT = "45073708876097"

export default function CreationPrintMug() {
  const { creationId } = useParams()

  return (
    <PrintShopCharacterManager
      creationId={creationId}
      variant={MUG_VARIANT}
      price={"$19.99"}
      productImgs={[img1, img2, img3, img4]}
      title="MUG"
      description={`Porcelain 10oz mug
Dishwasher and microwave safe`}
      product={`Presenting the magic mug, where your child's artistic visions take center stage on their very own drinkware! Let them transform their morning cocoa or afternoon juice into a magical experience with their own custom-designed, enchanting creations.

From majestic mermaids to morning monsters, their imagination is the only limit to what can be brewed up. Gift your little artist the magic mug and watch them sip their way through a world of wonder and whimsy!`}
    />
  )
}
