import { Icon } from "@chakra-ui/react"

const HomeIcon: typeof Icon = (props) => (
  <Icon
  viewBox="0 0 31 29"
  fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_7674_30222)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9136 3.47203C16.3671 3.11926 16.9253 2.92773 17.4998 2.92773C18.0743 2.92773 18.6325 3.11926 19.086 3.47203L29.9153 11.8937C30.8879 12.6519 30.3506 14.2084 29.1209 14.2084H27.8331V24.5417C27.8331 25.2268 27.561 25.8839 27.0765 26.3684C26.592 26.8529 25.9349 27.125 25.2498 27.125H9.74981C9.06467 27.125 8.40758 26.8529 7.92312 26.3684C7.43865 25.8839 7.16648 25.2268 7.16648 24.5417V14.2084H5.87868C4.64773 14.2084 4.11298 12.6506 5.08431 11.895L15.9136 3.47203ZM9.26543 11.9157C9.56122 12.1546 9.74981 12.5202 9.74981 12.9296V24.5417H25.2498V12.9296C25.2498 12.5202 25.4384 12.1546 25.7342 11.9157L17.4998 5.51157L9.26543 11.9157Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_7674_30222">
        <rect
          width="31"
          height="31"
          fill="currentColor"
          transform="translate(2)"
        />
      </clipPath>
    </defs>
  </Icon>
)

export default HomeIcon
