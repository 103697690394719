import axios from "axios"
import { ChatEntry } from "../context/ChatContext"
import UserData from "../data/models/UserData"
import { API_ENDPOINTS } from "../presentation/components/Creations/GlobalSettings"

const MESSAGE_UNAVAILABLE =
  "I'm sorry looks like there's traffic in the galaxy and I can't retrieve your answer right away. Could you send your message again a bit later?\n\nIn the meantime, go and checkout our sketch quests! Have you completed all of them?"

export default class ChatService {
  static async sendChat(
    chat: ChatEntry[],
    user: UserData | null,
    creationId?: string,
    retries = 1
  ): Promise<ChatEntry> {
    let headers = {}
    if (user) {
      headers = {
        Authorization: `Bearer ${await user?.fbUser?.getIdToken(true)}`,
      }
    }
    try {
      const res = await axios.post<ChatEntry>(
        API_ENDPOINTS.CHAT,
        {
          chat: [...chat].map((c) => ({ content: c.content, role: c.role })),
          creationId,
        },
        {
          headers,
        }
      )

      if (
        typeof res.data.content !== "string" ||
        typeof res.data.content !== "string"
      ) {
        return {
          content: MESSAGE_UNAVAILABLE,
          role: "assistant",
        }
      }

      return res.data
    } catch (error) {
      if (retries > 0)
        return await ChatService.sendChat(chat, user, creationId, retries - 1)
      return {
        content: MESSAGE_UNAVAILABLE,
        role: "assistant",
      }
    }
  }
}
