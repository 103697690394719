import { Icon, IconProps } from "@chakra-ui/react"

export default function PrimarySectionSeparator(props: IconProps) {
  return (
    <Icon
      width="100%"
      height="fit-content"
      viewBox="0 0 390 24"
      fill="none"
      color="primary.400"
      {...props}
    >
      <g clipPath="url(#clip0_3777_3103)">
        <path
          d="M423.033 34.514L451.612 262.768L454.866 288.757L1.44017 352.991C-28.0639 221.258 -78.6226 -37.0681 -44.8248 -16.5099C-2.5775 9.18791 113.417 32.5379 233.219 12.9468C329.061 -2.72602 399.695 20.7946 423.033 34.514Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3777_3103">
          <rect
            width="518"
            height="24"
            fill="white"
            transform="translate(-62)"
          />
        </clipPath>
      </defs>
    </Icon>
  )
}
