import { Icon, IconProps } from "@chakra-ui/react"

export default function TextIcon(props: IconProps) {
  return (
    <Icon
      width="43"
      height="43"
      viewBox="0 0 50 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.9877 18.5599C12.3104 18.5599 11.712 18.1189 11.5115 17.472L11.1346 16.2561H4.0201L3.64322 17.472C3.4427 18.1189 2.84431 18.5599 2.16701 18.5599C1.10561 18.5599 0.360083 17.5146 0.705767 16.5111L5.80674 1.70286C6.06703 0.947232 6.77816 0.440053 7.57736 0.440053C8.37656 0.440053 9.08769 0.947232 9.34798 1.70286L14.449 16.5111C14.7946 17.5146 14.0491 18.5599 12.9877 18.5599ZM4.81354 13.5899H10.3147L7.56414 4.60763L4.81354 13.5899Z"
        fill="currentColor"
      />
      <path
        d="M25.8804 9.24114C27.8905 9.42234 29.6889 10.9237 29.6889 13.5123C29.6889 16.6444 27.4673 18.5599 23.791 18.5599H20.8517C18.8671 18.5599 17.2584 16.9512 17.2584 14.9667V4.03334C17.2584 2.04882 18.8671 0.440053 20.8517 0.440053H23.4472C27.1499 0.440053 29.398 2.20027 29.398 5.15123C29.398 7.61035 28.0227 8.82697 25.8804 9.24114ZM23.315 3.05449H20.2999V8.02452H23.315C25.2192 8.02452 26.3565 7.09264 26.3565 5.53951C26.3565 3.96049 25.2457 3.05449 23.315 3.05449ZM23.7381 15.9455C25.5631 15.9455 26.6474 14.936 26.6474 13.2016C26.6474 11.6744 25.4837 10.639 23.7646 10.639H20.2999V15.9455H23.7381Z"
        fill="currentColor"
      />
      <path
        d="M41.0341 19C35.004 19 31.407 15.0395 31.407 9.5C31.407 3.96049 35.004 0 41.0341 0C45.2503 0 48.2395 1.92494 49.4651 4.75375C49.8799 5.71107 49.0651 6.65259 48.0218 6.65259C47.2281 6.65259 46.5681 6.08873 46.2033 5.38383C45.3212 3.6793 43.5593 2.66621 41.0606 2.66621C36.9611 2.66621 34.6072 5.35831 34.6072 9.5C34.6072 13.6417 36.9611 16.3338 41.0606 16.3338C43.5593 16.3338 45.3212 15.3207 46.2033 13.6162C46.5681 12.9113 47.2281 12.3474 48.0218 12.3474C49.0651 12.3474 49.8799 13.2889 49.4651 14.2462C48.2395 17.0751 45.2503 19 41.0341 19Z"
        fill="currentColor"
      />
    </Icon>
  )
}
