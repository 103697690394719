import { Box, Flex, Text } from "@chakra-ui/react"
import BackButton from "../../../components/Buttons/BackButton"
import Navigation from "../../../components/Wrapper/Navigation"

export default function ContactPage() {
  return (
    <Navigation includeHeader backgroundColor="transparent">
      <Flex flexDir="column">
        <Flex justifyContent="space-between">
          <Box>
            <BackButton color="black" />
            <Text color="black" mb="1em" fontWeight="bold">
              Contact
            </Text>
          </Box>
          {/* <CreditsCount availableCredits={user?.credits ?? 0} /> */}
        </Flex>
      </Flex>
      <Box
        backdropFilter="blur(4px)"
        background="#ffffff77"
        padding="2rem 1rem"
        borderRadius="12px"
        mt="2rem"
      >
        <Text textAlign="center">You can reach our team at this adress:</Text>
        <Text decoration="underline" textAlign="center" mt="1rem">
          <a href="mailto:hello@ministudio.ai">hello@ministudio.ai</a>
        </Text>
      </Box>
    </Navigation>
  )
}
