import { Icon, IconProps } from "@chakra-ui/react"

export default function PencilIcon(props: IconProps) {
  return (
    <Icon
      width="32"
      height="32"
      viewBox="-3 -3 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31.681 7.63108L24.3689 0.318955C23.9437 -0.106318 23.2548 -0.106318 22.8295 0.318955L2.817 20.3311L2.86106 20.3751L2.79193 20.3565L0.0543352 30.5728L0.0559521 30.5732C-0.068962 30.9512 0.0143141 31.3833 0.315482 31.6841C0.647778 32.0164 1.13935 32.0855 1.54158 31.8988L1.54239 31.9024L11.6596 29.1915L11.6532 29.1676L11.6685 29.183L31.681 9.17047C32.1063 8.7452 32.1063 8.05635 31.681 7.63108ZM3.38416 28.5916L5.01007 22.5241L9.452 26.9661L3.38416 28.5916Z"
        fill="currentColor"
      />
    </Icon>
  )
}
