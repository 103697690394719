import Creation from "../../../../../data/models/Creation"
import UserData from "../../../../../data/models/UserData"

export function openNewWindow(link: string) {
  window.open(link, "_blank")
}

export function getFrameShopLink(
  creation: Creation,
  variant: string,
  user: UserData
) {
  const selectedImageUrl = encodeURIComponent(creation.selectedImage ?? "")
  const characterOriginalUrl = encodeURIComponent(
    creation.originalImagePath ?? ""
  )
  return `https://shop.ministudio.ai/cart/${variant}:1?storefront=true&characterName=${creation.title}&creationId=${creation.id}&characterSelectedUrl=${selectedImageUrl}&userId=${user.id}&characterOriginalUrl=${characterOriginalUrl}`
}
