import { ChakraProvider } from "@chakra-ui/react"
import ReactDOM from "react-dom/client"
import App from "./App"
import { LoadingProvider } from "./context/LoadingContext"
import { SegmentProvider } from "./context/SegmentContext"
import { TextToSpeechProvider } from "./context/TextToSpeechContext"
import { UserContextProvider } from "./context/userContext"
import "./index.css"
import customTheme from "./presentation/chakra-ui/customTheme"
import * as serviceWorker from "./serviceWorker"

serviceWorker.register()
const root = ReactDOM.createRoot(document.getElementById("root")!)
root.render(
  <ChakraProvider theme={customTheme}>
    <UserContextProvider>
      <LoadingProvider>
        <SegmentProvider>
          <TextToSpeechProvider>
            <App />
          </TextToSpeechProvider>
        </SegmentProvider>
      </LoadingProvider>
    </UserContextProvider>
  </ChakraProvider>
)
