import { useMemo, useState } from "react"
import ChooseDrawing from "./ChooseDrawing"

import Creation from "../../../../data/models/Creation"
import UserData from "../../../../data/models/UserData"
import ConfirmDrawing from "./ConfirmDrawing"
import OpenGift from "./OpenGift"
import { useNavigate } from "react-router-dom"
import { LOCAL_STORAGE_CREATION_INDEX } from "../../../components/Creations/GlobalSettings"
import { useUserContext } from "../../../../context/userContext"

export default function ChooseCreationPage({
  creation,
  user,
  setGiftOpen,
  setSelectedImage,
}: {
  creation: Creation
  user: UserData | undefined
  setGiftOpen: (creation: Creation) => any
  setSelectedImage: (creation: Creation, image: string) => any
}) {
  const navigate = useNavigate()
  const [image, setImage] = useState(null as null | string)
  const { userCreations } = useUserContext()
  const creations = useMemo(
    () => userCreations.filter((c) => c.questId == null),
    [userCreations]
  )

  if (!creation.isOpened)
    return (
      <OpenGift
        creation={creation}
        user={user}
        onOpen={() => setGiftOpen(creation)}
      />
    )

  if (image)
    return (
      <ConfirmDrawing
        image={image}
        onConfirm={() =>
          setSelectedImage(creation, image).then(() => {
            if (creation.questId) {
              navigate(`/weeklyTheme/${creation.questId}`)
            } else {
              localStorage.setItem(
                `${user?.id}/${LOCAL_STORAGE_CREATION_INDEX}`,
                creations.findIndex((c) => c.id === creation.id).toString()
              )
            }
          })
        }
        onCancel={() => setImage(null)}
        creation={creation}
      />
    )

  return <ChooseDrawing creation={creation} onSelect={(str) => setImage(str)} />
}
