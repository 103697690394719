import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react"
import backgroundImg from "../../assets/images/homepage/story/quest-bg.png"
import tv from "../../assets/images/homepage/story/tv.png"
import youmnaVideo from "../../assets/images/homepage/story/video-youmna.mp4"
import ResponsiveContainer from "../../components/Wrapper/ResponsiveContainer"
import Footer from "./Footer"
import { useNavigate } from "react-router-dom"

export default function MinistudioStory() {
  const navigate = useNavigate()

  return (
    <Box position="relative" h={["1500px", "1500px", "1500px", "1600px", "1600px", "1400px"]} minH="fit-content" w="100%" mt="-1px">
      <Image
        src={backgroundImg}
        position="absolute"
        top="0"
        left="0"
        h="100%"
        w="100%"
        objectFit="cover"
        zIndex={-1}
      />
      <Box
        position="absolute"
        top="0%"
        left="0"
        w="100%"
        height="25%"
        bg="linear-gradient(0, rgba(0, 0, 0, 0.00) 0.08%, #8fbad1 100%)"
        zIndex={-1}
      />
      <ResponsiveContainer
        width={["100%", "100%", "600px", "1000px", "1200px", "1400px"]}
        minH=""
        display="flex"
        flexDirection="column"
        position="relative"
      >
        <Heading
          color="white"
          textAlign="center"
          mt="200px"
          fontSize={["30px", "32px", "38px", "50px", "60px"]}
        >
          The story of Mini Studio
        </Heading>
        <Flex
          mt="4rem"
          gap="1rem"
          flexDir={["column", "column", "column", "column", "column", "row"]}
        >
          <Box position="relative" minW="40%" w="fit-content" mx="auto">
            <Image src={tv} />
            <video
              src={youmnaVideo}
              playsInline
              style={{
                objectFit: "cover",
                position: "absolute",
                top: "23%",
                left: "8%",
                borderRadius: "8px",
                height: "60%",
                width: "85%",
              }}
              autoPlay
              muted
              loop
              disablePictureInPicture
            />
          </Box>
          <Text
            color="white"
            fontSize={["17px", "17px", "17px", "30px"]}
            alignSelf="center"
            mt={["1rem", "1rem", "1rem", "1rem", "1rem", "6rem"]}
            textAlign={[
              "center",
              "center",
              "center",
              "center",
              "center",
              "left",
            ]}
          >
            Our founder Youmna grew up in Beirut. Her uncle turned her imaginary
            friends into the stars of a hit TV show for kids, also called Mini
            Studio. It aired daily for 25 years and brought joy, colors and hope
            to millions of families. This made her see that anything you imagine
            can be made real, no matter the circumstances you’re in. Inspired by
            her own story, and with her incredible cofounders Marcella and
            Fabrice, they now want to give kids of the world the gift of
            bringing their imagination to life.
          </Text>
        </Flex>
      </ResponsiveContainer>
      <Box position="absolute" bottom="0" left="0" w="100%">
        <Footer
          onSignup={() => navigate("/sign-up")}
          onLogin={() => navigate("/login")}
        />
      </Box>
    </Box>
  )
}
