import { Box, Button, Flex, Grid, Image, Spinner, Text } from "@chakra-ui/react"
import { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useUserContext } from "../../../context/userContext"
import Creation from "../../../data/models/Creation"
import { useQuery } from "../../../utilities/hooks"
import whiteMonster from "../../assets/images/white-monster.png"
import Button3d from "../../components/Buttons/Button3d"
import LoadingScreen from "../../components/LoadingScreen"
import FullscreenCarousel from "../../components/Sliders/FullscreenCarousel"
import Background from "../../components/Wrapper/Background"
import useWaitingStories from "./useWaitingStories"
import { SmallCloseIcon } from "@chakra-ui/icons"
import CreationService from "../../../service/CreationService"

function CreationStatus({ creation }: { creation?: Creation }) {
  const [show, setShow] = useState(true)
  const ready = creation?.generativeAiResults?.length === 4
  const navigate = useNavigate()

  useEffect(() => {
    if (creation && !ready)
      setTimeout(() => {
        setShow(false)
      }, 5000)
    if (ready) setShow(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creation])

  return (
    <Flex
      position="fixed"
      top="1rem"
      left="50%"
      transform={
        creation && show
          ? "translate(-50%, 0)"
          : "translate(-50%, calc(-100% - 1rem))"
      }
      bg={ready ? "#4AD970" : "secondary.500"}
      zIndex={3}
      w="100%"
      maxW="450px"
      borderRadius="12px"
      boxShadow="0 0 4px #0000007f"
      minH="fit-content"
      height="60px"
      transition="400ms"
    >
      <Image
        src={whiteMonster}
        h="100%"
        bg={
          ready
            ? "linear-gradient(180deg, #4DF3B7 0%, #46C232 100%)"
            : "linear-gradient(180deg, #594DF3 0%, #9087F5 100%)"
        }
        borderRadius="12px"
      />
      <Text
        color="white"
        textAlign="center"
        placeSelf="center"
        w="100%"
        p="1rem 0"
        fontSize={["15px", "20px"]}
        fontWeight="bold"
      >
        {ready ? "Your art is ready!" : "Read while your art is generating"}
      </Text>
      {!ready && (
        <Spinner
          minW="20px"
          minH="20px"
          h="20px"
          w="20px"
          placeSelf="center"
          color="white"
          mr="1rem"
        />
      )}
      {ready && (
        <Button3d
          mt="4px"
          mr="4px"
          backgroundColor="#32a733"
          bg3dColor="#0f320f"
          onClick={async () => {
            await CreationService.setGiftOpen(creation)
            navigate(`/creations/${creation.id}`)
          }}
        >
          Open now!
        </Button3d>
      )}
    </Flex>
  )
}

export default function WaitingStoriesPage() {
  const query = useQuery()
  const { waitingStoryId } = useParams()
  const creationId = query.get("waitingFor")
  const { stories } = useWaitingStories()
  const { userCreations } = useUserContext()
  const creation = useMemo(() => {
    return userCreations.find((c) => c.id === creationId)
  }, [userCreations, creationId])
  const navigate = useNavigate()
  const [indexMobile, setIndexMobile] = useState(0)
  const [indexDesktop, setIndexDesktop] = useState(0)

  const story = stories.find((story) => story._key === waitingStoryId)
  if (!story) return <LoadingScreen />

  return (
    <>
      <CreationStatus creation={creation} />
      <Button
        onClick={() => navigate(-1)}
        w="fit-content"
        h="50px"
        p="16px"
        gap="0.25rem"
        variant="transparent"
        position="fixed"
        top="1rem"
        right="1rem"
        bg="rgba(0,0,0,0.4)"
        zIndex={2}
      >
        <SmallCloseIcon
          border="solid 2px white"
          borderRadius="full"
          w="auto"
          h="100%"
        />
        <Text fontWeight="bold" fontSize="18px" color="white" m="auto">
          Close
        </Text>
      </Button>

      <Background display={["block", "block", "none"]}>
        <FullscreenCarousel
          onChangeIndex={setIndexMobile}
          objectFit="cover"
          images={story.panels.map((panel) => ({
            imageSrc: panel.panelImageUrlMobile,
            cardContent: (
              <Flex
                position="absolute"
                bottom="0"
                left="0"
                w="100%"
                bg="linear-gradient(180deg, #00000000 0%, #0000007f 1rem)"
                p="1rem"
                pb="100px"
              >
                <Text textAlign="center" color="white" mx="auto" maxW="600px">
                  {panel.panelText}
                </Text>
              </Flex>
            ),
          }))}
        />
        <Grid
          autoFlow="column"
          position="fixed"
          bottom="0"
          left="0"
          zIndex={2}
          w="100%"
          gap="2px"
        >
          {story.panels.map((_, i) => (
            <Box
              key={i}
              h="4px"
              bg={i === indexMobile ? "secondary.500" : "white"}
              w="100%"
              transition="200ms"
            ></Box>
          ))}
        </Grid>
      </Background>
      <Background display={["none", "none", "block"]} position="relative">
        <FullscreenCarousel
          onChangeIndex={setIndexDesktop}
          objectFit="cover"
          images={story.panels.map((panel) => ({
            imageSrc: panel.panelImageUrlDesktop,
            cardContent: (
              <Flex
                position="absolute"
                bottom="0"
                left="0"
                w="100%"
                bg="linear-gradient(180deg, #00000000 0%, #0000007f 1rem)"
                pt="1rem"
                pb={["100px", "100px", "120px", "200px"]}
              >
                <Text textAlign="center" color="white" mx="auto" maxW="600px">
                  {panel.panelText}
                </Text>
              </Flex>
            ),
          }))}
        />
        <Flex
          position="absolute"
          color="white"
          bg="#252525"
          p="0.5rem 1rem"
          borderRadius="full"
          bottom="40px"
          right="80px"
        >
          {indexDesktop + 1} / {story.panels.length}
        </Flex>
      </Background>
    </>
  )
}
