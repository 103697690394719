import { SanityClient, createClient } from "@sanity/client"
import React, { FC, createContext, useContext } from "react"

const client = createClient({
  projectId: "99notztd",
  dataset: "production",
  apiVersion: "2022-05-10",
  token: process.env.SANITY_TOKEN,
  useCdn: true,
})

interface ContextProps {
  sanityClient: SanityClient
}

interface ProviderProps {
  children: JSX.Element
}

const SanityContext = createContext<ContextProps>({
  sanityClient: client,
})

export const SanityProvider: FC<ProviderProps> = ({ children }) => {
  return (
    <SanityContext.Provider
      value={{
        sanityClient: client,
      }}
    >
      {children}
    </SanityContext.Provider>
  )
}

export const useSanity = (): ContextProps => useContext(SanityContext)
