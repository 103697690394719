import { Box, Flex, Image } from "@chakra-ui/react"
import BackButton from "./Buttons/BackButton"
import { CloseIcon } from "@chakra-ui/icons"

export default function ZoomedImage({
  src,
  isOpen,
  onClose,
}: {
  src: string
  isOpen: boolean
  onClose: () => void
}) {
  if (!isOpen) {
    return null
  }

  return (
    <Flex position="fixed" zIndex={10} top="0" left="0" w="100%" height="100%">
      <Box
        position="fixed"
        left="0"
        top="0"
        bg="rgba(0,0,0,0.6)"
        w="100%"
        height="100%"
        zIndex={-1}
        onClick={onClose}
        cursor="pointer"
      />
      <Box position="relative" m="auto" maxW="80%" maxH="80%">
        <Image src={src} zIndex={1} />
        <BackButton
          position="absolute"
          top="1rem"
          left="1rem"
          buttonBg
          icon={<CloseIcon m="auto" />}
          color="white"
          onClick={onClose}
        />
      </Box>
    </Flex>
  )
}
