import { defineStyleConfig } from "@chakra-ui/react"

export const Text = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    fontWeight: "normal",
    fontFamily: "GT Walsheim Pro",
  },
  sizes: {
    "2xs": {
      fontSize: "0.6rem",
    },
    xs: {
      fontSize: "0.8rem",
    },
    sm: {
      fontSize: "1rem",
    },
    md: {
      fontSize: "20px",
    },
    lg: {
      fontSize: "2rem",
    },
  },
  // Two variants: outline and solid
  variants: {
    blue: {
      color: "#004AAD",
    },
    red: {
      color: "#FF5533",
    },
    black: {
      color: "black",
    },
    solid: {
      color: "white",
    },
    error: {
      color: "red",
    },
    raleway: {
      fontFamily: "Raleway",
    },
    cartoon: {
      fontFamily: "ShantellSans",
    },
    coverTitle: {
      fontFamily: "PlayfairDisplay",
      fontWeight: "black",
      textShadow:
        "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 8px rgba(0, 0, 0, 0.9), 0.5px 1px 5px #000000",
      fontSize: "24px",
    },
    coverAuthor: {
      fontFamily: "Raleway",
      textShadow:
        "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 8px rgba(0, 0, 0, 0.9), 0.5px 1px 5px #000000",
      fontSize: "24px",
    },
    bluesSmile: {
      fontFamily: "Blues Smile",
      fontSize: "16px"
    },
  },
  // The default size and variant values
  defaultProps: {
    size: "md",
    variant: "black",
  },
})
