import { useEffect } from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { useSegment } from "./context/SegmentContext"
import { useUserContext } from "./context/userContext"
import UserData from "./data/models/UserData"
import ProtectedRoute from "./presentation/components/ProtectedRoute"
import Changelog from "./presentation/pages/Changelog/Changelog"
import Chat from "./presentation/pages/Chat/Chat"
import ChatPage from "./presentation/pages/Chat/ChatPage"
import Community from "./presentation/pages/Community/Community"
import CommunityZoom from "./presentation/pages/Community/Creations/CommunityZoom"
import Draw from "./presentation/pages/Create/Draw"
import CreateWebtoonPage from "./presentation/pages/CreateWebtoon/CreateWebtoonPage"
import WebtoonFail from "./presentation/pages/CreateWebtoon/PaymentStatus/WebtoonFail"
import WebtoonSuccess from "./presentation/pages/CreateWebtoon/PaymentStatus/WebtoonSuccess"
import CreationPage from "./presentation/pages/Creations/Creation/CreationPage"
import CreationPrint from "./presentation/pages/Creations/Creation/Print/CreationPrint"
import CreationPrintFrame from "./presentation/pages/Creations/Creation/Print/CreationPrintFrame"
import CreationPrintMug from "./presentation/pages/Creations/Creation/Print/CreationPrintMug"
import CreationPrintRug from "./presentation/pages/Creations/Creation/Print/CreationPrintRug"
import CreationPrintTotebag from "./presentation/pages/Creations/Creation/Print/CreationPrintTotebag"
import CreationPrintTshirt from "./presentation/pages/Creations/Creation/Print/CreationPrintTshirt"
import MyCreations from "./presentation/pages/Creations/MyCreations"
import WebtoonPage from "./presentation/pages/Creations/Webtoon/WebtoonPage"
import WebtoonPanelPage from "./presentation/pages/Creations/Webtoon/WebtoonPanelPage"
import WebtoonSuccessPage from "./presentation/pages/Creations/Webtoon/WebtoonSuccess/WebtoonSuccessPage"
import Dashboard from "./presentation/pages/Dashboard/Dashboard"
import DashboardPending from "./presentation/pages/Dashboard/DashboardPending"
import Home from "./presentation/pages/Home/Home"
import HubPage from "./presentation/pages/Hub/HubPage"
import InviteEmails from "./presentation/pages/InviteEmails/InviteEmails"
import Login from "./presentation/pages/Login/Login"
import Onboarding from "./presentation/pages/Onboarding/Onboarding"
import ContactPage from "./presentation/pages/Settings/Contact/ContactPage"
import PaymentSuccess from "./presentation/pages/Settings/Payment/Success/PaymentSuccess"
import ProfileSettings from "./presentation/pages/Settings/ProfileSettings/ProfileSettings"
import Settings from "./presentation/pages/Settings/Settings"
import SignUp from "./presentation/pages/SignUp/SignUp"
import SignUpInfo from "./presentation/pages/SignUp/SignUpInfo"
import Stories from "./presentation/pages/Stories/Stories"
import StoriesBuySuccess from "./presentation/pages/Stories/StoriesBuy/StoriesBuySuccess"
import StoryPage from "./presentation/pages/Stories/StoryPage/StoryPage"
import Upload from "./presentation/pages/Upload/Upload"
import WaitingStoriesPage from "./presentation/pages/WaitingStories/WaitingStoriesPage"
import WeeklyTheme from "./presentation/pages/WeeklyTheme/WeeklyTheme"
import WeeklyThemeList from "./presentation/pages/WeeklyTheme/WeeklyThemeList"
import Mission from "./presentation/pages/Mission/Mission"
import Privacy from "./presentation/pages/Privacy/Privacy"

function App() {
  const { load, identify } = useSegment()
  const { user } = useUserContext()
  // set --vh variable to 1% of innerscreen size
  useEffect(() => {
    load()
    const setVhCSSVar = () => {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      )
    }
    setVhCSSVar()
    window.addEventListener("resize", setVhCSSVar)
    return () => window.removeEventListener("resize", setVhCSSVar)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user && user.id) {
      identify(user.id, {
        name: user.childName,
        email: user.email,
        childName: user.childName,
        credits: user.credits,
        fbUser: user.fbUser,
        admin: user.admin,
        pronouns: user.pronouns,
        claimedDailyReward: user,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id])

  const manageNormalRouteAccess = (user: UserData | null) => {
    if (user === null)
      return `/sign-up?redirectTo=${encodeURIComponent(
        window.location.pathname
      )}`
    else if (!user.childName)
      return `/sign-up-info?redirectTo=${encodeURIComponent(
        window.location.pathname
      )}`
    else if (user.onboarded === false) return "/onboarding"
    return null
  }
  const managePartialAccountInfo = (user: UserData | null) => {
    if (user === null) return "/"
    else if (!user.childName) return null
    else if (user.onboarded === false) return "/onboarding"
    return "/app"
  }
  const manageOnboardingAccess = (user: UserData | null) => {
    if (user === null) return "/"
    else if (!user.childName)
      return `/sign-up-info?redirectTo=${encodeURIComponent(
        window.location.pathname
      )}`
    // else if (user.onboarded === true) return "/app"
    return null
  }

  const manageUnauthedAccess = (user: UserData | null) => {
    if (user === null) return null
    else if (!user.childName)
      return `/sign-up-info?redirectTo=${encodeURIComponent(
        window.location.pathname
      )}`
    else if (user.onboarded === false) return "/onboarding"
    return "/app"
  }

  const manageAdminAccess = (user: UserData | null) => {
    if (!user)
      return `/login?redirectTo=${encodeURIComponent(window.location.pathname)}`
    if (user?.admin === true) return null
    return "/"
  }

  // const manageChatbotAccess = (user: UserData | null) => {
  //   return null
  // }

  return (
    <div className="App">
      <BrowserRouter basename="/">
        <Routes>
        <Route
            path="/"
            element={
              <ProtectedRoute redirectionManager={manageUnauthedAccess}>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/mission"
            element={
              <ProtectedRoute redirectionManager={manageUnauthedAccess}>
                <Mission />
              </ProtectedRoute>
            }
          />
          <Route
            path="/privacy"
            element={
              <ProtectedRoute redirectionManager={manageUnauthedAccess}>
                <Privacy />
              </ProtectedRoute>
            }
          />
          <Route
            path="/changelog"
            element={
              <ProtectedRoute redirectionManager={manageUnauthedAccess}>
                <Changelog />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings/contact"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <ContactPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings/profile"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <ProfileSettings />
              </ProtectedRoute>
            }
          />
          {/* <Route
            path="/settings/payment"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <Payment />
              </ProtectedRoute>
            }
          /> */}
          <Route
            path="/settings/payment/success"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <PaymentSuccess />
              </ProtectedRoute>
            }
          />
          <Route
            path="/upload"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <Upload />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute redirectionManager={manageAdminAccess}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard-pending"
            element={
              <ProtectedRoute redirectionManager={manageAdminAccess}>
                <DashboardPending />
              </ProtectedRoute>
            }
          />
          <Route
            path="/invite-emails"
            element={
              <ProtectedRoute redirectionManager={manageAdminAccess}>
                <InviteEmails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/login"
            element={
              <ProtectedRoute redirectionManager={manageUnauthedAccess}>
                <Login />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sign-up"
            element={
              <ProtectedRoute redirectionManager={manageUnauthedAccess}>
                <SignUp />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sign-up-info"
            element={
              <ProtectedRoute redirectionManager={managePartialAccountInfo}>
                <SignUpInfo />
              </ProtectedRoute>
            }
          />
          <Route
            path="/app"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <HubPage />
              </ProtectedRoute>
            }
          />
          <>
            <Route
              path="/draw"
              element={
                <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                  <Draw />
                </ProtectedRoute>
              }
            />
            <Route
              path="/draw/:themeId"
              element={
                <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                  <Draw />
                </ProtectedRoute>
              }
            />
          </>

          <Route
            path="/creations"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <MyCreations />
              </ProtectedRoute>
            }
          />
          <Route
            path="/creations/:creationId"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <CreationPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/creations/print"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <CreationPrint />
              </ProtectedRoute>
            }
          >
            <Route path="/creations/print/:creationId" />
          </Route>
          <Route
            path="/creations/print/frame"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <CreationPrintFrame />
              </ProtectedRoute>
            }
          >
            <Route path="/creations/print/frame/:creationId" />
          </Route>
          <Route
            path="/creations/print/rug"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <CreationPrintRug />
              </ProtectedRoute>
            }
          >
            <Route path="/creations/print/rug/:creationId" />
          </Route>
          <Route
            path="/creations/print/totebag"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <CreationPrintTotebag />
              </ProtectedRoute>
            }
          >
            <Route path="/creations/print/totebag/:creationId" />
          </Route>
          <Route
            path="/creations/print/mug"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <CreationPrintMug />
              </ProtectedRoute>
            }
          >
            <Route path="/creations/print/mug/:creationId" />
          </Route>
          <Route
            path="/creations/print/t-shirt"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <CreationPrintTshirt />
              </ProtectedRoute>
            }
          >
            <Route path="/creations/print/t-shirt/:creationId" />
          </Route>
          <Route
            path="/creations/webtoon/:userStoryPackId/:webtoonId"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <WebtoonPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/creations/webtoon/:userStoryPackId/:webtoonId/:panelPosition"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <WebtoonPanelPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/creations/webtoon/:userStoryPackId/:webtoonId/success"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <WebtoonSuccessPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/weeklyTheme/:themeId"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <WeeklyTheme />
              </ProtectedRoute>
            }
          />
          <Route
            path="/weeklyTheme/"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <WeeklyThemeList />
              </ProtectedRoute>
            }
          />

          <Route
            path="/community"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <Community />
              </ProtectedRoute>
            }
          />
          <Route
            path="/community/:creationId"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <CommunityZoom />
              </ProtectedRoute>
            }
          />
          <Route
            path="/onboarding"
            element={
              <ProtectedRoute redirectionManager={manageOnboardingAccess}>
                <Onboarding />
              </ProtectedRoute>
            }
          />
          <Route
            path="/stories"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <Stories />
              </ProtectedRoute>
            }
          />
          {/* <Route
            path="/stories/buy/:storyPackId"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <StoriesBuy />
              </ProtectedRoute>
            }
          /> */}
          <Route
            path="/stories/buy/:storyPackId/success"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <StoriesBuySuccess />
              </ProtectedRoute>
            }
          />
          <Route
            path="/stories/:storyId"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <StoryPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create-webtoon/:storyId"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <CreateWebtoonPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create-webtoon/:storyId/success"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <WebtoonSuccess />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create-webtoon/:storyId/failure"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <WebtoonFail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/chat"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <Chat />
              </ProtectedRoute>
            }
          />
          <Route
            path="/chat/:creationId"
            element={
              <ProtectedRoute redirectionManager={manageNormalRouteAccess}>
                <ChatPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/waiting-story/:waitingStoryId"
            element={<WaitingStoriesPage />}
          />
          <Route path="*" element={<Navigate to={"/"} replace />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
