import { useEffect, useState } from "react"
import { useSanity } from "../../../context/SanityContext"
import { IS_PROD } from "../../components/Creations/GlobalSettings"

export type WaitingStory = {
  imageUrl: string
  title: string
  subtitle: string
  previewUrlMobile: string
  PreviewUrlDesktop: string
  panels: {
    panelText: string
    panelImageUrlMobile: string
    panelImageUrlDesktop: string
  }[]
  isVisibleProd: boolean
  isVisibleTest: boolean
  isVisibleDev: boolean
  _key: string
}

export default function useWaitingStories() {
  const { sanityClient } = useSanity()
  const [stories, setStories] = useState<WaitingStory[]>([])

  useEffect(() => {
    sanityClient
      .fetch<{ modules: WaitingStory[] }[]>('*[_type == "waitingStories"]')
      .then((result) => {
        if (result.length === 0) return
        setStories(
          result[0].modules.filter(
            (module) =>
              (module.isVisibleProd && IS_PROD) ||
              (!IS_PROD && module.isVisibleDev)
          )
        )
      })
  }, [sanityClient])
  return { stories }
}
