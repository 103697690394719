import { ButtonProps, useDisclosure } from "@chakra-ui/react"
import BrushIcon from "../Icons/BrushIcon"
import CreatePopup from "../Popup/CreatePopup"
import Button3d from "./Button3d"

export default function CreateNewButton({ ...props }: ButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <CreatePopup isOpen={isOpen} onClose={onClose} />
      <Button3d size="sm" fontSize="12px" onClick={onOpen} {...props}>
        <BrushIcon mr="0.25rem" />
        Create Mini
      </Button3d>
    </>
  )
}
