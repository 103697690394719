import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react"
import SlidingPopup from "./SlidingPopup"
import BrushIcon from "../Icons/BrushIcon"
import CloseIcon from "../Icons/CloseIcon"
import ActionButton from "../Buttons/ActionButton"
import { useNavigate } from "react-router-dom"

function CreatePopupHeader({ onClose }: { onClose: () => any }) {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <BrushIcon
        color="#86E17F"
        width="40px"
        height="40px"
        padding="4px"
        border="solid 1px rgba(0,0,0,0.1)"
        borderRadius="full"
      />
      <Heading size="sm">Create</Heading>
      <Button
        p="0"
        color="#777"
        variant="ghostBlack"
        onClick={onClose}
        fontSize="12px"
      >
        <CloseIcon width="24px" height="24px" />
      </Button>
    </Flex>
  )
}

export default function CreatePopup({
  isOpen,
  onClose,
  onClickUpload,
  onClickDescribe,
  onClickDraw,
}: {
  isOpen: boolean
  onClose: () => any
  onClickUpload?: () => any
  onClickDraw?: () => any
  onClickDescribe?: () => any
}) {
  const navigate = useNavigate()

  return (
    <SlidingPopup
      id="create-popup"
      onClose={onClose}
      show={isOpen}
      header={<CreatePopupHeader onClose={onClose} />}
      flexDir="row"
      justifyContent="space-evenly"
      mt="1rem"
      mb="2rem"
      textAlign="center"
    >
      <Box>
        <ActionButton
          buttonType="upload"
          iconSize="48px"
          onClick={onClickUpload ?? (() => navigate("/upload"))}
        />
        <Text fontSize="18px" fontWeight="light">
          Upload
        </Text>
      </Box>
      <Box>
        <ActionButton
          buttonType="draw"
          iconSize="48px"
          onClick={onClickDraw ?? (() => navigate("/draw"))}
        />
        <Text fontSize="18px" fontWeight="light">
          Draw
        </Text>
      </Box>
      <Box>
        <ActionButton
          buttonType="describe"
          iconSize="48px"
          onClick={onClickDescribe ?? (() => navigate("/upload?describe=true"))}
        />
        <Text fontSize="18px" fontWeight="light">
          Describe
        </Text>
      </Box>
    </SlidingPopup>
  )
}
