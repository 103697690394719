import { Box, Text } from "@chakra-ui/react"
import Story from "../../../../data/models/Story"
import { Webtoon } from "../../../../data/models/Webtoon"
import StoryBookCard from "../../../components/Stories/StoryBookCard"

export default function WebtoonUnavailableStories({
  stories,
  webtoons,
  lightMode = false,
}: {
  stories: Story[]
  webtoons: Webtoon[]
  lightMode?: boolean
}) {
  return (
    <>
      {stories
        .filter(
          (story) =>
            !webtoons.some((w) => w.storyId === story.id) && story.comingSoon
        )
        .sort((a, b) => a.order - b.order)
        .map((story) => {
          return (
            <Box key={story.id}>
              <StoryBookCard
                lightMode={lightMode}
                story={story}
                opacity="0.3"
                cursor="not-allowed"
                dontPlayVideo
              />
              <Text
                size="xs"
                fontWeight="light"
                color={lightMode ? "black" : "white"}
              >
                {"Coming soon!"}
              </Text>
            </Box>
          )
        })}
    </>
  )
}
