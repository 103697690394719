import { Icon, IconProps } from "@chakra-ui/react"

export default function GoogleIcon(props: IconProps) {
  return (
    <Icon
      width="16px"
      height="16px"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.6977 6.5679H14.171V6.54077H8.28644V9.15612H11.9816C11.4425 10.6786 9.99394 11.7715 8.28644 11.7715C6.11994 11.7715 4.3634 10.0149 4.3634 7.84845C4.3634 5.68195 6.11994 3.92541 8.28644 3.92541C9.28648 3.92541 10.1963 4.30268 10.889 4.91892L12.7384 3.06954C11.5707 1.98122 10.0086 1.31006 8.28644 1.31006C4.67561 1.31006 1.74805 4.23762 1.74805 7.84845C1.74805 11.4593 4.67561 14.3868 8.28644 14.3868C11.8973 14.3868 14.8248 11.4593 14.8248 7.84845C14.8248 7.41005 14.7797 6.98211 14.6977 6.5679Z"
        fill="#FFC107"
      />
      <path
        d="M2.50391 4.80515L4.65209 6.38058C5.23336 4.94148 6.64107 3.92541 8.28842 3.92541C9.28846 3.92541 10.1983 4.30268 10.891 4.91892L12.7404 3.06954C11.5727 1.98122 10.0106 1.31006 8.28842 1.31006C5.77702 1.31006 3.59909 2.72791 2.50391 4.80515Z"
        fill="#FF3D00"
      />
      <path
        d="M8.28759 14.3864C9.97646 14.3864 11.511 13.7401 12.6713 12.689L10.6476 10.9766C9.96912 11.4926 9.14002 11.7717 8.28759 11.771C6.58696 11.771 5.14296 10.6867 4.59896 9.17334L2.4668 10.8161C3.5489 12.9336 5.74645 14.3864 8.28759 14.3864Z"
        fill="#4CAF50"
      />
      <path
        d="M14.6983 6.56766H14.1717V6.54053H8.28711V9.15588H11.9823C11.7244 9.88047 11.2599 10.5136 10.6462 10.9772L10.6471 10.9765L12.6708 12.6889C12.5276 12.819 14.8255 11.1174 14.8255 7.8482C14.8255 7.40981 14.7804 6.98187 14.6983 6.56766Z"
        fill="#1976D2"
      />
    </Icon>
  )
}
