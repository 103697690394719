import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react"
import { useUserContext } from "../../../../context/userContext"
import UserService from "../../../../service/UserService"
import { Pronouns } from "../../../../utilities/dateUtils"
import BackButton from "../../../components/Buttons/BackButton"
import PronounSelect from "../../../components/Select/PronounsSelect"
import Navigation from "../../../components/Wrapper/Navigation"

export default function ProfileSettings() {
  const toast = useToast()
  const { user, isLoading, updatePronouns } = useUserContext()

  const resetPassword = () => {
    if (user)
      UserService.resetPassword(user.email).then(() => {
        toast({
          title: "Password reset",
          description: "An email has been sent to your email address",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        })
      })
  }

  const changePronouns = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updatePronouns(e.currentTarget.value as Pronouns).then(() => {
      toast({
        title: "Pronouns updated!",
        description: "Your child's pronouns have been updated",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      })
    })
  }

  return (
    <Navigation includeHeader backgroundColor="transparent">
      <Flex flexDir="column">
        <Flex justifyContent="space-between">
          <Box>
            <BackButton color="black" />
            <Text color="black" mb="1em" fontWeight="bold">
              Profile info
            </Text>
          </Box>
        </Flex>
        <Box
          display="flex"
          flexDirection="column"
          overflowY="auto"
          flex="1"
          maxHeight={400}
          alignItems="center"
          width="90%"
          lineHeight="2em"
          bg="#ffffff77"
          padding="1rem"
          borderRadius="12px"
          w="100%"
          backdropFilter="blur(4px)"
        >
          <Text width="100%" textAlign="left" variant="black" size="sm">
            Username: <b>{user?.childName}</b>
          </Text>
          <Flex w="100%">
            <Text
              alignSelf="center"
              w="fit-content"
              whiteSpace="nowrap"
              textAlign="left"
              variant="black"
              size="sm"
              pr="1rem"
            >
              Pronouns:
            </Text>
            <PronounSelect
              maxW="150px"
              value={user?.pronouns}
              onChange={changePronouns}
              disabled={isLoading}
              bg="white"
            />
          </Flex>
          <Text width="100%" textAlign="left" variant="black" size="sm">
            Email: <b>{user?.email}</b>
          </Text>
          <Flex width="100%">
            <Text textAlign="left" variant="black" size="sm" mr="0.25em">
              {"Password: "}
            </Text>
            <Box>
              <Text width="100%" textAlign="left" variant="black" size="sm">
                {"●●●●●●●●●"}
              </Text>
              <Button
                size="sm"
                padding="0"
                variant="ghostBlack"
                onClick={resetPassword}
              >
                Reset password
              </Button>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Navigation>
  )
}
