import { Icon } from "@chakra-ui/react";

const BrushIcon: typeof Icon = (props) => (
    <Icon viewBox="-3 -3 26 26" {...props}>
        <path d="M3.8377 10.6872C2.75087 10.947 1.78239 11.5633 1.08682 12.4379C0.391254 13.3124 0.00869317 14.3948 0.000196947 15.5122V19.3997C-0.00150817 19.4789 0.012846 19.5577 0.0423952 19.6313C0.0719444 19.7049 0.116077 19.7717 0.172135 19.8277C0.228194 19.8838 0.295019 19.9279 0.368585 19.9575C0.442151 19.987 0.520936 20.0014 0.600197 19.9997L4.6502 19.9247C5.55501 19.9261 6.44121 19.6678 7.20347 19.1803C7.96573 18.6928 8.57199 17.9967 8.9502 17.1747C9.33828 16.4 9.49775 15.5308 9.40995 14.6688C9.32216 13.8069 8.99073 12.9877 8.45444 12.3072C7.91814 11.6266 7.19915 11.1129 6.38158 10.826C5.564 10.5391 4.68163 10.491 3.8377 10.6872Z" fill="currentColor" />
        <path d="M19.0128 0.574546C18.4883 0.160932 17.828 -0.0414891 17.1618 0.00708736C16.4956 0.0556638 15.8717 0.351723 15.4128 0.837046L7.43776 8.84955C7.32936 8.95948 7.26859 9.10766 7.26859 9.26205C7.26859 9.41643 7.32936 9.56462 7.43776 9.67455L10.2503 12.4995C10.3602 12.6079 10.5084 12.6687 10.6628 12.6687C10.8171 12.6687 10.9653 12.6079 11.0753 12.4995L19.1878 4.41205C19.4281 4.17061 19.6182 3.88387 19.7469 3.56846C19.8756 3.25304 19.9405 2.91521 19.9378 2.57455C19.9466 2.19267 19.8678 1.81383 19.7075 1.46714C19.5471 1.12044 19.3095 0.815093 19.0128 0.574546Z" fill="currentColor" />
    </Icon>
)

export default BrushIcon
