import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react"
import { Navigate, useNavigate, useSearchParams } from "react-router-dom"
import { useQuery } from "../../../../../utilities/hooks"
import CreditsDisplay from "../../../../components/CreditsDisplay"
import RewardLayout from "./RewardLayout"

function DefaultContinueButtons({
  navigate,
}: {
  navigate: ReturnType<typeof useNavigate>
}) {
  return (
    <>
      <Button variant="border" onClick={() => navigate("/")}>
        Explore
      </Button>
      <Button onClick={() => navigate("/upload")}>Upload a drawing</Button>
    </>
  )
}
function RedirectToButton({
  navigate,
  url,
}: {
  navigate: ReturnType<typeof useNavigate>
  url: string
}) {
  return <Button onClick={() => navigate(url)}>Continue</Button>
}

export default function PaymentSuccess() {
  const [searchParams] = useSearchParams()
  const quantity = searchParams.get("quantity")
  const navigate = useNavigate()
  const query = useQuery()
  const redirectTo = query.get("redirectTo")

  let qtty = 0;
  if (!quantity) return <Navigate to="/settings" replace />
  try {
    qtty = parseInt(quantity)
    if (isNaN(qtty) || qtty <= 0) return <Navigate to="/settings" replace />
  } catch {
    return <Navigate to="/settings" replace />
  }

  return (
    <RewardLayout>
      <Heading color="#3BC8A0" size="sm" textAlign="center">
        Payment successful!
      </Heading>
      <Box my="1rem">
        <CreditsDisplay credits={`+${qtty}`} starWithS={qtty > 1} />
      </Box>
      <Text size="sm" textAlign="center" mb="1rem">
        You have successfully bought {quantity} magic star(s)! <br />
        Go and use them to create your own magic creatures!
      </Text>
      <Flex gap="1rem" m="auto" flexDir="column">
        {redirectTo ? (
          <RedirectToButton navigate={navigate} url={redirectTo} />
        ) : (
          <DefaultContinueButtons navigate={navigate} />
        )}
      </Flex>
    </RewardLayout>
  )
}
