import { createContext, useContext, useState } from "react";
import React from "react";

export interface ContextProps {
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

const LoadingContext = createContext<ContextProps>({
  loading: false,
  setLoading: () => {},
});

export function LoadingProvider({ children }: {
  children: React.ReactNode
}) {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {children}
    </LoadingContext.Provider>
  );
}

export const useLoading = (): ContextProps => {
  return useContext(LoadingContext);
};
