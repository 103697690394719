import { Box, BoxProps, Button, Flex, Heading } from "@chakra-ui/react"
import ReactDOM from "react-dom"
import CloseIcon from "../Icons/CloseIcon"

export function SlidingPopupDefaultHeader({
  onClose,
  hideCloseButton,
  title,
}: {
  hideCloseButton?: boolean
  title?: string
  onClose: () => any
}) {
  return (
    <Flex
      alignItems="center"
      borderBottom={title ? "solid 1px" : undefined}
      borderColor="grey"
      mx="-1em"
      px="1em"
      pb="0.5em"
    >
      <Heading as="h3" size="sm" color="#1f1f1f" textAlign="center" w="100%">
        {title}
      </Heading>
      <Button
        display={hideCloseButton ? "none" : undefined}
        p="0"
        color="#777"
        variant="ghostBlack"
        onClick={onClose}
        fontSize="12px"
        h={title ? undefined : "20px"}
      >
        <CloseIcon />
      </Button>
    </Flex>
  )
}

/** Duration of the sliding animation in milliseconds */
export const SLIDING_POPUP_ANIMATION_DURATION = 250

export type SlidingPopupProps = {
  onClose: () => any
  show: boolean
  title?: string
  hideCloseButton?: boolean
  header?: React.ReactNode
  hideOverlay?: boolean
} & BoxProps

export function SlidingPopupComponent({
  onClose,
  show,
  title,
  hideCloseButton,
  header = (
    <SlidingPopupDefaultHeader
      onClose={onClose}
      hideCloseButton={hideCloseButton}
      title={title}
    />
  ),
  backgroundColor = "rgba(255, 255, 255, 0.95)",
  hideOverlay = false,
  ...props
}: SlidingPopupProps) {
  return (
    <>
      <Flex
        pointerEvents={show ? undefined : "none"}
        position="fixed"
        bottom="0"
        left="50%"
        maxW="100%"
        transform="translateX(-50%)"
        width={hideCloseButton ? "fit-content" : "100%"}
        height={hideCloseButton ? "fit-content" : "100%"}
        zIndex="15"
      >
        {!hideOverlay && (
          <Box
            display={show ? "block" : "none"}
            bg="rgba(0,0,0,0.2)"
            backdropFilter="blur(1px)"
            position="absolute"
            width="100%"
            h="100%"
            onClick={onClose}
            cursor="pointer"
          />
        )}
        <Box
          transform={show ? "translateY(0)" : "translateY(100%)"}
          transition={`${SLIDING_POPUP_ANIMATION_DURATION}ms all ease-in-out`}
          mt="auto"
          mx="auto"
          width="100%"
          maxW="700px"
          bg={backgroundColor}
          zIndex={1}
          borderRadius="20px 20px 0 0"
          p="1rem"
          boxShadow={show ? "0 0 100px #0000007f" : undefined}
          overflowY="auto"
          overflow="hidden"
        >
          {header}
          <Flex
            flexDir="column"
            mt={title ? "1rem" : "0"}
            mx="auto"
            maxH="80vh"
            {...props}
          />
        </Box>
      </Flex>
    </>
  )
}

export default function SlidingPopup({
  id,
  ...props
}: SlidingPopupProps & { id: string }) {
  return ReactDOM.createPortal(
    <SlidingPopupComponent id={id} {...props} />,
    document.body
  )
}
