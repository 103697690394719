import { Box, Button, Flex, Image, Text } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import Creation from "../../../data/models/Creation"
import printTeaser from "../../assets/images/print-teaser.png"

export default function PrintActionBlock({ creation }: { creation?: Creation }) {
  const navigate = useNavigate()

  return (
    <Flex
      background="#C36B97"
      borderRadius="20px"
      padding="1em"
      mt="0.5em"
      h="168px"
    >
      <Box width="60%" placeSelf="center" textAlign="left">
        <Text variant="solid" size="md" fontWeight="bold">
          Print your art
        </Text>
        <Text variant="solid" size="xs">
          Make a poster & more!
        </Text>
        <Button
          onClick={() => navigate(`/creations/print/${creation?.id ?? ''}`)}
          mt="1em"
          variant="white"
          size="sm"
        >
          Make a print
        </Button>
      </Box>
      <Flex
        width="40%"
        placeContent="flex-end"
        placeItems="end"
        position="relative"
      >
        <Image
          src={printTeaser}
          borderRadius="8px"
          objectFit={"cover"}
          height="100%"
          transform={"translateY(-20%)"}
          right="0px"
          top="-20px"
        />
      </Flex>
    </Flex>
  )
}
