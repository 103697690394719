// import {
//   collection,
//   getCountFromServer,
//   query,
//   where,
// } from "firebase/firestore"
// import { db } from "../data/apis/firebase/Firebase"

export default class CreditDAO {
  static async getAvailableCredits(uid: string): Promise<number> {
    // NOTE: disabled, no more credits
    // return await getCountFromServer(
    //   query(
    //     collection(db, 'users', uid, 'credits'),
    //     where("used", "==", false)
    //   )).then(snapshot => snapshot.data().count)
    return 1000
  }
}
