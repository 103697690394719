import { Box } from "@chakra-ui/react"
import { WebtoonMask } from "../../../../data/models/Webtoon"

export default function WebtoonMaskElem({ mask }: { mask: WebtoonMask }) {
  const x1 = mask.x1 * 100
  const w = (mask.x2 - mask.x1) * 100
  const y1 = mask.y1 * 100
  const h = (mask.y2 - mask.y1) * 100
  return (
    <>
      <Box position="absolute" bg="white" top="0" left="0" w="100%" h={`${mask.y1 * 100}%`} />
      <Box position="absolute" bg="white" top="0" left="0" w={`${mask.x1 * 100}%`} h="100%" />
      <Box position="absolute" bg="white" top="0" right="0" w={`${Math.abs(1 - mask.x2) * 100}%`} h="100%" />
      <Box position="absolute" bg="white" bottom="0" left="0" w="100%" h={`${Math.abs(1 - mask.y2) * 100}%`} />
      <Box position="absolute" border="solid 7px black" left={`${x1}%`} top={`${y1}%`} w={`${w}%`} h={`${h}%`} />
    </>
  )
}
