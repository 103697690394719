import { Icon, IconProps } from "@chakra-ui/react"

export default function DrawingAddIcon(props: IconProps) {
  return (
    <Icon
      width="48px"
      height="48px"
      viewBox="0 0 38 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34.0417 12.5418V17.8989C34.0417 17.8989 30.4763 17.9168 30.4583 17.8989V12.5418H25.0833C25.0833 12.5418 25.1013 8.97641 25.0833 8.9585H30.4583V3.5835H34.0417V8.9585H39.4167V12.5418H34.0417ZM28.6667 19.7085V14.3335H23.2917V8.9585H8.95833C6.9875 8.9585 5.375 10.571 5.375 12.5418V34.0418C5.375 36.0127 6.9875 37.6252 8.95833 37.6252H30.4583C32.4292 37.6252 34.0417 36.0127 34.0417 34.0418V19.7085H28.6667ZM8.95833 34.0418L14.3333 26.8752L17.9167 32.2502L23.2917 25.0835L30.4583 34.0418H8.95833Z"
        fill="currentColor"
      />
    </Icon>
  )
}
