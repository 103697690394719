import { Box, useToast } from "@chakra-ui/react"
import BackButton from "./Buttons/BackButton"
import Camera from "react-html5-camera-photo"

export default function FullscreenCamera({
  open,
  setOpen,
  onTakeImage,
}: {
  open: boolean
  setOpen: (open: boolean) => void
  onTakeImage: (image: string) => void
}) {
  const toast = useToast()
  if (!open) return null
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      height="100%"
      width="100%"
      display="flex"
      bg="black"
      zIndex="20"
      sx={{
        ".react-html5-camera-photo": {
          video: {
            width: "100%",
            maxHeight: "100%",
          },
          img: {
            width: "100%",
            maxHeight: "100%",
            objectFit: "contain",
          },
          width: "100%",
        },
      }}
    >
      <Box
        position="fixed"
        top="1rem"
        left="1rem"
        onClick={() => setOpen(false)}
        zIndex={1}
      >
        <BackButton color="white" buttonBg onClick={() => setOpen(false)} />
      </Box>
      <Camera
        sizeFactor={0.8}
        idealFacingMode="environment"
        onTakePhotoAnimationDone={(a) => {
          onTakeImage(a)
          setOpen(false)
        }}
        onCameraError={(e) => {
          setOpen(false)
          toast({
            title: "Couldn't open your camera",
            description:
              "If you have a camera and denied its access, please allow it in your browser settings",
            status: "error",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          })
        }}
      />
    </Box>
  )
}
