import { Box, Button, Flex, Image, SimpleGrid, Text } from "@chakra-ui/react"
import Creation from "../../../../data/models/Creation"
import BackButton from "../../../components/Buttons/BackButton"
import Background from "../../../components/Wrapper/Background"
import ResponsiveContainer from "../../../components/Wrapper/ResponsiveContainer"
import HeartIcon from "../../../components/Icons/HeartIcon"
import BackgroundImageBlurred from "../../../components/Wrapper/BackgroundImageBlurred"

export default function ChooseDrawing({
  creation,
  onSelect,
}: {
  creation: Creation
  onSelect: (url: string) => any
}) {
  return (
    <Background overflowY="auto">
      <BackgroundImageBlurred backgroundColor="transparent" />
      <ResponsiveContainer>
        <Flex
          alignItems="center"
          paddingTop="1em"
          justifyContent="space-between"
          py="2em"
          maxWidth="100%"
        >
          <BackButton color="white" buttonBg />
          <Text
            size="sm"
            color="black"
            bg="white"
            borderRadius="12px"
            p="1rem 1.5rem"
            fontWeight="bold"
            textAlign="center"
            boxShadow="0px 4px 6px 0px rgba(0, 0, 0, 0.21);"
          >
            Choose your Mini
          </Text>
          {/* Empty box to center text */}
          <Box width="60px" />
        </Flex>

        <Flex direction="column" alignItems="center">
          <Box width={"100%"} maxW={[400, 400, 500, 600]} position="relative">
            <SimpleGrid
              columns={2}
              borderRadius={[0, 0, "25px"]}
              overflow="hidden"
              mx="-1em"
            >
              {creation.generativeAiResults?.map((result, index) => {
                return (
                  <Box
                    onClick={() => onSelect(result)}
                    border={
                      creation.selectedImage === result
                        ? "2px solid #000"
                        : "none"
                    }
                    key={index.toString() + result}
                    margin="0"
                    cursor={"pointer"}
                    height={[200, 200, 250, 300]}
                    position="relative"
                  >
                    <Image
                      src={result}
                      height="100%"
                      width="100%"
                      objectFit="cover"
                      alt="Ministudio Ai Art"
                    />
                    <Button
                      position="absolute"
                      bottom="0.5rem"
                      right="0.5rem"
                      bg="primary.100"
                      border="solid 1px"
                      borderColor="primary.500"
                      color="primary.500"
                      variant="colored"
                      gap="0.5rem"
                    >
                      <HeartIcon w="24px" h="24px" />
                    </Button>
                  </Box>
                )
              })}
            </SimpleGrid>
          </Box>
        </Flex>
        {/* {creation.rating === undefined ? (
          <CreationRating
            onChoose={(rating) => CreationService.setRating(creation, rating)}
          />
        ) : (
          <Text
            mt="0.5em"
            size="md"
            variant="black"
            fontWeight="bold"
            textAlign="center"
          >
            {creation.rating > 3
              ? "Glad you like it 🥰"
              : "Sorry you didn't like it 😔"}
          </Text>
        )} */}
      </ResponsiveContainer>
    </Background>
  )
}
