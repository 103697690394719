import { Icon, IconProps } from "@chakra-ui/react"

export default function SendIcon(props: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.4 19.4251C4.06667 19.5584 3.75 19.5291 3.45 19.3371C3.15 19.1451 3 18.8661 3 18.5001V14.7751C3 14.5417 3.06667 14.3334 3.2 14.1501C3.33333 13.9667 3.51667 13.8501 3.75 13.8001L11 12.0001L3.75 10.2001C3.51667 10.1501 3.33333 10.0334 3.2 9.85005C3.06667 9.66672 3 9.45838 3 9.22505V5.50005C3 5.13338 3.15 4.85405 3.45 4.66205C3.75 4.47005 4.06667 4.44105 4.4 4.57505L19.8 11.0751C20.2167 11.2584 20.425 11.5667 20.425 12.0001C20.425 12.4334 20.2167 12.7417 19.8 12.9251L4.4 19.4251Z"
        fill="currentColor"
      />
    </Icon>
  )
}
