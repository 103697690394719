import axios from "axios"
import React, { createContext, useContext } from "react"
import { ELEVEN_LABS_API_KEY } from "../presentation/components/Creations/GlobalSettings"
import { Pronouns } from "../utilities/dateUtils"

const BASE_URL = "https://api.elevenlabs.io/v1/text-to-speech"
const VOICE_IDS: {
  [key in Pronouns]: string
} = {
  "she/her": "TvAJAjiONx7dI8EyGcOI",
  "he/him": "Ofp1n2agrpeYXOeyAX8b",
  "they/them": "azBIAe3bWWC3GBSKpmj1",
}

const VOICE_SETTINGS: {
  [key in Pronouns]: {
    stability: number
    similarity_boost: number
  }
} = {
  "he/him": {
    stability: 0.25,
    similarity_boost: 0.14,
  },
  "she/her": {
    stability: 0.07,
    similarity_boost: 0,
  },
  "they/them": {
    stability: 0.07,
    similarity_boost: 0,
  },
}

export interface ContextProps {
  play: (text: string, pronouns: Pronouns) => Promise<void>
  playAfterLoad: (
    textProvider: () => Promise<string>,
    pronouns: Pronouns
  ) => Promise<void>
  stop: (text: string) => void
  playing: { [key: string]: boolean }
  loading: { [key: string]: boolean }
}

const TextToSpeechContext = createContext<ContextProps>({
  play: async () => {},
  playAfterLoad: async () => {},
  stop: () => {},
  playing: {},
  loading: {},
})

export function TextToSpeechProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [audio, setAudio] = React.useState(new Map<string, HTMLAudioElement>())
  const [playing, setPlaying] = React.useState<ContextProps["playing"]>({})
  const [loading, setLoading] = React.useState<ContextProps["loading"]>({})

  const stopAllAndPlay = async (a: HTMLAudioElement) => {
    audio.forEach((a) => a.pause())
    a.currentTime = 0
    a.play()
  }

  const loadAndPlay = async (
    t: string,
    a: HTMLAudioElement,
    pronouns: Pronouns
  ) => {
    const text = t.trim()
    if (text.length === 0) return
    setLoading({ ...loading, [text]: true })
    return await axios
      .post(
        `${BASE_URL}/${VOICE_IDS[pronouns]}`,
        {
          text: text,
          voice_settings: VOICE_SETTINGS[pronouns],
        },
        {
          responseType: "blob",
          headers: {
            "xi-api-key": ELEVEN_LABS_API_KEY,
          },
        }
      )
      .then((res) => {
        a.setAttribute("src", URL.createObjectURL(res.data))
        setAudio(audio.set(text, a))
        setLoading({ [text]: false })
        a.addEventListener("ended", () => {
          setPlaying({ [text]: false })
        })
        a.addEventListener("pause", () => {
          setPlaying({ [text]: false })
        })
        a.addEventListener("play", () => {
          setPlaying({ [text]: true })
        })
        stopAllAndPlay(a)
      })
      .catch(() => {
        setLoading({ [text]: false })
      })
  }

  return (
    <TextToSpeechContext.Provider
      value={{
        async play(text, pronouns) {
          let a = audio.get(text)
          if (!a) {
            a = new Audio()
            return loadAndPlay(text, a, pronouns)
          }
          stopAllAndPlay(a)
        },
        async playAfterLoad(textProvider, pronouns) {
          const a = new Audio()
          return loadAndPlay(await textProvider(), a, pronouns)
        },
        stop(text: string) {
          audio.get(text)?.pause()
        },
        playing,
        loading,
      }}
    >
      {children}
    </TextToSpeechContext.Provider>
  )
}

export const useTextToSpeech = (): ContextProps => {
  return useContext(TextToSpeechContext)
}
