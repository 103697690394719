import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, Grid, Image, Text } from "@chakra-ui/react"
import { useEffect, useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useUserContext } from "../../../context/userContext"
import bg from "../../assets/images/backgrounds/app-bg.png"
import ActionButton from "../../components/Buttons/ActionButton"
import { LOCAL_STORAGE_CREATION_INDEX } from "../../components/Creations/GlobalSettings"
import CreatePopup from "../../components/Popup/CreatePopup"
import MagicPopup from "../../components/Popup/MagicPopup"
import Navigation from "../../components/Wrapper/Navigation"
import { ChatInput } from "../Chat/ChatBoxUtils"
import CharacterWithCloud from "./CharacterWithCloud"
import whiteMonster from "../../assets/images/white-monster.png"
import BrushIcon from "../../components/Icons/BrushIcon"
import Button3d from "../../components/Buttons/Button3d"

export default function HubPage() {
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const [isOpenSuggestions, setOpenSuggestions] = useState(false)
  const [text, setText] = useState("")
  const [isOpenCreate, setOpenCreate] = useState(false)
  const [isOpenMagic, setOpenMagic] = useState(false)
  const { userCreations } = useUserContext()
  const creations = useMemo(
    () => userCreations.filter((c) => c.questId == null),
    [userCreations]
  )
  const { user } = useUserContext()
  const [creationIndex, setCreationIndex] = useState<number>(() => {
    try {
      return parseInt(
        localStorage.getItem(`${user?.id}/${LOCAL_STORAGE_CREATION_INDEX}`) ??
          "0"
      )
    } catch {
      return 0
    }
  })
  const navigate = useNavigate()

  const isChattable = useMemo(() => {
    return (creations[creationIndex]?.selectedImage?.length ?? 0) > 0
  }, [creations, creationIndex])

  useEffect(() => {
    localStorage.setItem(
      `${user?.id}/${LOCAL_STORAGE_CREATION_INDEX}`,
      creationIndex.toString()
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creationIndex])

  const character = creations.at(creationIndex)

  return (
    <Navigation
      backgroundImage={bg}
      display="flex"
      flexDir="column"
      p="0!important"
      backgroundColor="transparent"
    >
      <Grid
        pt={["48px", "48px", "64px"]}
        m="auto"
        gridTemplateColumns="50px 1fr 50px"
        w="100%"
        placeItems="center"
        height={["425px", "500px", "700px", "800px"]}
        overflow="hidden"
      >
        {creationIndex > 0 && (
          <Button
            variant="unstyled"
            p="0"
            h="fit-content"
            w="fit-content"
            onClick={() => setCreationIndex(creationIndex - 1)}
          >
            <ChevronLeftIcon w="50px" h="50px" color="#8E86F5" />
          </Button>
        )}
        {character ? (
          (character.generativeAiResults?.length ?? 0) > 0 ? (
            <CharacterWithCloud creation={character!} />
          ) : (
            <Flex
              flexDir="column"
              onClick={() => navigate(`/creations/${character.id}`)}
              cursor="pointer"
              gridColumn={2}
            >
              <Box
                filter="saturate(0.92) hue-rotate(-14deg)"
                position="relative"
                mt={[0, 0, 0, "100px"]}
              >
                <Image
                  w={["250px", "250px", "300px", "300px"]}
                  src={whiteMonster}
                  filter="brightness(50%) sepia(100%) saturate(10000%) hue-rotate(257deg) brightness(2.16) saturate(0.98) brightness(0.975);"
                />
              </Box>
              <Text
                textAlign="center"
                fontWeight="bold"
                color="black"
                py="17px"
              >
                Generating...
              </Text>
            </Flex>
          )
        ) : (
          <Flex flexDir="column" gridColumn={2}>
            <Box
              filter="saturate(0.92) hue-rotate(-14deg)"
              position="relative"
              mt={[0, 0, 0, "100px"]}
            >
              <Image
                w={["250px", "250px", "300px", "300px"]}
                src={whiteMonster}
                filter="brightness(50%) sepia(100%) saturate(10000%) hue-rotate(257deg) brightness(2.16) saturate(0.98) brightness(0.975);"
              />
              <BrushIcon
                color="white"
                position="absolute"
                left="50%"
                top="50%"
                width="70px"
                height="70px"
                transform="translate(-50%, -50%)"
              />
            </Box>
            <Button3d
              size="lg"
              m="auto"
              bg="secondary.500"
              bg3dColor="#2418B1"
              onClick={() => setOpenCreate(true)}
            >
              Create Mini
            </Button3d>
          </Flex>
        )}
        {creationIndex < creations.length && (
          <Button
            variant="unstyled"
            p="0"
            h="fit-content"
            w="fit-content"
            onClick={() => setCreationIndex(creationIndex + 1)}
          >
            <ChevronRightIcon w="50px" h="50px" color="#8E86F5" />
          </Button>
        )}
      </Grid>
      <Box mt="auto" mx="auto" maxW="700px" w="100%">
        <Flex w="100%" justifyContent="space-between" px="2rem">
          <ActionButton
            buttonType="hint"
            onClick={() => setOpenSuggestions(true)}
            opacity={isChattable ? 1 : 0}
            pointerEvents={isChattable ? "auto" : "none"}
          />
          <ActionButton buttonType="magic" onClick={() => setOpenMagic(true)} />
          <ActionButton
            buttonType="create"
            onClick={() => setOpenCreate(true)}
          />
        </Flex>
        <CreatePopup
          isOpen={isOpenCreate}
          onClose={() => setOpenCreate(false)}
        />
        <MagicPopup isOpen={isOpenMagic} onClose={() => setOpenMagic(false)} />
        <ChatInput
          inputRef={inputRef}
          isOpenSuggestions={isChattable && isOpenSuggestions}
          onSubmitText={(text) => {
            if (text.trim().length === 0) return
            navigate(
              `/chat/${character?.id ?? "camcam"}?text=${text}&send=true`
            )
          }}
          setOpenSuggestions={setOpenSuggestions}
          setText={setText}
          text={isChattable ? text : ""}
          placeholder={
            isChattable ? undefined : "Choose your character to chat!"
          }
          isDisabled={!isChattable}
        />
      </Box>
    </Navigation>
  )
}
