import { Box, Heading, IconButton, Image, Text } from "@chakra-ui/react"
import backgroundImg from "../../assets/images/privacy-page/intro-privacy.png"
import MinistudioLogo from "../../components/ImagePresets/MinistudioLogo"
import ResponsiveContainer from "../../components/Wrapper/ResponsiveContainer"
import Footer from "../Home/Footer"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { HamburgerIcon } from "@chakra-ui/icons"
import BurgerMenu from "../../components/Popup/BurgerMenu"

export default function Privacy() {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)

  return (
    <Box overflow="auto">
      <IconButton
        aria-label="Open"
        my="auto"
        ml="0.5rem"
        onClick={() => setOpen(true)}
        variant="ghostWhite"
        fontSize="40px"
        position="fixed"
        top="2rem"
        right="1rem"
        bg="#0000007f"
        borderRadius="12px"
        height="fit-content"
        padding="8px 8px"
        zIndex={10}
      >
        <HamburgerIcon />
      </IconButton>
      <BurgerMenu onClose={() => setOpen(false)} open={open} />

      <Box
        position="relative"
        h="600px"
        minH="fit-content"
        w="100%"
        bg="#0000004f"
      >
        <Image
          src={backgroundImg}
          h="100%"
          w="100%"
          objectFit="cover"
          position="absolute"
          objectPosition="50% 35%"
          top="0"
          left="0"
          zIndex={-1}
        />
        <Box
          position="absolute"
          bottom="0"
          left="0"
          w="100%"
          height="20%"
          bg="linear-gradient(180deg, rgba(35, 27, 42, 0.00) 2.08%, #ffffff 67%)"
        />
        <ResponsiveContainer
          p="0!important"
          position="relative"
          width={["100%", "100%", "100%", "1000px", "1200px", "1400px"]}
          display="flex"
          flexDir="column"
        >
          <MinistudioLogo
            mt={["2rem", "2rem", "4rem"]}
            variant="white"
            height={["60px", "60px", "100px"]}
            width=""
            ml="1rem"
            mr="auto"
          />
          <Heading m="auto" textAlign="center" color="white">
            Safety & privacy <Box display={["block", "block", "none"]} />
            by design
          </Heading>
        </ResponsiveContainer>
      </Box>
      <ResponsiveContainer maxW="800px">
        <Heading
          fontWeight="normal"
          fontSize={["30px", "32px", "38px", "50px", "60px"]}
        >
          Trustworthy AI framework
        </Heading>
        <Text>
          The project follows the principles and framework of Trustworthy AI,
          emphasizing privacy by design and prioritizing data privacy from the
          start.
        </Text>
        <Heading
          mt="4rem"
          fontWeight="normal"
          fontSize={["30px", "32px", "38px", "50px", "60px"]}
        >
          Content moderation guidelines{" "}
        </Heading>
        <Text>
          Large Language Models (LLMs) that are used to power AI chat-bots such
          as ours use data from the open-internet to retrieve answers to
          questions. We follow strict content moderation guidelines as well as
          engineer prompts that restrict unsafe behavior. We explicitly disallow
          hate speech, violence, sexual language, and any other type of speech
          that would be considered unsafe for children. However please keep in
          mind this technology is still at its infancy and we recommend the
          presence of an adult at all times.
        </Text>
        <Heading
          mt="4rem"
          fontWeight="normal"
          fontSize={["30px", "32px", "38px", "50px", "60px"]}
        >
          Minimal data collection
        </Heading>
        <Text>
          Personal data, especially concerning children, is strictly limited.
          During sign-up, only an email address is required, and users are
          encouraged to create fictional usernames instead of sharing real
          identities.
        </Text>

        <Heading
          mt="4rem"
          fontWeight="normal"
          fontSize={["30px", "32px", "38px", "50px", "60px"]}
        >
          Secure data handling
        </Heading>
        <Text>
          Robust security measures, including internet gateway protection,
          restricted security groups, and multi-layered database security, are
          implemented to safeguard user data. Continuous monitoring, such as
          with AWS GuardDuty, detects and responds to malicious activity. The
          project actively analyzes and enhances security measures to protect
          user data. Mini Studio will never give away data to any third party.
          Currently no chat data is being stored, monitored or saved.
        </Text>

        <Heading
          mt="4rem"
          fontWeight="normal"
          fontSize={["30px", "32px", "38px", "50px", "60px"]}
        >
          Informed user consent
        </Heading>
        <Text>
          Users explicitly give consent before their creations are shared on
          social media or community pages, ensuring they have control over their
          data and its usage.
        </Text>

        <Heading
          mt="4rem"
          fontWeight="normal"
          fontSize={["30px", "32px", "38px", "50px", "60px"]}
        >
          Additional measures
        </Heading>
        <Text>
          Programmable guardrails for conversational systems Sentiment analysis
          to guide conversations to positive and friendly tone. Training our own
          models based on children’s books
        </Text>
      </ResponsiveContainer>

      <Footer
        onSignup={() => navigate("/sign-up")}
        onLogin={() => navigate("/login")}
      />
    </Box>
  )
}
