import { loadImage } from "canvas"
import { Buffer } from "buffer"

export function dataURItoBlob(dataURI: string) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = Buffer.from(dataURI.split(",")[1], "base64")

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0]

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length)

  // create a view into the buffer
  var ia = new Uint8Array(ab)

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.at(i)!
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString })
  return blob
}

export const blobToBase64 = (blob: Blob, callback: (data: string) => any) => {
  const reader = new FileReader()
  reader.onload = () => {
    const res = reader.result
    if (typeof res === "string") callback(res)
  }
  reader.readAsDataURL(blob)
}

export async function drawCanvasCircleImage(
  ctx: CanvasRenderingContext2D,
  imageUrl: string,
  x: number,
  y: number,
  r: number
) {
  return new Promise((resolve) => {
    fetch(imageUrl).then(async (res) => {
      return blobToBase64(await res.blob(), async (data) => {
        return await loadImage(data).then((img) => {
          ctx.beginPath()
          ctx.arc(x, y, r, 0, Math.PI * 2, true)
          ctx.closePath()
          ctx.clip()
          ctx.drawImage(
            img as unknown as CanvasImageSource,
            x - r,
            y - r,
            2 * r,
            2 * r
          )
          ctx.save()
          resolve(void 0)
        })
      })
    })
  })
}

export function drawCanvasImage(
  ctx: CanvasRenderingContext2D,
  imageUrl: string,
  x: number,
  y: number,
  width: number,
  height: number
) {
  return new Promise((resolve) => {
    fetch(imageUrl).then(async (res) => {
      return blobToBase64(await res.blob(), async (data) => {
        return await loadImage(data).then((img) => {
          ctx.drawImage(
            img as unknown as CanvasImageSource,
            x,
            y,
            width,
            height
          )
          ctx.save()
          resolve(void 0)
        })
      })
    })
  })
}

export function drawCanvasBackground(
  ctx: CanvasRenderingContext2D,
  color: string
) {
  ctx.fillStyle = color
  ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
}
