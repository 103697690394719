import axios from "axios"
import UserData from "../data/models/UserData"
import { API_ENDPOINTS } from "../presentation/components/Creations/GlobalSettings"

export default class CreditService {
  static async getPaymentIntent(
    quantity: number,
    noOffer: boolean
  ): Promise<string> {
    return await axios
      .get(
        `${API_ENDPOINTS.GET_BUY_CREDITS_SECRET}/${quantity}?noOffer=${noOffer}`,
      )
      .then((response) => response.data.clientSecret)
  }

  static async addCreditPack(
    user: UserData,
    paymentIntentId: string
  ): Promise<boolean> {
    return await axios
      .post(
        API_ENDPOINTS.ADD_CREDITS,
        { paymentIntentId, noOffer: false },
        {
          headers: {
            Authorization: `Bearer ${await user.fbUser.getIdToken(true)}`,
          },
        }
      )
      .then((response) => response.data.success)
      .catch(() => false)
  }

  static async addCredits(
    user: UserData,
    paymentIntentId: string
  ): Promise<boolean> {
    return await axios
      .post(
        API_ENDPOINTS.ADD_CREDITS,
        { paymentIntentId, noOffer: true },
        {
          headers: {
            Authorization: `Bearer ${await user.fbUser.getIdToken(true)}`,
          },
        }
      )
      .then((response) => response.data.success)
      .catch(() => false)
  }

  static async collectDailyFreeCredit(user: UserData): Promise<void> {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    await axios.post(
      API_ENDPOINTS.ADD_FREE_CREDIT,
      { timeZone },
      {
        headers: {
          Authorization: `Bearer ${await user.fbUser.getIdToken(true)}`,
        },
      }
    )
  }
}
