import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons"
import {
  Box,
  ButtonProps,
  IconButton,
  Image,
  ImageProps,
} from "@chakra-ui/react"
import { useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"

export default function Carousel({
  images,
  ...props
}: { images: string[] } & ImageProps) {
  const [slider, setSlider] = useState<Slider | null>(null)

  const buttonStyle: ButtonProps = {
    position: "absolute",
    top: "50%",
    zIndex: 1,
    variant: "unstyled",
    color: "white",
    bg: "rgba(0,0,0,0.4)",
    fontSize: "2rem",
    borderRadius: "100%",
    h: "50px",
    w: "50px",
    transform: "translateY(-50%)",
  }

  return (
    <Box position="relative">
      {images.length > 1 && (
        <>
          <IconButton
            {...buttonStyle}
            aria-label="Previous image"
            left="0.5rem"
            onClick={() => slider?.slickPrev()}
            icon={<ChevronLeftIcon />}
          />
          <IconButton
            {...buttonStyle}
            onClick={() => slider?.slickNext()}
            right="0.5rem"
            icon={<ChevronRightIcon />}
            aria-label="Next Image"
          />
        </>
      )}
      <Slider
        arrows={false}
        ref={(slider) => setSlider(slider)}
        adaptiveHeight
      >
        {images.map((img) => (
          <Image key={img} src={img} w="100%" h="100%" {...props} />
        ))}
      </Slider>
    </Box>
  )
}
