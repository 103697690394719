import { Select, SelectProps } from "@chakra-ui/react"
import { ComponentProps } from "react"

export default function PronounSelect(
  props: SelectProps & ComponentProps<"select">
) {
  return (
    <Select
      borderRadius="12px"
      border="solid 2px #494949 !important"
      variant="unstyled"
      style={{
        padding: "0.5rem 1.5rem",
        lineHeight: "inherit",
      }}
      {...props}
    >
      <option value="he/him">He/him</option>
      <option value="she/her">She/her</option>
      <option value="they/them">They/them</option>
    </Select>
  )
}
