import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react"
import backgroundImg from "../../assets/images/homepage/cards/portal-bg.png"
import embark from "../../assets/images/homepage/cards/embark.png"
import train from "../../assets/images/homepage/cards/train.png"
import discover from "../../assets/images/homepage/cards/discover.png"
import explore from "../../assets/images/homepage/cards/explore.png"
import journey from "../../assets/images/homepage/cards/journey.png"
import Button3d from "../../components/Buttons/Button3d"
import ResponsiveContainer from "../../components/Wrapper/ResponsiveContainer"

export default function DreamBlock({ onClick }: { onClick: () => void }) {
  return (
    <Box position="relative" h="1200px" minH="fit-content" w="100%" mt="-1px">
      <Image
        src={backgroundImg}
        position="absolute"
        top="0"
        left="0"
        h="100%"
        w="100%"
        objectFit="cover"
        zIndex={-1}
      />
      <Box
        position="absolute"
        top="0%"
        left="0"
        w="100%"
        height="33%"
        bg="linear-gradient(0, rgba(0, 0, 0, 0.00) 0.08%, #000000 90%)"
        zIndex={-1}
      />
      <ResponsiveContainer
        width={["100%", "100%", "100%", "1000px", "1200px", "1400px"]}
        display="flex"
        flexDirection="column"
        position="relative"
      >
        <Text
          color="white"
          textAlign="center"
          fontSize={["16px", "16px", "16px", "20px"]}
        >
          Foster empowering beliefs
        </Text>
        <Heading
          color="white"
          textAlign="center"
          mt="2rem"
          fontSize={["26px", "32px", "38px", "50px", "60px"]}
        >
          Breathe life into your
          <br /> kids dreams
        </Heading>
        <Box mx="-1rem" my="36px">
          <Flex w="100%" overflow="auto" gap="1rem" px="1rem" pb="0.5rem">
            <Image src={embark} borderRadius="12px" />
            <Image src={train} borderRadius="12px" />
            <Image src={discover} borderRadius="12px" />
            <Image src={explore} borderRadius="12px" />
            <Image src={journey} borderRadius="12px" />
          </Flex>
        </Box>
        <Button3d
          size="lg"
          fontSize={["16px", "20px", "24px"]}
          mx="auto"
          backgroundColor="#FBF6F3"
          color="secondary.500"
          bg3dColor="#9C9998"
          onClick={onClick}
        >
          Create your Stories
        </Button3d>
        <Heading
          fontSize={["26px", "32px", "38px", "50px", "60px"]}
          color="white"
          mt="auto"
          textAlign="center"
        >
          The home of children’s
          <br /> imagination
        </Heading>
        <Text
          textAlign="center"
          color="white"
          fontSize={["17px", "17px", "17px", "30px"]}
        >
          Mini Studio is a vessel in the clouds that is revolutionizing
          children’s entertainment. For the first time ever, we’re putting
          children’s imagination into their own hands. Our mission is to build
          tools that allow kids to create their own IP. From characters, to
          stories, cartoons or games.
        </Text>
      </ResponsiveContainer>
      <Box
        zIndex={-1}
        position="absolute"
        bottom="0"
        left="0"
        w="100%"
        height="40%"
        bg="linear-gradient(180deg, rgba(38, 126, 182, 0.00) 0.52%, #38A3CB 40.33%, #399EC3 80.15%)"
      />
    </Box>
  )
}
