import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react"
import aquadog from "../../assets/images/homepage/hero/aquadog.png"
import sidekicks from "../../assets/images/homepage/hero/hero-sidekicks.png"
import heroBg from "../../assets/images/homepage/hero/hero.png"
import youmba from "../../assets/images/homepage/hero/youmba.png"
import Button3d from "../../components/Buttons/Button3d"
import MinistudioLogo from "../../components/ImagePresets/MinistudioLogo"
import ResponsiveContainer from "../../components/Wrapper/ResponsiveContainer"

export default function HomeHeroBlock({
  onLogin,
  onSignUp,
}: {
  onLogin: () => void
  onSignUp: () => void
}) {
  return (
    <Box
      position="relative"
      h="100vh"
      minH="fit-content"
      w="100%"
      bg={["transparent", "transparent", "transparent", "#0000007f"]}
    >
      <Image
        src={heroBg}
        h={["60%", "60%", "60%", "100%"]}
        w="100%"
        objectFit="cover"
        position="absolute"
        objectPosition="bottom"
        top="0"
        left="0"
        zIndex={-1}
      />
      <Box
        display={["block", "block", "block", "none"]}
        position="absolute"
        top="50%"
        left="0"
        w="100%"
        height="50%"
        bg="linear-gradient(180deg, rgba(35, 27, 42, 0.00) 2.08%, #191321 17.71%)"
      />
      <Box
        display={["none", "none", "none", "block"]}
        position="absolute"
        bottom="0"
        left="0"
        w="100%"
        height="20%"
        bg="linear-gradient(180deg, rgba(35, 27, 42, 0.00) 2.08%, #191321 67%)"
      />
      <Image
        src={aquadog}
        display={["block", "block", "block", "none"]}
        position="absolute"
        top="40px"
        w="100%"
        h="50%"
        objectFit="contain"
        objectPosition="top"
      />
      <ResponsiveContainer
        p="0!important"
        position="relative"
        width={["100%", "100%", "100%", "1000px", "1200px", "1400px"]}
        display="flex"
        flexDir="column"
      >
        <MinistudioLogo
          mt={["2rem", "2rem", "4rem"]}
          variant="white"
          height={["60px", "60px", "100px"]}
          width=""
          ml="1rem"
          mr="auto"
        />
        <Box
          mt={["auto", "auto", "auto", "50px", "150px", "200px"]}
          mb={["4rem", "4rem", "4rem", 0]}
          zIndex="1"
        >
          <Heading
            color="white"
            textAlign="center"
            fontSize={["26px", "32px", "32px", "40px", "50px", "60px"]}
          >
            Create your Mini friends, <br />
            chat & learn together
          </Heading>
          <Text
            color="white"
            textAlign="center"
            mt="1rem"
            fontSize={["14px", "16px", "16px", "20px", "24px", "28px"]}
          >
            Little companions designed to foster children’s <br />
            imagination and curiosity.
          </Text>
          <Flex w="100%" justifyContent="center" gap="2rem" mt="4rem">
            <Button3d
              backgroundColor="#FBF6F3"
              color="secondary.500"
              bg3dColor="#9C9998"
              size="lg"
              onClick={onLogin}
            >
              Login
            </Button3d>
            <Button3d
              size="lg"
              bg3dColor="#201789"
              color="white"
              background="secondary.500"
              onClick={onSignUp}
            >
              Sign up
            </Button3d>
          </Flex>
        </Box>
        <Box
          w="100%"
          h="100%"
          position="absolute"
          top="0"
          left="0"
          overflow="hidden"
        >
          <Image
            bottom={["65%", "65%", "60%", 0]}
            transform={[
              "translateY(50%)",
              "translateY(50%)",
              "translateY(50%)",
              "none",
            ]}
            src={youmba}
            objectFit="contain"
            objectPosition="top"
            position="absolute"
            left="-32px"
            w={["175px", "200px", "250px", "auto"]}
          />
          <Image
            bottom="0"
            src={aquadog}
            objectFit="contain"
            objectPosition="top"
            position="absolute"
            left="175px"
            height="350px"
            display={["none", "none", "none", "block"]}
          />
          <Image
            bottom={["60%", "60%", "55%", 0]}
            transform={[
              "translateY(50%)",
              "translateY(50%)",
              "translateY(50%)",
              "none",
            ]}
            src={sidekicks}
            objectFit="contain"
            objectPosition="top"
            position="absolute"
            right={["-2rem", "-2rem", "-2rem", 0]}
            w={["175px", "200px", "250px", "auto"]}
          />
        </Box>
      </ResponsiveContainer>
    </Box>
  )
}
