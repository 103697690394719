import { Box, Button, Flex } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import Button3d from "../../components/Buttons/Button3d"
import FacebookIcon from "../../components/Icons/FacebookIcon"
import InstagramIcon from "../../components/Icons/InstagramIcon"
import LinkedinIcon from "../../components/Icons/LinkedinIcon"
import TwitterIcon from "../../components/Icons/TwitterIcon"
import MinistudioLogo from "../../components/ImagePresets/MinistudioLogo"
import {
  RedCloudsBottom,
  RedCloudsBottomCropped,
} from "../../components/SectionSeparators/RedCloudsBottom"

export default function Footer({
  onLogin,
  onSignup,
}: {
  onLogin: () => void
  onSignup: () => void
}) {
  const navigate = useNavigate()
  return (
    <Box w="100%" maxW="100%">
      <RedCloudsBottom
        color="secondary.500"
        width="100%"
        height="0%"
        display={["none", "none", "block"]}
        mb="-1px"
      />
      <RedCloudsBottomCropped
        color="secondary.500"
        width="100%"
        height="0%"
        mb="-1px"
        display={["block", "block", "none"]}
      />
      <Flex
        bg="secondary.500"
        py="50px"
        justifyContent="space-evenly"
        alignItems="center"
        flexDir={["column", "column", "row"]}
        gap="1rem"
      >
        <Box display="flex" flexDir="column">
          {/* <Heading
            color="white"
            size="sm"
            textAlign={["center", "center", "left"]}
          >
            Ministudio
          </Heading> */}
          <MinistudioLogo
            variant="white"
            height={["60px", "60px", "100px"]}
            width="auto"
          />
          <Flex
            color="white"
            gap="1rem"
            display={["none", "none", "flex"]}
            mx="auto"
            mt="2rem"
          >
            <a
              href="https://www.instagram.com/ministudio.ai/"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon height="24px" width="24px" />
            </a>
            <a
              href="https://www.facebook.com/ministudioai"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon height="24px" width="24px" />
            </a>
            <a
              href="https://twitter.com/ministudio_AI"
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon height="24px" width="24px" />
            </a>
            <a
              href="https://www.linkedin.com/company/mini-studio-ai"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedinIcon height="24px" width="24px" />
            </a>
          </Flex>
        </Box>
        <Flex
          flexDir={["column", "column", "column-reverse"]}
          gap="1rem"
          mb="1rem"
        >
          <Flex>
            <Button variant="ghostWhite" onClick={() => navigate("/")} size={["sm", "md"]}>
              About us
            </Button>
            <Button variant="ghostWhite" onClick={() => navigate("/mission")} size={["sm", "md"]}>
              Our Mission
            </Button>
            <Button variant="ghostWhite" onClick={() => navigate("/privacy")} size={["sm", "md"]}>
              Safety
            </Button>
          </Flex>
          <Flex gap="1rem" mx="auto">
            <Button3d
              onClick={onLogin}
              color="secondary.500"
              backgroundColor="#FBF6F3"
              bg3dColor="#9C9998"
            >
              Login
            </Button3d>
            <Button3d onClick={onSignup}>Sign up</Button3d>
          </Flex>
        </Flex>

        <Flex color="white" gap="1rem" display={["flex", "flex", "none"]}>
          <a
            href="https://www.instagram.com/ministudio.ai/"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon height="24px" width="24px" />
          </a>
          <a
            href="https://www.facebook.com/ministudioai"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon height="24px" width="24px" />
          </a>
          <a
            href="https://twitter.com/ministudio_AI"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon height="24px" width="24px" />
          </a>
          <a
            href="https://www.linkedin.com/company/mini-studio-ai"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedinIcon height="24px" width="24px" />
          </a>
        </Flex>
      </Flex>
    </Box>
  )
}
