import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons"
import {
  Box,
  ButtonProps,
  IconButton,
  Image,
  ImageProps,
} from "@chakra-ui/react"
import React, { useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"

export default function CardCarousel({
  images,
  onSwipe,
  ...props
}: {
  images: { imageSrc: string; cardContent: React.ReactNode }[]
  onSwipe?: () => void
} & ImageProps) {
  const [slider, setSlider] = useState<Slider | null>(null)
  const [hideLeftArrow, setHideLeftArrow] = useState<boolean>(true)
  const [hideRightArrow, setHideRightArrow] = useState<boolean>(false)

  const buttonStyle: ButtonProps = {
    position: "absolute",
    top: "50%",
    zIndex: 1,
    variant: "unstyled",
    color: "white",
    bg: "rgba(0,0,0,0.4)",
    fontSize: "2rem",
    borderRadius: "100%",
    h: "50px",
    w: "50px",
    transform: "translateY(-50%)",
  }

  return (
    <Box position="relative">
      {images.length > 1 && (
        <>
          <IconButton
            {...buttonStyle}
            display={hideLeftArrow ? "none" : "block"}
            aria-label="Previous image"
            left="0.5rem"
            onClick={() => slider?.slickPrev()}
            icon={<ChevronLeftIcon />}
          />
          <IconButton
            {...buttonStyle}
            display={hideRightArrow ? "none" : "block"}
            onClick={() => slider?.slickNext()}
            right="0.5rem"
            icon={<ChevronRightIcon />}
            aria-label="Next Image"
          />
        </>
      )}
      <Slider
        infinite={false}
        swipe
        arrows={false}
        ref={(slider) => setSlider(slider)}
        adaptiveHeight
        onSwipe={onSwipe}
        afterChange={(index) => {
          setHideLeftArrow(index === 0)
          setHideRightArrow(index === images.length - 1)
        }}
      >
        {images.map((img, i) => (
          <Box
            key={img.imageSrc + i}
            w="100%"
            h="100%"
            position="relative"
            outline="none"
          >
            <Image src={img.imageSrc} w="100%" h="100%" {...props} />
            {img.cardContent}
          </Box>
        ))}
      </Slider>
    </Box>
  )
}
