import { defineStyleConfig } from "@chakra-ui/react"

export const Button = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    fontWeight: "bold",
    borderRadius: "35px",
    transition: "ease-in-out 0.2s",
    fontFamily: "GT Walsheim Pro",
  },
  // Two sizes: sm and md
  sizes: {
    xs: {
      fontSize: "12px",
      px: 2,
      py: 2,
    },
    sm: {
      fontSize: "14px",
      px: 4,
      py: 4,
    },
    md: {
      fontSize: "16px",
      px: 6,
      py: 6,
    },
    lg: {
      fontSize: "24px",
      px: 8,
      py: 8,
    },
  },
  // Two variants: outline and solid
  variants: {
    // new variants
    full: {
      bg: "primary.500",
      color: "white",
      _hover: {
        bg: "primary.400",
      },
      _active: {
        bg: "primary.300",
      },
      _disabled: {
        bg: "primary.500 !important",
        opacity: 0.5,
      },
    },
    border: {
      border: "1px solid",
      borderColor: "primary,500",
      bg: "white",
      color: "primary.500",
      _hover: {
        bg: "gray.100",
      },
      _active: {
        bg: "gray.200",
      },
      _disabled: {
        opacity: 0.5,
      },
    },
    white: {
      bg: "white",
      color: "primary.500",
      _hover: {
        bg: "gray.100",
      },
      _active: {
        bg: "gray.200",
      },
      _disabled: {
        opacity: 0.5,
      },
    },
    colored: {
      bg: "primary.500",
      color: "white",
      _hover: {
        filter: "brightness(1.1)",
      },
      _active: {
        filter: "brightness(1.2)",
      },
      _disabled: {
        opacity: 0.5,
      }
    },
    lightPrimary: {
      bg: "primary.100",
      color: "primary.500",
      _hover: {
        bg: "primary.50",
      },
      _active: {
        bg: "white",
      },
      _disabled: {
        opacity: 0.5,
      },
    },
    ghostPrimary: {
      bg: "none",
      color: "primary.500",
      _hover: {
        color: "primary.400",
      },
      _active: {
        color: "primary.100",
      },
      _disabled: {
        opacity: 0.5,
      },
    },
    ghostBlack: {
      bg: "none",
      color: "black",
      _hover: {
        color: "gray.700",
      },
      _active: {
        color: "gray.500",
      },
      _disabled: {
        opacity: 0.5,
      },
    },

    ghostWhite: {
      bg: "none",
      color: "white",
      _hover: {
        color: "gray.100",
      },
      _active: {
        color: "gray.300",
      },
      _disabled: {
        opacity: 0.5,
      },
    },

    ghostSecondary: {
      bg: "none",
      color: "secondary.500",
      _hover: {
        color: "secondary.400",
      },
      _active: {
        color: "secondary.100",
      },
      _disabled: {
        opacity: 0.5,
      },
    },

    outlineSecondaryDark: {
      bg: "none",
      color: "secondary.900",
      border: "solid 1px",
      borderColor: "secondary.900",
      _hover: {
        color: "secondary.800",
        borderColor: "secondary.800",
      },
      _active: {
        color: "secondary.700",
        borderColor: "secondary.700",
      },
      _disabled: {
        opacity: 0.5,
      },
    },

    fullSecondary: {
      bg: "secondary.500",
      color: "white",
      _hover: {
        bg: "secondary.400",
      },
      _active: {
        bg: "secondary.300",
      },
      _disabled: {
        opacity: 0.5,
        bg: "secondary.500 !important",
      },
    },

    fullSecondaryDark: {
      bg: "secondary.900",
      color: "white",
      _hover: {
        bg: "secondary.800",
      },
      _active: {
        bg: "secondary.700",
      },
      _disabled: {
        opacity: 0.5,
        bg: "secondary.900 !important",
      },
    },

    icon: {
      bg: "none",
      height: "fit-content",
      px: 1,
      py: 1,
      borderRadius: "5px",
    },

    transparent: {
      bg: "transparent",
      color: "white",
      _hover: {
        bg: "rgba(0,0,0,0.2)",
      },
      _active: {
        bg: "rgba(0,0,0,0.3)",
      },
    },

    // old variants
    noBckWhite: {
      bg: "none",
      color: "white",
    },
    noBckBlue: {
      bg: "none",
      color: "#004AAD",
    },
    noBckCyan: {
      bg: "none",
      color: "#38B6FF",
    },
    yellow: {
      bg: "#FFB649",
      color: "white",
      _hover: {
        bg: "#ffc266",
      },
      _active: {
        bg: "#ffcc80",
      },
      _disabled: {
        opacity: 0.5,
        bg: "#FFB649 !important",
      },
    },
    whiteBtn: {
      bg: "white",
      color: "#004AAD",
    },
    blueBtn: {
      bg: "#38B6FF",
      color: "white",
    },
    kingBlueBtn: {
      bg: "#004AAD",
      color: "white",
    },
    cyan: {
      bg: "#38B6FF",
      color: "white",
    },
    red: {
      bg: "#FF5533",
      color: "white",
    },
    solid: {
      bg: "white",
      color: "#0C2134",
    },
    useYourArt: {
      bg: "white",
      fontSize: "1rem",
    },
    useYourArtSpecial: {
      bg: "#ffffff22",
      color: "#004AAD",
      border: "3px solid #004AAD",
      fontSize: "1rem",
    },
  },
  // The default size and variant values
  defaultProps: {
    size: "md",
    variant: "full",
  },
})
