import { Box, Button, Flex, Heading } from "@chakra-ui/react"
import Navigation from "../../components/Wrapper/Navigation"
import MyCharacters from "./MyCharacters"
import WebtoonList from "./WebtoonList/WebtoonList"
import { SettingsIcon } from "@chakra-ui/icons"
import { useNavigate } from "react-router-dom"

export default function MyCreations() {
  const navigate = useNavigate()
  return (
    <Navigation
      display="flex"
      flexDir="column"
      w="100% !important"
      includeHeader
      backgroundColor="#ffffff07"
      blurredBackground
    >
      <Box width={["100%", "100%", "700px", "800px", "1200px"]} mx="auto">
        <Flex>
          <Heading size="md" color="black" textAlign="left">
            My Studio
          </Heading>
          <Button
            variant="ghost"
            bg="#ffffff77"
            color="black"
            p="0"
            ml="auto"
            onClick={() => navigate("/settings")}
          >
            <SettingsIcon />
          </Button>
        </Flex>

        <MyCharacters />
        <WebtoonList lightMode />
      </Box>
    </Navigation>
  )
}
