import {
  CollectionReference,
  DocumentSnapshot,
  Query,
  Timestamp,
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  where
} from "firebase/firestore"
import { db } from "../data/apis/firebase/Firebase"
import Creation, { CreationFirebase } from "../data/models/Creation"

export default class CreationDAO {
  private static docToCreation(
    doc: DocumentSnapshot<CreationFirebase>
  ): Creation | undefined {
    const data = doc.data()
    if (!data) return undefined
    let createdAt = new Date()
    if (typeof data.createdAt === "string") {
      createdAt = new Date(data.createdAt)
    } else if (data.createdAt instanceof Timestamp) {
      createdAt = data.createdAt.toDate()
    }
    return {
      id: doc.id ?? "",
      title: data.title ?? "",
      originalImagePath: data.originalImagePath ?? "",
      generativeAiResults: data.generativeAiResults ?? [],
      generativeAiResultsCropped: data.generativeAiResultsCropped ?? [],
      prompt: data.prompt ?? "",
      questId: data.questId,
      createdAt,
      isValidated: true, //data.isValidated ?? false,
      isOpened: data.isOpened ?? false,
      userId: doc.ref.parent.parent?.id ?? "",
      selectedImage: data.selectedImage ?? "",
      promptForAI: data.promptForAI,
      originalGeneratedPrompt: data.originalGeneratedPrompt,
      shareAgreement: data.shareAgreement,
      shareable: data.shareable,
      rating: data.rating,
      pronouns: data.pronouns ?? "they/them",
      slackNotification: data.slackNotification ?? false,
      selectedImageCroppedLeft: data.selectedImageCroppedLeft,
      selectedImageCroppedRight: data.selectedImageCroppedRight,
    }
  }

  static async getAllCreations(): Promise<Creation[]> {
    return await getDocs<CreationFirebase>(
      collectionGroup(db, "creations") as Query<CreationFirebase>
    ).then((docs) => docs.docs.map((doc) => this.docToCreation(doc)!))
  }

  static listenToUserCreations(
    userId: string,
    callback: (creations: Creation[]) => void
  ): () => void {
    const unsubscribe = onSnapshot<CreationFirebase>(
      collection(
        db,
        `users/${userId}/creations`
      ) as CollectionReference<CreationFirebase>,
      (creationDocs) => {
        callback(creationDocs.docs.map((doc) => this.docToCreation(doc)!))
      }
    )

    return unsubscribe
  }

  static listenToCreations(
    callback: (creations: Creation[]) => void
  ): () => void {
    const unsubscribe = onSnapshot<CreationFirebase>(
      collectionGroup(db, `creations`) as CollectionReference<CreationFirebase>,
      (creationDocs) => {
        callback(creationDocs.docs.map((doc) => this.docToCreation(doc)!))
      }
    )

    return unsubscribe
  }

  static async getCreations(userId: string): Promise<Creation[]> {
    return await getDocs<CreationFirebase>(
      collection(
        db,
        `users/${userId}/creations`
      ) as CollectionReference<CreationFirebase>
    ).then((docs) => docs.docs.map((doc) => this.docToCreation(doc)!))
  }

  static async getSketchQuests(userId: string): Promise<Creation[]> {
    return await getDocs<CreationFirebase>(
      collection(
        db,
        `users/${userId}/creations`
      ) as CollectionReference<CreationFirebase>
    ).then((docs) => docs.docs.filter((doc) => doc.data().questId != null).map((doc) => this.docToCreation(doc)!))
  }

  

  static async getOtherCreations(
    userId: string,
    quantity: number
  ): Promise<Creation[]> {
    return (
      await getDocs<CreationFirebase>(
        query(collectionGroup(db, "creations"), where("shareable", "==", true))
      ).then((docs) =>
        docs.docs
          .map((doc) => this.docToCreation(doc)!)
          .filter(
            (creation) =>
              creation.userId !== userId &&
              (creation.selectedImage?.length ?? 0) > 0
          )
      )
    ).slice(0, quantity)
  }

  static async getCreation(
    userId: string,
    creationId: string
  ): Promise<Creation | null> {
    let creation: Creation
    const creationSnapshot = await getDoc<CreationFirebase>(
      doc(db, `users/${userId}/creations`, creationId)
    )

    if (
      !creationSnapshot.exists() ||
      creationSnapshot.ref.parent.parent?.id !== userId
    )
      return null
    creation = this.docToCreation(creationSnapshot)!

    return creation
  }

  static async getCreationsByQuestId(
    userId: string,
    questId: string
  ): Promise<Creation[] | []> {
    return (await getDocs<CreationFirebase>(
      query(
        collection(db, `users/${userId}/creations`),
        where("questId", "==", questId)
      )
      ).then((docs) => docs.docs.map((doc) => this.docToCreation(doc)!)))
  }

  static async updateField(
    userId: string,
    creationId: string,
    field: string,
    value: any
  ): Promise<void> {
    await updateDoc(doc(db, `users/${userId}/creations`, creationId), {
      [field]: value,
    })
  }
}
