import { Box } from "@chakra-ui/react"

const ResponsiveContainer: typeof Box = (props) => {
  return (
    <Box
      width={["100%", "100%", "700px", "800px", "1200px"]}
      minH="100%"
      padding={["1em", "1em", "1em"]}
      pt={["1em", "1em", "1em"]}
      margin="auto"
      {...props}
    />
  )
}

export default ResponsiveContainer;
