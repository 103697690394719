import { Icon, IconProps } from "@chakra-ui/react"

export default function ArrowRightIcon(props: IconProps) {
  return (
    <Icon
      viewBox="0 0 45 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Icons/54/CTA Arrow">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.1353 16.49C23.4841 14.6325 23.6513 11.7881 25.5088 10.1368C27.3664 8.4856 30.2108 8.65283 31.862 10.5104L43.8627 24.0103C45.3784 25.7154 45.3784 28.2849 43.8627 29.99L31.862 43.4899C30.2108 45.3475 27.3664 45.5147 25.5088 43.8634C23.6513 42.2122 23.4841 39.3678 25.1353 37.5103L30.4781 31.5001H4.50027C2.01492 31.5001 0.000148773 29.4853 0.000148773 27C0.000148773 24.5146 2.01492 22.4998 4.50027 22.4998L30.4777 22.4998L25.1353 16.49Z"
          fill="white"
        />
      </g>
    </Icon>
  )
}
