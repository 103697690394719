import { defineStyleConfig } from "@chakra-ui/react"

export const Heading = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    fontWeight: "bold",
    lineHeight: "100%",
    fontFamily: "GT Walsheim Pro",
    color: "black",
  },
  sizes: {
    sm: {
      fontSize: "1.5rem",
    },
    md: {
      fontSize: "2rem",
    },
    lg: {
      fontSize: "2.5rem",
    },
  },
  // Two variants: outline and solid
  variants: {
    solid: {
      color: "black",
    },
    white: {
      color: "white",
    },
    blue: {
      color: "blue",
    },
    bluesSmile: {
      fontFamily: "Blues Smile",
    },
  },
  // The default size and variant values
  defaultProps: {
    size: "lg",
    variant: "solid",
  },
})
