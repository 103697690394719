import { Box, BoxProps, Image, ImageProps } from "@chakra-ui/react"
import { WebtoonAnimatedAsset } from "../../../../data/models/Webtoon"
import { useEffect, useState } from "react"

export default function WebtoonAnimatedAssetElement({
  asset,
  startAnimation,
}: {
  asset: WebtoonAnimatedAsset
  startAnimation: boolean
}) {
  const [boxProps, setBoxProps] = useState<BoxProps>({})
  const [imageProps, setImageProps] = useState<ImageProps>({})
  useEffect(() => {
    if (startAnimation) {
      setBoxProps({
        maxW: `${asset.end.maxWidthPercent * 100}%`,
        maxH: `${asset.end.maxHeightPercent * 100}%`,
        w: `${asset.end.maxWidthPercent * 100}%`,
        h: `${asset.end.maxHeightPercent * 100}%`,
        top: `${asset.end.y * 100}%`,
        left: `${asset.end.x * 100}%`,
        transform: `translate(-50%, -50%)`,
      })
      setImageProps({
        transform: `rotate(${asset.end.angle}deg)`,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startAnimation])
  return (
    <Box
      maxH={`${asset.start.maxHeightPercent * 100}%`}
      maxW={`${asset.start.maxWidthPercent * 100}%`}
      h={`${asset.start.maxHeightPercent * 100}%`}
      w={`${asset.start.maxWidthPercent * 100}%`}
      top={`${asset.start.y * 100}%`}
      left={`${asset.start.x * 100}%`}
      transition={"all 4s linear"}
      transform={`translate(-50%, -50%)`}
      position="absolute"
      zIndex={1}
      {...boxProps}
    >
      <Image
        src={asset.image}
        w="100%"
        h="100%"
        objectFit="contain"
        objectPosition="center"
        transition={"all 4s linear !important"}
        transform={`rotate(${asset.start.angle}deg)`}
        {...imageProps}
      />
    </Box>
  )
}
