const API_URL = process.env.REACT_APP_API_URL || "/api"

export const STORY_PACK_ID = "94FLem3xMnGYSx9lzYV4"
export const JOURNEY_SIZE = 10

export const API_ENDPOINTS = {
  ADD_CREDITS: `${API_URL}/addCredits`,
  ADD_FREE_CREDIT: `${API_URL}/addFreeCredit`,
  GET_BUY_CREDITS_SECRET: `${API_URL}/getBuyCreditsClientSecret`,
  ADD_CREATION: `${API_URL}/creation`,
  ADD_CREATION_QUEST: `${API_URL}/creation`,
  INVITE_EMAILS: `${API_URL}/invite-emails`,
  WEBTOON_CREATE: `${API_URL}/createStoryFromPack`,
  BUY_STORY_PACK: `${API_URL}/storyPack`,
  SEND_SLACK_NOTIFICATION: `${API_URL}/sendSlackNotification`,
  CHAT: `${API_URL}/chat`,
  CHANGE_CHARACTER: `${API_URL}/changeChatCharacter`,
}

export const IS_PROD = process.env.REACT_APP_IS_DEV !== "true"
export const SEGMENT_WRITE_KEY = process.env.REACT_APP_SEGMENT_WRITE_KEY || ""
export const GOOGLE_SPEECH_TO_TEXT_CLOUD_API_KEY = process.env.REACT_APP_GOOGLE_SPEECH_TO_TEXT_CLOUD_API_KEY || ""

export const LOCAL_STORAGE_CHAT_TEXT = "CHAT_LAST_TEXT"
export const LOCAL_STORAGE_CHAT_HISTORY = "CHAT_HISTORY"
export const LOCAL_STORAGE_CHAT_BOT_MUTED = "CHAT_BOT_MUTED"
// export const LOCAL_STORAGE_CHAT_BOT_BACKGROUND = "CHAT_BOT_BACKGROUND"

export const LOCAL_STORAGE_CREATION_INDEX = "CREATION_INDEX"

export const ELEVEN_LABS_API_KEY =
  process.env.REACT_APP_ELEVEN_LABS_API_KEY || ""
