import { AddIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, Heading } from "@chakra-ui/react"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import Creation from "../../../data/models/Creation"
import CharacterCard from "../../components/Creations/CharacterCard"

export default function ChooseCharacterMenu({
  creations,
  onChoose,
  chosenCreation,
  onCreate,
  loading,
  chooseText = "Yes, I choose this character",
  disclamer,
  choosingForYour = "story",
  hideSketchQuests = false,
}: {
  creations: Creation[]
  chosenCreation: Creation | null
  onChoose: (creation: Creation) => void
  onCreate: () => void
  loading: boolean
  chooseText?: string
  disclamer?: React.ReactNode
  choosingForYour?: string
  hideSketchQuests?: boolean
}) {
  const navigate = useNavigate()
  const readyCreations = useMemo(() => {
    const c = creations
      .filter((c) => (c.selectedImage?.length ?? 0) > 0)
      .sort((a, b) => {
        return a.title?.localeCompare(b.title ?? "") ?? 0
      })
    if (!hideSketchQuests) return c
    return c.filter((c) => !c.questId)
  }, [creations, hideSketchQuests])

  return (
    <Box>
      {chosenCreation && (
        <Flex gap="0.25rem" justifyContent="center" mb="1rem">
          <Heading fontSize="1rem" as="h3">
            You chose
          </Heading>
          <Heading fontSize="1rem" as="h3" color="primary.500">
            {chosenCreation?.title}
          </Heading>
          <Heading fontSize="1rem" as="h3">
            for your {choosingForYour}
          </Heading>
        </Flex>
      )}

      <Flex mx="-1rem">
        <Flex
          maxW="100%"
          gap="1rem"
          px="1rem"
          overflowX="auto"
          pb="1rem"
          m="auto"
        >
          <Flex
            minW="150px"
            h="220px"
            minH="220px"
            border="solid 4px"
            borderColor="primary.500"
            borderRadius="10px"
            color="primary.500"
            fontWeight="bold"
            alignItems="center"
            justifyContent="center"
            fontSize="48px"
            onClick={() => navigate("/upload")}
            cursor="pointer"
          >
            <AddIcon />
          </Flex>
          {readyCreations.map((creation) => (
            <CharacterCard
              key={creation.id}
              creation={creation}
              onClick={() => onChoose(creation)}
              imageHeight="220px"
              borderImage={creation.id === chosenCreation?.id}
            />
          ))}
        </Flex>
      </Flex>
      {disclamer}
      <Flex mt="1rem">
        <Button
          onClick={onCreate}
          loadingText="Creating magic..."
          isLoading={loading}
          isDisabled={!chosenCreation || loading}
          w="100%"
        >
          {chooseText}
        </Button>
      </Flex>
    </Box>
  )
}
