import { Box, BoxProps, Button, Flex } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { useUserContext } from "../../../context/userContext"
import bg from "../../assets/images/backgrounds/app-bg.png"
import Background from "../../components/Wrapper/Background"
import CanvasIcon from "../Icons/CanvasIcon"
import ChatIcon from "../Icons/ChatIcon"
import CommunityIcon from "../Icons/CommunityIcon"
import HomeIcon from "../Icons/HomeIcon"
import BackgroundImageBlurred from "./BackgroundImageBlurred"
import ResponsiveContainer from "./ResponsiveContainer"

function CreationsIcon() {
  const { userCreations } = useUserContext()
  const hasNewCreations = userCreations.some(
    (creation) =>
      creation.selectedImage === undefined ||
      creation.selectedImage.length === 0
  )

  return (
    <Box
      w={["28px", "28px", "28px", "48px"]}
      h={["28px", "28px", "28px", "48px"]}
      position="relative"
    >
      <CanvasIcon w="100%" h="100%" />
      <Box
        position="absolute"
        display={hasNewCreations ? "block" : "none"}
        width="12px"
        height="12px"
        borderRadius="12px"
        top="-2px"
        right="0"
        bg="secondary.500"
      />
    </Box>
  )
}

const NAV_ELEMENTS: {
  icon: React.ReactNode
  text: string
  route: string
  width: string
}[] = [
  {
    icon: (
      <HomeIcon
        width={["28px", "28px", "28px", "48px"]}
        height={["28px", "28px", "28px", "48px"]}
      />
    ),
    text: "Create",
    route: "/app",
    width: "100%",
  },
  {
    icon: (
      <ChatIcon
        width={["28px", "28px", "28px", "48px"]}
        height={["28px", "28px", "28px", "48px"]}
      />
    ),
    text: "Chat",
    route: "/chat",
    width: "100%",
  },
  {
    icon: <CreationsIcon />,
    text: "My studio",
    route: "/creations",
    width: "100%",
  },
  {
    icon: (
      <CommunityIcon
        width={["28px", "28px", "28px", "48px"]}
        height={["28px", "28px", "28px", "48px"]}
      />
    ),
    text: "Community",
    route: "/community",
    width: "100%",
  },
]

function checkPathname(pathname: string, route: string) {
  return pathname === route || pathname.startsWith(route + "/")
}

export function Navbar() {
  const navigate = useNavigate()
  return (
    <Flex
      position="fixed"
      top="0"
      left="50%"
      width={"100%"}
      height={["48px", "48px", "64px", "96px"]}
      background="linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0))"
      transform="translateX(-50%)"
      placeContent="space-between"
      alignItems="center"
      px={["1em", "2em"]}
      zIndex={11}
    >
      <Flex w="100%" maxW="600px" m="auto" h="100%">
        {NAV_ELEMENTS.map((element) => {
          const active = checkPathname(window.location.pathname, element.route)
          return (
            <Button
              variant="icon"
              onClick={() => navigate(element.route)}
              key={element.route}
              height="100%"
              p="0"
              w={element.width}
            >
              <Flex
                height="100%"
                color={"#fff"}
                opacity={active ? 1 : 0.5}
                flexDir="column"
                position="relative"
              >
                <Box margin="auto">{element.icon}</Box>
                {/* <Text
                  fontSize={["10px", "10px", "16px"]}
                  color="inherit"
                  mb="auto"
                  mt="0.25rem"
                >
                  {element.text}
                </Text> */}
              </Flex>
            </Button>
          )
        })}
      </Flex>
    </Flex>
  )
}

export default function Navigation({
  includeHeader = false,
  showBackButton = false,
  backgroundColor,
  backgroundImage = bg,
  blurredBackground = false,
  ...props
}: {
  includeHeader?: boolean
  showBackButton?: boolean
  blurredBackground?: boolean
} & BoxProps) {
  return (
    <Background overflowY="auto">
      <BackgroundImageBlurred
        backgroundColor={backgroundColor}
        blur={blurredBackground}
      />
      <Navbar />
      <ResponsiveContainer pt={["64px", "64px", "80px", "112px"]} {...props} />
    </Background>
  )
}
