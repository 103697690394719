import { Box, Grid, Heading, Text } from "@chakra-ui/react"
import { CommunityActionBlock } from "../../components/ActionBlocks/CommunityActionBlock"
import UploadActionBlock from "../../components/ActionBlocks/UploadActionBlock"
import BackButton from "../../components/Buttons/BackButton"
import Navigation from "../../components/Wrapper/Navigation"
import WebtoonList from "../Creations/WebtoonList/WebtoonList"

export default function StoriesBought() {
  return (
    <Navigation px="1rem !important">
      <Grid
        mb="1rem"
        gridTemplateColumns="50px 1fr 50px"
        mx={["-0.5rem", "-0.5rem", "0"]}
      >
        <BackButton color="white" buttonBg />
        <Heading
          fontSize="24px"
          my="auto"
          textAlign="center"
          color="white"
          textShadow="0 0 4px black"
        >
          Create your stories
        </Heading>
      </Grid>
      <Box
        bg="#ffffffb0"
        backdropFilter="blur(4px)"
        borderRadius="32px"
        p={["1.5rem"]}
        mx="-1.5rem"
      >
        <Heading size="sm" mt="1rem" color="black">
          Embark on journeys to fantastical realms with our magical comic book
          collection
        </Heading>
        <Text fontWeight="light" color="black">
          And learn empowering values
        </Text>
        <WebtoonList hideTitle lightMode />
        <Heading size="sm" mt="2rem" color="black">
          ...And keep creating magic!
        </Heading>
        <Box maxW="500px" mt="1rem">
          <UploadActionBlock />
        </Box>
        <Heading size="sm" mt="2rem" color="black">
          Check what kids are creating
        </Heading>
        <Box maxW="500px" mt="1rem">
          <CommunityActionBlock />
        </Box>
      </Box>
    </Navigation>
  )
}
