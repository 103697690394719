import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useLoading } from "../../../context/LoadingContext"
import { useSegment } from "../../../context/SegmentContext"
import { useUserContext } from "../../../context/userContext"
import { Pronouns } from "../../../utilities/dateUtils"
import giftBck from "../../assets/images/backgrounds/giftBck.jpg"
import PronounSelect from "../../components/Select/PronounsSelect"
import AuthContainer from "../../components/Wrapper/AuthContainer"
import { useQuery } from "../../../utilities/hooks"

export default function SignUpInfo() {
  const [childName, setChildName] = useState("")
  const [pronouns, setPronouns] = useState(undefined as Pronouns | undefined)
  const { setLoading } = useLoading()
  const { track } = useSegment()
  const navigate = useNavigate()
  const { setUserInfo, authError, setAuthError, isLoadingUser } =
    useUserContext()
  const query = useQuery()

  const onSubmit: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault()
    setLoading(true)
    let cname = childName.trim()
    if (cname.length > 0 && pronouns) {
      setUserInfo(cname, pronouns).then(() => {
        const redirect = query.get("redirectTo")
        if (redirect) {
          navigate(redirect)
        } else {
          navigate("/app")
        }
        track("Sign Up Part 2", { name: cname })
      })
    } else {
      setAuthError("Please fill in all the fields")
    }
    setLoading(false)
  }

  useEffect(() => {
    const allImages = [giftBck]
    const cacheAssets = async (imagesArray: any[], callback: () => any) => {
      setLoading(true)
      const promises = imagesArray.map((src) => {
        return new Promise((resolve, reject) => {
          const img = new Image()
          img.src = src
          img.onload = () => resolve(true)
          img.onerror = () => reject(true)
        })
      })
      await Promise.all(promises)
      callback()
    }
    cacheAssets(allImages, () => {
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AuthContainer hideSSO>
      <FormControl
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        p={"1em"}
      >
        <Box width={"full"} display="flex">
          <FormLabel flex="2">
            <Flex mb="0.25rem">
              <Text
                fontSize="12px"
                color="secondary.500"
                fontWeight="bold"
                my="auto"
                mr="0.25rem"
              >
                Username for your child
              </Text>
              <Tooltip
                hasArrow
                label="For improved child safety, kindly create a username that is different from their real name within the app."
                bg="secondary.100"
                placement="top"
                color="black"
              >
                <Button
                  bg="secondary.200"
                  variant="fullSecondary"
                  size="xs"
                  p="0"
                  width="24px"
                  height="24px"
                >
                  ?
                </Button>
              </Tooltip>
            </Flex>
            <Input
              name="child"
              id="child"
              border="solid 1px #dbdbdb"
              type="text"
              placeholder="Enter a username..."
              onChange={(e) => setChildName(e.target.value)}
              borderRadius="100px"
              _placeholder={{
                color: "#666",
              }}
            />
          </FormLabel>
          <FormLabel mt="auto" flex="1">
            <PronounSelect
              placeholder="Pronouns"
              bg="white"
              onChange={(e) => setPronouns(e.currentTarget.value as Pronouns)}
              borderRadius="full"
            />
          </FormLabel>
        </Box>
        {authError && (
          <Text
            size="xs"
            variant="error"
            textAlign="center"
            fontWeight="bold"
            mb="1rem"
          >
            {authError}
          </Text>
        )}
        <Button
          mt="1rem"
          type="submit"
          onClick={onSubmit}
          width="full"
          isLoading={isLoadingUser}
          loadingText="Setting up your account..."
          variant="fullSecondary"
        >
          Start the journey
        </Button>
      </FormControl>
    </AuthContainer>
  )
}
