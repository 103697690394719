import { Box, BoxProps, Button, Flex, Text } from "@chakra-ui/react"
import { useSegment } from "../../../context/SegmentContext"
import useChatSuggestions, { ChatSuggestion } from "./useChatSuggestions"

export default function ChatIdeas({
  onChatSuggestion,
  isOpen,
  onOpen,
  height = "64px",
  header,
  py,
  ...props
}: {
  onChatSuggestion: (suggestion: ChatSuggestion) => void
  isOpen: boolean
  onOpen?: () => void
  height?: string
  header?: React.ReactNode
} & BoxProps) {
  const { actions, questions } = useChatSuggestions()
  const { track } = useSegment()

  if (!isOpen && onOpen) {
    return (
      <Button
        variant="fullSecondary"
        ml="auto"
        onClick={onOpen}
        size="sm"
        mb="1rem"
      >
        Chat ideas
      </Button>
    )
  }
  return (
    <Box
      transform={isOpen ? "translateY(0)" : "translateY(100%)"}
      transition="transform 300ms ease-out"
      px="1rem"
      {...props}
      py={py}
    >
      <Flex>{header}</Flex>
      <Box mx="-1rem">
        <Flex mt="0.5rem" gap="0.5rem" px="1rem" overflow="auto" pb="0.5rem">
          {actions.map((action) => (
            <Flex
              key={action.title}
              w="96px"
              h={height}
              minW={"96px"}
              minH={height}
              bgImage={action.imageUrl}
              bgPos="center"
              bgSize="cover"
              borderRadius="12px"
              cursor="pointer"
              onClick={() => {
                track("Click on chat suggestion", {
                  type: "action",
                  suggestion: action.title,
                })
                onChatSuggestion(action)
              }}
              userSelect="none"
            >
              <Flex w="100%" bg="rgba(0,0,0,0.3)" borderRadius="12px">
                <Text
                  size="sm"
                  textAlign="center"
                  m="auto"
                  fontWeight="bold"
                  color="white"
                >
                  {action.title}
                </Text>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Box>
      <Box mx="-1rem">
        <Flex
          mt="0.5rem"
          gap="0.5rem"
          px="1rem"
          h="fit-content"
          pb="1rem"
          overflow="auto"
        >
          {questions.map((question) => (
            <Flex
              key={question.title}
              w="96px"
              h={height}
              minW="96px"
              minH={height}
              bgImage={question.imageUrl}
              bgPos="center"
              bgSize="cover"
              borderRadius="12px"
              cursor="pointer"
              onClick={() => {
                track("Click on chat suggestion", {
                  type: "question",
                  suggestion: question.title,
                })
                onChatSuggestion(question)
              }}
              userSelect="none"
            >
              <Flex w="100%" bg="rgba(0,0,0,0.3)" borderRadius="12px">
                <Text
                  size="sm"
                  textAlign="center"
                  m="auto"
                  fontWeight="bold"
                  color="white"
                >
                  {question.title}
                </Text>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Box>
    </Box>
  )
}
