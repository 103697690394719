import { Box } from "@chakra-ui/react"

const Background: typeof Box = (props: {}) => {
  return (
      <Box
        id="background"
        width="100%"
        height={"100vh; height: calc(var(--vh, 1vh) * 100)"}
        // minH={"100%"}
        bgPos={"center"}
        bgSize={"cover"}
        {...props}
      />
  )
}

export default Background
