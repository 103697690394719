import { Text } from "@chakra-ui/react"
import Background from "../../components/Wrapper/Background"
import CreationsTablePending from "./CreationsTablePending"
import "./Dashboard.css"

export default function DashboardPending() {
  return (
    // <Flex
    //   flexDirection={"column"}
    //   justifyContent="center"
    //   justifyItems={"center"}
    //   alignItems={"center"}
    //   maxHeight={"100vh; max-height: var(--vh, 1vh) * 100;"}
    //   height={"100vh; height: var(--vh, 1vh) * 100;"}
    // >
    <Background>
      <Text margin={"20px"} color={"black"} fontWeight="bold">
        Dashboard
      </Text>
      <CreationsTablePending />
    </Background>
    // </Flex>
  )
}
