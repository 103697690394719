import { Box, BoxProps, Image } from "@chakra-ui/react"
import "./PendingCreationAnimation.css"
import character1 from "../../assets/images/pending-animation/character-1.png"
import character2 from "../../assets/images/pending-animation/character-2.png"
import character3 from "../../assets/images/pending-animation/character-3.png"
import character4 from "../../assets/images/pending-animation/character-4.png"
import character5 from "../../assets/images/pending-animation/character-5.png"
import character6 from "../../assets/images/pending-animation/character-6.png"

export default function PendingCreationAnimation(props: BoxProps) {
  return (
    <Box className="animated-pending-creation" {...props} position="relative">
      <Image
        src={character1}
        w="100%"
        h="100%"
        objectFit="contain"
        position="absolute"
      />
      <Image
        src={character2}
        w="100%"
        h="100%"
        objectFit="contain"
        position="absolute"
      />
      <Image
        src={character3}
        w="100%"
        h="100%"
        objectFit="contain"
        position="absolute"
      />
      <Image
        src={character4}
        w="100%"
        h="100%"
        objectFit="contain"
        position="absolute"
      />
      <Image
        src={character5}
        w="100%"
        h="100%"
        objectFit="contain"
        position="absolute"
      />
      <Image
        src={character6}
        w="100%"
        h="100%"
        objectFit="contain"
        position="absolute"
      />
    </Box>
  )
}
