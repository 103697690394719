import { Box, Image, Text } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { useUserContext } from "../../../context/userContext"
import Creation from "../../../data/models/Creation"
import CardBottomClouds from "../SectionSeparators/CardBottomClouds"
import { getAgeString } from "../../../utilities/dateUtils"

export default function CharacterCard({
  creation,
  imageHeight = "220px",
  imageWidth = "180px",
  url = `/creations/${creation.id}`,
  cloudsColor = "primary.500",
  onClick,
  borderImage = false,
  showCreatedBy = true,
}: {
  creation: Creation
  imageHeight?: string
  imageWidth?: string
  url?: string
  cloudsColor?: string
  onClick?: () => any
  borderImage?: boolean
  showCreatedBy?: boolean
}) {
  const navigate = useNavigate()
  const { user } = useUserContext()

  const isSketchQuest = creation.questId != null

  return (
    <Box
      cursor="pointer"
      position="relative"
      minWidth={imageWidth}
      maxW={imageWidth}
      h={imageHeight}
      maxH={imageHeight}
      onClick={onClick ?? (() => navigate(url))}
    >
      <Image
        w={imageWidth}
        h={imageHeight}
        objectFit="cover"
        src={creation.selectedImage}
        borderRadius="12px"
        mb="0.5em"
        border={borderImage ? "4px solid" : "none"}
        borderColor={cloudsColor}
      />
      {!isSketchQuest ? (
        creation.originalImagePath && (
          <Image
            height="70px"
            width="60px"
            objectFit="cover"
            src={creation.originalImagePath}
            borderRadius="5px"
            bottom="6px"
            left="6px"
            position="absolute"
            zIndex={1}
            bg="white"
          />
        )
      ) : (
        <Text
          position="absolute"
          size="sm"
          color="white"
          fontWeight="bold"
          borderRadius="12px"
          width={"100%"}
          px="1rem"
          bg="secondary.500"
          top="0"
          textAlign={"center"}
        >
          {"Sketch Quest"}
        </Text>
      )}
      <CardBottomClouds
        borderRadius="12px"
        position="absolute"
        bottom="0"
        left="0"
        width={imageWidth}
        height="auto"
        maxHeight="96px"
        color={cloudsColor}
      />
      <Box
        position="absolute"
        bottom="0"
        left="0"
        w="100%"
        pb="0.5rem"
        pl={isSketchQuest ? "1rem" : "75px"}
        pr="1rem"
      >
        {showCreatedBy ? (
          <Text
            color="white"
            fontSize="10px"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            fontWeight="light"
            maxW="100%"
          >
            Created by {user?.childName}
          </Text>
        ) : (
          <Text
            color="white"
            fontSize="10px"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            fontWeight="light"
            maxW="100%"
          >
            {getAgeString(creation.createdAt)} old
          </Text>
        )}
        <Text
          color="white"
          fontSize="14px"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          fontWeight="bold"
          maxW="100%"
        >
          {creation.title}
        </Text>
      </Box>
    </Box>
  )
}
