import { Icon, IconProps } from "@chakra-ui/react"

export default function FacebookIcon(props: IconProps) {
  return (
    <Icon
      width="14"
      height="24"
      viewBox="0 0 14 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.89414 13.8H11.8941L13.0941 9H8.89414V6.6C8.89414 5.364 8.89414 4.2 11.2941 4.2H13.0941V0.168C12.7029 0.1164 11.2257 0 9.66574 0C6.40774 0 4.09414 1.9884 4.09414 5.64V9H0.494141V13.8H4.09414V24H8.89414V13.8Z"
        fill="currentColor"
      />
    </Icon>
  )
}
