import { Box, Flex, Text } from "@chakra-ui/react"
import useWaitingStories from "../../pages/WaitingStories/useWaitingStories"
import SlidingPopup from "./SlidingPopup"
import { useNavigate } from "react-router-dom"

export default function WaitingStoriesPopup({
  isOpen,
  onClose,
  creationId,
}: {
  isOpen: boolean
  onClose: () => any
  creationId?: string
}) {
  const { stories } = useWaitingStories()
  const navigate = useNavigate()

  return (
    <SlidingPopup
      id="waiting-stories"
      show={isOpen}
      onClose={onClose}
      backgroundColor="linear-gradient(180deg, #342D87 0%, #6459E4 100%)"
      header={null}
      hideCloseButton
      hideOverlay
    >
      <Text fontWeight="500" color="white" my="0.5rem" textAlign="center">
        Read a story while <br />
        you wait for your art
      </Text>
      <Flex overflow="auto" gap="1rem" pb="0.5rem">
        {stories.map((story, i) => (
          <Box
            key={i}
            minW="350px"
            w="350px"
            h="200px"
            backgroundImage={[
              story.previewUrlMobile,
              story.previewUrlMobile,
              story.PreviewUrlDesktop,
            ]}
            bgPos="center"
            bgSize="cover"
            borderRadius="12px"
            cursor="pointer"
            onClick={() => {
              if (creationId)
                navigate(`/waiting-story/${story._key}?waitingFor=${creationId}`)
              else navigate(`/waiting-story/${story._key}`)
            }}
          >
            <Flex
              borderRadius="12px"
              w="100%"
              h="100%"
              bg="linear-gradient(180deg, #00000000 0%, #00000077 70%, #000000cc 100%)"
            >
              <Text
                color="white"
                mt="auto"
                mb="0.5rem"
                textAlign="center"
                w="100%"
              >
                {story.title}
              </Text>
            </Flex>
          </Box>
        ))}
      </Flex>
    </SlidingPopup>
  )
}
