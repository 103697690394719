import { CloseIcon, DeleteIcon, DownloadIcon } from "@chakra-ui/icons"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Grid,
  HStack,
  Input,
  Select,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { RefObject, useRef, useState } from "react"
import { CirclePicker, GithubPicker } from "react-color"
import { useNavigate, useParams } from "react-router-dom"
import { ReactSketchCanvas, ReactSketchCanvasRef } from "react-sketch-canvas"
import { useUserContext } from "../../../context/userContext"
import { useSketchQuests } from "../../../data/apis/SketchQuestUtils"
import CreationService from "../../../service/CreationService"
import { dataURItoBlob } from "../../../utilities/canvasUtils"
import { hexColorToRgbString, resizeFile } from "../../../utilities/imageUtils"
import { sleep } from "../../../utilities/sleep"
import BackButton from "../../components/Buttons/BackButton"
import BrushIcon from "../../components/Icons/BrushIcon"
import DrawingAddIcon from "../../components/Icons/DrawingAddIcon"
import EraserIcon from "../../components/Icons/EraserIcon"
import StarIcon from "../../components/Icons/StarIcon"
import StrokeWidthIcon from "../../components/Icons/StrokeWidthIcon"
import UndoIcon from "../../components/Icons/UndoIcon"
import Background from "../../components/Wrapper/Background"
import ResponsiveContainer from "../../components/Wrapper/ResponsiveContainer"
import DrawingDetailsForm from "../Upload/Forms/DrawingDetailsForms"
import { WeeklyThemeUploadImage } from "../WeeklyTheme/WeeklyTheme"

// List of background images
const backgroundImages = [
  "https://pradaandpearls.com/wp-content/uploads/2020/10/Blank-600-x-900-162.jpg",
  "https://pradaandpearls.com/wp-content/uploads/2020/10/Blank-600-x-900-162.jpg",
  "https://media1.popsugar-assets.com/files/thumbor/G7TaFrS9Cl5LIEDYSImEmgghMyo/fit-in/2048xorig/filters:format_auto-!!-:strip_icc-!!-/2020/09/23/919/n/1922507/69627564b15b793f_eberhard-grossgasteiger-J9NDmBVhN04-unsplash/i/Pastel-Sky-iPhone-Wallpaper.jpg",
]

const COLORS = [
  "#000000",
  "#FFFFFF",
  "#FF6900",
  "#FCB900",
  "#7BDCB5",
  "#00D084",
  "#8ED1FC",
  "#0693E3",
  "#ABB8C3",
  "#EB144C",
  "#F78DA7",
  "#9900EF",
]

function Alert({
  isOpen,
  onClose,
  onConfirm,
  confirmText,
  cancelText,
  text,
  title,
}: {
  isOpen: boolean
  onClose: () => void
  onConfirm?: () => void
  confirmText: string
  cancelText: string
  title: string
  text: string
}) {
  const ref = useRef(null)
  return (
    <AlertDialog
      leastDestructiveRef={ref}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>{title}</AlertDialogHeader>
          <AlertDialogBody whiteSpace="pre-wrap" pt="1rem">
            {text}
          </AlertDialogBody>

          <AlertDialogFooter gap="1rem">
            <Button onClick={onClose}>{cancelText}</Button>
            <Button
              onClick={() => {
                if (onConfirm) onConfirm()
                onClose()
              }}
              variant="border"
            >
              {confirmText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

const BackgroundCarousel: React.FC<{
  onSelectBackground: (image: string) => void
}> = ({ onSelectBackground }) => {
  return (
    <HStack spacing={4}>
      {backgroundImages.map((image) => (
        <Button
          key={image}
          p={0}
          borderRadius="lg"
          overflow="hidden"
          onClick={() => onSelectBackground(image)}
        >
          <Box
            w="80px"
            h="80px"
            backgroundImage={`url(${image})`}
            backgroundSize="cover"
            backgroundPosition="center"
          />
        </Button>
      ))}
    </HStack>
  )
}

function Canvas({
  onValidateDrawing,
  submitButtonText,
  drawingRef,
}: {
  onValidateDrawing: (drawingUrl: string) => void
  submitButtonText: string
  drawingRef: RefObject<ReactSketchCanvasRef>
}) {
  const [strokeWidth, setStrokeWidth] = useState<number>(4)
  const [strokeColor, setStrokeColor] = useState<string>("#000000")
  const [eraserMode, setEraserMode] = useState<boolean>(false)
  const [backgroundImage, setBackgroundImage] = useState<string>("")
  const [showCarousel, setShowCarousel] = useState<boolean>(false)
  const closeDisc = useDisclosure()
  const navigate = useNavigate()
  const eraseDisc = useDisclosure()
  const showMobileColorDisc = useDisclosure()

  const handleGetImage = () => {
    if (drawingRef.current) {
      drawingRef.current.exportImage("png").then((data) => {
        const link = document.createElement("a")
        link.href = data
        link.download = "image.png"
        link.click()
      })
    }
  }

  const onValidate = () => {
    drawingRef.current?.exportImage("png").then((data) => {
      const img = dataURItoBlob(data)
      resizeFile(img).then((img) => {
        if (img) onValidateDrawing(img)
      })
    })
  }

  const handleColorChange = ({ hex }: any) => {
    setEraserMode(false)
    setStrokeColor(hex)
  }

  const handleSelectBackground = (image: string) => {
    setBackgroundImage(image)
    setShowCarousel(false)
  }
  const handleRemoveBackground = () => {
    setBackgroundImage("")
  }

  const handleFileUpload: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const file = event.target.files?.item(0)
    if (file) {
      if (file.type.substring(0, 5) === "image") {
        resizeFile(file).then((img) => {
          if (img) setBackgroundImage(img)
        })
      }
    }
  }

  const handleBackgroundUploadClick = () => {
    const fileInput = document.getElementById("background-upload")
    if (fileInput) {
      fileInput.click()
    }
  }

  const canvasStyle: React.CSSProperties = {
    border: `1px solid black`,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "calc(100% - 100px)",
    maxHeight: "calc(1600px - 100px)",
    cursor: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${
      strokeWidth * 2
    }" height="${
      strokeWidth * 2
    }"><circle cx="${strokeWidth}" cy="${strokeWidth}" r="${
      strokeWidth / 2
    }" fill="none" stroke="${
      eraserMode ? "black" : hexColorToRgbString(strokeColor)
    }" /></svg>') ${strokeWidth} ${strokeWidth}, auto`,
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : "none",
    backgroundPosition: "center",
    backgroundSize: "contain", // Update to "contain"
  }

  return (
    <Background position="fixed" left="0" top="0">
      <ResponsiveContainer
        flexDir="column"
        height="100%"
        maxHeight="calc(1600px - 100px)"
        display="flex"
        padding="0!important"
        background="primary.100"
        width={["100%", "100%", "100%", "800px", "1200px"]}
      >
        <Alert
          isOpen={closeDisc.isOpen}
          onClose={closeDisc.onClose}
          onConfirm={() => navigate(-1)}
          confirmText="Yes, go back"
          cancelText="No, stay here"
          title="Leave page?"
          text="You will lose your drawing if you go back."
        />
        <Alert
          isOpen={eraseDisc.isOpen}
          onClose={eraseDisc.onClose}
          onConfirm={drawingRef.current?.clearCanvas}
          confirmText="Erase"
          cancelText="Keep drawing"
          title="Do you want to erase your drawing?"
          text="This will erase your drawing. You can't undo this action."
        />

        <Box height="calc(100% - 100px)" width="100%" zIndex={0}>
          <ReactSketchCanvas
            ref={drawingRef}
            strokeWidth={strokeWidth}
            eraserWidth={strokeWidth}
            strokeColor={eraserMode ? "white" : strokeColor}
            style={canvasStyle}
            backgroundImage={backgroundImage}
            preserveBackgroundImageAspectRatio="xMidYMid slice"
            exportWithBackgroundImage
          />
          <BackButton
            icon={<CloseIcon w="10px" h="10px" m="auto" />}
            position="fixed"
            buttonBg
            left="1rem"
            color="white"
            top="1rem"
            width="32px"
            height="32px"
            onClick={closeDisc.onOpen}
          />
        </Box>

        <Box
          display={"flex"}
          alignItems="center"
          bg="primary.100"
          height="100px"
          gap="0.5rem"
          px="1rem"
          overflow="auto"
        >
          <Input
            id="background-upload"
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleFileUpload}
          />

          {showCarousel && (
            <Box mt={4}>
              <BackgroundCarousel onSelectBackground={handleSelectBackground} />
            </Box>
          )}

          <Box display={["none", "none", "none", "block"]}>
            <CirclePicker
              circleSize={20}
              circleSpacing={12}
              colors={COLORS}
              color={strokeColor}
              onChangeComplete={handleColorChange}
              styles={{
                default: {
                  card: {
                    width: "200px",
                    minWidth: "200px",
                  },
                },
              }}
            />
          </Box>
          <Grid gap="0.5rem" gridTemplateRows="32px 32px" minW="fit-content">
            <Text
              color="primary.500"
              fontWeight="bold"
              fontSize="14px"
              my="auto"
            >
              <StrokeWidthIcon mr="0.25rem" width="20px" height="20px" />
              Size
            </Text>
            <Select
              size="xs"
              bg="white"
              w="fit-content"
              borderRadius="8px"
              height="32px"
              onChange={(e) => setStrokeWidth(parseInt(e.target.value))}
              value={strokeWidth.toString()}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="4">4</option>
              <option value="8">8</option>
              <option value="16">16</option>
              <option value="32">32</option>
              <option value="64">64</option>
            </Select>
          </Grid>
          <Grid gap="0.5rem" gridTemplateColumns={"1fr 1fr"}>
            <Box position="relative">
              <Box
                display={
                  showMobileColorDisc.isOpen
                    ? ["block", "block", "block", "none"]
                    : "none"
                }
              >
                <GithubPicker
                  triangle="hide"
                  width="162px"
                  colors={COLORS}
                  color={strokeColor}
                  onChangeComplete={(color) => {
                    handleColorChange(color)
                    showMobileColorDisc.onClose()
                  }}
                  styles={{
                    default: {
                      card: {
                        position: "fixed",
                        bottom: "100px",
                        left: "18px",
                      },
                    },
                  }}
                />
              </Box>

              <Button
                size="sm"
                p="0"
                onClick={() => {
                  showMobileColorDisc.onToggle()
                  setEraserMode(false)
                }}
                position="relative"
                _before={
                  !eraserMode
                    ? {
                        content: '""',
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "calc(100% + 6px)",
                        height: "calc(100% + 6px)",
                        borderRadius: "50%",
                        border: "solid 3px",
                        borderColor: "primary.300",
                      }
                    : {}
                }
              >
                <BrushIcon />
              </Button>
            </Box>
            <Button
              size="sm"
              p="0"
              onClick={() => setEraserMode(true)}
              position="relative"
              _before={
                eraserMode
                  ? {
                      content: '""',
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "calc(100% + 6px)",
                      height: "calc(100% + 6px)",
                      borderRadius: "50%",
                      border: "solid 3px",
                      borderColor: "primary.300",
                    }
                  : {}
              }
            >
              <EraserIcon />
            </Button>
            <Button
              size="sm"
              p="0"
              w="100%"
              onClick={drawingRef.current?.undo}
              gridColumnStart={1}
              gridColumnEnd="span 2"
              fontSize={["10px", "12px", "14px"]}
            >
              <UndoIcon mr="0.25rem" /> Undo
            </Button>
          </Grid>
          <Flex flexDir="column" gap="0.5rem" ml="auto">
            <Button variant={"white"} size="sm" onClick={eraseDisc.onOpen} p="0">
              <DeleteIcon />
            </Button>
            <Button variant="white" onClick={handleGetImage} size="sm" p="0">
              <DownloadIcon />
            </Button>
          </Flex>
          <Flex flexDir="column" gap="0.5rem">
            <Button
              onClick={
                backgroundImage
                  ? handleRemoveBackground
                  : handleBackgroundUploadClick
              }
              fontSize={["10px", "12px", "14px"]}
              size="sm"
            >
              {backgroundImage ? (
                <>
                  <CloseIcon mr="0.25rem" />
                  Background
                </>
              ) : (
                <>
                  <DrawingAddIcon mr="0.25rem" height="20px" width="20px" />
                  Background
                </>
              )}
            </Button>

            <Button
              size="sm"
              onClick={onValidate}
              fontSize={["10px", "12px", "14px"]}
              variant="fullSecondary"
            >
              <StarIcon mr="0.25rem" width="16px" height="16px" />
              {submitButtonText}
            </Button>
          </Flex>
        </Box>
      </ResponsiveContainer>
    </Background>
  )
}

export default function Draw() {
  const [drawingUrl, setDrawingUrl] = useState("")
  const { themeId } = useParams()
  const ref = useRef<ReactSketchCanvasRef>(null)
  const { user } = useUserContext()
  const toast = useToast()
  const navigate = useNavigate()
  const { sketchQuests } = useSketchQuests()

  let content = <></>

  if (drawingUrl) {
    if (themeId) {
      content = (
        <WeeklyThemeUploadImage
          onUpload={async (description) => {
            if (!user) return
            const sq = sketchQuests.find((sq) => sq._key === themeId)
            if (!sq) return

            await CreationService.uploadQuestCreation(
              user,
              drawingUrl,
              sq?.title,
              description,
              sq?._key,
              true
            ).then(() => {
              navigate("/app")
            })
          }}
          drawingUrl={drawingUrl}
          clearDrawing={() => setDrawingUrl("")}
        />
      )
    } else {
      content = (
        <DrawingDetailsForm
          handleUpload={async (title, description, pronouns) => {
            if (!user || !pronouns || !description || !title) {
              await sleep(500)
              const fields = []
              if (!title) fields.push("Name")
              if (!description) fields.push("Description")
              if (!pronouns) fields.push("Pronouns")

              toast({
                title: "Fields missing",
                description: `Please enter the following fields: ${fields.join(
                  ", "
                )}`,
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
              })
              return
            }
            await CreationService.uploadCreation(
              user,
              drawingUrl,
              title,
              description,
              pronouns,
              true
            ).then(() => {
              navigate("/app")
            })
          }}
          imageUrl={drawingUrl}
          resetImage={() => setDrawingUrl("")}
        />
      )
    }
  }

  return (
    <>
      <Background
        opacity={drawingUrl ? 0 : 1}
        position={drawingUrl ? "fixed" : undefined}
        pointerEvents={drawingUrl ? "none" : undefined}
        background="primary.100"
      >
        <Canvas
          drawingRef={ref}
          onValidateDrawing={setDrawingUrl}
          submitButtonText={themeId ? "Complete quest" : "Transform"}
        />
      </Background>
      {content}
    </>
  )
}
