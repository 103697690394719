import { Button, Heading, Text, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useUserContext } from "../../../../context/userContext"
import Creation from "../../../../data/models/Creation"
import Story from "../../../../data/models/Story"
import { UserStoryPack } from "../../../../data/models/UserStoryPack"
import WebtoonService from "../../../../service/WebtoonService"
import { ORDER_NAMES } from "../../../../utilities/dateUtils"
import SlidingPopup from "../../../components/Popup/SlidingPopup"
import StoryBookCard from "../../../components/Stories/StoryBookCard"
import ChooseCharacterMenu from "../../../components/Creations/ChooseCharacterMenu"

function UnlockedContent({
  story,
  storyCount,
  onClick,
}: {
  story?: Story
  storyCount: number
  onClick: () => void
}) {
  let orderName = ORDER_NAMES[story?.order ?? 0]
  const [shownStory, setShownStory] = useState<Story>()

  useEffect(() => {
    if (story) {
      setShownStory(story)
    }
  }, [story])

  if ((story?.order ?? -1) + 1 === storyCount) {
    orderName = "last"
  }
  return (
    <>
      <Heading size="md" mb="1rem" textAlign="center">
        You unlocked the <br />
        {orderName} universe!
      </Heading>
      {shownStory && (
        <StoryBookCard m="auto" h="350px" w="250px" story={shownStory} />
      )}
      <Button w="100%" mt="1rem" onClick={onClick}>
        Choose my character
      </Button>
    </>
  )
}

export default function WebtoonCreatePopup({
  open = true,
  storyToBuy,
  userStoryPack,
  storyCount,
  onClose,
}: {
  open?: boolean
  storyToBuy: Story | undefined
  userStoryPack: UserStoryPack
  onClose: () => void
  storyCount: number
}) {
  const toast = useToast()
  const navigate = useNavigate()
  const { user, userCreations } = useUserContext()
  const [step, setStep] = useState(0)
  const [creation, setCreation] = useState(null as Creation | null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setStep(0)
  }, [storyToBuy])

  const createWebtoon = async () => {
    if (user && creation && storyToBuy) {
      setLoading(true)
      await WebtoonService.create(
        user,
        creation.id,
        storyToBuy.id,
        userStoryPack.id
      )
        .then((wt) => {
          navigate(`/creations/webtoon/${wt.userStoryPackId}/${wt.id}/success`)
        })
        .catch((e) => {
          if (e instanceof Error)
            toast({
              title: "Error",
              description: "Something went wrong, please try again later",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <SlidingPopup
      id="webtoon-create"
      show={storyToBuy !== undefined && open}
      onClose={onClose}
    >
      {step === 0 ? (
        <>
          <UnlockedContent
            onClick={() => setStep(1)}
            storyCount={storyCount}
            story={storyToBuy}
          />
        </>
      ) : (
        <ChooseCharacterMenu
          creations={userCreations}
          chosenCreation={creation}
          onChoose={setCreation}
          onCreate={createWebtoon}
          loading={loading}
          hideSketchQuests
          disclamer={
            <Text size="sm" textAlign="center" maxW="350px" mx="auto" mt="1rem">
              Once you make your choice, we will create your MiniTale with this
              character as the hero. <br />
              <br />
              This can’t be changed later, make sure you select a character you
              love for this story.
            </Text>
          }
        />
      )}
    </SlidingPopup>
  )
}
