import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react"
import stars from "../../assets/images/homepage/living-characters/stars.png"
import charactersDesk from "../../assets/images/homepage/living-characters/three-characters.png"
import charactersMobile from "../../assets/images/homepage/living-characters/three-characters-mobile.png"
import Button3d from "../../components/Buttons/Button3d"
import ResponsiveContainer from "../../components/Wrapper/ResponsiveContainer"

export default function LivingCharactersBlock({
  onClickMission,
  onClickPrivacy,
}: {
  onClickMission: () => void
  onClickPrivacy: () => void
}) {
  return (
    <Box
      position="relative"
      h={["1200px", "1200px", "1400px"]}
      minH="fit-content"
      w="100%"
      mt="-1px"
    >
      <Box
        bg={
          "linear-gradient(180deg, #a6e0ff 15%, #75c5ee 30%, #75c5ee 40%, #bce4f7 70%, #bce4f7 80%, #9dd3f1 90%, #8fbad1 100%)"
        }
        position="absolute"
        top="0"
        left="0"
        h="100%"
        w="100%"
        objectFit="cover"
        zIndex={-1}
      />
      <ResponsiveContainer
        width={["100%", "100%", "600px", "1000px", "1200px", "1200px"]}
        display="flex"
        flexDirection="column"
        position="relative"
      >
        <Heading
          color="#363636"
          textAlign="center"
          mt="2rem"
          fontSize={["30px", "32px", "38px", "50px", "60px"]}
        >
          Living Characters
        </Heading>
        <Text
          color="#363636"
          textAlign="center"
          fontSize={["17px", "17px", "17px", "30px"]}
        >
          For the first time in history, we are able to give characters a
          ‘brain’ with our AI. Kids can interact with their characters, talk to
          them, ask questions and use them as a tool to explore the world or
          create together through natural like conversations.
        </Text>
        <Box position="relative" m="2rem auto 0 auto">
          <Button3d
            display={["none", "inline-flex"]}
            size="lg"
            backgroundColor="#FBF6F3"
            color="secondary.500"
            bg3dColor="#9C9998"
            onClick={onClickMission}
          >
            Our mission
          </Button3d>
          <Image
            display={["none", "block"]}
            src={stars}
            position="absolute"
            right="-1rem"
            top="50%"
            transform="translate(100%, -50%)"
          />
        </Box>
        <Image
          src={charactersDesk}
          mt="2rem"
          w="800px"
          maxW="100%"
          mx="auto"
          display={["none", "block"]}
        />
        <Box mx="-1rem" zIndex={1} pointerEvents="none">
          <Image
            src={charactersMobile}
            mt="2rem"
            w="100%"
            mx="auto"
            display={["block", "none"]}
          />
        </Box>
        <Button3d
          display={["inline-flex", "none"]}
          size="lg"
          backgroundColor="#FBF6F3"
          color="secondary.500"
          bg3dColor="#9C9998"
          onClick={onClickMission}
          mt="-50px"
        >
          Our mission
        </Button3d>
        <Flex
          flexDir={[
            "column-reverse",
            "column-reverse",
            "column-reverse",
            "row",
          ]}
          mt="auto"
          justifyContent="center"
          columnGap="1rem"
        >
          <Heading
            textAlign="center"
            fontSize={["30px", "32px", "38px", "50px", "60px"]}
          >
            AI for good
          </Heading>
          <Heading
            color="#252525"
            textAlign="center"
            fontSize={["30px", "32px", "38px", "50px", "60px"]}
          >
            ❤️
          </Heading>
        </Flex>
        <Text
          color="#252525"
          textAlign="center"
          fontSize={["17px", "17px", "17px", "30px"]}
        >
          We’re here to make kids believe in the power of dreaming and
          visualizing their ideas into tangible form. We think this is what is
          going to turn them into change makers, earth savers, inventors,
          believers. Believing in your dreams fosters a culture of possibility.
          We harness AI as a tool to generate dreams into shape and to share
          knowledge. We built the first ever safe and audiovisual ChatGPT for
          kids. Children’s safety and privacy is our first priority. Click below
          to learn more about our safety and privacy protocols.
        </Text>
        <Button
          variant="link"
          mb="auto"
          color="secondary.500"
          textDecoration="underline"
          fontWeight="normal"
          fontSize="20px"
          mt="0.5rem"
          onClick={onClickPrivacy}
        >
          Safety by Design
        </Button>
      </ResponsiveContainer>
    </Box>
  )
}
