import { InfoOutlineIcon, SmallCloseIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, Text } from "@chakra-ui/react"

import { LOCAL_STORAGE_CHAT_BOT_MUTED } from "../../components/Creations/GlobalSettings"
import ResponsiveContainer from "../../components/Wrapper/ResponsiveContainer"
import MuteButton from "./MuteButton"

export default function ChatPageHeader({
  showCloseButton,
  onCloseButton,
  onToggleMute,
  muted,
  onOpenLearnMoreModal,
  show,
}: {
  showCloseButton: boolean
  onCloseButton: () => void
  onToggleMute: () => void
  muted: boolean
  onOpenLearnMoreModal: () => void
  show: boolean
}) {
  return (
    <Box
      w="100%"
      zIndex={1}
      position="fixed"
      top="0"
      left="0"
      transition="400ms cubic-bezier(.52,1.07,.74,.98)"
      filter={show ? "opacity(1)" : "opacity(0.5)"}
    >
      <Flex bg="linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0));">
        <ResponsiveContainer
          maxW="800px"
          display="flex"
          position="relative"
          justifyContent="space-between"
        >
          <Box position="relative" my="auto">
            <MuteButton
              onToggle={() => {
                localStorage.setItem(
                  LOCAL_STORAGE_CHAT_BOT_MUTED,
                  (!muted).toString()
                )
                onToggleMute()
              }}
              muted={muted}
              position="absolute"
              right="0"
              transform="translateX(100%)"
              variant="transparent"
            />
            <Button
              variant="transparent"
              onClick={onOpenLearnMoreModal}
              w="40px"
              h="40px"
              p="8px"
            >
              <InfoOutlineIcon w="100%" h="100%" />
            </Button>
          </Box>
          {showCloseButton && (
            <Button
              onClick={onCloseButton}
              w="fit-content"
              h="50px"
              p="16px"
              gap="0.25rem"
              variant="transparent"
            >
              <SmallCloseIcon
                border="solid 2px white"
                borderRadius="full"
                w="auto"
                h="100%"
              />
              <Text fontWeight="bold" fontSize="18px" color="white" m="auto">Close</Text>
            </Button>
          )}
        </ResponsiveContainer>
      </Flex>
    </Box>
  )
}
