import { ButtonProps, Button } from "@chakra-ui/react"
import { signInWithGoogle } from "../../../data/apis/firebase/Firebase"
import GoogleIcon from "../Icons/GoogleIcon"
import { useSegment } from "../../../context/SegmentContext"
import { useEffect } from "react"
import { useUserContext } from "../../../context/userContext"
import { getAdditionalUserInfo, UserCredential } from "firebase/auth"

export default function GoogleSSOButton({
  onLogin,
  ...props
}: { onLogin?: () => void } & ButtonProps) {
  const { track } = useSegment()
  const { user } = useUserContext()

  useEffect(() => {
    if (user && onLogin) {
      onLogin()
    }
  }, [user, onLogin])

  return (
    <Button
      variant="white"
      onClick={() => {
        signInWithGoogle().then((userCredential: UserCredential) => {
          const { user } = userCredential
          if (getAdditionalUserInfo(userCredential)?.isNewUser) {
            track("Sign Up Part 1", {
              email: user.email,
              displayName: user.displayName,
              type: "SSO",
            })
          } else {
            track("Login", {
              email: user.email,
              displayName: user.displayName,
              type: "SSO",
            })
          }
        })
      }}
      color="black"
      {...props}
    >
      <GoogleIcon mr="2rem" />
      Login with Google
    </Button>
  )
}
