import { Flex, Text } from "@chakra-ui/layout"
import { Spinner } from "@chakra-ui/react"
import { Webtoon } from "../../../../data/models/Webtoon"

export default function WebtoonGeneratingElement({
  webtoon,
}: {
  webtoon: Webtoon
}) {
  return (
    <Flex
      w="150px"
      minW="150px"
      h="220px"
      minH="150px"
      borderRadius="10px"
      bg="#fee"
      pos="relative"
      flexDir="column"
      justifyContent="space-evenly"
      key={webtoon.id}
    >
      <Spinner mx="auto" w="50px" h="50px" color="primary.500" />
      <Text size="sm" color="primary.500" px="0.5rem">
        Generating...
        <br />
      </Text>
      <Text size="xs" color="primary.500" px="0.5rem">
        Your story will be ready in a few minutes ☺️
      </Text>
    </Flex>
  )
}
