import { Icon } from "@chakra-ui/react"

const BackIcon: typeof Icon = (props) => (
  <Icon viewBox="0 0 15 28" {...props} fill="none">
    <path
      d="M13 26L2.89503 16.1212C1.70166 14.9545 1.70166 13.0455 2.89503 11.8788L13 2"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)

export default BackIcon
