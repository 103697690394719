import { CloseIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, IconButton } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import Button3d from "../Buttons/Button3d"

export type BurgerMenuItem = {
  label: string
  link: string
}

const LINKS: BurgerMenuItem[] = [
  {
    label: "Home",
    link: "/",
  },
  {
    label: "Our Mission",
    link: "/mission",
  },
  {
    label: "Safety",
    link: "/privacy",
  },
]

export default function BurgerMenu({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) {
  const navigate = useNavigate()
  return (
    <>
      <Box
        position="fixed"
        top="0"
        left="0"
        width="100%"
        height="100%"
        bg="rgba(0,0,0,0.3)"
        zIndex={10}
        transition="400ms"
        opacity={open ? 1 : 0}
        pointerEvents={open ? "all" : "none"}
        onClick={onClose}
        cursor="pointer"
      ></Box>
      <Flex
        position="fixed"
        zIndex={11}
        width="80%"
        maxW="400px"
        h="100%"
        top="0"
        right="0"
        bg="#ffffffcc"
        backdropFilter="blur(4px)"
        transform={open ? "translateX(0)" : "translateX(100%)"}
        transition="400ms"
        flexDir="column"
        p="1rem"
        justifyContent="right"
      >
        <IconButton
          aria-label="close"
          ml="auto"
          icon={<CloseIcon />}
          variant="ghostBlack"
          onClick={onClose}
        />
        <Flex w="100%" flexDir="column">
          {LINKS.map((link) => (
            <Button
              variant="ghostBlack"
              px="10px"
              key={link.label}
              ml="auto"
              onClick={() => navigate(link.link, { preventScrollReset: false })}
            >
              {link.label}
            </Button>
          ))}
        </Flex>
        <Button3d mt="auto" onClick={() => navigate("/sign-up")}>
          Try our app!
        </Button3d>
      </Flex>
    </>
  )
}
