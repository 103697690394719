import { BoxProps, Button, ButtonProps } from "@chakra-ui/react"
import BrushIcon from "../Icons/BrushIcon"
import HintIcon from "../Icons/HintIcon"
import PencilIcon from "../Icons/PencilIcon"
import StarIcon from "../Icons/StarIcon"
import TextIcon from "../Icons/TextIcon"
import UploadIcon from "../Icons/UploadIcon"

export default function ActionButton({
  buttonType,
  iconSize = "32px",
  ...p
}: {
  iconSize?: BoxProps["height"]
  buttonType: "create" | "magic" | "hint" | "upload" | "draw" | "describe"
} & ButtonProps) {
  let props: ButtonProps = { ...p }

  switch (buttonType) {
    case "create":
      props.children = <BrushIcon width={iconSize} height={iconSize} />
      props.bg = "linear-gradient(213.5deg, #34A96A 9.25%, #A3F586 96.41%);"
      break
    case "magic":
      props.children = <StarIcon width={iconSize} height={iconSize} />
      props.bg = "linear-gradient(213.5deg, #FF4264 9.25%, #F97F94 96.41%);"
      break
    case "hint":
      props.children = <HintIcon width={iconSize} height={iconSize} />
      props.bg = "linear-gradient(213.5deg, #5548F2 9.25%, #8E86F5 96.41%);"
      break
    case "upload":
      props.children = <UploadIcon width={iconSize} height={iconSize} />
      props.bg =
        "linear-gradient(158.96deg, #5548F2 6.45%, rgba(72, 171, 242, 0.91) 86.11%)"
      break
    case "draw":
      props.children = <PencilIcon width={iconSize} height={iconSize} />
      props.bg = "linear-gradient(158.96deg, #9A54F2 6.45%, #D049FF 86.11%)"
      break
    case "describe":
      props.children = <TextIcon width={iconSize} height={iconSize} />
      props.bg = "linear-gradient(158.96deg, #FF4263 6.45%, #FB96A8 86.11%)"
      break
  }

  return (
    <Button
      color="white"
      variant="unstyled"
      p="12px"
      height="fit-content"
      w="fit-content"
      filter="drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.25)) brightness(1)"
      transition="200ms"
      _hover={{
        filter: "drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.25)) brightness(1.05)",
      }}
      _active={{
        filter: "drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.25)) brightness(1.15)",
      }}
      borderRadius="full"
      {...props}
    />
  )
}
